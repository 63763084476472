<template>
    <base-modal
        :title="`${approvedType(true)} approved!`"
        :visible="visible"
        @close="$emit('close')"
    >
        <div class="flex flex-col gap-y-4">

            <div class="pb-4 border-b">
            
                <div class="text-h6 text-purple-m-secondary mb-2">
                    You can send a notification to the creator to let them know that the {{approvedType()}} was approved.
                </div>

                <base-button 
                    @action="sendNotification()"
                    :disabled="loading || notificationSent"
                    :label="notificationSent ? 'Notification sent' : 'Send notification'"
                    size="lg"
                />

            </div>

            <div class="text-h6 text-purple-m-secondary">
                Or can also send an email to the creator to notify them, the email will be sent to the following address:
            </div>
            
            <FormulateInput
                v-model="email"
                type="text"
                label="Creator Email"
                :disabled="true"
            />

            <div class="text-h6 text-purple-m-secondary">
                Email message
            </div>

            <FormulateInput
                type="textarea"
                class="w-full"
                placeholder="Write email message..."
                v-model="message"
                input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-12"
                :rows="1"
            />

            <div class="pt-4 flex justify-between">
                <base-button 
                    @action="$emit('close')" 
                    type="secondary" 
                    label="Cancel"
                />
                <base-button 
                    @action="sendEmail()" 
                    :label="emailSent ? 'Email sent' : 'Send email'"
                    :disabled="loading || emailSent"
                />
            </div>
    
        </div>
    </base-modal>
</template>

<script>
import { notifyCatchError } from '../../common';
export default {
    props: ['visible', 'version', 'creator', 'deliverableName'],
    data() {
        return {
            email: this.creator.email,

            notificationSent: false,

            emailSent: false,
            message: `Your concept was approved !`,

            loading: false
        }
    },
    methods: {
        async sendEmail(){
            this.loading = true
            this.$http.post(`/api/userDeliverableVersions/approved-email`, {
                email: this.email,
                message: this.message,
                type: this.approvedType(),
                url: window.location.href,
                deliverableName: this.deliverableName
            }).then(() => {
                this.$notify({ title: 'Success', text: 'Email sent successfully', type: 'success' });
                this.emailSent = true
            }).catch(err => {
                notifyCatchError(err, this.$notify, 'The email could not be sent. Try again later')
            }).finally(() => {
                this.loading = false
            });
        },
        async sendNotification(){
            this.loading = true
            this.$http.post(`/api/userDeliverableVersions/approved-notification`, {
                userId: this.creator.id,
                versionId: this.version.id,
                versionType: this.approvedType(),
                deliverableName: this.deliverableName,
                relativeUrl: this.$route.fullPath
            }).then(() => {
                this.$notify({ title: 'Success', text: 'Notification sent successfully', type: 'success' });
                this.notificationSent = true
            }).catch(err => {
                notifyCatchError(err, this.$notify, 'The notification could not be sent. Try again later')
            }).finally(() => {
                this.loading = false
            });
        },
        approvedType(uppercase = false){
            const type = this.version ? this.version.type ? this.version.type : null : null
            if(!type) return ''
            let str = type == 1 ? 'concept' : type == 2 ? 'draft' : 'concept'  
            if(uppercase) str = str.charAt(0).toUpperCase() + str.slice(1);
            return str
        },
        checkAllSent(){
            if(this.emailSent && this.notificationSent){
                this.$emit('close')
            }
        }
    },
    watch: {
        emailSent(/*val*/){
            this.checkAllSent()
        },
        notificationSent(/*val*/){
            this.checkAllSent()
        },
        visible: function (newVal, oldVal) {
            if(newVal != oldVal){
                this.message = `Your ${this.version.type == 1 ? 'concept' : this.version.type == 2 ? 'draft' : 'concept'} was approved !`        
                // reset values on close modal
                if(newVal == false){
                    this.emailSent = false
                    this.notificationSent = false
                } 
            }   
        }
    },
}
</script>