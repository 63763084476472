<template>
    <base-modal  
        :title="computedTitle"
        :visible="visible"
        title-styles="padding-left: 1.75rem;padding-right: 1.75rem;"
        @close="close()"
        no-padding
    >
        <!-- validate base info -->
        <div 
            class="empty-state" 
            v-if="!isValidObject(user)"
        >
            <div>Creator not found</div>
        </div>
        <div 
            class="empty-state" 
            v-else-if="isValidString(user.email) && !user.id"
        >
            <div>Unable to get creator identifiers</div>
        </div>
        <!-- content -->
        <template v-else>
            <div class="send-invite-modal-container">
                <div class="header">
                    <div class="user">
                        <avatar :user="user" />
                        <div class="name">
                            {{ user.first_name }} {{ user.last_name }}
                        </div>
                    </div>
                    <base-icon name="link" />
                    <div
                        class="network" 
                        v-if="isValidString(networkSlug)"
                    >
                        <base-icon :size="10" :name="networkSlug" />
                        {{ networkLabel }}
                    </div>
                </div>

                <div
                    v-if="step == 'invite-creation'"
                    class="content"
                >
                    <div>An invite link will be created, you can copy it or send it by email to the creator to login in on the platform with {{ networkLabel }}</div>
                </div>

                <div 
                    v-if="step == 'invite-send'"
                    class="content"
                >
                    <div class="copy-link">
                        <FormulateInput 
                            :value="computedLink" 
                            class="w-full"
                            :disabled="creatingInvite || sendingInvite"
                            readonly
                        />
                        <base-button 
                            label="Copy to clipboard"
                            :disabled="creatingInvite || sendingInvite || token == ''"
                            @action="copyToClipboard()"
                            size="xl"
                            icon="clipboard-copy"
                            bold
                        />
                    </div>

                    <FormulateInput
                        label="Invite with email"
                        v-if="user"
                        v-model="email"
                        :disabled="creatingInvite || sendingInvite"
                        class="w-full mt-2"
                        placeholder="example@mail.com"
                        validation="email"
                        :readonly="isValidString(user.email)"
                    />

                    <FormulateInput
                        placeholder="Enter message..."
                        type="textarea"
                        v-model="emailMessage"
                        :disabled="creatingInvite || sendingInvite"
                        input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-32"
                        class="w-full mt-2"
                        :rows="1"
                    />

                </div>
            </div>

            <div class="send-invite-modal-footer">
                <base-button
                    label="Cancel" 
                    type="secondary"
                    :disabled="creatingInvite || sendingInvite"
                    @action="close()"   
                    bold
                    size="md" 
                />
                <!-- create invite button -->
                <base-button 
                    label="Continue"
                    v-if="step == 'invite-creation'"
                    :disabled="creatingInvite || sendingInvite"    
                    @action="createInvite()"
                    bold
                    size="md"  
                />
                <!-- send invite button -->
                <base-button 
                    label="Send"
                    v-if="step === 'invite-send'"
                    :disabled="creatingInvite || sendingInvite || (token === '' || email === '')"    
                    @action="sendInvite()"
                    bold
                    size="md"  
                />
            </div>
        </template>
    </base-modal>
</template>
<script>
import { isValidObject, isValidString, notifyCatchError } from '../common';
import { getNetwork } from '../lib/network';
export default {
    name: 'SendInviteModal',
    props: {
        visible: { 
            type: Boolean, 
            default: false
        },
        networkId: {
            type: Number,
            default: 0
        },
        // by default this will be an invitation for creators
        roleId: {
            type: Number,
            required: false,
            default: 13
        },
        user: {
            type: Object,
            default: function () {
                return {};
            }
        },
        useUserId: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            token: '',
            step: 'invite-creation',
            creatingInvite: false,
            sendingInvite: false,
            email: '',
            emailMessage: '',

            isValidString,
            isValidObject
        }
    },
    watch: {
        // reset vars on open
        visible: function (val, old) {
            if (val && val !== old) {
               this.resetVars();
            }
        }
    },
    computed: {
        titleCreatorName(){
            return isValidObject(this.user) && isValidString(`${this.user.first_name} ${this.user.last_name}`) ? `${this.user.first_name} ${this.user.last_name}` : 'this creator';
        },
        computedTitle(){
            if(this.step == 'invite-send'){
                return `Send ${this.networkLabel} login invite to ${this.titleCreatorName}`;
            }else{
                return `Invite ${this.titleCreatorName} to login with ${this.networkLabel}?`;
            }
        },
        computedLink() {
            return isValidString(this.token) ? `${window.location.origin}/social-login?t=${this.token}` : '';
        },
        networkLabel(){
            if(this.networkId){
                const obj = getNetwork(this.networkId);
                if(isValidObject(obj) && isValidString(obj.display_name)){
                    return obj.display_name;
                }
            }
            return null;
        },
        networkSlug(){
            if(this.networkId){
                const obj = getNetwork(this.networkId);
                if(isValidObject(obj) && isValidString(obj.name)){
                    return obj.name;
                }
            }
            return null;
        }
    },
    methods: {
        createInvite() {
            this.creatingInvite = true;
            this.$http.post('/api/invite/intent', {
                roleId: this.roleId,
                // one of these values (targetEmails, targetId) has to be set
                targetEmails: this.user.email,
                targetId: this.useUserId ? this.user.id : null,
                networkId: this.networkId // nullable
            }).then(async ({ data }) => {
                if(!isValidObject(data) || !isValidString(data.token)){
                    throw new Error("Something went wrong");
                }
                this.step = 'invite-send';
                this.token = data.token;
                if(isValidString(data.target_emails)) this.email = data.target_emails;
                this.$notify({ title: 'Success', text: 'Invite created successfully', type: 'success' });
            }).catch((err) => {
                console.log('createInvite error', err);
                notifyCatchError(err, this.$notify, 'Something went wrong, try again later.');
            }).finally(() => {
                this.creatingInvite = false;
            });
        },
        sendInvite() {
            this.sendingInvite = true;
            this.$http.post('/api/invite/social-login', {
                email: this.email,
                message: this.emailMessage,
                url: this.computedLink,
                networkName: this.networkLabel
            }).then(( { data } ) => {
                if(data){
                    this.$notify({ title: 'Success', text: 'Invite sent successfully', type: 'success' });
                    this.close();
                }
            }).catch((err) => {
                console.log(err);
                this.$notify({ title: 'Failed to send email', text: 'The email was not sent. Try again later.', type: 'warn' });
            }).finally(() => {
                this.sendingInvite = false;
            });
        },
        copyToClipboard(){
            this.$copyToClipboard(this.$notify, this.computedLink);
        },
        resetVars(){
            this.step = 'invite-creation';
            this.token = '';
            this.creatingInvite = false;
            this.sendingInvite = false;
            this.email = '';
            this.emailMessage = '';
        },
        // on close reset vars and emit close
        close(){
            this.resetVars();
            this.$emit('close');
        }
    }
}
</script>
<style lang="scss" scoped>
    .empty-state{
        @apply pt-3 px-14 pb-20;
        @apply text-h5;
        min-height: 250px;        
    }
    .send-invite-modal-container{
        @apply pt-3 px-14 pb-20;
        min-height: 250px;
        > .header{
            @apply flex items-center gap-x-4 pb-4;
            > .user{
                @apply flex items-center gap-x-3;
                > .name{
                    @apply text-h5 font-bold;
                }
            }
            > .network{
                @apply flex items-center gap-x-3 font-bold text-h5;
            }
        }
        > .content{
            @apply text-h5;
            > .copy-link{
                @apply flex justify-between gap-x-5 my-5;
            }
        }
    }
    .send-invite-modal-footer{
        @apply flex justify-between rounded-2xl py-7 px-14;
    }
</style>