import { validateUrl } from "./strings";
import { isValidString } from "../common"; 

// Google Drive Subdomains
const subdomains = [
    'docs.google.com',
    'jamboard.google.com',
    'www.google.com',
    'sites.google.com',
    'drive.google.com'
];

// Google Drive Urls
const urls = [
    {
        // Just a name, not currently using this
        type: "Document",
        // Used to find a url's google drive type 
        keyword: "document",
        // The different kind of urls for this type
        urls: {
            browser: 'https://docs.google.com/document/d/{ google_drive-file_id }/edit',
            shareable: 'https://docs.google.com/document/d/{ google_drive-file_id }/edit?usp=drive_link'
        },
        // Function to get the id from the url, "default" will just get the content between "d/" and "/edit"
        id_getter: 'default'
    },
    {
        // Most regular files will be using these types of URLs
        // Files like: .pdf .csv .json .mp3 .wav .mp4 .mov .png 
        type: "File",
        keyword: "file",
        urls: {
            shareable: "https://drive.google.com/file/d/{ google_drive-file_id }/view?usp=drive_link"
        },
        id_getter: (url) => {
            return url.split('d/')[1].split('/view')[0];
        }
    },
    {
        type: "Drawing",
        keyword: "drawings",
        urls: {
            browser: 'https://docs.google.com/drawings/d/{ google_drive-file_id }/edit',
            shareable: 'https://docs.google.com/drawings/d/{ google_drive-file_id }/edit?usp=drive_link'
        },
        id_getter: 'default'
    },
    {
        type: "Form",
        keyword: "forms",
        urls: {
            browser: 'https://docs.google.com/forms/d/{ google_drive-file_id }/edit'
        },
        id_getter: 'default'
    },
    {
        // these type of urls are also being used by the following file types: "xlsx", "xls"
        type: "Spreadsheet",
        keyword: "spreadsheets",
        urls: {
            browser: "https://docs.google.com/spreadsheets/d/{ google_drive-file_id }/edit#gid=7907638",
            shareable: "https://docs.google.com/spreadsheets/d/{ google_drive-file_id }/edit?usp=drive_link"
        },
        id_getter: 'default'
    },
    {
        type: "Jamboard",
        keyword: "jamboard",
        urls: {
            browser: "https://jamboard.google.com/d/{ google_drive-file_id }/viewer",
            shareable: "https://jamboard.google.com/d/{ google_drive-file_id }/edit?usp=drive_link"
        },
        id_getter: (url) => {
            return url.split('d/')[1].split('/viewer')[0].split('/edit')[0];
        }
    },
    {
        type: "Map",
        keyword: "maps",
        urls: {
            browser: "https://www.google.com/maps/d/u/0/edit?mid={ google_drive-file_id }",
            shareable: "https://www.google.com/maps/d/edit?mid={ google_drive-file_id }&usp=drive_link"
        },
        id_getter: (url) => {
            return url.split('mid=')[1].split('&')[0];
        }
    },
    {
        type: "Presentation",
        keyword: "presentation",
        urls: {
            browser: "https://docs.google.com/presentation/d/{ google_drive-file_id }",
            shareable: "https://docs.google.com/presentation/d/{ google_drive-file_id }/edit?usp=drive_link"
        },
        id_getter: 'default'
    },
    {
        type: "Site",
        keyword: "sites",
        urls: {
            browser: "https://sites.google.com/d/{ google_drive-file_id }/edit"
        },
        id_getter: (url) => {
            return url.split('p/')[1].split('/edit')[0];
        }
    }
];

const defaultIdGetter = (url) => {
    // basically get the content between "d/" and "/edit"
    return url.split('d/')[1].split('/edit')[0];
}

const isGoogleDriveUrl = (url) => {
    if(validateUrl(url)){
        for (const subdomain of subdomains) {
            if(url.includes(subdomain) && url.includes('/d/')){
                return true;
            }
        }
    }
    return false;
}

const findUrlObject = (url) => {
    if(isGoogleDriveUrl(url)){
        for (const _url of urls) {
            const { keyword } = _url;
            if(url.includes(keyword)){
                return _url;
            }
        }
    }
    return null;
}

const getIdFromGoogleDriveUrl = (url) => {
    if(isGoogleDriveUrl(url)){

        // remove any query parameters and anchors
        // import { removeQueryParamsAndAnchors } from "./url";
        // url = removeQueryParamsAndAnchors(url);

        const urlObject = findUrlObject(url);     
        let id = null;

        if(urlObject){
            if(urlObject.id_getter == 'default'){
                id = defaultIdGetter(url);
            }else{
                id = urlObject.id_getter(url);
            }
        }
        
        if(isValidString(id)){
            return id;
        }

    }
    return null;
} 

export { isGoogleDriveUrl, getIdFromGoogleDriveUrl }