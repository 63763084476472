import Vue from "vue";
import VueRouter from "vue-router";

import Landing from "../views/Landing";
import ProjectList from "../views/ProjectList";
import Login from "../views/Login";
import Register from "../views/Register";
import RegisterCompany from "../views/Register/Company";
import CompanyRedirect from "../views/Register/CompanyRedirect";
import ForgotPassword from "../views/ForgotPassword";

import ProjectDetail from "../views/ProjectDetail";
import CreatorDetail from "../views/CreatorDetail";

import CreatorEdit from "../views/CreatorEdit";
import ReportView from "../views/ReportView";
import ReportViewV2 from "../views/ReportView/v2";
import ProjectCreateEdit from "../views/ProjectCreateEdit";
import ReportCreate from "../views/ReportCreate";
import ReportImport from "../views/ReportImport";
import PasswordReset from "../views/PasswordReset";
import ReportList from "../views/ReportList";
import FileViewer from "../views/FileViewer";
import ProfileEdit from "../views/ProfileEdit";
import TeamSettings from "../views/TeamSettings";

// import DeliverableCreate from "../views/DeliverableCreate";
// import DeliverableList from "../views/DeliverableList";
import DeliverableListNew from "../views/DeliverableList/indexNew";
import DeliverableDetail from "../views/DeliverableDetail";

import CreatorList from "../views/CreatorList";
import CreatorImport from "../views/CreatorImport";
import AgentList from "../views/AgentList";
import CompanySettings from "../views/CompanySettings";
import NavigationError from "../views/NavigationError";
import CompanyNotFound from "../views/CompanyNotFound";
import VersionCreate from "../views/VersionCreate";
import UserEdit from "../views/UserEdit";

import UserDetail from "../views/UserDetail";
import FbPageRequest from "../views/FbPageRequest";
import Docs from "../docs";
import NotificationList from "../views/NotificationList";

import SocialLogin from "../views/SocialLogin";

import PrivacyPolicy from "../views/PrivacyPolicy";
import TermsOfService from "../views/TermsOfService";
import TikTokAuth from "../views/TikTokAuth";
import DriveAuth from "../views/DriveAuth";
import GoogleAuth from '../views/GoogleAuth';

import IgConnectionWizard from "../views/IgConnectionWizard";

import bookmarkletToken from "../views/bookmarkletToken";

// [recruitment]
// import JobPostCreate from '../views/JobPostCreate';

// import CreatorHome from '../views/CreatorHome';

import store from "../store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "landing",
        component: Landing,
        meta: {
            requiresAuth: false,
            layout: "landing",
        },
    },
    {
        path: "/home",
        name: "Home",
        component: ProjectList,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/register",
        name: "register",
        component: Register,
        meta: {
            layout: "no-sidebar",
            checkGuestToken: true,
        },
    },
    {
        path: "/new-company",
        name: "new-company",
        component: RegisterCompany,
        meta: {
            layout: "no-sidebar",
            checkGuestToken: true,
        },
    },
    {
        path: "/new-company/register",
        name: "new-company-user",
        component: Register,
        meta: {
            layout: "no-sidebar",
            companySignup: true,
            checkGuestToken: true,
        },
    },
    {
        path: "/company-redirect",
        name: "company-redirect",
        component: CompanyRedirect,
        meta: {
            requiresAuth: false,
            layout: "no-sidebar",
        },
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: { layout: "no-sidebar" },
    },
    {
        path: "/social-login",
        name: "social-login",
        component: SocialLogin,
        meta: { layout: "no-sidebar" },
    },
    {
        path: "/forgot-password",
        name: "forgot-password",
        component: ForgotPassword,
        meta: { layout: "no-sidebar" },
    },
    {
        path: "/password-reset",
        name: "password-reset",
        component: PasswordReset,
        meta: { layout: "no-sidebar" },
    },
    {
        path: "/projects/create",
        name: "create-project",
        component: ProjectCreateEdit,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/projects/:projectId/edit",
        name: "edit-project",
        component: ProjectCreateEdit,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/edit-user/:userId",
        name: "edit-user",
        component: UserEdit,
        meta: {
            requiresAuth: true,
            showBackBtn: true,
        },
    },
    {
        path: "/projects/:projectId",
        name: "project-detail",
        component: ProjectDetail,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/projects/:projectId/creator/:userId",
        name: "project-creator-detail",
        component: CreatorDetail,
        meta: {
            requiresAuth: false,
            tokenAccess: true,
            showBackBtn: true,
        },
    },
    {
        path: "/projects/:projectId/creator/:userId/edit",
        name: "project-creator-edit",
        component: CreatorEdit,
        meta: {
            requiresAuth: true,
            showBackBtn: true,
        },
    },
    {
        path: "/creator/:userId",
        name: "creator-detail",
        component: CreatorDetail,
        meta: {
            requiresAuth: true,
            showBackBtn: true,
        },
    },

    {
        path: "/creator/:userId/edit",
        name: "edit-creator",
        component: CreatorEdit,
        meta: {
            requiresAuth: true,
            showBackBtn: true,
        },
    },
    {
        path: "/projects/:projectId/reports/create",
        name: "project-report-create",
        component: ReportCreate,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/reports/import",
        name: "report-import",
        component: ReportImport,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: "/reports/:id/edit",
        name: "report-edit",
        component: ReportCreate,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/reports/:id",
        name: "report-view",
        component: ReportView,
        meta: { layout: "no-sidebar" },
    },
    {
        path: "/reports-v2/:id/edit",
        name: "report-edit-v2",
        component: ReportCreate,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/reports-v2/:id",
        name: "report-view-v2",
        component: ReportViewV2,
        meta: { layout: "no-sidebar" },
    },
    {
        path: "/files/:id",
        name: "file-view",
        component: FileViewer,
        meta: { layout: "no-sidebar" },
    },
    {
        path: "/profile/edit",
        name: "profile-edit",
        component: ProfileEdit,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/team",
        name: "team-settings",
        component: TeamSettings,
        meta: {
            requiresAuth: true,
        },
    },
    /*{
        path: '/deliverables/create',
        name: 'deliverables-create',
        component: DeliverableCreate,
        meta: {
            requiresAuth: true
        }
    },*/
    // {
    //     path: '/deliverables',
    //     name: 'deliverables',
    //     component: DeliverableList,
    //     meta: {
    //         requiresAuth: false,
    //         tokenAccess: true
    //     }
    // },
    {
        path: "/deliverables",
        name: "deliverables",
        component: DeliverableListNew,
        meta: {
            requiresAuth: false,
            tokenAccess: true,
        },
    },
    {
        path: "/deliverables/:id",
        name: "deliverable-detail",
        component: DeliverableDetail,
        meta: {
            requiresAuth: false,
            tokenAccess: true,
        },
    },
    {
        path: "/creators",
        name: "creators",
        component: CreatorList,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/me",
        name: "me",
        component: UserDetail,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/creators/import",
        name: "creators-import",
        component: CreatorImport,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/agents",
        name: "agents",
        component: AgentList,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/company",
        name: "company-settings",
        component: CompanySettings,
        meta: {
            requiresRole: 'isAdminOrHigher',
            requiresAuth: true,
        },
    },
    {
        path: "/company-not-found",
        component: CompanyNotFound,
        meta: { layout: "no-sidebar" },
    },
    {
        path: "/collection",
        component: VersionCreate,
        meta: { layout: "no-sidebar" },
    },
    {
        path: "/reports",
        component: ReportList,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/fb-page-request",
        component: FbPageRequest,
        meta: {
            layout: "no-sidebar",
        },
    },
    {
        path: "/docs/:component",
        component: Docs,
        meta: {
            layout: "no-sidebar",
        },
    },
    {
        path: "/docs",
        component: Docs,
        meta: {
            layout: "no-sidebar",
        },
    },
    {
        path: "/notifications",
        component: NotificationList,
    },
    {
        path: "/privacy-policy",
        component: PrivacyPolicy,
        meta: {
            layout: "no-sidebar",
        },
    },
    {
        path: "/terms-of-service",
        component: TermsOfService,
        meta: {
            layout: "no-sidebar",
        },
    },
    {
        path: "/drive/auth",
        component: DriveAuth,
        meta: {
            layout: "no-sidebar",
        },
    },
    {
        path: '/setAccount',
        component: GoogleAuth,
        meta: { 
            layout: "no-sidebar",
        }
    },
    {
        path: "/tiktok/auth",
        component: TikTokAuth,
        meta: {
            layout: "no-sidebar",
        },
    },
    {
        path: "/ig-connection-wizard",
        component: IgConnectionWizard,
        meta: {
            requiresAuth: false,
            layout: "no-sidebar",
        },
    },
    {
        path: "/bookmarkletToken/",
        component: bookmarkletToken,
        meta: {
            requiresAuth: false,
            layout: "no-sidebar",
        },
    },
    // [recruitment]
    // {
    //     path: '/projects/:projectId/job-post/create',
    //     name: 'job-post-create',
    //     component: JobPostCreate,
    //     meta: {
    //         requiresAuth: true
    //     }
    // },
    {
        path: "*",
        component: NavigationError,
    },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => {
        if (!["NavigationDuplicated"].includes(err.name)) {
            throw err;
        }
    });
};

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    let errors = 0;
    let redirect = "/login";

    // requiresRole
    if (to.matched.some((record) => record.meta.requiresRole)) {
        let found = to.matched.find((element) => element.meta.requiresRole);
        
        // single required role
        if (typeof found.meta.requiresRole === "string") {
            // checks for role getters in store/index.js
            if (!store.getters[found.meta.requiresRole]) {
                errors++;
                redirect = `/navigation-error?code=403&role=${found.meta.requiresRole}`;
            }
        }

        // multiple required roles
        if (Array.isArray(found.meta.requiresRole)) {
            // has at least 1 required role
            let hasRole = false;
            for (const role of found.meta.requiresRole) {
                // checks for role getters in store/index.js
                if (store.getters[role]) {
                    hasRole = true;
                }
            }
            if (hasRole == false) {
                errors++;
                redirect = `/navigation-error?code=403&role=${found.meta.requiresRole.join(',')}`;
            }
        }
    }

    // requiresAuth
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!store.getters.isLoggedIn) {
            errors++;
            redirect = "/login";
        }
    }

    if (!errors) {
        return next();
    } else {
        return next(redirect);
    }
});

export default router;
