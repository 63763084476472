<template>
<div>

    <Title type="main">Base Button</Title>

    <Title>Usage</Title>
    <Code title="Simple use" :code='`
        <base-button
            label="My button"
            icon="arrow-up"
            @action="doSomething"
        />
    `'/>

    <Title>Main Props</Title>
    <Table
        title="The component accepts the following props:"
        :columns="['Name', 'Type', 'Default', 'Description']"
        :data="[
            ['type', 'String', 'main', 'Type of the button, options are: { main, secondary, label }'],
            ['size', 'String', 'md', 'Size of the button, options are: { xs, sm, md, lg, xl, label }'],
            ['label', 'String', '-', 'Label to be displayed on the button'],
            ['disabled', 'Boolean', 'false', 'Disables the button, and its actions']
        ]"
    />

    <Title>Icon Props</Title>
    <Table
        title="Props for the button icon:"
        :columns="['Name', 'Type', 'Default', 'Description']"
        :data="[
            ['icon', 'String', '-', 'Name of the icon to be displayed at the left of the button'],
            ['secondaryIcon', 'String', '-', 'Name of the icon to be displayed at the right of the button'],
            ['iconColor', 'String', '-', 'Color for the icon'],
            ['iconSize', 'Number', '5', 'Size for the icon (applies to main & secondary icon)'],
            ['iconFill', 'Boolean', 'true', 'Fill for the icon (applies to main & secondary icon)']
        ]"
    />

    <Title>Style Props</Title>
    <Table
        title="Props for styling:"
        :columns="['Name', 'Type', 'Default', 'Description']"
        :data="[
            ['justify', 'String', 'center', 'Justify the contents of the button. Accepts tailwinds justify-... classes'],
            ['rounded', 'String', 'full', 'How rounded the button will be. Accepts tailwinds rounded-... classes'],
            ['theme', 'String', 'light', 'Changes buttons theme. Accepts { light, dark, cancel, neutral-dark }'],
            ['border', 'Boolean', 'true', 'Whether the button will have a border or not'],
            ['outline', 'Boolean', 'true', 'Whether the button will have an outline or not'],
            ['bold', 'Boolean', 'false', 'Makes the label text bold'],
            ['padding', 'String', 'px-4 py-4', 'Sets the padding of the button. Accepts tailwind classes']
        ]"
    />

    <Title>Additional Props</Title>
    <Table
        title="The component accepts the following additional props:"
        :columns="['Name', 'Type', 'Default', 'Description']"
        :data="[
            ['img', 'String', '-', 'Url of an image that will be rendered at the left (instead of the left icon)'],
            ['isWhite', 'Boolean', 'false', 'Forces white text on the button'],
            ['displays', 'String', 'flex', 'Changes the display: {} css style property. Accepts flex, block, inline, etc.'],
            ['hover', 'Boolean', 'true', 'Forces hover state'],
            ['link', 'String', `''`, 'External link to be redirected to when the button is clicked'],
            ['active', 'Boolean', 'false', 'Forces active state (same as hover)'],
            ['submit', 'Boolean', 'false', 'Sets this button as a submit button (required for FormulateForm submit buttons)']
        ]"
    />

</div>
</template>
<script>
import Code from '../Code.vue'
import Table from '../Table.vue'
import Title from '../Title.vue'
export default {
    components: {
        Code,
        Table,
        Title
    }
}
</script>