import axios from 'axios';
import { isValidArray } from '../common';

export const colors = {
    namespaced: true,
    state: {
        colors: [],
        colorsLoading: false
    },
    mutations: {
        setColors(state, val) {
            state.colors = val;
        },
        setColorsLoading(state, val){
            state.colorsLoading = val;
        },
    },
    actions: {
        async getColors({commit, state}){

            try{
                
                // if the "colors" array already has elements, don't request the colors again
                if (isValidArray(state.colors)) {
                    return;
                }

                await commit('setColorsLoading', true);

                const { data } = await axios.get('/api/statuses/colors');
                
                if(isValidArray(data)){
                    await commit('setColors', data);
                }
                
            }catch(err){
                console.log('getColors error', err);
            }finally{
                await commit('setColorsLoading', false);
            }

        }
    }
};