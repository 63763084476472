<template>    
    <!-- Only seems to be used for project statuses and boards (deprecated) -->
    <!-- So this component is only used in: -->
    <!-- ProjectList -> BaseTable.vue as 'status' -->
    <!-- PinnedProjectsList.vue -->
    <!-- TODO color picker hover state -->
    <!-- TODO "create status" button and "back" button transitions on hover state -->
    <!-- TODO Tooltips for icon-only buttons -->
    <!-- TODO getValueColor ? is being checked as a string ? -->
    <!-- TODO "theme" is this being used ? -->
    <div class="crud-dropdown-wrapper">
      
      <!-- button -->
      <base-button 
        type="label"
        @action="toggleDropdownVisible(!dropdownVisible)"  
        rounded="full"
        :disabled="disabled || loading"
        :theme="getValueColor ? (hexToRgbSum(getValueColor) > themeThreshold ? 'light' : 'dark') : theme"
        :hover="getValueColor ? false : true"
        :class="[
          'main-button',
          theme,
          { 'dropdown-visible': dropdownVisible },
          { 'disabled': disabled }
        ]"
        :style="getValueColor ? `background-color: ${getValueColor}` : ''"
      >
        <div class="main-button-content">
          <div class="main-button-label">{{ getValueName }}</div>
          <base-icon 
            name="chevron-down"
            v-if="!disabled" 
            class="ml-auto" 
          />
        </div>
      </base-button>

      <!-- dropdown wrapper -->
      <div 
        v-if="dropdownVisible"
        :class="[
          'dropdown-wrapper',
          theme
        ]"
      >

        <!-- dropdown container -->
        <div class="dropdown-container">

          <!-- content -->
          <div class="dropdown-content">

            <!-- header with actions -->
            <div  
              class="header"
              @click="(e)=>e.stopPropagation()"
            >

              <!-- back actions -->

              <!-- back button for when we are creating a new status -->
              <base-button
                label="New Status"
                v-if="step == 'create'"
                @action="back()" 
                type="label" 
                icon="arrow-left" 
                rounded="none" 
                theme="neutral"   
                :disabled="loading"           
              />
              
              <!-- back button for when we are editing a new status -->
              <base-button 
                label="Edit Status"
                v-else-if="step == 'edit'" 
                @action="back()" 
                type="label" 
                icon="arrow-left" 
                rounded="none" 
                theme="neutral"    
                :disabled="loading"          
              />
              
              <!-- Main header title -->
              <span v-else class="title">Statuses</span>
              
              <!-- if we are not currently creating nor editing an status, we have the option to CREATE here -->
              <base-button 
                v-if="step == 'default'"
                icon="plus"
                type="label"
                @action="step = 'create'"
                :disabled="loading"
              />

            </div>
            
            <!-- statuses list (default option) -->
            <div 
              class="status-list"
              v-if="step == 'default'"
            >
              <!-- empty state -->
              <div 
                class="empty-state"
                v-if="!isValidArray(statuses)"
              >
                There are no statuses available.
              </div>

              <!-- list -->
              <template v-else>
                <div 
                  class="status-item"
                  v-for="(status, i) in statuses" 
                  :key="i" 
                  @mouseenter="status.showActions = true" 
                  @mouseleave="status.showActions = false"
                >

                  <div class="left button-container">
                    <base-button 
                      v-if="!status.editing"
                      @action="selectStatus(status)" 
                      type="label" 
                      rounded="lg" 
                      justify="start"
                      :theme="status.color ? (hexToRgbSum(status.color.name) > themeThreshold ? 'light' : 'dark') : theme"
                      :hover="status.color ? false : true"
                      :disabled="loading"
                      :class="[
                        'status-button',
                        { 'has-color': status.color }
                      ]"
                      :style="status.color ? `background-color: ${status.color.name}` : ''"
                      :label="status.name"
                    />
                  </div>

                  <div 
                    class="right actions" 
                    @click="(e)=>e.stopPropagation()"
                  >
                    <base-button 
                      v-if="!status.editing" 
                      @action="editStatusIntent(status)" 
                      :disabled="step == 'edit' || step == 'create' || status.is_default || loading"
                      v-tooltip="status.is_default ? { content: `Default status`, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } } : {}"
                      :theme="status.is_default ? 'light' : 'neutral'"
                      icon="pencil"
                      type="label"
                      rounded="full"                
                      :class="['w-full h-full']"
                    />                
                  </div>

                </div>
                
              </template>
            </div>

            <!-- create status step wrapper -->
            <div 
              v-if="step == 'create'" 
              class="create-status-step-wrapper"
            >
              <!-- create status step container -->
              <div
                :class="[ 
                  'create-status-step-container',
                  theme
                ]"
              >
                <!-- input -->
                <input 
                  placeholder="Label"
                  v-model="createStatusLabel" 
                  :disabled="loading"
                  type="text" 
                  :class="[
                    'label-input',
                    theme
                  ]"
                />

                <!-- color list -->
                <base-icon 
                  v-if="colorsLoading" 
                  name="loader"
                />
                <template>
                  <div class="empty-state" v-if="!isValidArray(colors)">
                    No colors found
                  </div>
                  <div 
                    class="color-grid" 
                    v-else
                  >
                    <span
                      v-for="(color, i) in colors" 
                      :key="i"
                      @click.prevent="createStatusColor = color"
                      class="color-item" 
                      :style="`background-color: ${color.name}`"
                    >
                        <base-icon 
                          name="check"
                          v-if="createStatusColor === color"
                          class="color-icon"
                          :class="`text-${(hexToRgbSum(color.name) > themeThreshold ? 'black' : 'white')}`" 
                        />
                    </span>
                  </div>
                </template>

              </div>
            </div>  
            
            <!-- edit status step -->
            <div 
              class="edit-status-step-wrapper"
              v-if="step == 'edit'" 
            >
              <!-- edit status step container -->
              <div 
                :class="[
                  'edit-status-step-container',
                  theme
                ]"
              >
                <!-- input -->
                <input 
                  placeholder="Label"
                  v-model="statuses[editableStatusIndex].editName" 
                  type="text" 
                  class="label-input"
                  :disabled="loading"
                />
                
                <!-- color list -->
                <base-icon 
                  v-if="colorsLoading" 
                  name="loader"
                />
                <template v-else>
                  <div class="empty-state" v-if="!isValidArray(colors)">
                    No colors found
                  </div>
                  <div 
                    class="color-grid"
                    v-else
                  >
                    <span
                      v-for="(color, i) in colors" 
                      :key="i"
                      @click.prevent="statuses[editableStatusIndex].editColor = color"
                      class="color-item" 
                      :style="`background-color: ${color.name}`"
                    >
                      <base-icon 
                        name="check"
                        v-if="statuses[editableStatusIndex].editColor && statuses[editableStatusIndex].editColor.id === color.id" 
                        class="color-icon"
                        :class="`text-${(hexToRgbSum(color.name) > themeThreshold ? 'black' : 'white')}`" 
                      />
                    </span>
                  </div>
                </template>
              </div>              
            </div>

          </div>
          
          <!-- actions footer only for when we are creating or editing an status -->
          <div 
            class="actions-footer"
            @click="(e)=>e.stopPropagation()"
          >
          
            <!-- create status actions -->
            <div
              class="create-status-actions" 
              v-if="step == 'create'"
            >
              <base-button 
                label="Create"
                @action="createStatus()" 
                type="label"
                rounded="lg" 
                :disabled="loading"
                theme="neutral-dark"                
                :class="[
                  'create-status-button',
                  theme
                ]"
              />
            </div>
            
            <!-- editing status actions -->
            <div 
              class="edit-status-actions"
              v-if="step == 'edit'"
            >                
              
              <!-- TODO this should be a CANCEL/BACK button and the DELETE button should be at the right side of the input -->
              <base-button 
                label="Delete"
                @action="deleteStatusIntent(statuses[editableStatusIndex])"  
                rounded="lg" 
                theme="danger"
                :disabled="loading"
                icon="trash"
                :class="[
                  'delete-button',
                  theme
                ]"
              />

              <base-button 
                label="Save"
                @action="editStatus()"  
                rounded="lg"
                icon="pencil"    
                :disabled="loading"         
                :class="[
                  'save-button',
                  theme
                ]"
              />
            </div>
          </div>

        </div>      
      </div>      
    </div>    
</template>

<script>
import { isValidArray, isValidObject, isValidString, notifyCatchError } from '../common';
import { mapGetters } from 'vuex';
export default {
  props:{
    create: {
      type: Object
    },
    select: {
      type: Object
    },
    edit: {
      type: Object
    },
    delete: {
      type: Object
    },
    restore: {
      type: Object,
      default: function () {
        return null;
      }
    },
    statuses: {
      type: Array,
      required: false,
      default: function () {
        return [];
      }
    },
    value: {
        type: Object,        
        default: function () {
          return {
            name: 'Status'
          }
        }
    },
    theme: {
      type: String,
      default: 'light'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {

      // main
      dropdownVisible: false,
      step: "default",
      
      // creating status
      createStatusLabel: "",
      createStatusColor: null,
      
      // editing status
      editableStatusIndex: null,

      // loading
      loading: false,

      // other
      themeThreshold: 400, // 0 is black, 765 is white.
      colorMod: -51, // 20% darker.
      
      // imports
      isValidArray
    };
  },

  computed: {
    ...mapGetters(['colors', 'colorsLoading']),

    getValueName() {
      if(!this.value.id)
        return this.value.name;
      else{
        const found = this.statuses.find(el => el.id === this.value.id);        
        return found ? found.name : this.value.name;
      }
    },

    getValueColor() {
      if(this.value.id){
        const found = this.statuses.find(el => el.id === this.value.id);        
        return (found && found.color) ? found.color.name : '';      
      }else{
        return '';
      }
    }
  },

  mounted() {
    document.addEventListener('click', this.close);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.close)
  },

  methods: {
    
    // ---------
    // Dropdown
    // ---------

    toggleDropdownVisible(val) {
      this.dropdownVisible = val;
      this.$emit('toggle-dropdown', val);
    },

    // ----------
    // Selecting
    // ----------

    async selectDefault() {
      this.dropdownVisible = false;
      this.$emit('default');
    },

    async selectStatus(status) {
      if(this.select) {
        let statusId = status.id; 
        this.loading = true;
        this.$http.post(this.select.route, { 
          optionId: statusId 
        }).then(({ data }) => {
          if (data) {
            this.dropdownVisible = false;
            this.$notify({ title: 'Success', text: this.select.success, type: 'success' })
            this.$emit('done');
          }
        }).catch(err => {
          notifyCatchError(this.$notify, err);
        }).finally(() => {
          this.loading = false;
        });
      } else {
        this.dropdownVisible = false;
        this.$emit('select', status);
      }
    },

    // ---------
    // Creating
    // ---------

    async createStatus() {
      let name = this.createStatusLabel;      

      name = name.trim();

      if(isValidString(name)) {
          // Build request body
          let body = {};
          if(isValidObject(this.createStatusColor)){
            body = { 
              name: name, 
              color_id: this.createStatusColor.id 
            };
          }else{
            body = { 
              name: name
            }
          }
          // Execute request
          this.loading = true;
          this.$http.post(this.create.route, body).then((/*response*/) => {
              this.$notify({ title: 'Success', text: this.create.success, type: 'success' })
          }).catch((e) => {
              console.log(e);
              this.$notify({ title: 'Error', text: this.create.error, type: 'error' })
          }).finally(() => {     
              this.loading = false;
              // TODO check that this is actually refreshing things
              this.$emit('done');
          });
      } else {
          this.$notify({ title: 'Warning', text: this.create.warning, type: 'warn' })
      }

      this.back();
    },

    // --------
    // Editing
    // --------

    editStatusIntent(status) {
      status.editing = true;
      this.editableStatusIndex = this.statuses.indexOf(status);
      this.step = 'edit';
    },

    async editStatus() {
      const status = this.statuses[this.editableStatusIndex];
      if(!status || status.editName === ''){
        return this.$notify({ title: 'Warning', text: this.edit.warning, type: 'warn' });
      }
      try {
          this.loading = true;
          let body = {};
          if(status.editColor){
            body = { name: status.editName, color_id: status.editColor.id };
          }else{
            body = { name: status.editName };
          }
          const { data } = await this.$http.put(this.edit.route.concat(status.id), body);
          if (data) {
              this.$notify({ title: 'Success', text: this.edit.success, type: 'success' })
              if(this.edit.overrideEmit)
                this.$emit('edit', status);
              else
                this.$emit('done');
          }
      } catch (err) {
          console.log(err);
          this.$notify({ title: 'Error', text: this.edit.error, type: 'error' });
      } finally {
        this.loading = false;
        this.back();
      }
    },

    // ---------
    // Deleting
    // ---------

    deleteStatusIntent(status) {
      this.$swal.fire({
          title: `Are you sure you want to delete the '${status.name}' ${this.delete.deleteLabel}?`,
          text: "You won't be able to revert this!",
          icon: 'warning',
          iconColor: '#0E092C',
          showCancelButton: true,
          confirmButtonText: 'Delete',
          reverseButtons: true,
      }).then((result) => {
          if (result.isConfirmed) {
              this.deleteStatus(status);  
              this.back();            
          }
          else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.back();
          }
      });
    },

    async deleteStatus(status) {
      try {
          this.loading = true;
          const { data } = await this.$http.delete(this.delete.route.concat(status.id));
          if (data) {
            if (data.show_undo) {
              this.$store.dispatch('restorer/set', {
                message: this.delete.success,
                route: this.restore ? this.restore.route.concat(status.id) : null,
                success: this.restore ? this.restore.success : '',
                fail: this.restore ? this.restore.error : '',
                action: true,
                fn: () => {
                  if (this.delete.overrideEmit) {
                    // TODO maybe this should also emit done so everything gets refreshed
                    this.$emit('delete', status);
                  } else {
                    // TODO check that this is actually refreshing things
                    this.$emit('done');
                  }
                }
              });
            } else {
              this.$notify({ title: 'Success', text: this.delete.success, type: 'success' });
              if (this.delete.overrideEmit) {
                // TODO maybe this should also emit done so everything gets refreshed
                this.$emit('delete', status);
              } else {
                  // TODO check that this is actually refreshing things
                  this.$emit('done');
              }
            }
          }
      } catch (err) {
          console.log(err);
          this.$notify({ title: 'Error', text: this.delete.error, type: 'error' });
      } finally {
        this.loading = false;
      }
    },

    // ---------------
    // Color handlers
    // ---------------

    hexToRgbSum(hex) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if(result){
          let r = parseInt(result[1], 16);
          let g = parseInt(result[2], 16);
          let b = parseInt(result[3], 16);          
          return r+g+b;
          // 0 is black, 765 is white.
      } 
      else
        return 0;
    },

    hexToModRgb(hex, mod) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if(result){
          let r = parseInt(result[1], 16);
          let g = parseInt(result[2], 16);
          let b = parseInt(result[3], 16);                    
          return `rgba(${r+mod}, ${g+mod}, ${b+mod})`;
      } 
      else
        return '';
    },

    // ------
    // Other
    // ------

    back() {

      if(this.step == 'create'){
        this.createStatusLabel = '';
        this.createStatusColor = null;
      }

      if(this.step == 'edit'){
        let status = this.statuses[this.editableStatusIndex];
        status.editing = false;
        status.editName = status.name;
        if(status.editColor){
          status.editColor = status.color;
        }

      }

      this.step = 'default';

    },

    close(e) {
      if (!this.$el.contains(e.target) && this.dropdownVisible) {
          this.toggleDropdownVisible(false);
      }
    }

  }
};
</script>
<style lang="scss" scoped>
.crud-dropdown-wrapper {
  @apply relative flex w-full h-full;
  
  > .main-button{

    // MAIN classes
    @apply w-full h-full py-1 pr-3 pl-4 rounded-full;
    // background
    @apply bg-tan-m;
    // border
    @apply border border-transparent;
    // text
    @apply text-pxs;
    // transitions
    @apply transition ease-in-out duration-150;

    // HOVER state
    &:hover{
      @apply opacity-75;
    }

    // FOCUS state
    &:focus{
      @apply outline-none;
    }

    // DISABLED state
    &.disabled{
      @apply bg-transparent;
    }


    // LIGHT THEME
    &.light{
      @apply bg-white;
      &:hover{
        @apply bg-gray-m-light;
      }
      &.dropdown-visible{
        @apply bg-gray-m-light;
      }
    }

    // DARK THEME
    &.dark{
      @apply bg-black;
      &:hover{
        @apply bg-gray-m-dark;
      }
      &.dropdown-visible{
        @apply bg-gray-m-dark;
      }
    }

    // CHILD ELEMENTS
    > .main-button-content{
      @apply w-full h-full flex gap-x-2 justify-start items-center;
      > .main-button-label{
        @apply text-left;
        // .line-clamp-1 :
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

  }

  > .dropdown-wrapper {
    @apply absolute top-full w-72 z-10 rounded-lg shadow-lg;
    @apply right-0;
    max-height: 25em !important;
    overflow-y: auto;
    &.light{
      @apply bg-white;
    }
    &.dark{
      @apply bg-black;
    }

    > .dropdown-container{
      //
      > .dropdown-content{
        @apply flex flex-col gap-y-2 p-3 pb-2;
        
        > .header{
          @apply h-8 flex items-center justify-between;
          > .title{
            @apply text-h6 text-purple-m-secondary;
          }
        }

        > .create-status-step-wrapper{
          //
          .create-status-step-container{
            @apply w-full flex flex-col gap-y-2;

            > input.label-input{
              @apply w-full text-left text-pxs py-3 px-4 rounded-lg;
              @apply border border-gray-m;
              &:focus{
                @apply outline-none;
              }
              &.light{
                @apply text-purple-m-secondary bg-white;
              }
              &.dark{
                @apply text-white bg-black;
              }
            }

            > .color-grid{
              @apply grid grid-cols-4 gap-2;
              > .color-item{
                @apply relative cursor-pointer h-10 rounded-lg border border-transparent;
                > .color-icon{
                  @apply absolute top-2 left-4;
                }
              }
            }
          }
        }

        > .edit-status-step-wrapper{
          //
          .edit-status-step-container{
            @apply w-full flex flex-col gap-y-2;
            > input.label-input{
              @apply w-full text-left text-pxs py-3 px-4 rounded-lg;
              @apply border border-gray-m;
              &:focus{
                @apply outline-none;
              }
              &.light{
                @apply text-purple-m-secondary bg-white;
              }
              &.dark{
                @apply text-white bg-black;
              }
            }
            > .color-grid{
              @apply grid grid-cols-4 gap-2;
              > .color-item{
                @apply relative cursor-pointer h-10 rounded-lg border border-transparent;
                > .color-icon{
                  @apply absolute top-2 left-4;
                }
              }
            }

          }
        }

        // default step: status list
        > .status-list{
          @apply flex flex-col gap-y-2;
          > .status-item{
            @apply flex justify-between items-center gap-x-1;
            > .left.button-container{
              @apply w-5/6 max-w-5/6 overflow-x-hidden;
              > .status-button{
                @apply w-full max-w-full truncate px-4 text-pxs bg-tan-m py-3 border border-transparent;
                &.light{
                  @apply bg-gray-m-light;
                }
                &.dark{
                  @apply bg-gray-m-dark;
                }
                &.has-color{
                  &:hover{
                    @apply opacity-75;
                  }
                }
              }
            }
            > .right.actions{
              @apply h-8 w-1/6 flex justify-center items-center;
            }
          }
        }


      }

      > .empty-state{
        @apply px-6 leading-10 py-4 text-sm text-gray-m-disable cursor-not-allowed text-center;
      }

      > .actions-footer{
        @apply px-3 pb-3 pt-0;

        > .create-status-actions{
          @apply flex justify-between items-center;
          > .create-status-button{
            @apply w-full px-4 py-3 bg-purple-m-main text-pxs;
            &.light{
              &:hover{
                @apply bg-purple-m-secondary;
              }
            }
            &.dark{
              &:hover{
                @apply bg-gray-m-dark;
              }
            }
          }
        }

        > .edit-status-actions{
          @apply flex justify-between gap-x-2 items-center;
          > .save-button{
            @apply w-full px-4 py-3 text-pxs;
          }
          > .delete-button{
            @apply w-full px-4 py-3 text-pxs;
          }
        }
      }
    }
  }
}
</style>