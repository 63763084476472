<template>
    <base-modal 
        title="Add Social Account"
        :visible="visible"
        @close="close()"
    >
       <network-select
           v-if="isValidArray(socialAccounts)"
           :values="socialAccountsLocalValue"
           :excludedNetworks="excludedSocialAccounts"
           cols="grid-cols-1"
           container-class="overflow-y-auto h-96 max-h-96"
           @users-exists="(users) => existingHandle = isValidArray(users) ? true : false"
       />
       <div class="actions-footer">
           <base-button 
               @action="addSocialAccount()"
               size="lg"
               :disabled="loading || existingHandle || !isValidObject(filteredLocalValue)"
               label="Add Social Account"
           />
       </div>
   </base-modal>
</template>

<script>
import NetworkSelect from '@/components/NetworkSelect'
import { isValidArray, isValidObject, isValidString, notifyCatchError } from '../../common';
export default {
    name: 'AddSocialAccountModal',
    components: {
        NetworkSelect
    },
    props: {
        visible: { 
            type: Boolean, 
            default: false
        },
        userId: {
            type: Number,
            required: true
        },
        socialAccounts: {
            type: Array,
            required: false,
            default: function () {
                return [];
            }
        }
    },
    data(){
        return {
            socialAccountsLocalValue: {
                instagram_handle: '',
                tiktok_handle: '',
                youtube_handle: ''
            },
            existingHandle: false,
            loading: false,
            isValidArray,
            isValidObject
        }
    },
    computed: {
        // returns an array of the networks that wont be displayed in the list because
        // the user already has them
        excludedSocialAccounts(){
            return this.socialAccounts.filter(element => {
                return isValidObject(element.network);
            }).map(element => element.network);
        },
        // returns only the social accounts that have been filled by the user in this form
        filteredLocalValue(){
            let obj = {};
            for (let key in this.socialAccountsLocalValue) {
                const value = this.socialAccountsLocalValue[key];
                if (isValidString(value)) {
                    // push to object
                    obj[key] = value;
                }
            }
            return obj;
        },
    },
    methods: {
        async addSocialAccount() {    
            const obj = this.filteredLocalValue;
            if(isValidObject(obj)){
                this.loading = true;
                this.$http.post(`/api/projects/0/influencer/${this.userId}/network`, {
                    ...obj,
                }).then(({ data }) => {
                    if(data){
                        this.$notify({ title: 'Success', text: 'Social Account Added', type: 'success' });
                        this.$emit('refresh');
                        this.close();
                    }
                }).catch(err => {
                    notifyCatchError(this.$notify, err);
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        close(){
            // reset vars
            this.socialAccountsLocalValue = {
                instagram_handle: '',
                tiktok_handle: '',
                youtube_handle: ''
            },
            this.existingHandle = false;
            this.loading = false;
            this.$emit('close');
        }
    }
}
</script>
<style lang="scss" scoped>
    .actions-footer{
        @apply flex justify-end;
    }
</style>