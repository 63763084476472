<template>
<main class="project-settings flex-1 relative z-0 overflow-y-hidden focus:outline-none" tabindex="0">
    <base-table 
        class="px-7 py-10"
        :columns="[
                {
                    name: 'name',
                    es_column_name: 'first_name',
                    label: 'Creator',
                    type: 'avatar-list',
                    value: (element) => {
                        return [element]
                    }
                },
                {
                    name: 'projects',
                    sort_type: 'count',
                    es_column_name: 'user_projects',
                    label: 'Projects',
                    value: (element) => {
                        return element.user_projects_active;
                    }
                },
                {
                    name: 'completed-deliverables',
                    label: 'Completed Deliverables',
                    es_column_name: 'user_projects.project_user_deliverables.project_user_deliverable_posts',
                    sort_type: 'count',
                    value: (element) => {
                        return element['user_projects.project_user_deliverables.project_user_deliverable_posts_active']
                    }
                },
                {
                    name: 'reports',
                    label: 'Reports',
                    sort_type: 'count',
                    es_column_name: 'reports',
                    type: 'creator_list_reports',
                    value: (element) => {
                        return element.reports_active;
                    }
                },
                company.company_creator_columns.find(col => col.creator_column_id === 20) ? {
                    name: 'rating',
                    es_column_name: 'rating',
                    label: 'Rating',
                    type: 'rating',
                    value: (element) => {
                        return element.rating;
                    }
                } : {}, 
                company.company_creator_columns.find(col => col.creator_column_id === 6) ? {
                    name: 'user_tags',
                    label: 'Tags',
                    type: 'user-tags',
                    sort: false,
                    value: (element) => {
                        return element.user_tags
                    }
                } : {}, 
                company.company_creator_columns.find(col => col.creator_column_id === 5) ? {
                    name: 'email',
                    label: 'Email',
                    es_column_name: 'email',
                    type: 'text',
                    value: (element) => {                        
                        return element.email ? element.email : '-'
                    }
                } : {},   
                company.company_creator_columns.find(col => col.creator_column_id === 4) ? {
                    name: 'phone',
                    label: 'Phone',
                    sort: false,
                    type: 'text',
                    value: (element) => {                        
                        return element.phone ? element.phone : '-'
                    }
                } : {},  
                company.company_creator_columns.find(col => col.creator_column_id === 7) ? {
                    name: 'links',
                    label: 'Links',
                    sort: false,
                    type: 'networks',
                    currentValues: networksValues,
                    value: (element) => {                        
                        return element.social_accounts
                    },
                    currentAccounts: (element) => {
                        return element.social_accounts.map(n => n.network_id);
                    },
                    options: {
                        stopPropagation: true
                    }
                } : {},
                company.company_creator_columns.find(col => col.creator_column_id === 8) ? {
                    name: 'instagram-followers',
                    nested_path: 'reach',
                    label: 'Instagram Followers',
                    es_custom_sort: {field:'social_accounts',valueField:'network_id',value:2,targetField:'reach'},
                    type: 'number',
                    value: (element) => {                        
                        return instagramFollowersValue(element)
                    }
                } : {},
                company.company_creator_columns.find(col => col.creator_column_id === 17) ? {
                    name: 'youtube-subscribers',
                    label: 'Youtube Subscribers',
                    es_custom_sort: {field:'social_accounts',valueField:'network_id',value:3,targetField:'reach'},
                    type: 'number',
                    value: (element) => {                        
                        return youtubeFollowersValue(element)
                    }
                } : {},
                company.company_creator_columns.find(col => col.creator_column_id === 9) ? {
                    name: 'tiktok-followers',
                    label: 'TikTok Followers',
                    es_custom_sort: {field:'social_accounts',valueField:'network_id',value:14,targetField:'reach'},
                    type: 'number',
                    value: (element) => {                        
                        return tiktokFollowersValue(element)
                    }
                } : {},           
                company.company_creator_columns.find(col => col.creator_column_id === 1) ? {
                    name: 'city',
                    label: 'City',
                    type: 'text',
                    value: (element) => {
                        return element.city
                    }
                } : {},
                company.company_creator_columns.find(col => col.creator_column_id === 2) ? {
                    name: 'state',
                    label: 'State',
                    type: 'text',
                    value: (element) => {
                        return element.state
                    }
                } : {},
                company.company_creator_columns.find(col => col.creator_column_id === 3) ? {
                    name: 'country',
                    label: 'Country',
                    type: 'text',
                    value: (element) => {
                        return element.country
                    }
                } : {},   
                creatorColumns.find(col => col.creator_column_id === 11) ? {
                    name: 'agent-name',
                    label: 'Agent',
                    sort: false,
                    type: 'avatar-list',
                    value: (element) => {
                        return element.parent_users
                    }
                } : {},
                creatorColumns.find(col => col.creator_column_id === 12) ? {
                    name: 'agent-email',
                    label: 'Agent Email',
                    sort: false,
                    type: 'text',
                    value: (element) => {                        
                        return element.parent_users.length ? element.parent_users.map(user => user.email).join(', ') : '-'
                    }
                } : {},
                creatorColumns.find(col => col.creator_column_id === 13) ? {
                    name: 'agent-phone',
                    sort: false,
                    label: 'Agent Phone',
                    type: 'text',
                    value: (element) => {
                        return element.parent_users.length ? element.parent_users.map(user => user.phone).join(', ') : '-'
                    }
                } : {},
                creatorColumns.find(col => col.creator_column_id === 14) ? {
                    name: 'cost-per-engagement',
                    sort: true,
                    label: 'Cost per engagement',
                    es_column_name: 'cost_per_engagement',
                    type: 'price',
                    value: (element) => {
                        return element.cost_per_engagement
                    }
                } : {},  
                creatorColumns.find(col => col.creator_column_id === 15) ? {
                    name: 'cost-per-post',
                    sort: true,
                    label: 'Cost per post',
                    es_column_name: 'cost_per_post',
                    type: 'price',
                    value: (element) => {
                        return element.cost_per_post
                    }
                } : {},  
                creatorColumns.find(col => col.creator_column_id === 16) ? {
                    name: 'engagement-rate',
                    sort: true,
                    label: 'Engagement rate',
                    es_column_name: 'engagement_rate',
                    type: 'price',
                    value: (element) => {
                        return element.engagement_rate
                    }
                } : {},   
            ].filter(
                obj => !(obj && Object.keys(obj).length === 0 && obj.constructor === Object)
            )"
        :data="creators"
        :onRowClick="(element) => {
            return this.$router.push(`/creator/${element.id}`)
        }"
        :key="creatorsKey"
        :loading="loading"
        :reload="reload"
        @set-sort="changeSort"
        @changePage="changePage"
        @search="onSearch"
        :page="currentPage"
        :per-page="perPage"
        :total="total"
        container-styles="min-height: calc(100vh - 340px);max-height: calc(100vh - 340px);overflow-y: scroll;margin-right: 5px;"
        sticky-header
        overflow
    >

        <template v-slot:filters-footer>
            <div class="flex justify-end items-center my-2 pb-3 pt-0 rounded-3xl">
                <div class="flex gap-4">
                    <base-button
                        @action="editCreatorsColumnsModalVisible = true"
                        size="lg"
                        class="font-bold"
                        :iconSize="4"
                        theme="dark"
                        type="secondary"
                        label="Edit Columns"
                    />
                    <base-button
                        @action="filtersModalVisible = true"
                        class="font-bold"
                        label="Filters"
                        size="lg"
                        :iconSize="4"
                        theme="dark"
                        type="secondary"
                        icon="filter"
                    />
                    <base-button
                        @action="inviteCreatorModalVisible = true"
                        size="lg"
                        :iconSize="4"
                        class="font-bold"
                        theme="dark"
                        type="secondary"
                        label="Invite Creator"
                        icon="plus"
                    />
                    <base-button
                        @action="createInfluencerModalVisible = true"
                        size="lg"
                        :iconSize="4"
                        class="font-bold"
                        theme="dark"
                        type="secondary"
                        label="Add Creator"
                        icon="plus"
                    />
                    <base-button
                        size="lg"
                        :iconSize="4"
                        theme="dark"
                        type="secondary"
                        @action="$router.push('/creators/import')"
                        class="font-bold"
                        label="Import Creators"
                        icon="plus"
                    />
                    <base-button
                        @action="exportCreatorsModalVisible = true"
                        class="font-bold"
                        size="lg"
                        :iconSize="4"
                        theme="dark"
                        type="secondary"
                        label="Export Creators"
                        icon="export"
                    />
                </div>
            </div>
        </template>
    </base-table>
    <create-influencer-modal
        :visible="createInfluencerModalVisible"
        @close="createInfluencerModalVisible = false"
        @created-influencer="onCreatedInfluencer"
    />
    <edit-creators-columns-modal
        :visible="editCreatorsColumnsModalVisible"
        @close="editCreatorsColumnsModalVisible = false"  
        :creatorColumns="creatorColumns"      
        @edited-columns="getCompany" 
    />
    <filters-modal
        :visible="filtersModalVisible"
        :columns="creatorColumns"
        :networks="networksValues"
        @apply="onApplyFilters"
        @close="filtersModalVisible = false"
    />
    <export-creators-modal
        :visible="exportCreatorsModalVisible"
        @close="exportCreatorsModalVisible = false"
        :total="total"
        :currentQueryString="currentQueryString"
        :pageTotal="creators.length"
        :pageCreators="creators"
        :companyColumns="company.company_creator_columns"
        :searchTerm="searchTerm"
    />
    <invite-creator-modal
        @close="inviteCreatorModalVisible = false"
        :visible="inviteCreatorModalVisible"
    />
</main>
</template>
<script>
import CreateInfluencerModal from '../../components/CreateInfluencerModal.vue'
import { strToQuery } from '../../lib/strings';
import EditCreatorsColumnsModal from './EditCreatorsColumnsModal.vue'
import FiltersModal from './FiltersModal.vue'
import ExportCreatorsModal from './ExportCreatorsModal.vue'
import InviteCreatorModal from './InviteCreatorModal.vue'
import { instagramFollowers, tiktokFollowers, youtubeFollowers, audienceDataLocation } from '../../lib/user'
export default {
    components: {
        CreateInfluencerModal,
        EditCreatorsColumnsModal,
        FiltersModal,
        ExportCreatorsModal,
        InviteCreatorModal
    },
    data() {
        return {
            loading: false,
            reload: false,
            company: {
                company_creator_columns: [],
            },
            creators: [],
            networksValues: [],
            createInfluencerModalVisible: false,
            editCreatorsColumnsModalVisible: false,
            filtersModalVisible: false,
            exportCreatorsModalVisible: false,
            inviteCreatorModalVisible: false,
            filtersQuery: null,
            searchTerm: '',
            currentPage: 1,
            total: 35,
            perPage: 10,
            sort: {},
            filters: {},

     
            creatorsKey: false,

            currentQueryString: ''
        }
    },
    mounted() {
        this.getNetworks();
        this.getCompany();
        this.getData();
    },
    computed: {
        creatorColumns() {
            return this.company.company_creator_columns.filter(col => {
              return col.creator_column.status == 1;
            });
        },
        computedPaginationSortingQuery() {
            return this.paginationSortingQuery();
        }
    },
    methods: {
        async getNetworks() {
            const { data } = await this.$http.get('/api/userDeliverables/networks');
            this.networksValues = data.map(network => {
                network.value = network.id;
                network.label = network.display_name;
                return network;
            });
        },
        customCityValue(element){
            let arr = []
            const igValue = audienceDataLocation(element, 'instagram', 'city')
            const tiktokValue = audienceDataLocation(element, 'tiktok', 'city')
            if(igValue) arr.push({ network: 'Instagram', value: igValue })
            if(tiktokValue) arr.push({ network: 'Tiktok', value: tiktokValue })
            return arr
        },
        customStateValue(element){
            let arr = []
            const igValue = audienceDataLocation(element, 'instagram', 'state')
            const tiktokValue = audienceDataLocation(element, 'tiktok', 'state')
            if(igValue) arr.push({ network: 'Instagram', value: igValue })
            if(tiktokValue) arr.push({ network: 'Tiktok', value: tiktokValue })
            return arr
        },
        customCountryValue(element){
            let arr = []
            const igValue = audienceDataLocation(element, 'instagram', 'country')
            const tiktokValue = audienceDataLocation(element, 'tiktok', 'country')
            if(igValue) arr.push({ network: 'Instagram', value: igValue })
            if(tiktokValue) arr.push({ network: 'Tiktok', value: tiktokValue })
            return arr        
        },
        onApplyFilters(filtersQuery){
            this.searchTerm = '';
            this.filtersQuery = filtersQuery;
            this.getData();
        },
        async changePage(page, reload = true) {
            this.currentPage = page;
            if (reload) {
                this.getData(false);
            }
        },
        onSearch(term) {
            this.searchTerm = term;
            this.currentPage = 1;
            this.getData();
        },

        async getData(showLoader = true, reload = false) {
            this.loading = showLoader;
            this.reload = reload;

            const { currentPage, perPage,/* filters,*/ sort } = this;

            let searchParam = {};
            let thePage = currentPage;
            
            // search term
            if ( this.searchTerm && this.searchTerm.trim() != '' ) {
                searchParam = { q: this.searchTerm }
            }

            // filters
            let rangeFilters = [];
            let normalFilters = [];
            const appliedFilters = [
                `page=${thePage}`,
                `perPage=${perPage}`,
                ];

            if (this.filtersQuery && this.filtersQuery.length > 0) {
                for (let filter_ of this.filtersQuery) {

                    if (filter_.nested_path !== "" && filter_.target_property !== "") {
                        // not using this right now
                        // const fieldParts = filter_.field.split('-');
                        // const networkName = fieldParts[0];
                        const isRangeFilter = filter_.filter_type == "range";

                        if (isRangeFilter) {

                            let rangeFilter = {};

                            console.log('the filter_', filter_);

                            const whereClause = filter_.where_clause;
                            const value = filter_.value;
                            const operator = value.type === 'greater' ? 'gte' : 'lte';
                            const filterValue = value.min > value.max ? value.min : value.max;

                            if ( filter_.nested_path ) {
                                rangeFilter = {
                                    [filter_.target_property]: {
                                        [filter_.nested_path]: { [operator]: filterValue }
                                    },
                                    where: whereClause 
                                };
                            } else {
                                rangeFilter = {
                                    [filter_.target_property]: { [operator]: filterValue }
                                };
                            }

                            rangeFilters.push(rangeFilter);

                        } else {

                            var nested_filter = {
                                [filter_.nested_path]: { [filter_.target_property]: filter_.value }
                            };

                            normalFilters.push(nested_filter);
                        }
                    } else {
                        if ( filter_.nested_path == "" && filter_.target_property !== "" ) {
                            nested_filter = {
                                [filter_.target_property]: filter_.value
                            };

                            normalFilters.push(nested_filter);
                        }
                    }
                }

                appliedFilters.push(`filters=${JSON.stringify(normalFilters)}`);
                appliedFilters.push(`rangeFilters=${JSON.stringify(rangeFilters)}`);
            }

            // sorting
            if (Object.keys(sort).length > 0 && sort.column !== '') {
                if ( sort.es_custom_sort ) {
                    sort.es_custom_sort.order = sort.asc ? 'asc' : 'desc';
                    appliedFilters.push(`customSort=${JSON.stringify(sort.es_custom_sort)}`);
                } else {
                    const sortParams = [];
                    let the_col = sort.es_column_name || sort.column;
                    sortParams.push(the_col + ':' + (sort.asc ? 'asc' : 'desc'));
                    appliedFilters.push(`sort=${JSON.stringify(sortParams)}`);
                }   
            }

            const queryString = '?' + appliedFilters.join('&');

            try {
                const { data } = await this.$http.get( `/api/users/project-users-simple-es${queryString}`,
                    {
                        params: {
                            ...(searchParam),
                        },
                    }
                );

                this.currentQueryString = queryString;

                this.creators = data.data;
                this.total = data.count;

            } catch (error) {
                if ( error.response.status >= 400 ) {
                    // Handle the 401 error

                    this.$notify({ title: 'Error', text: error.response.data.message, type: 'error' });
                    this.$router.push("/home");

                } else {
                    // Handle other errors
                    console.error(error);
                }
            }

            this.reload = false;
            this.loading = false;
        },

        paginationSortingQuery(exceptions) {
            return strToQuery(this, exceptions);
        },

        changeSort(sort, changeColumn = false) {
            console.log('the sort', sort);
            if (changeColumn) {
                this.currentPage = 1;
            }
            this.sort = sort;
            this.getData(false, true);
        },
        async getCompany() {
            await new Promise(resolve => setTimeout(resolve, 500));
            const { data } = await this.$http.get(`/api/companies`)
            this.company = data
        },
        onCreatedInfluencer(/*influencer*/){
            this.createInfluencerModalVisible = false
            this.getData()            
        },
        instagramFollowersValue(element){
            return instagramFollowers(element)
        },
        tiktokFollowersValue(element){
            return tiktokFollowers(element)
        },
        youtubeFollowersValue(element) {
            return youtubeFollowers(element)
        },
    }
}
</script>