<template>
    <base-modal
        :visible="open"
        persistent
        @close="$emit('close')"
        with-picture
        modal-icon="image"
        modal-title="Add Manual Content"
        :title="manualTitle"
        no-padding
    >
        <!-- content wrapper -->
        <div class="content-wrapper">

            <div class="container">
                
                <!-- Step 1 -->
                <div class="step-1" v-if="step === 1">

                    <div class="field" :class="manualPost.searched ? 'col-span-4' : 'col-span-6'">
                        <div class="flex justify-between items-center">
                            <div class="label">Manual Content Link</div>
                            <base-icon name="loader" v-if="manualPost.searching"/>
                        </div>
                        <FormulateInput 
                            :input-class="['not-uppercased-placeholder']" 
                            type="text" 
                            validation="required" 
                            placeholder="https://" 
                            v-model="manualPost.post_url"
                            v-debounce:500="searchPost"
                        />
                    </div>

                    <div class="field col-span-2" v-if="manualPost.searched">
                        <div class="label">Manual Content Media</div>
                        <custom-select
                            placeholder="Social Media"
                            :options="manualPostMediums"
                            v-model="manualPost.medium"
                            :key="saving"
                        />
                    </div>

                    <template v-if="manualPost.searched">
                        <div class="field col-span-4">
                            <div class="label">Platform</div>
                            <custom-select
                                placeholder="Select a platform"
                                class="w-full"
                                :options="manualPostPlatforms"
                                v-model="manualPost.platform"
                                :key="selectPlatformKey"
                            />
                        </div>
                        <div class="field col-span-2">
                            <div class="w-full flex justify-between relative">
                                <div class="label">Post Identifier</div>
                                <span class="absolute right-2 top-0 cursor-pointer">
                                    <base-icon 
                                        name="info-circle"
                                        @action="() => $swal.fire({
                                            title: 'Post Identifier',
                                            html: `
                                                <div style='font-size: 14px'>
                                                    <div>Unique Identifier of the post, usually included in the URL, for example:</div>
                                                    <br>
                                                    <div>For an Instagram URL:</div>
                                                    <div>https://www.instagram.com/p/Cmsjhtytkhs_/</div>
                                                    <div>The post identifier would be: <span style='font-weight: bold'>Cmsjhtytkhs_</span></div>
                                                    <br>
                                                    <div>For a TikTok URL:</div>
                                                    <div>https://www.tiktok.com/@username/video/123456789</div>
                                                    <div>The post identifier would be: <span style='font-weight: bold'>123456789</span></div>
                                                    <br>
                                                    <div>For a Facebook URL:</div>
                                                    <div>https://www.facebook.com/photo/?fbid=123456789</div>
                                                    <div>The post identifier would be: <span style='font-weight: bold'>123456789</span></div>
                                                </div>
                                            `,
                                            iconColor: '#0E092C',
                                            customClass: {
                                                popup: 'swal-wide'
                                            },
                                            showCancelButton: true,
                                            cancelButtonText: 'Close',
                                            confirmButtonText: 'Ok',
                                            reverseButtons: true
                                        })"
                                    />
                                </span>
                            </div>
                            <FormulateInput 
                                :wrapper-class="['flex items-center']"
                                type="text" 
                                validation="required" 
                                placeholder="Post Identifier" 
                                v-model="manualPost.post_identifier"
                            />
                        </div>
                        <div class="field col-span-4">
                            <div class="label">Caption</div>
                            <FormulateInput type="text" placeholder="Type the caption of the content" v-model="manualPost.caption"/>
                        </div>
                        <div class="field col-span-2">
                            <div class="label">Date</div>
                            <FormulateInput 
                                :wrapper-class="['flex items-center']"
                                type="date" 
                                validation="required" 
                                placeholder="Type the content published at date" 
                                v-model="manualPost.date"
                            />
                        </div>
                    </template>
                </div>

                <!-- Step 2 -->
                <div class="step-2" v-if="step === 2">
                    <template v-if="manualPost.searched">

                        <div class="field col-span-2">
                            <div class="label">Username</div>
                            <FormulateInput type="text" validation="required" placeholder="Username" v-model="manualPost.username" :wrapper-class="inputWrapperClasses"/>
                        </div>
                        <div class="field col-span-2">
                            <div class="label">Full name</div>
                            <FormulateInput type="text" validation="required" placeholder="Full name" v-model="manualPost.full_name" :wrapper-class="inputWrapperClasses"/>
                        </div>
                        <div class="field col-span-2">
                            <template v-if="manualPost.medium.id == 'video'">
                                <div class="label">Video Duration</div>
                                <FormulateInput type="text" validation="required" placeholder="Video duration" v-model="manualPost.video_duration" :wrapper-class="inputWrapperClasses"/>
                            </template>
                        </div>

                        <!-- media url -->
                        <div class="media-url">

                            <div class="header">
                                <div class="label">Media Url</div>
                                <base-button v-if="(typeof manualPost.url == 'string' && manualPost.url.trim() != '')" type="label" size="sm" class="text-pxs" label="View file" @action="openLink(manualPost.url)"/>
                            </div>

                            <div class="help">The url of the image or video</div>

                            <div class="input-container">
                                <FormulateInput 
                                    type="text" 
                                    validation="required" 
                                    placeholder="File url" 
                                    v-model="manualPost.url" 
                                    :wrapper-class="inputWrapperClasses"
                                />

                                <div class="upload-button">
                                    <base-button type="label" size="sm" icon="upload2" @action="$refs.mediaFile.click()" class="w-5 h-5 text-pxs"/>
                                    <input class="hidden h-0" type="file" ref="mediaFile" :disabled="uploadingFile" @change="uploadMediaFile">
                                </div>

                            </div>
                        </div>

                        <!-- cover url -->
                        <div class="cover-url">

                            <div class="header">
                              <div class="label">Display Url</div>
                              <base-button  v-if="(typeof manualPost.display_url == 'string' && manualPost.display_url.trim() != '')" type="label" class="text-pxs" label="View file" @action="openLink(manualPost.display_url)"/>
                            </div>
                            
                            <div class="help">The url of the cover image</div>
                            
                            <div class="input-container">
                                <FormulateInput 
                                    type="text" 
                                    validation="required" 
                                    placeholder="Cover url" 
                                    v-model="manualPost.display_url" 
                                    :wrapper-class="inputWrapperClasses"
                                />
                                <div class="upload-button">
                                    <base-button type="label" size="sm" icon="upload2" @action="$refs.displayFile.click()"/>
                                    <input class="hidden h-0" type="file" ref="displayFile" :disabled="uploadingFile" @change="uploadDisplayFile">
                                </div>
                            </div>

                        </div>

                        <!-- profile picture url -->
                        <div class="profile-picture-url">
                            
                            <div class="header">
                              <div class="label">Profile Picture Url</div>
                              <base-button v-if="(typeof manualPost.profile_picture_url == 'string' && manualPost.profile_picture_url.trim() != '')" type="label" class="text-pxs" label="View file" @action="openLink(manualPost.profile_picture_url)"/>
                            </div>
                            
                            <div class="help">The url of the user profile image</div>

                            <div class="input-container">
                                <FormulateInput 
                                    type="text" 
                                    validation="required" 
                                    placeholder="Profile picture url" 
                                    v-model="manualPost.profile_picture_url" 
                                    :wrapper-class="inputWrapperClasses"
                                /> 
                                <div class="upload-button">
                                    <base-button type="label" size="sm" icon="upload2" @action="$refs.profileFile.click()"/>
                                    <input class="hidden h-0" type="file" ref="profileFile" :disabled="uploadingFile" @change="uploadProfileFile">
                                </div> 
                            </div>

                        </div>

                        <div class="field col-span-2">
                            <div class="label">Followers</div>
                            <FormulateInput type="number" validation="required" placeholder="Followers count" v-model="manualPost.total_followers" :wrapper-class="inputWrapperClasses"/>
                        </div>
                        <div class="field col-span-2">
                            <div class="label">Following</div>
                            <FormulateInput type="number" validation="required" placeholder="Following count" v-model="manualPost.total_following" :wrapper-class="inputWrapperClasses"/>
                        </div>
                        <div class="field col-span-2">
                            <div class="label">User Likes</div>
                            <FormulateInput type="number" validation="required" placeholder="User likes count" v-model="manualPost.total_likes" :wrapper-class="inputWrapperClasses"/>
                        </div>
                        <div class="col-span-6 grid grid-cols-5 gap-4">
                            <div class="field">
                                <div class="label">Likes</div>
                                <FormulateInput type="number" validation="required" placeholder="Post likes count" v-model="manualPost.likes" :wrapper-class="inputWrapperClasses"/>
                            </div>
                            <div class="field">
                                <div class="label">Dislikes</div>
                                <FormulateInput type="number" validation="required" placeholder="Post dislikes count" v-model="manualPost.dislikes" :wrapper-class="inputWrapperClasses" />
                            </div>
                            <div class="field">
                                <div class="label">Comments</div>
                                <FormulateInput type="number" validation="required" placeholder="Post comment count" v-model="manualPost.comments" :wrapper-class="inputWrapperClasses"/>
                            </div>
                            <div class="field">
                                <div class="label">Views</div>
                                <FormulateInput type="number" validation="required" placeholder="Type the views count" v-model="manualPost.views" :wrapper-class="inputWrapperClasses"/>
                            </div>
                            <div class="field">
                                <div class="label">Shares</div>
                                <FormulateInput type="number" validation="required" placeholder="Type the shares count" v-model="manualPost.shares" :wrapper-class="inputWrapperClasses"/>
                            </div>
                            <div class="field">
                                <div class="label">Saves</div>
                                <FormulateInput type="number" validation="required" placeholder="Type the saves count" v-model="manualPost.saves" :wrapper-class="inputWrapperClasses"/>
                            </div>
                        </div>
                    </template>
                </div>
                
                <!-- preview -->
                <div class="step-3" v-if="step === 3">
                    <div class="container">
                        <report-post-card 
                            is-preview 
                            class="post-card mb-4" 
                            :post="computedManualPost"
                        />
                    </div>
                    <div class="w-20"></div>
                </div>
            </div>
        </div>

        <!-- Footer -->
        <div>
            <div class="footer">
                <base-button 
                    label="Close"
                    v-if="step == 1" 
                    @action="$emit('close')" 
                    :disabled="saving" 
                    type="secondary" 
                    size="md" 
                    :icon-size="4" 
                    bold 
                />
                <base-button 
                    label="Back"
                    v-if="step > 1" 
                    @action="previousStep" 
                    :disabled="saving" 
                    type="secondary" 
                    size="md" 
                    :icon-size="4" 
                    bold 
                    icon="arrow-left"
                />
                <base-button 
                    :label="submitButtonLabel"
                    @action="nextStep" 
                    :disabled="disabledManualPost || disableNext || !manualPost.searched || manualPost.searching || saving" 
                    size="md" 
                    :icon-size="4" 
                    bold 
                    secondary-icon="arrow-right"
                />
            </div>
        </div>

        <!-- Link not supported modal -->
        <unsupported-link-modal
            :visible="unsupportedLinkModalVisible" 
            @close="unsupportedLinkModalVisible = false" 
        />

        <!-- Post already attached to report - modal -->
        <base-modal :visible="attachedMessage" @close="attachedMessage = false" title="This post is already attached to this report">
            <div>Please try with another link.</div>
            
            <div class="flex justify-center">
                <base-button @action="attachedMessage = false" size="md" label="Accept" bold/>
            </div>
        </base-modal>

        <!-- Post already exists modal -->
        <base-modal :visible="postExists !== null" persistent :hide-close-button="true" title="This post already exists">
            <div>Do you want to attach the link?</div>
            
            <div class="flex justify-between gap-3 py-3 px-14">
                <base-button @action="cancelPostExists" size="md" bold label="Cancel"/>
                <base-button @action="attachExistent" size="md" bold label="Attach"/>
            </div>
        </base-modal>
    </base-modal>
</template>

<script>
import { validateNumbers, validateObject } from '@/lib/strings';
import ReportPostCard from '@/components/ReportPostCard';
import { postRoutes, removeUnnecessaryQueryParams, getParamsFromSocialPostUrl } from '@/lib/network';
import { notifyCatchError } from '@/common';
import UnsupportedLinkModal from './UnsupportedLinkModal.vue';
const inputWrapperClasses = 'border rounded-md py-2 px-3 bg-white h-12 flex items-center';
export default {
    name: 'ManualPost',
    components: {
        ReportPostCard,
        UnsupportedLinkModal
    },
    props: {
        open: {
            type: Boolean,
            default: false
        },
        suggestedLink: {
            type: String,
            default: ''
        },
        reportId: {
            type: Number,
            default: -1
        }
    },
    watch: {
        open: function (val, old) {
            if (val && !old && this.suggestedLink !== '') {
                this.manualPost.post_url = JSON.parse(JSON.stringify(this.suggestedLink));
                this.searchPost();
            }

            if (val && !old && this.reportId > -1) {
                this.report_id = JSON.parse(JSON.stringify(this.reportId));
            } else {
                this.report_id = this.$route.params.id;
            }
        }
    },
    data() {
        return {
            unsupportedLinkModalVisible: false,
            saving: false,
            step: 1, // TODO default 1
            social: false,
            uploadingFile: false,
            attachedMessage: false,
            postExists: null,
            newPostError: '',
            report_id: 0,
            selectPlatformKey: false,
            manualPost: {
                searching: false,
                searched: false, // TODO default false
                description: '',
                platform: {id: 'facebook', name: 'Facebook', value: 6},
                medium: {id: 'video', name: 'Video'},
                url: '',
                post_url: '',
                display_url: '',
                date: '',
                caption: '',
                profile_picture_url: '',
                profile_url: '',
                bio: '',
                full_name: '',
                username: '',
                social_id: '',
                post_identifier: '',
                video_duration: '00:00:00',
                likes: 0,
                dislikes: 0,
                comments: 0,
                views: 0,
                shares: 0,
                saves: 0,
                total_followers: 0,
                total_following: 0,
                total_likes: 0,
            },

            manualPostPlatforms: [
                {id: 'facebook', name: 'Facebook', label: 'Facebook', value: 6},
                {id: 'instagram', name: 'Instagram', label: 'Instagram', value: 2},
                {id: 'tiktok', name: 'TikTok', label: 'TikTok', value: 14},
                {id: 'twitter', name: 'Twitter', label: 'Twitter', value: 7},
                {id: 'pinterest', name: 'Pinterest', label: 'Pinterest', value: 4},
                {id: 'linkedin', name: 'LinkedIn', label: 'LinkedIn', value: 16},
            ],

            manualPostMediums: [
                {id: 'video', name: 'Video'},
                {id: 'image', name: 'Image'}
            ],

            postRoutes,
            inputWrapperClasses
        }
    },
    computed: {
        disabledManualPost() {
            let { platform, medium, searching, searched } = this.manualPost;

            let isValidText = validateObject(this.manualPost, [
                'post_url',
                'date',
                'url',
                'display_url',
                'profile_picture_url',
                'full_name',
                'username'
            ]);

            let isValidNumbers = validateNumbers(this.manualPost, [
                'likes',
                'dislikes',
                'comments',
                'views',
                'shares',
                'saves',
                'total_followers',
                'total_following',
                'total_likes'
            ]);

            return [2, 3].includes(this.step) && (!isValidText || !isValidNumbers || !platform.id || !medium.id || searching || !searched || this.saving);
        },

        submitButtonLabel() {
            return this.saving ? 'Saving...' : (this.step === 2 ? 'Preview' : (this.step === 3 ? 'Save' : 'Next'));
        },

        manualTitle() {
            switch (this.step) {
                case 2:
                    return 'Manual Content';
                case 3:
                    return 'Preview';
            }
            return 'Manual Content Link';
        },

        disableNext() {
            let required = [];
            switch (this.step) {
                case 1:
                    required = ['post_url', /* 'caption', 'date' */];
                    break;
            }
            return this.allowRequired(required);
        },

        computedManualPost() {
            let {
                description,
                platform,
                medium,
                url,
                post_url,
                display_url,
                date,
                caption,
                profile_picture_url,
                bio,
                full_name,
                username,
                profile_url,
                social_id,
                post_identifier,
                video_duration,
                likes,
                dislikes,
                comments,
                views,
                shares,
                saves,
                total_followers,
                total_following,
                total_likes,
            } = this.manualPost;

            platform.name = platform.name.toLowerCase();

            let is_video = medium.id === 'video';

            return {
                post_identifier,
                network_id: 2,
                date,
                network: platform,
                content_type_id: 1,
                type: {id: 1, name: 'post'},
                is_video,
                url: post_url,
                caption,
                description,
                display_url,
                likes,
                dislikes,
                comments,
                views,
                shares,
                saves,
                media: [
                    {mimetype: is_video ? 'video/mp4' : 'image/png', video_duration, url, poster: display_url}
                ],
                profile_item: {
                    bio,
                    uri: profile_url,
                    username,
                    full_name,
                    social_id,
                    total_likes,
                    total_views: 0,
                    total_videos: 0,
                    total_followers,
                    total_following,
                    profile_picture_url
                }
            }
        }
    },
    methods: {
        openLink(url) {
            if (url !== '') {
            let a = document.createElement('a');
            a.href = url;
            a.target = '_blank';
            a.click();
            }
        },
        uploadMediaFile(e) {
            this.uploadFile(e, 'url');
        },
        uploadDisplayFile(e) {
            this.uploadFile(e, 'display_url');
        },
        uploadProfileFile(e) {
            this.uploadFile(e, 'profile_picture_url');
        },
        uploadFile(e, path) {
            this.uploadingFile = true;
            let fileData = new FormData();
            fileData.append('file', e.target.files[0]);
            this.$http.post(`/api/files/manual/posts`, fileData).then(async ({data}) => {
            this.manualPost[path] = data.url;
            }).catch((err) => {
            this.manualPost[path] = '';
            console.log('upload picture error', err.response);
            this.$notify({ title: 'Something went wrong while uploading media.', text: 'Try again later', type: 'warn' });
            }).finally(() => {
            this.uploadingFile = false;
            });
        },

        previousStep() {
            if (this.step > 1) {
                this.step--;
            }
        },

        nextStep() {
            if (this.step < 3) {
                this.step++;
            } else if (this.step === 3) {
                this.saveCustomPost();
            }
        },

        allowRequired(required) {
            let fails = 0;
            for (let i in required) {
                let val = this.manualPost[required[i]];
                if (val === undefined || val === null || val === '' || (Array.isArray(val) && !val.length)) {
                    fails++;
                }
            }

            return !!fails;
        },

        async searchPost() {

            this.manualPost.post_url = removeUnnecessaryQueryParams(this.manualPost.post_url)

            this.manualPost.searching = true;
            try {
                let success = false;
                
                // search for platform in the url
                const params = getParamsFromSocialPostUrl(this.manualPost.post_url)
                if(params.platform && params.type){
                    this.setManualPostPlatform(params.platform, params.type);
                    success = true;
                }
                if(params.userId) this.manualPost.social_id = params.userId;
                if(params.postId) this.manualPost.post_identifier = params.postId;
                if(params.userName) this.manualPost.username = params.userName;
                if(params.videoId) this.manualPost.post_identifier = params.videoId;

                if (success) {
                    // another scraping call
                    // let payload = {link: this.manualPost.post_url};
                    // if (this.$route.params && this.$route.params.id) {
                    //     payload.reportId = this.$route.params.id;
                    // }
                    // const {data} = await this.$http.post(`/api/reports/search/get-url`, payload);
                    // if (data.exists || data.attached) {
                    //     this.manualPost.post_url = '';
                    //     if (data.attached) {
                    //         this.attachedMessage = true;
                    //     } else if (data.exists) {
                    //         this.postExists = data;
                    //     }
                    // } else {
                    //     this.setManualPostPlatform(data.platform, data.medium);
                    //     this.manualPost.description = data.description;
                    //     // this.manualPost.medium = data.medium;
                    //     // this.manualPost.platform = data.platform;
                    //     this.manualPost.display_url = data.display_url;
                    //     this.manualPost.post_url = data.post_url;
                    //     this.manualPost.date = data.date;
                    //     this.manualPost.caption = data.caption;
                    //     this.manualPost.profile_picture_url = data.profile_picture_url;
                    //     this.manualPost.profile_url = data.profile_url;
                    //     this.manualPost.bio = data.bio && data.bio !== '' ? data.bio : '-';
                    //     this.manualPost.full_name = data.full_name;
                    //     this.manualPost.username = data.username;
                    //     this.manualPost.social_id = data.social_id;
                    //     this.manualPost.post_identifier = data.post_identifier;
                    //     this.manualPost.video_duration = data.video_duration;
                    //     this.manualPost.url = data.url;
                    // }

                } else {
                    this.unsupportedLinkModalVisible = true;
                }
            } catch (e) {
                console.log(e);
                this.$notify({ title: 'We couldn\'t get the data from that post.', text: 'You can still continue creating the post', type: 'warn' });
            } finally {
                this.manualPost.searched = true;
                this.manualPost.searching = false;
            }
        },

        cancelPostExists() {
            this.postExists = null;
            this.manualPost.searched = false;
        },

        attachExistent() {
            if (this.postExists.post) {
                this.$emit('attach-existent', this.postExists.post);
            }
            this.postExists = null;
        },

        setManualPostPlatform(platform, type) {
            let searchPlatform = this.manualPostPlatforms.find(p => p.id === platform);
            if (searchPlatform) {
                this.manualPost.platform = searchPlatform;
            }

            let searchType = this.manualPostMediums.find(p => p.id === type);
            if (searchType) {
                this.manualPost.medium = searchType;
            }

            this.selectPlatformKey = !this.selectPlatformKey
        },

        compareRoute(string, route) {
            let stringItems = string.split('/');
            let compare = this.trimc(route.route, '/');
            let compareItems = compare.split('/');
            if (stringItems.length !== compareItems.length) {
                return false;
            }

            let check = 0;
            for (let i = 0; i < stringItems.length; i++) {
                let char = compareItems[i].substring(0, 1);
                if (char !== ':') {
                    if (compareItems[i] === stringItems[i]) {
                        check++;
                    }

                    if (char === '?') {
                        if (stringItems[i].includes(compareItems[i])) {
                            check++;
                        }
                    }
                } else {
                    check++;
                }
            }

            return check === stringItems.length;
        },

        trimc(string, char = ' ') {
            string = string.charAt(0) === char ? string.substring(1) : string;
            string = string.charAt(string.length - 1) === char ? string.slice(0, -1) : string;
            return string
        },

        async saveCustomPost() {
            this.saving = true;
            try {
                let { data } = await this.$http.post(`/api/reports/${this.report_id}/add-post`, { manualPost: this.manualPost });

                this.$notify({ title: 'Success', text: `Content saved successfully`, type: 'success' });
                
                // reset to initial state
                this.manualPost = {
                    searching: false,
                    searched: false,
                    platform: {id: 'facebook', name: 'Facebook'},
                    medium: {id: 'video', name: 'Video'},
                    description: '',
                    url: '',
                    post_url: '',
                    display_url: '',
                    date: '',
                    caption: '',
                    profile_picture_url: '',
                    bio: '',
                    full_name: '',
                    username: '',
                    social_id: '',
                    post_identifier: '',
                    video_duration: '00:00:00',
                    likes: 0,
                    dislikes: 0,
                    comments: 0,
                    views: 0,
                    shares: 0,
                    saves: 0,
                    total_followers: 0,
                    total_following: 0,
                    total_likes: 0,
                };
                
                this.step = 1;

                this.$emit('finish-manual', data);
            }
            catch(e) {
                notifyCatchError(e, this.$notify)
                this.newPostError = 'Error saving! Please try again!';
            }
            finally {
                this.saving = false;
            }
        },
    }
}
</script>
<style lang="scss" scoped>
    .field{
        @apply gap-y-1;
    }
    .label{
        @apply text-h6 font-bold;
    }
    .content-wrapper{
        @apply flex flex-col justify-between;
        min-height: 260px;
        .container{
            @apply py-5 px-7;
            .step-1{
                @apply grid grid-cols-6 gap-4;
            }
            .step-2{
                @apply grid grid-cols-6 gap-4;
                
                // media url
                .media-url{
                    @apply col-span-2 flex flex-col gap-y-1;
                    .header{
                        @apply w-full flex justify-between;
                    }
                    .help{
                        @apply text-xs italic;
                    }
                    .input-container{
                        @apply w-full relative;
                        .upload-button{
                            @apply absolute right-0 top-0 h-12 w-12 border rounded-md bg-white flex justify-center items-center;
                        }
                    }
                }

                // cover-url
                .cover-url{
                    @apply col-span-2 flex flex-col gap-y-1;
                    .header{
                        @apply flex justify-between;
                    }
                    .help{
                        @apply text-xs italic;
                    }
                    .input-container{
                        @apply w-full relative;
                        .upload-button{
                            @apply absolute right-0 top-0 h-12 w-12 border rounded-md bg-white flex justify-center items-center;
                        }
                    }
                }

                .profile-picture-url{
                    @apply col-span-2 flex flex-col gap-y-1;
                    .header{
                        @apply flex justify-between;
                    }
                    .help{
                        @apply text-xs italic;
                    }
                    .input-container{
                        @apply w-full relative;
                        .upload-button{
                            @apply absolute right-0 top-0 h-12 w-12 border rounded-md bg-white flex justify-center items-center;
                        }
                    }
                }


            }
            .step-3{
                @apply flex gap-2;
                .container{
                    @apply flex w-full justify-center;
                }
            }
        }
    }
    .footer{
        @apply flex justify-end gap-3 bg-white rounded-br-2xl py-3 px-7;
    }
</style>
