<template>
    <div>
        <div class="profile-completion" v-if="user && profileCompletion && !profileCompletion.completed">

            <div class="left">
                <div>
                    <div class="title">This creator's profile is incomplete.</div>
                    <div class="message">{{profileCompletion.adminMessage}}</div>
                    <div class="message">You can send them an invite to complete their account.</div>
                </div>
                <base-button
                    v-if="profileCompletion.redirect"
                    label="Send Invite"
                    size="lg"
                    @action="sendInvite()"
                />
            </div>

            <div class="right">

                <div class="label">
                    <div class="percentage">{{profileCompletion.percentage}}</div>
                    <div class="text">Completed</div>
                </div>
                <div class="chart">
                    <doughnut
                        :chartData="{
                        labels: ['Female', 'Male'],
                        datasets: [
                                {
                                    data: [
                                        parseInt(profileCompletion.percentage.replace('%','')), 
                                        100 - parseInt(profileCompletion.percentage.replace('%',''))
                                    ],
                                    backgroundColor: [
                                        '#70F880',
                                        '#FFFFFF'
                                    ]
                                }
                            ]
                        }"
                        :options="{
                            legend: { 
                                display: false 
                            },
                            tooltips: {
                                enabled: false
                            },
                            cutoutPercentage: 50
                        }"
                    />
                </div>
            </div>
        </div> 
        <send-invite-modal
            @close="sendInviteModalVisible = false"
            :visible="sendInviteModalVisible"
            :network-id="inviteNetworkId"
            :user="user"
        />
        <complete-profile-invite-modal
            :visible="completeProfileInviteModalVisible"
            @close="completeProfileInviteModalVisible = false"
            :user="user"
            :profileCompletion="profileCompletion"
        />
    </div>
</template>

<script>
import SendInviteModal from '@/components/SendInviteModal';
import Doughnut from '@/components/_charts/Doughnut.vue';
import { isObject } from '../../common';
import CompleteProfileInviteModal from './CompleteProfileInviteModal.vue';
export default {
    props: {
        user: {
            type: Object,
            required: false,
            default: null
        }
    },
    components: {
        SendInviteModal,
        Doughnut,
        CompleteProfileInviteModal
    },

    data(){
        return {
            profileCompletion: null,

            sendInviteModalVisible: false,
            inviteNetworkId: null,

            completeProfileInviteModalVisible: false
        }
    },

    watch: {
        user(val){
            if(isObject(val)){
                this.getProfileCompletion()
            }
        }
    },

    methods: {
        getProfileCompletion(){
            this.$http.get(`/api/users/${this.user.id}/profile-completion`).then(({data}) => {
                if(data){
                    this.profileCompletion = data
                }
            }).catch(err => {
                console.log('getProfileCompletion error', err)
            })
        },
        sendInvite(){

            if( this.profileCompletion.redirect.includes('/profile/edit') ){
                const connectionError = this.profileCompletion.errors.find(element => element.field == 'connection')
                if(connectionError){
                    this.inviteNetworkId = connectionError.errors[0].data.login_network_id;
                    this.sendInviteModalVisible = true
                }
            }

            if(this.profileCompletion.redirect.includes('/me')){
                this.completeProfileInviteModalVisible = true
            }

        }
    }
}
</script>
<style lang="scss" scoped>
    .profile-completion{
        @apply relative px-12 py-2 bg-tan-m flex justify-between items-center;

        .left{
            @apply flex gap-x-12 items-center;
            .title{
                @apply font-bold text-h4;
            }
            .message{
                @apply text-h6 text-gray-m-dark;
            }
        }
        .right{
            @apply flex items-center gap-x-8;
            .label{
                @apply flex flex-col items-end;
                .percentage{
                    @apply font-bold text-h4;
                }
                .text{
                    @apply text-h6 text-gray-m-dark;
                }
            }
            .chart{
                width: 70px; 
                height: 70px;
            }
        }
    }
</style>
