<template>
    <base-modal 
        title="Share deliverable"
        :visible="visible"
        @close="$emit('close')"
    >
        <div>
            <FormulateForm v-if="step === 1" name="share-deliverables-form" @submit="submit()">

                <div class="font-semibold mb-2">Link Settings</div>

                <div v-if="ready" class="flex gap-x-4">

                    <div style="width: 400px">
                        <custom-select
                            :options="permissionsOptions"
                            v-model="permissions"
                            @input="onPermissionsInput"
                            :key="permissionsKey"
                            preventMountedEmit
                        />
                    </div>

                    <div style="width: 200px">
                        <custom-select
                            :options="allowEditingOptions"
                            v-model="allowEditing"
                            @input="(option) => update({ allow_editing: option.value })"
                            :key="allowEditingKey"
                            bold
                            preventMountedEmit
                        />
                    </div>

                </div>

                <div v-if="permissions.value == 'password-protected'">
                    
                    <div class="mt-2 mb-2 font-semibold">Password</div>
                    
                    <div class="flex gap-x-4 w-full">

                        <FormulateInput
                            type="password"
                            v-if="oldPassword"
                            v-model="oldPassword"
                            class="w-1/2"
                            :disabled="true"
                        />

                        <FormulateInput
                            v-else
                            type="password"
                            ref="password"
                            placeholder="Enter"
                            v-model="password"
                            class="w-1/2"
                            :disabled="loading"
                        />

                        <base-button
                            v-if="oldPassword"
                            @action="onChangePasswordClick"
                            size="lg"                                                    
                            label="Change password"
                            class="font-bold"
                            :disabled="loading"
                        />

                        <base-button
                            v-else
                            @action="update({ password: password })"
                            size="md"                                                    
                            label="Save"
                            class="font-bold"
                            :disabled="loading"
                        />

                    </div>

                </div>

            </FormulateForm>

            <div v-if="step === 2">
                <base-button
                    type="label"
                    @action="copyLink()"
                    icon="link"
                    label="Copy Link"
                    iconColor="#70F880"
                    class="font-bold"
                    :disabled="loading"
                />

                <div class="pt-5 w-full">
                    <FormulateInput
                        v-model="email"
                        class="w-full"
                        label="Share by email"
                        placeholder="example@mail.com"
                        validation="email"
                    />

                    <FormulateInput
                        type="textarea"
                        class="w-full mt-2"
                        placeholder="Enter message..."
                        v-model="emailMessage"
                        input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-20"
                        :rows="1"
                    />

                    <div class="w-full flex justify-end mt-2 mb-6">
                        <base-button
                            @action="sendEmail()" 
                            :disable="loading"
                            label="Send email"
                            class="font-bold"
                            :disabled="loading"
                        />
                    </div>
                </div>
            </div>
            <div class="pt-10 w-full flex justify-between">
                <base-button 
                    @action="$emit('close')" 
                    label="Cancel"
                    type="secondary"
                    class="font-bold"
                />

                <div class="flex gap-x-2">
                    <base-button 
                        v-if="step === 1"
                        @action="step++" 
                        label="Next"
                        secondary-icon="arrow-right"
                        class="font-bold"
                    />
                    <base-button 
                        v-if="step === 2"
                        @action="step--"
                        label="Back"
                        type="secondary"
                        icon="arrow-left"
                        class="font-bold"
                    />
                    <!-- done button will only dismiss the modal -->
                    <base-button
                        v-if="step === 2"
                        @action="$emit('close')" 
                        :disable="loading"
                        submit 
                        label="Done"
                        class="font-bold"
                        :disabled="loading"
                    />
                </div>
            </div>
        </div>
    </base-modal>
</template>

<script>
import CustomSelect from '../../components/CustomSelect.vue'
export default {
    props: ['visible', 'deliverable'],
    components: {
        CustomSelect
    },
    data() {
        return {
            step: 1,
            token: '',
            
            permissions: { id: 1, value: 'public', label: 'Public', help: 'Anyone with the link can'},
            permissionsOptions: [
                { id: 1, value: 'public', label: 'Public', help: 'Anyone with the link can'},
                { id: 2, value: 'password-protected', label: 'Password protected', help: 'Anyone with the link and the password can'}
            ],
            password: '',
            oldPassword: null,
            permissionsKey: 0,
            
            allowEditingOptions: [
                { value: false, label: 'View' },
                { value: true, label: 'Edit' }
            ],
            allowEditing: { value: false, label: 'View' },
            allowEditingKey: 0,

            email: '',
            emailMessage: '',

            loading: false,
            ready: false
        }
    },
    watch: {
        visible(val, old){
            if(val != old && val == true){
                this.getProjectShare()
            }
        }
    },
    computed: {
        project(){
            return this.deliverable.project_user.project
        }
    },
    methods: {
        async getProjectShare(){
            this.loading = true
            try{
                const { data } = await this.$http.get(`/api/project-shares?projectId=${this.project.id}`)
                this.projectShare = data
                this.token = data.token

                if(!data.password || data.password == ''){
                    this.setPermissions('public')
                    this.oldPassword = null
                }else{
                    this.setPermissions('password-protected')
                    this.oldPassword = '.......'
                }

                this.setAllowEditing(data.allow_editing)

            }catch(error){
                console.log(error)
            }finally{
                this.ready = true
                this.loading = false
            }
        },
        async update(body) {
            this.loading = true
            try{
                if("password" in body){
                    if(this.permissions.value == 'password-protected' && (!this.password || this.password == '')) return this.$notify({ title: 'Password required', type: 'warn' })
                }
                await this.$http.put(`/api/project-shares/${this.project.id}`, body)
                this.$notify({ title: 'Permissions updated', type: 'success' })
                if("password" in body){
                    this.password = null
                    this.oldPassword = '.......'
                }
            }catch(error){
                console.log(error)
            }finally{
                this.loading = false
            }
        },
        async copyLink() {
            this.loading = true
            try{
                const link = `${window.location.origin}/deliverables/${this.deliverable.id}?token=${this.token}`
                this.$copyToClipboard(this.$notify, link); 
            }catch(error){
                console.log(error)
            }finally{
                this.loading = false
            }
        },

        async onPermissionsInput(/*option*/){
            if(this.permissions.value == 'public'){
                this.$swal.fire({
                    title: 'Remove password ?',
                    text: 'This will make all activities of this project public',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Remove',
                    reverseButtons: true,
                    focusCancel: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.update({ password: null })
                        this.setPermissions('public')
                        this.oldPassword = null
                    } else {
                        this.setPermissions('password-protected')
                    }
                })
            }
        },

        async sendEmail(){
            this.loading = true
            const url = `${window.location.origin}/deliverables/${this.deliverable.id}?token=${this.token}`
            this.$http.post('/api/project-shares/send-email', {
                email: this.email,
                message: this.emailMessage,
                url
            }).then((/*res*/) => {
                this.$notify({ title: 'Email sent successfully', type: 'success' })
                this.$emit('close')
            }).finally(() => {
                this.loading = false
            });
        },

        setAllowEditing(value){
            this.allowEditing = this.allowEditingOptions.find(option => option.value == value)
            this.allowEditingKey++
        },

        setPermissions(value){
            this.permissions = this.permissionsOptions.find(option => option.value == value)
            this.permissionsKey++
        },

        onChangePasswordClick(){
            this.oldPassword = null
            setTimeout(() => {
                this.$refs.password.$el.querySelector('input').focus()
            }, 100)
        },
    }
}
</script>