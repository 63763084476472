<template>
    <base-modal 
        title="Edit Columns"
        :visible="visible"
        @close="$emit('close')"
    >
        <FormulateForm name="form" @submit="submit()">

            <Checkbox
                v-if="creatorColumns && columns.length"
                label="Select columns to show for creators"
                :options="columns"
                v-model="selectedColumns"
            />

            <span v-else class="text-purple-m-secondary text-h6">Error loading options</span>

            <div class="pt-3 flex justify-end">
                <base-button @action="$formulate.submit('form')" :disabled="loading || !creatorColumns" submit :label="loading ? 'Saving...' : 'Save'"/>
            </div>

        </FormulateForm>
    </base-modal>
</template>

<script>
import Checkbox from '../../components/Checkbox.vue'
export default {
    props: ['visible', 'creatorColumns', 'admin', 'projectId'],
    components: {
        Checkbox
    },
    data() {
        return {
            loading: false,
            columns: [],
            selectedColumns: this.creatorColumns ? this.creatorColumns.map(col => col.creator_column_id) : []
        }
    },
    watch: {
        creatorColumns: {
            deep: true,
            handler: function (val) {
                if (val) {
                    this.selectedColumns = this.creatorColumns.map(col => col.creator_column_id);
                } else {
                    this.selectedColumns = [];
                }
            }
        }
    },
    mounted(){
        this.getData();
    },
    /* watch: {
        'company': { 
            deep: true,           
            handler: function (val) {                
                this.selectedColumns = [];
                val.company_creator_columns.forEach(col => {
                    this.selectedColumns.push(col.creator_column_id);
                });                
            }
        }
    }, */
    methods: {
        async getData(showLoader = true) {
            this.loading = showLoader ? true : false;

            // get all columns
            const { data } = await this.$http.get('/api/companies/creator-columns');            
            this.columns = data;

            // format data            
            this.columns.forEach(c => {
                c.value = c.id,
                c.label = c.name
            });

            this.loading = false;
        },
        
        async submit() {
            this.loading = true
            try{
                let route = "";
                if(!this.projectId)
                    route = "/api/companies/creator-columns";
                else
                    route = `/api/projects/${this.projectId}/creator-columns`
                const { data } = await this.$http.put(route, {                     
                    selectedColumns: this.selectedColumns
                });
                console.log('submit data', data);
                setTimeout(() => {
                    this.loading = false;
                    this.$notify({ title: 'Success', text: 'Columns edited successfully', type: 'success' });
                    this.$emit('edited-columns');  
                    this.$emit('close');                                
                }, 1000);
                
            }catch(error){
                console.log(error);
                this.loading = false;
            }finally{
                //this.loading = false
            }
        }
    }
}
</script>