var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container max-w-none"},[_c('div',{staticClass:"project flex items-start pb-10"},[(!_vm.loading)?_c('h1',{staticClass:"title font-black"},[_vm._v(_vm._s(_vm.project.brand)+" - "+_vm._s(_vm.project.name))]):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"flex items-center flex-row-reverse"},[((_vm.isProjectOwner || _vm.isAdminOrHigher || _vm.isProjectManager) && !_vm.isDeleted)?_c('options-dropdown',{staticClass:"float-right self-start",attrs:{"theme":"light","options":(
                        _vm.isProjectOwner || _vm.isAdminOrHigher ? [
                            {
                                label: 'Edit', 
                                icon: 'pencil-alt', 
                                event: 'edit', 
                                separator: true
                            }
                        ] : []).concat( (_vm.isAdminOrHigher || _vm.isProjectManager ? [
                            {
                                label: 'Share project', 
                                icon: 'pencil-alt', 
                                event: 'shareProject'
                            }
                        ] : []),
                    (_vm.isProjectOwner || _vm.isAdminOrHigher ? [
                            {
                                label: 'Archive', 
                                icon: 'trash', 
                                event: 'delete', 
                                theme: 'cancel'
                            }
                        ] : [])
                )},on:{"edit":function($event){return _vm.$router.push(("/projects/" + (_vm.project.slug) + "/edit"))},"delete":function($event){return _vm.deleteProjectIntent(_vm.project)},"shareProject":function($event){return _vm.$emit('shareProject')}}}):_vm._e(),_c('div',{staticClass:"self-start hidden md:flex items-center float-right mr-20 gap-2"},[(_vm.isUserAdminOrHigher)?_c('stat-card',{staticClass:"ml-15",attrs:{"iconSize":3,"cardSize":8,"type":'mini',"loading":!_vm.project.id,"label":"Creators","value":_vm.totalCreators,"icon":"user-group"}}):_vm._e(),(_vm.totalReach != 0 && _vm.isUserAdminOrHigher)?_c('stat-card',{staticClass:"ml-15",attrs:{"iconSize":3,"cardSize":8,"type":'mini',"loading":!_vm.project.id,"label":"Reach","value":_vm._f("numeral")(_vm.totalReach,'0.00a'),"theme":"light","icon":"trending-up","value-class":"uppercase","truncate":true}}):_vm._e(),(_vm.isUserAdminOrHigher)?_c('stat-card',{staticClass:"ml-15",attrs:{"iconSize":3,"cardSize":8,"type":'mini',"loading":!_vm.project.id,"label":"Budget Used","value":_vm._f("numeral")(_vm.totalCost,'0.0%'),"icon":"cash","compareFirst":_vm._f("numeral")(_vm.totalCost,'$0,0'),"compareSecond":_vm._f("numeral")(_vm.project.budget,'$0,0')}}):_vm._e(),(_vm.totalAdSpend > 0 && _vm.totalAds > 0  && _vm.isUserAdminOrHigher)?_c('stat-card',{staticClass:"ml-15",attrs:{"iconSize":3,"cardSize":8,"type":'mini',"loading":!_vm.project.id,"label":("Ad spend (" + _vm.totalAds + " ads)"),"value":_vm._f("numeral")(_vm.totalAdSpend,'$0,0'),"icon":"cash"}}):_vm._e(),(!!_vm.endsAt)?_c('stat-card',{staticClass:"ml-15",attrs:{"iconSize":3,"cardSize":8,"type":'mini',"loading":!_vm.project.id,"label":"End Date","value":_vm._f("moment")(_vm.endsAt,'MMM D, Y'),"icon":"calendar"}}):_vm._e()],1)],1):_c('skeleton',{attrs:{"height":6}})],1),_c('div',{staticClass:"tabs"},[_c('tabs',{attrs:{"theme":"light","tabs":_vm.projectTabs,"value":_vm.value},on:{"input":function (val) {
                _vm.$emit('input', val); 
                _vm.$forceUpdate();
            }}}),_c('div',{staticClass:"date"},[(!!_vm.project.start_at)?_c('time',[_vm._v("Started "+_vm._s(_vm._f("moment")(_vm.project.starts_at,'MMM D, Y')))]):_vm._e()])],1),_c('hr')])}
var staticRenderFns = []

export { render, staticRenderFns }