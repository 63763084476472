<template>
    <div>
        <div>
            <base-icon v-if="loading" name="loader"/>
            <facebook-token-info
                v-if="user"
                :user-id="user.id"
                :user="user"
                @showInviteToLoginWithFacebook="openSendInviteModal()"
            />
            <div v-else>No token info</div>
        </div>
        <div class="footer">
            <base-button
                type="secondary"
                icon="arrow-left"
                @action="$emit('close')"
                label="Back"
            />
        </div>
        <send-invite-modal
            :visible="sendInviteModalVisible"
            :user="user"
            :network-id="6"
            @close="sendInviteModalVisible = false"
        />
    </div>
</template>
<script>
import FacebookTokenInfo from '@/components/FacebookTokenInfo.vue'
import SendInviteModal from '@/components/SendInviteModal.vue'
export default {
    props: ['user'],
    components: {
        FacebookTokenInfo,
        SendInviteModal
    },
    data(){
        return {
            loading: false,
            sendInviteModalVisible: false
        }
    },
    methods: {
        openSendInviteModal(){
            this.sendInviteModalVisible = true;
        }
    }
}
</script>
<style lang="scss" scoped>
    .footer{
        @apply flex justify-between mt-10;
    }
</style>
