<template>
    <base-modal 
        title="Instagram Hashtag Search" 
        :visible="visible"
        @close="close()"
        styles="max-height: 90vh; overflow-y: auto; min-width: 60vw; width: 60vw;"
    >
        <div class="instagram-hashtag-search-container">
            <tab :visible="step === 1">
                <div class="step-1">
                    
                    <div class="title">Search for instagram posts by hashtag</div>

                    <div class="form">
                        
                        <div class="types-recent">
                            <div class="type-list">
                                <div 
                                    v-for="(type, index) in typeOptions" 
                                    :class="['type-item', { 'selected': selectedType.id == type.id }]"
                                    :key="index"
                                    @click="selectedType = type"
                                >
                                    {{ type.label }}
                                </div>
                            </div>
                            <div class="recently-searched-hashtags-button" @click="onToggleRecentlySearchedHashtags()">
                                <div class="title">{{recentlySearchedHashtagsVisible ? 'Hide' : 'Show'}} recently searched hashtags</div>
                                <base-icon :name="recentlySearchedHashtagsVisible ? 'chevron-up' : 'chevron-down'" :size="4"/>
                            </div>
                        </div>

                        <div class="recently-searched-hashtags-list" v-if="recentlySearchedHashtagsVisible">
                            <base-icon v-if="fetchingRecentlySearchedHashtags" name="loader"/>
                            <template v-else>
                                <div v-if="!recentlySearchedHashtags || !recentlySearchedHashtags.length">No recently searched hashtags found</div>
                                <div 
                                    v-for="(hashtag, index) in recentlySearchedHashtags" 
                                    :class="['hashtag-item', { 'selected': query == hashtag.name }]"
                                    :key="`hashtag-${index}`"
                                    @click="query = hashtag.name"
                                >
                                    #{{ hashtag.name }}
                                </div>
                            </template>
                        </div>

                        <div class="search">
                            <FormulateInput
                                v-model="query"
                                type="text"
                                placeholder="coke"
                                class="flex-grow flex-shrink"
                                :element-class="['flex items-center gap-x-1']"
                            >
                                <template #prefix>#</template>
                            </FormulateInput>
                            <base-button 
                                label="Search" 
                                class="flex-grow-0 flex-shrink-0"
                                :disabled="!query || query.trim() == ''"
                                icon="search"
                                @action="search()"
                            />
                        </div>

                    </div>
                    
                    <div class="posts">
                        <div class="loader" v-if="searching">
                            <div class="title">Searching #{{query}} {{selectedType.label.toLowerCase()}}</div> 
                            <base-icon name="loader" size="6" />
                            <div class="description">Please wait...</div>
                        </div>
                        <template v-else>
                            <div class="empty-state" v-if="!posts || !posts.length">No results found</div>  
                            <template v-else>
                                <div class="description">Select posts to add to the report</div>
                                <div class="post-list">
                                    <post-item 
                                        v-for="(post, index) in posts" 
                                        :key="index"
                                        :value="post"
                                        :is-selected="postIsSelected(post)"
                                        @toggle-post="togglePost(post)"
                                    />
                                </div>
                            </template>  
                        </template>
                    </div>
                </div>

            </tab>

            <tab :visible="step === 2">
                <div class="step-2">
                    <div class="title">{{`${selectedPosts.length == 1 ? `This post` : `These posts`}`}} will be added to the report</div>
                    <div class="posts">
                        <div class="post-list">
                            <post-item 
                                v-for="(post, index) in selectedPosts" 
                                :key="`selected-post-${index}`"
                                :value="post"
                                :readonly="true"
                            />
                        </div>
                    </div>
                </div>
            </tab>

            <tab :visible="step === 3">
                <div class="step-3">
                    <div class="loader">
                        <div class="title">Adding posts to report</div>
                        <base-icon name="loader" />
                        <div class="description">Please wait...</div>
                    </div>
                    <div class="progress">
                        <div class="progress-bar" :style="`transform: scaleX(${addingPostsProgressPercentage / 100});`" />
                    </div>
                </div>
            </tab>

            <tab :visible="step === 4">
                <div class="step-4">
                    <div class="title">Done</div>
                    <div v-if="successPosts > 0">{{successPosts}} posts added to the report.</div>
                    <div v-if="errorPosts > 0">{{errorPosts}} posts could not be added to the report.</div>
                </div>
            </tab>

            <div class="footer">
                <base-button 
                    label="Cancel" 
                    v-if="step == 1"
                    @action="close()" 
                    type="secondary" 
                    size="md"
                />
                <base-button 
                    label="Back"
                    v-if="step == 2"
                    @action="step--"
                    type="secondary" 
                    size="md" 
                />
                
                <div 
                    class="selected-count" 
                    v-if="step == 1 && selectedPosts.length"
                >
                    {{`${selectedPosts.length} ${`post${selectedPosts.length != 1 ? `s` : ``}`} selected`}}
                </div>

                <base-button 
                    label="Next"
                    bold
                    v-if="step == 1"
                    :disabled="!selectedPosts.length" 
                    @action="step++"
                    size="md"
                />
                <!-- TODO: add disabled tooltip -->
                <base-button
                    v-if="step == 2"
                    :label="`${!selectedPosts.length ? `Add posts to report` : `Add ${selectedPosts.length} ${`post${selectedPosts.length != 1 ? `s` : ``}`} to report`}`"
                    :disabled="!selectedPosts.length" 
                    @action="submit()"
                    bold
                    size="xl" 
                />

                <!-- to move continue button to the right -->
                <div v-if="step == 4" />
                <base-button 
                    label="Continue"
                    bold
                    v-if="step == 4"
                    @action="close()"
                    size="md" 
                />
            </div>
           
        </div>
    </base-modal>
</template>

<script>
    import { notifyCatchError } from '../../common'
    import PostItem from './InstagramHashtahSearchModalPostItem.vue';
    const typeOptions = [
        { id: 1, label: 'Top media', value: 'top_media' },
        { id: 2, label: 'Recent media', value: 'recent_media' }
    ];
    export default {
        name: 'InstagramHashtagSearchModal',
        props: ['visible'],
        components: {
            PostItem
        },
        data() {
            return {
                step: 1,
                
                // recently searched hashtags
                fetchingRecentlySearchedHashtags: false,
                recentlySearchedHashtagsVisible: false,
                recentlySearchedHashtags: null,

                query: '',
                selectedType: typeOptions[0],
                posts: [],
                selectedPosts: [],
                searching: false,

                // adding post step
                addingPosts: false,
                addingPostsProgressPercentage: 0, 

                // overview step
                errorPosts: 0,
                successPosts: 0,

                typeOptions
            }
        },
        methods: {
            postIsSelected(post){
                return !!this.selectedPosts.find(element => element.id == post.id)
            },
            togglePost(post){
                if(this.postIsSelected(post)){
                    this.selectedPosts = this.selectedPosts.filter(element => element.id !== post.id);
                }else{
                    this.selectedPosts.push(post)
                }
            },
            onToggleRecentlySearchedHashtags(){
                if(this.recentlySearchedHashtagsVisible){
                    this.recentlySearchedHashtagsVisible = false
                }else{
                    if(this.recentlySearchedHashtags == null){
                        this.getRecentlySearchedHashtags()
                    }
                    this.recentlySearchedHashtagsVisible = true
                }
            },
            getRecentlySearchedHashtags(){
                this.fetchingRecentlySearchedHashtags = true
                this.$http.get('/api/facebook/recently_searched_hashtags').then(({ data }) => {
                    this.recentlySearchedHashtags = data 
                }).catch(err => {
                    console.log(err);
                    notifyCatchError(this.$notify, err, 'Error while conducting hashtag search, please try again later.')
                }).finally(() => {
                    this.fetchingRecentlySearchedHashtags = false;
                });
            },
            search(){
                this.searching = true
                this.$http.get(`/api/facebook/ig_hashtag_search`, {
                    params: {
                        query: this.query,
                        type: this.selectedType.value
                    }
                }).then(({ data }) => {
                    this.posts = data
                }).catch(err => {
                    console.log(err);
                    notifyCatchError(this.$notify, err, 'Error while conducting hashtag search, please try again later.')
                }).finally(() => {
                    this.searching = false;
                });
            },
            submit(){

                this.step = 3;
                this.addingPosts = true;

                let totalPosts = this.selectedPosts.length;
                
                let completedPosts = 0;
                let successPosts = 0;
                let errorPosts = 0;

                const requests = this.selectedPosts.map(post => this.$http.post(`/api/reports/${this.$route.params.id}/add-post`, { 
                        link: post.permalink
                    }).then((/*{ data }*/) => {
                        completedPosts++;
                        successPosts++;

                        let percentage = Math.ceil(completedPosts * 100 / totalPosts);
                        percentage = percentage <= 100 ? percentage : 100;
                        this.addingPostsProgressPercentage = percentage;

                    }).catch((/*err*/) => {
                        completedPosts++;
                        errorPosts++;
                    })
                );

                Promise.allSettled(requests).then(responses => {
                    console.log('responses', responses)
                }).catch(err => {
                    console.log('err', err)
                }).finally(() => {

                    if(!errorPosts){
                        this.$notify({ title: 'Success', text: `All posts added to report`, type: 'success' });
                    }else{
                        this.$notify({ 
                            title: 'Success with warnings',                             
                            text: `${successPosts} post(s) added, and ${errorPosts} post(s) could not be added to the report.`, 
                            type: 'warn' 
                        });
                    }
                    
                    // show overview
                    this.errorPosts = errorPosts;
                    this.successPosts = successPosts;
                    this.step = 4;
                    
                    // reset stuff
                    this.addingPosts = false;
                    this.addingPostsProgressPercentage = 0;

                    this.$emit('reload');

                });

            },
            close(){
                this.step = 1;
                this.query = '';
                this.selectedType = typeOptions[0];
                this.posts = [];
                this.selectedPosts = [];
                this.searching = false;
                this.addingPosts = false;
                this.addingPostsProgressPercentage = 0; 
                this.errorPosts = 0;
                this.successPosts = 0;
                this.$emit('close')
            }
        }
    }
</script>
<style lang="scss" scoped>
    .instagram-hashtag-search-container{

        .step-1{
            .title{
                @apply font-bold text-h4;
            }
            .form{
                @apply flex flex-col gap-y-4 mt-8;
                
                .types-recent{
                    @apply flex gap-x-4;
                    .type-list{
                        @apply flex gap-x-4;
                        > .type-item{
                            @apply cursor-pointer rounded-lg px-4 py-2 bg-gray-200;
                            @apply transition-colors duration-200;
                            &:hover{
                                @apply bg-gray-300;
                            }
                            &.selected{
                                @apply bg-purple-m-main text-white;
                            }
                        }
                    }

                    .recently-searched-hashtags-button{
                        @apply p-2.5 rounded-lg cursor-pointer transition-colors duration-300;
                        @apply flex items-center gap-x-4;
                        > .title{
                            @apply text-h5;
                        }
                        &:hover{
                            @apply bg-gray-400;
                        }
                    }
                }

                .recently-searched-hashtags-list{
                    @apply flex gap-x-4;
                    .hashtag-item{
                        @apply cursor-pointer rounded-lg px-4 py-2 bg-gray-200;
                        @apply transition-colors duration-200;
                        &:hover{
                            @apply bg-gray-300;
                        }
                        &.selected{
                            @apply bg-purple-m-main text-white;
                        }
                    }
                }

                .search{
                    @apply flex;
                }

            }
            .posts{
                @apply mt-4;
                .loader{
                    @apply flex flex-col items-center gap-y-2;
                    .title{
                        @apply font-bold text-h4;
                    }
                    .description{
                        @apply font-bold;
                    }
                }
                .empty-state{
                    @apply flex justify-center font-bold;
                }
                .description{
                    @apply font-bold pb-2;
                }
                .post-list{
                    @apply flex flex-wrap gap-x-4 gap-y-4;
                }
            }
        }

        .step-2{
            .title{
                @apply font-bold text-h4;
            }
            .posts{
                @apply mt-4;
                .post-list{
                    @apply flex flex-wrap gap-x-4 gap-y-4;
                }
            }
        }

        .step-3{
            .loader{
                @apply flex flex-col items-center gap-y-2;
                .title{
                    @apply font-bold text-h4;
                }
                .description{
                    @apply font-bold;
                }
            }
            .progress{
                @apply w-96 h-6 mt-4 mx-auto rounded-full overflow-hidden bg-gray-300;
                .progress-bar{
                    @apply bg-green-m-main w-full h-6 transition-transform duration-500;
                    transform-origin: left;
                }
            }
        }

        .step-4{
            .title{
                @apply font-bold text-h4 mb-2;
            }
        }
    }
    .footer{
        @apply sticky bottom-0 left-0 right-0 bg-tan-m h-20 z-50;
        bottom: -1px;
        @apply w-full flex justify-between items-center;
        > .selected-count{
            @apply font-bold;
        }
    }
</style>
