<template>
<div class="report-user-card-wrapper">
    
    <!-- header -->
    <div class="header">

        <!-- last updated at -->
        <div class="last-updated-at" v-if="isAdmin && lastUpdated">
            Last updated at {{ lastUpdated }}
        </div>
        
        <!-- profile picture -->
        <div class="profile-picture">
            <img-selector
                :images="profilePicture"
                cover
                :prop-class="`rounded-full h-28 w-28 ${audiences === 1 ? 'cursor-pointer' : ''}`"
                @click="openProfile"
            />
        </div>
        
        <!-- creator info -->
        <div class="creator-info">
            
            <!-- general info -->
            <div class="general-info">
                <!-- name -->
                <div class="name">{{ displayName }}</div>
                <!-- city container -->
                <div class="city-container" v-if="audience.user_profile && audience.user_profile.geo && audience.user_profile.geo.city">
                    <base-icon name="location" wrapper-class="inline-block"/>
                    <span class="city">{{ audience.user_profile.geo.city.name }}</span>
                </div>
            </div>
            
            <!-- feedback button -->
            <div class="feedback-button-container">
                <base-button 
                    label="Feedback"
                    v-if="!printView && allowFeedback"
                    class="ml-auto"
                    @action="$emit('feedback', {id: user.id, feedback })"  
                    bold 
                    icon="report-feedback" 
                />
            </div>

            <!-- description -->
            <div class="description enriched-format" v-if="!!reportUser.description && reportUser.description !== ''" v-html="reportUser.description"></div>
            
            <!-- tags list -->
            <div class="tag-list" v-if="user.tags.length">
                <div
                    v-for="tag in computedTags"
                    :key="`${tag.slug}_${user.id}${Math.random()}`"
                    class="tag-item"
                    :class="{'is-admin': isAdmin}"
                >
                    {{ tag.name }}
                    <a class="remove-tag-button" v-if="isAdmin" @click="removeTag(user, tag)">
                        <base-icon name="close"/>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <!-- stats -->
    <report-user-card-stats
        v-if="audience.user_profile"
        :user="user"
        :settings="settings"
        :printView="printView"
        :is-admin="isAdmin"
    />
    
    <!-- empty states & CTA -->
    <template v-else>
        <div 
            v-if="isAdmin" 
            class="empty-state"
        >
            This user has no audience data yet.
        </div>
        <div 
            v-else
            class="empty-state"
        >
            We don't have data for this creator yet. Try again later.
        </div>

        <div class="call-to-action" v-if="isAdmin">
            <base-button 
                label="Get Profile" 
                @action="$emit('reload-user')"
            />
        </div>
        
    </template>

    <!-- content -->
    <div class="content space-y-3">

        <tabs
            :tabs="[
                { name: 'Posts', active: true }, 
                { name: 'Additional Information', active: true }
            ]"
            v-model="currentTab"
            capitalize
            nav-class="pt-5"
            nav-styles="margin-top: 0px;"
            styles="font-size: 1.25rem; padding-bottom: 0.8rem;"
            custom-class=""
        />
        
        <!-- show posts -->
        <div class="post-grid-wrapper" v-if="currentTab == 'Posts'">

            <!-- empty state -->
            <div
                v-if="!isValidArray(computedPosts)"
                class="empty-state"
            >
                {{ reportUser && (reportUser.post_show_type == 2 || (reportUser.post_show_type == null && isValidArray(reportUser.featured_posts) ) ) ? 'No featured posts yet' : 'No top posts yet' }} 
            </div>

            <!-- post grid -->
            <div v-else class="post-grid">
                <div  
                    :key="masonryItemsKey" 
                    v-masonry 
                    item-selector=".post-card" 
                    fit-width="true" 
                    transition-duration="0.3s" 
                    gutter="20" 
                    horizontal-order="true"
                >
                    <component
                        :is="settings.platforms ? 'report-social-post-card' : 'report-post-card'"
                        v-masonry-tile
                        class="post-card mb-4"
                        :post="item"
                        is-user-report
                        v-for="(item, i) in computedPosts" :key="i" v-show="i < 9"
                        :printView="printView"
                        @redraw="redrawPosts()"
                        @edit-post="(p) => $emit('edit-post', p)"
                        @replace-post="replacePost"
                        :allow-edit="allowReplace"
                        @loaded="loadedPost"
                        :allow-replace="allowReplace"
                        :hide-stats="settings.hide_stats"
                        :show-boosted-data="settings.show_boosted_data"
                        :force-wait="forceWait"
                        :run-loader="runLoader"
                        :updated-at="updatedAt"
                    />
                </div>
            </div>
        </div>

        <!-- show additional information -->
        <tab :visible="currentTab == 'Additional Information'">
            <div class="additional-info">
                <div class="content">
                    <div 
                        class="empty-state" 
                        v-if="!isArray(reportUser.additional_info)"
                    >
                        No additional info
                    </div>
                    <div 
                        v-else 
                        class="info-list divide-y divide-gray-200"
                    >
                        <div
                            class="info-item" 
                            v-for="(info, index) in reportUser.additional_info" 
                            :key="`info-${index}`"
                        >
                            <div class="content">
                                <div class="name">{{info.name}}</div>
                                
                                <!-- link type -->
                                <template v-if="info.type_detail.slug == 'link'">
                                    <!-- links with title -->
                                    <a  
                                        v-if="info.parsed_value.includes('|')"
                                        :href="info.parsed_value.split('|')[1]"
                                        class="value link"
                                        target="_blank"
                                    >
                                        {{info.parsed_value.split('|')[0]}}
                                    </a>
                                    <!-- simple links -->
                                    <a  
                                        v-else
                                        :href="info.parsed_value.trim()"
                                        class="value link"
                                        target="_blank"
                                    >
                                        {{info.parsed_value.trim()}}
                                    </a>
                                </template>
                                <!-- other values -->
                                <div v-else class="value">{{ info.parsed_value || info.value }}</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </tab>
        
        <!-- printview feedback -->
        <h1 
            class="feedback-title"
            v-if="printView && allowFeedback && false"
        >
            Feedback
        </h1>

        <!-- TODO Not sure when this is shown as we are never changing "tab" to "feedback" maybe check the computedTabs() method but its also not being used -->
        <div 
            class="feedback-content" 
            v-if="(tab === 'feedback' && allowFeedback) || (printView && allowFeedback && false)"
        >
            <table>
                <tr v-for="(item, i) in feedback.ratings" :key="i">
                    <th class="bg-white">{{ item.display }}</th>
                    <td>{{ item.value }}</td>
                </tr>
                <tr v-if="feedback.comments !== ''">
                    <th>Comments</th>
                    <td colspan="2">{{ feedback.comments }}</td>
                </tr>
            </table>
        </div>
    </div>

    <!-- <separator class="my-20" v-if="!printView && !isLast"/> -->

    <div class="printer-separator"></div>

</div>
</template>

<script>
const defaultPicture = require('../../assets/images/default-picture.png');
const defaultProfile = require('../../assets/images/default-profile.jpg');
import StatCard from '../../components/StatCard';
import { isArray, isNotNull, isValidArray, NETWORKS } from '../../common.js';
import { computedIndexStr } from '../../lib/strings.js';
import ReportPostCard from '../../components/ReportPostCard';
import ReportSocialPostCard from '../../components/ReportSocialPostCard';
import ReportUserCardStats from '../../components/ReportUserCardStats.vue';
import ImgSelector from '../../components/ImgSelector.vue';
import { 
    avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7,
    avatar8, avatar9, avatar10, avatar11, avatar12, avatar13, avatar14
} from '../../assets/images/avatars/index.js'

// not using this right now
// const arrayChunk = (arr, size) =>
//     Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
//         arr.slice(i * size, i * size + size)
//     );

export default {
    props: {
        user: {},
        feedback: {},
        reportUser: {},
        settings: {},
        isAdmin: {},
        allowFeedback: {},
        allowReplace: {},
        printView: {},
        isLast: {},
        replacedPost: {},
        position: {},
        updatedAt: {},
        runLoader: {
            type: Boolean,
            default: false
        },
        forceWait: {
            type: Boolean,
            default: false
        }
    },

    components: {
        ReportSocialPostCard,
        ReportPostCard,
        StatCard,
        ReportUserCardStats,
        ImgSelector
    },

    data() {
        return {
            tab: 'popular-posts',
            currentTab: 'Posts',
            selected: false,
            NETWORKS,
            postsLoaded: 0,
            masonryItemsKey: false,
            removedTags: [],
            avatars: [
                avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7,
                avatar8, avatar9, avatar10, avatar11, avatar12, avatar13, avatar14
            ],
            isArray,
            isValidArray
        }
    },

    computed: {
        lastUpdated() {
            if (this.updatedAt || this.post.updated_at) {
            let date = new Date(this.updatedAt || this.post.updated_at);
            let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString('en-US', options);
            }

            return null;
        },

        computedTags() {
            return this.user.tags.filter(t => {
                return !this.removedTags.includes(t.id);
            });
        },

        postsLimit() {
            if (this.settings && this.settings.posts_limit && this.settings.posts_limit.id) {
                return parseInt(this.settings.posts_limit.id);
            }

            return 9;
        },
        computedAudience() {
            let networks = this.user.report_user.networks;
            return this.user.audience_data.filter(network => {
                return (networks === null) || networks.includes(network.network_id);
            });
        },

        computedTabs() {
            let tabs = ['popular-posts', 'audience', 'description'];
            if (this.allowFeedback) {
                tabs.push('feedback');
            }
            return tabs;
        },

        computedPosts() {

            // if there is no user_profile we wont show anything
            if (!this.audience.user_profile) {
                return [];
            }

            // get basic profile info
            let { fullname, avg_likes, description, avg_views, username, followers, url, picture, type, user_id } = this.audience.user_profile;
            
            // set network & network_id
            let network = {};
            let network_id = 0;
            for (let i in NETWORKS) {
                if (NETWORKS[i].name === type || NETWORKS[i].id === type) {
                    network_id = NETWORKS[i].id;
                    network = {
                        display_name: NETWORKS[i].display_name,
                        name: NETWORKS[i].name,
                        id: network_id
                    }
                }
            }

            // profile item object
            let profile_item = {
                bio: description,
                uri: url,
                username: username || fullname,
                full_name: fullname,
                social_id: user_id,
                total_likes: avg_likes,
                total_views: avg_views,
                total_videos: 0,
                total_followers: followers,
                total_following: 0,
                profile_picture_url: picture,
                network_id,
            };
              
            
            // By default, "post_show_type" will be "top_posts" (1) this means that by default we will show the creator's top posts
            let post_show_type = 1;
            
            // If the "post_show_type" is explicitly set to "featured_posts" (2) then we will switch to the creator's featured posts
            if(this.reportUser && isNotNull(this.reportUser.post_show_type) && this.reportUser.post_show_type == 2){
                post_show_type = 2; // featured posts
            }
            
            // If the "post_show_type" is not set, or null, but the "featured_posts" array is populated, we will switch to the creator's featured posts too
            if(this.reportUser && this.reportUser.post_show_type == null && isValidArray(this.reportUser.featured_posts)){
                post_show_type = 2; // featured posts
            }
        
            let posts = [];
            if(post_show_type == 1){
                posts = this.audience.user_profile.top_posts;
            }
            if(post_show_type == 2){
                posts = this.reportUser.featured_posts;
                // [pending-featured-post]
                // There can be pending featured posts, in those cases the "post_id" is not defined,
                // "is_pending_featured_post" is true and the "url" is set.
                // We will filter out those pending featured posts as we dont want those to be showing
                // on the report view
                posts = posts.filter(post => {
                    return post.post_id && !post.is_pending_featured_post;
                }).map(post => {
                    post.is_featured = true;
                    return post;
                });
            }

            return posts.slice(0, this.postsLimit).map(post => {
                let is_video = post.type === 'video';
                let is_replaced = false || post.is_replaced;
                let element = {
                    comments: post.stat.comments,
                    likes: post.stat.likes,
                    dislikes: 0,
                    views: post.stat.views || post.stat.plays,
                    shares: post.stat.shares,
                    saves: post.stat.saves,
                    caption: post.text || '',
                    display_url: post.image || post.thumbnail,
                    id: post.id,
                    post_identifier: post.post_identifier || post.post_id,
                    is_copyrighted: !!post.is_copyrighted,
                    profile_item,
                    network_id,
                    network,
                    is_replaced,
                    is_featured: post.is_featured,
                    date: post.created,
                    media: [{
                        id: post.user_id,
                        mimetype: is_video ? 'video/mp4' : 'image/png',
                        media_identifier: null,
                        video_duration: "00:00:00",
                        url: post.video || post.image || post.thumbnail,
                        type: is_video ? 'video/mp4' : 'image/png'
                    }],
                    type: {
                        name: 'post'
                    },
                    url: post.link,
                    original_url: is_replaced ? post.original_url : post.link,
                    is_video,
                    boosted_data: post.boosted_data || [],
                    copyright_status: post.copyright_status || []
                };

                let find = this.replacedPost.filter(p => p.post).find(p => p.original_url === post.link);
                if (find) {
                    element.id = find.post.id;
                    element.post_identifier = find.post.post_identifier || find.post.post_id;
                    element.display_url = find.post.display_url;
                    element.media[0].url = find.post.display_url;
                    element.url = find.post.url;
                    element.is_replaced = true;
                    element.comments = find.post.comments;
                    element.likes = find.post.likes;
                    element.dislikes = find.post.dislikes;
                    element.views = find.post.views;
                    element.shares = find.post.shares;
                    element.saves = find.post.saves;
                    element.caption = find.post.caption || '';
                    element.date = find.post.date;
                    element.boosted_data = find.post.boosted_data;
                    element.copyright_status = find.post.copyright_status;
                }

                return element;
            }).sort((a, b) => {
                a.date = new Date(a.date);
                b.date = new Date(b.date);
                return b.date - a.date;
            });
        },

        displayName() {

            // use company name if it exists
            let useCompanyName = this.user.user_companies && this.user.user_companies[0] && (this.user.user_companies[0].first_name && this.user.user_companies[0].last_name)
            if(useCompanyName){
                return `${this.user.user_companies[0].first_name} ${this.user.user_companies[0].last_name}`
            }else{      
                if (this.user.first_name) {
                    return `${this.user.first_name} ${this.user.last_name}`;
                }

                return this.audience.user_profile.fullname;
            }
        },

        feedbackScore() {
            if (!this.feedback.ratings) {
                return 0;
            }

            return this.feedback.ratings.reduce((prev, curr) => {
                prev += curr.value;
                return prev;
            }, 0);
        },

        totalFeedback() {
            if (!this.feedback.ratings) {
                return this.feedback.length * 5;
            }

            return this.feedback.ratings.length * 5;
        },

        audience() {
            let igAccount = this.user.audience_data.find(audience => {
                return audience.network_id === 2;
            });
            let computed = this.computedAudience[0];
            let audience = this.user.audience_data[0];
            let payload = (computed && computed.payload) ? computed.payload : (igAccount ? igAccount.payload : (audience ? audience.payload : {})); 
            if((!payload.user_profile.type || payload.user_profile.type == "") && (computed && computed.network_id)) payload.user_profile.type = computed.network_id
            return payload
        },

        audiences() {
            return this.user && this.user.audience_data ? this.user.audience_data.length : 0;
        },

        profilePicture() {
            let images = [];

            if (this.audience.user_profile && this.audience.user_profile.picture) {
                let src = this.audience.user_profile.picture;
                let isTiktok = src.includes('tiktokcdn');
                
                if (!(this.printView && isTiktok)) {
                    images.push(src);
                }
            }
            if (this.user && this.user.avatar) {
                images.push(this.user.avatar);
            }
            images.push(this.randomAvatar);
            return images;
        },

        randomAvatar(){
            const computedIndex = this.computedIndex
            return this.avatars[computedIndex];
        },

        computedIndex(){
            return computedIndexStr(this.position || 0);
        },
    },

    methods: {
        loadedPost(status) {
            if (!status) {
                console.log('Failed to load video');
            }
            this.postsLoaded++;
            let videosLength = this.computedPosts.filter(p => p.is_video).length;
            if (this.postsLoaded >= videosLength) {
                this.$emit('videos-loaded');
            }
        },

        async removeTag(user, tag) {
            try {
                let { data } = await this.$http.post('/api/users/toggle-tags', {user_id: user.id, tag_id: tag.id});
                console.log('removeTag data', data);
                this.removedTags.push(tag.id);
                this.$notify({ title: 'Success', text: 'Tag deleted successfully', type: 'success' });
            } catch (err) {
                console.error(err);
            }
        },
        replacePost(item) {
            this.$emit('replace-post', this.user, item);
        },

        redrawPosts(){
            setTimeout(() => {
                this.$redrawVueMasonry();
            }, 500);
        },

        changePicture(isProfile = true, item = null) {
            if (isProfile) {
                this.audience.user_profile.picture = defaultProfile;
            } else if (item) {
                item.image = defaultPicture
            }
        },

        openLink(url) {
            window.open(url);
        },

        openProfile() {
            if (this.audiences === 1) {
                let url = this.user.audience_data[0].social_url;
                if (url) {
                    this.openLink(url)
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .report-user-card-wrapper{
        @apply px-0 relative;
        > .header{
            @apply flex w-full gap-6 relative;
            > .last-updated-at{
                @apply absolute text-xs right-0 bottom-2;
            }
            > .profile-picture{
                @apply w-36 mb-6;
            }
            > .creator-info{
                @apply w-full grid grid-cols-6 my-6 flex-grow items-center;
                > .general-info{
                    @apply col-span-3;
                    @media (width >= 48rem) {
                        @apply col-span-4;
                    }
                    > .name{
                        @apply font-bold text-2xl text-left;
                    }
                    > .city-container{
                        @apply flex items-center gap-x-1;
                        > .city{
                            @apply text-sm text-black whitespace-no-wrap;
                        }
                    }
                }
                > .feedback-button-container{
                    @apply col-span-3;
                    @media (width >= 48rem) {
                        @apply col-span-2;
                    }
                }
                > .description{
                    @apply col-span-6 text-sm text-gray-500 text-left;
                    @media (width >= 48rem) {
                        @apply col-span-4 mt-4;
                    }
                }
                > .tag-list{
                    @apply flex col-span-6 text-left gap-2;
                    @media (width >= 48rem) {
                        @apply mt-2;
                    }
                    > .tag-item{
                        @apply flex items-center text-xs font-bold rounded-full bg-green-m-main px-3 py-2;
                        &.is-admin{
                            &:hover{
                                @apply bg-green-m-main-dark;
                            }
                        }
                        > .remove-tag-button{
                            @apply flex items-center text-red-600 cursor-pointer;
                        }
                    }                

                }

            }
        }
        // <report-user-card-stats />
        > .empty-state{
            @apply text-center my-4;
        }
        > .call-to-action{
            @apply flex justify-center;
        }
        > .content{
            @apply w-full p-4 mb-10;
            > .post-grid-wrapper{
                @apply w-full;
                > .post-grid{
                    @apply grid grid-cols-2 gap-8 mt-8 mb-4;
                    @media (width >= 48rem) {
                        @apply grid-cols-4;
                    }
                }
                > .empty-state{
                    @apply p-12;
                }
            }
            > .feedback-title{
                @apply text-left font-semibold text-2xl pt-5;
            }
            > .feedback-content{
                @apply w-full;
            }
        }
    }
    // These classes are outside because they are inside a tab component
    .additional-info{
        @apply w-full;
        > .content{
            @apply mt-12 mb-10;
            > .empty-state{
                @apply;
            }
            > .info-list{
                // @apply grid grid-cols-2;
                > .info-item{
                    @apply flex p-5 gap-x-10 justify-between;
                    > .content{
                        @apply flex;
                        > .name{
                            @apply font-bold;
                            width: 300px;
                            text-align: left;
                        }
                        > .value{
                            @apply;
                            width: 300px;
                            text-align: left;
                            &.link{
                                @apply font-bold text-blue-500 cursor-pointer;
                                &:hover{
                                    @apply opacity-70;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
