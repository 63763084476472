import axios from 'axios';
import { isValidArray } from '../common';

export const statuses = {
    namespaced: true,
    state: {
        statuses: [],
        filterableStatuses: [], // These were used in the project list to filter the project, does not seem to be used anymore
        statusesLoading: false
    },
    mutations: {
        setStatuses(state, val) {
            state.statuses = val;
        },
        setFilterableStatuses(state, val){
            state.filterableStatuses = val;
        },
        setStatusesLoading(state, val){
            state.statusesLoading = val;
        }
    },
    actions: {
        async getStatuses({commit, state}, forceUpdate = true) {

            try{

                // if forceUpdate is set to false we will check if
                // the "statuses" array has elements, and if that
                // is the case we wont load the statuses again
                if(forceUpdate == false){
                    if (isValidArray(state.statuses)){
                        return;
                    }
                }

                await commit('setStatusesLoading', true);

                const { data } = await axios.get('/api/statuses');
                
                if (isValidArray(data)) {

                    // ----------------------------
                    // Setting filterable statuses
                    // ----------------------------
                    let filterableStatuses = [];
                    for (const status of data) {
                        // prevent duplicates
                        const found = !filterableStatuses.find(e => e.value === status.name);
                        if(!found){
                            filterableStatuses.push({value: status.name, label: status.name});
                        }
                    }
                    if(isValidArray(filterableStatuses)){
                        await commit('setFilterableStatuses', filterableStatuses);
                    }

                    // -----------------
                    // Setting statuses
                    // -----------------
                    const mapped = data.map(status => {
                        status.showActions = false;
                        status.editing = false;
                        status.editName = status.name;
                        status.editColor = status.color;
                        status.deleting = false;
                        return status;
                    });
                    await commit('setStatuses', mapped);

                }

            }catch(err){
                console.log('getStatuses error:', err);
            }finally{
                await commit('setStatusesLoading', false);
            }
        }
    }
};