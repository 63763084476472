<template>
    <div class="wrapper scroll-dark">

        <div id="scroll-container" ref="scroll_container">

            <div class="project" v-for="(project, index) in projects" :key="index">

                <div class="top">
                    <div class="info">
                        <div class="name has-tooltip" @click="$router.push(`/projects/${project.slug}`)">
                            {{`${project.name} - ${project.brand}`}}
                        </div>
                        <div class="budget" v-if="isAdminOrHigher">Budget: {{ project.budget | numeral('$0,0.00') }}</div>
                    </div>
                    <div class="options-container">
                        <options-dropdown
                            theme="dark"
                            custom-drop-down="right--4"
                            :right="false"
                            :top="false"
                            :options="[
                                ...(isAdminOrHigher ? [{
                                    label: 'Edit',
                                    icon: 'pencil-alt',
                                    event:'edit'
                                }] : []),
                                {
                                    label: 'Unpin',
                                    icon: 'flagged',
                                    event: 'unpin',
                                    separator: true
                                },
                                ...(isAdminOrHigher ? [{
                                    label: 'Archive',
                                    icon: 'trash',
                                    theme: 'cancel',
                                    event: 'archive'
                                }]: []),
                            ]"
                            @edit="$router.push(`/projects/${project.slug}/edit`)"
                            @unpin="$emit('toggle-pinned', project.id, true)"
                            @archive="$emit('archive-project', project, true)"
                        />
                    </div>
                </div>

                <div class="bottom">
                    <div class="owners-container">
                        <div class="empty-state" v-if="!owners(project.project_owners).length">
                            <span class="avatar">                                                
                                <span class="text">UA</span>
                            </span>
                            <span class="text">Unassigned</span>
                        </div>
                        <avatar-list
                            theme="dark"
                            :users="owners(project.project_owners)"
                            :showAmount="5"
                            :showExtra="true"
                        />
                    </div>
                    <div class="status-dropdown container">
                        <div v-if="!isAdminOrHigher" class="text-white w-full">{{project.statusLabel}}</div>
                        <crud-dropdown
                            v-else
                            :value="{
                                name: project.statusLabel,
                                id: project.status
                            }"
                            :create="{
                                route: '/api/statuses',
                                success: 'Status created successfully',
                                error: 'Failed to create new status',
                                warning: 'Status name required'
                            }"
                            :select="{
                                route: `/api/projects/${project.id}/status`,
                                success: 'Status updated successfully',
                            }"
                            :edit="{
                                route: '/api/statuses/',
                                success: 'Status updated successfully',
                                error: 'Failed to edit status',
                                warning: 'Status name required',
                            }"
                            :delete="{
                                deleteLabel: 'status',
                                route: '/api/statuses/',
                                success: 'Status deleted successfully',
                                error: 'Failed to delete status'
                            }"
                            :statuses="statuses"
                            @done="$emit('refresh-data')"
                            @toggle-dropdown="runScrollPinned"
                        />
                    </div>                                        
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AvatarList from '@/components/AvatarList.vue'
import { mapGetters } from 'vuex';

export default {
    props: ['projects'],
    components: {
        AvatarList,
    },
    data(){
        return {
            scrollFinished: true
        }
    },

    computed: {
        ...mapGetters(['statuses', 'isAdminOrHigher']),
    },
    
    methods: {
        runScrollPinned() {
            setTimeout(() => {
                let container = document.getElementById('scroll-container');
                if (container) {
                    if (container.scrollHeight > container.clientHeight) {
                        container.scroll(container.scrollLeft, container.scrollHeight - container.clientHeight);
                    }
                }
            }, 150);
        },

        scrollX(e) {

            if(this.projects.length >= 4){
                e.preventDefault();
            }

            if(this.scrollFinished){
                this.$refs['scroll_container'].scrollLeft += e.deltaY;
                this.shouldBe = this.$refs['scroll_container'].scrollLeft + e.deltaY
            }
            
            if(e.deltaY < 0){
                if(this.$refs['scroll_container'].scrollLeft - 50 <= this.shouldBe){
                    this.scrollFinished = true
                }else{
                    this.scrollFinished = false
                }
            }else{
                if(this.$refs['scroll_container'].scrollLeft + 50 >= this.shouldBe){
                    this.scrollFinished = true
                }else{
                    this.scrollFinished = false
                }
            }

        },
        owners(owners){
            return owners.map(element => element.user).reverse();
        }
    }
}
</script>
<style lang="scss" scoped>
    .wrapper{
        @apply w-full pr-1 overflow-y-auto;
        min-height: calc(100vh - 17rem);
        max-height: calc(100vh - 17rem);

        #scroll-container{
            @apply w-full h-full flex flex-col flex-no-wrap gap-x-4 gap-y-4 overflow-x-auto;
            scroll-behavior: smooth;
            &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }
            &::-webkit-scrollbar-track {
                background: transparent;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background: #5b5b5b;
            }
            &::-webkit-scrollbar-corner {
                background: #0c0c0c;
            }

            .project{
                @apply bg-purple-m-secondary bg-opacity-70 mr-6 py-4 px-6 flex-shrink-0 rounded-3xl;
                .top{
                    @apply flex w-full justify-between items-start;
                    .info{
                        @apply w-10/12;
                        .name{
                            @apply cursor-pointer truncate text-h6 text-white font-bold truncate;
                        }
                        .budget{
                            @apply text-purple-m-main-light text-ps pt-1 pb-2;
                        }
                    }
                    .options-container{
                        @apply w-2/12 inline-flex justify-end;
                    }
                }
                .bottom{
                    @apply flex flex-col w-full justify-between gap-2;
                    .owners-container{
                        @apply flex items-center h-8;
                        max-width: 200px;
                        .empty-state{
                            @apply inline-flex gap-2 items-center;
                            > .avatar{
                                @apply inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-m;
                                > .text{
                                    @apply text-white text-xs;
                                }
                            }
                            > .text{
                                @apply text-sm text-gray-m-disable;
                            }
                        }
                    }
                    .status-dropdown.container{
                        @apply flex flex-wrap justify-center w-44;
                    }
                }
            }
        }
    }
</style>