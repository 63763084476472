var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"crud-dropdown-wrapper"},[_c('base-button',{class:[
      'main-button',
      _vm.theme,
      { 'dropdown-visible': _vm.dropdownVisible },
      { 'disabled': _vm.disabled }
    ],style:(_vm.getValueColor ? ("background-color: " + _vm.getValueColor) : ''),attrs:{"type":"label","rounded":"full","disabled":_vm.disabled || _vm.loading,"theme":_vm.getValueColor ? (_vm.hexToRgbSum(_vm.getValueColor) > _vm.themeThreshold ? 'light' : 'dark') : _vm.theme,"hover":_vm.getValueColor ? false : true},on:{"action":function($event){return _vm.toggleDropdownVisible(!_vm.dropdownVisible)}}},[_c('div',{staticClass:"main-button-content"},[_c('div',{staticClass:"main-button-label"},[_vm._v(_vm._s(_vm.getValueName))]),(!_vm.disabled)?_c('base-icon',{staticClass:"ml-auto",attrs:{"name":"chevron-down"}}):_vm._e()],1)]),(_vm.dropdownVisible)?_c('div',{class:[
      'dropdown-wrapper',
      _vm.theme
    ]},[_c('div',{staticClass:"dropdown-container"},[_c('div',{staticClass:"dropdown-content"},[_c('div',{staticClass:"header",on:{"click":function (e){ return e.stopPropagation(); }}},[(_vm.step == 'create')?_c('base-button',{attrs:{"label":"New Status","type":"label","icon":"arrow-left","rounded":"none","theme":"neutral","disabled":_vm.loading},on:{"action":function($event){return _vm.back()}}}):(_vm.step == 'edit')?_c('base-button',{attrs:{"label":"Edit Status","type":"label","icon":"arrow-left","rounded":"none","theme":"neutral","disabled":_vm.loading},on:{"action":function($event){return _vm.back()}}}):_c('span',{staticClass:"title"},[_vm._v("Statuses")]),(_vm.step == 'default')?_c('base-button',{attrs:{"icon":"plus","type":"label","disabled":_vm.loading},on:{"action":function($event){_vm.step = 'create'}}}):_vm._e()],1),(_vm.step == 'default')?_c('div',{staticClass:"status-list"},[(!_vm.isValidArray(_vm.statuses))?_c('div',{staticClass:"empty-state"},[_vm._v(" There are no statuses available. ")]):_vm._l((_vm.statuses),function(status,i){return _c('div',{key:i,staticClass:"status-item",on:{"mouseenter":function($event){status.showActions = true},"mouseleave":function($event){status.showActions = false}}},[_c('div',{staticClass:"left button-container"},[(!status.editing)?_c('base-button',{class:[
                    'status-button',
                    { 'has-color': status.color }
                  ],style:(status.color ? ("background-color: " + (status.color.name)) : ''),attrs:{"type":"label","rounded":"lg","justify":"start","theme":status.color ? (_vm.hexToRgbSum(status.color.name) > _vm.themeThreshold ? 'light' : 'dark') : _vm.theme,"hover":status.color ? false : true,"disabled":_vm.loading,"label":status.name},on:{"action":function($event){return _vm.selectStatus(status)}}}):_vm._e()],1),_c('div',{staticClass:"right actions",on:{"click":function (e){ return e.stopPropagation(); }}},[(!status.editing)?_c('base-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(status.is_default ? { content: "Default status", classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } } : {}),expression:"status.is_default ? { content: `Default status`, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } } : {}"}],class:['w-full h-full'],attrs:{"disabled":_vm.step == 'edit' || _vm.step == 'create' || status.is_default || _vm.loading,"theme":status.is_default ? 'light' : 'neutral',"icon":"pencil","type":"label","rounded":"full"},on:{"action":function($event){return _vm.editStatusIntent(status)}}}):_vm._e()],1)])})],2):_vm._e(),(_vm.step == 'create')?_c('div',{staticClass:"create-status-step-wrapper"},[_c('div',{class:[ 
              'create-status-step-container',
              _vm.theme
            ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.createStatusLabel),expression:"createStatusLabel"}],class:[
                'label-input',
                _vm.theme
              ],attrs:{"placeholder":"Label","disabled":_vm.loading,"type":"text"},domProps:{"value":(_vm.createStatusLabel)},on:{"input":function($event){if($event.target.composing){ return; }_vm.createStatusLabel=$event.target.value}}}),(_vm.colorsLoading)?_c('base-icon',{attrs:{"name":"loader"}}):_vm._e(),[(!_vm.isValidArray(_vm.colors))?_c('div',{staticClass:"empty-state"},[_vm._v(" No colors found ")]):_c('div',{staticClass:"color-grid"},_vm._l((_vm.colors),function(color,i){return _c('span',{key:i,staticClass:"color-item",style:(("background-color: " + (color.name))),on:{"click":function($event){$event.preventDefault();_vm.createStatusColor = color}}},[(_vm.createStatusColor === color)?_c('base-icon',{staticClass:"color-icon",class:("text-" + ((_vm.hexToRgbSum(color.name) > _vm.themeThreshold ? 'black' : 'white'))),attrs:{"name":"check"}}):_vm._e()],1)}),0)]],2)]):_vm._e(),(_vm.step == 'edit')?_c('div',{staticClass:"edit-status-step-wrapper"},[_c('div',{class:[
              'edit-status-step-container',
              _vm.theme
            ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.statuses[_vm.editableStatusIndex].editName),expression:"statuses[editableStatusIndex].editName"}],staticClass:"label-input",attrs:{"placeholder":"Label","type":"text","disabled":_vm.loading},domProps:{"value":(_vm.statuses[_vm.editableStatusIndex].editName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.statuses[_vm.editableStatusIndex], "editName", $event.target.value)}}}),(_vm.colorsLoading)?_c('base-icon',{attrs:{"name":"loader"}}):[(!_vm.isValidArray(_vm.colors))?_c('div',{staticClass:"empty-state"},[_vm._v(" No colors found ")]):_c('div',{staticClass:"color-grid"},_vm._l((_vm.colors),function(color,i){return _c('span',{key:i,staticClass:"color-item",style:(("background-color: " + (color.name))),on:{"click":function($event){$event.preventDefault();_vm.statuses[_vm.editableStatusIndex].editColor = color}}},[(_vm.statuses[_vm.editableStatusIndex].editColor && _vm.statuses[_vm.editableStatusIndex].editColor.id === color.id)?_c('base-icon',{staticClass:"color-icon",class:("text-" + ((_vm.hexToRgbSum(color.name) > _vm.themeThreshold ? 'black' : 'white'))),attrs:{"name":"check"}}):_vm._e()],1)}),0)]],2)]):_vm._e()]),_c('div',{staticClass:"actions-footer",on:{"click":function (e){ return e.stopPropagation(); }}},[(_vm.step == 'create')?_c('div',{staticClass:"create-status-actions"},[_c('base-button',{class:[
              'create-status-button',
              _vm.theme
            ],attrs:{"label":"Create","type":"label","rounded":"lg","disabled":_vm.loading,"theme":"neutral-dark"},on:{"action":function($event){return _vm.createStatus()}}})],1):_vm._e(),(_vm.step == 'edit')?_c('div',{staticClass:"edit-status-actions"},[_c('base-button',{class:[
              'delete-button',
              _vm.theme
            ],attrs:{"label":"Delete","rounded":"lg","theme":"danger","disabled":_vm.loading,"icon":"trash"},on:{"action":function($event){return _vm.deleteStatusIntent(_vm.statuses[_vm.editableStatusIndex])}}}),_c('base-button',{class:[
              'save-button',
              _vm.theme
            ],attrs:{"label":"Save","rounded":"lg","icon":"pencil","disabled":_vm.loading},on:{"action":function($event){return _vm.editStatus()}}})],1):_vm._e()])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }