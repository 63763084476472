<template>
    <base-modal
        :visible="open"
        @close="$emit('close')"
        title="Edit Audience Insights"
    >
        <div class="flex flex-col justify-between" :key="!open" v-if="audience.profile">
          <div class="grid gap-5">
            <div class="field">
              <div class="text-h6 font-bold">Avg Likes</div>
              <FormulateInput type="text" placeholder="Avg Likes" v-model="audience.profile.avg_likes"/>
            </div>
            <div class="field">
              <div class="text-h6 font-bold">Followers</div>
              <FormulateInput type="text" placeholder="Followers" v-model="audience.profile.followers"/>
            </div>
            <div class="field" v-if="audience.profile.geo">
              <div class="text-h6 font-bold">Location (City)</div>
              <FormulateInput type="text" placeholder="Location (City)" v-model="audience.profile.geo.city.name"/>
            </div>
            <div class="field" v-if="audience.profile.geo">
              <div class="text-h6 font-bold">Location (State)</div>
              <FormulateInput type="text" placeholder="Location (State)" v-model="audience.profile.geo.state.name"/>
            </div>
            <div class="field" v-if="audience.profile.geo">
              <div class="text-h6 font-bold">Location (Country)</div>
              <FormulateInput type="text" placeholder="Location (Country)" v-model="audience.profile.geo.country.name"/>
            </div>
          </div>
        </div>
        <advanced-audience
          :open="advancedAudience !== null"
          :currentAudience="advancedAudience"
          @close="advancedAudience = null"
          @save-audience="saveAdvancedAudience"
        />
        <div class="flex justify-between gap-3 mt-6 rounded-br-2xl">
            <base-button
              label="Cancel" 
              @action="$emit('close')" 
              :disabled="saving" 
              type="secondary" 
              size="md" 
            />
            <base-button 
              label="Advanced Audience"
              v-if="advancedAudienceValid()"
              @action="editAudience"
              size="xl"
            />
            <base-button 
              label="Update"
              @action="updateAudience" 
              :disabled="saving" 
              size="md" 
              :icon-size="4"  
              bold
            />
        </div>
    </base-modal>
</template>

<script>
    import AdvancedAudience from "@/views/CreatorEdit/AdvancedAudience";
    export default {
        name: 'UpdateAudience',
        components: {
          AdvancedAudience
        },
        props: {
            open: {
                type: Boolean,
                default: false
            },
            currentAudience: {
                type: Object
            }
        },
        data() {
            return {
                saving: false,
                audience: {},
                advancedAudience: null
            }
        },
        watch: {
            open: function (val, old) {
                if (val && val !== old) {
                    let audience = JSON.parse(JSON.stringify(this.currentAudience));
                    if (audience.profile.geo && !audience.profile.geo.city) {
                      audience.profile.geo.city = { name: '' };
                    }

                    if (audience.profile.geo && !audience.profile.geo.state) {
                      audience.profile.geo.state = { name: '' };
                    }

                    if (audience.profile.geo && !audience.profile.geo.country) {
                      audience.profile.geo.country = { name: '' };
                    }

                    this.audience = audience;
                }
            }
        },
        computed: {
            disabledManualAudience() {
                return this.saving;
            }
        },
        methods: {
            advancedAudienceValid() {
              if(this.audience && this.audience.profile && this.audience.audience && this.audience.audience.data){
                return true;
              }else{
                return false;
              }
            },
            editAudience() {              
              let { profile, audience } = this.audience;
              this.advancedAudience = {
                payload: {
                  user_profile: profile,
                  audience_followers: {
                    data: audience.data
                  }
                }
              };
            },
            saveAdvancedAudience(audience) {
              let { user_profile, audience_followers } = audience.payload;
              this.audience.profile = user_profile;
              this.audience.audience.data = audience_followers.data
              this.advancedAudience = null;
            },
            trimc(string, char = ' ') {
                string = string.charAt(0) === char ? string.substring(1) : string;
                string = string.charAt(string.length - 1) === char ? string.slice(0, -1) : string;
                return string
            },

            async updateAudience() {
                this.saving = true;
                try {
                    const { id, profile, audience } = this.audience;
                    const { avg_likes, followers, geo } = profile;
                    let payload = { id, custom_payload: { avg_likes, followers, geo }, audience };
                    await this.$http.post(`/api/users/update-audience`, payload);

                    this.$notify({ title: 'Success', text: `Audience updated successfully`, type: 'success' });

                    this.$emit('finish-update');

                    this.audience = {};
                } catch(e) {
                    this.$notify({ title: 'Error', text: `Error saving! Please try again!`, type: 'warn' });
                    console.log(e);
                } finally {
                    this.saving = false;
                }
            },
        }
    }
</script>

<style scoped>

</style>