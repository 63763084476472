<template>
    <base-modal 
        title="Add Drive File"
        :visible="visible"
        @close="close()"
    >
        <div class="add-drive-file-modal-content">

            <p class="title">Connect a Google Drive file with a shareable url.</p>

            <div class="description">
                <base-icon name="info-circle"/>To get a shareable file url, click on "Share" then "Copy link" on the google drive file
            </div>

            <div class="input-container">
                <FormulateInput
                    type="text"
                    v-model="url"
                    placeholder="https://docs.google.com/document/d/13hy89_18sg6rhg-52gpxe/edit"
                    label="Shareable url"
                />
            </div>

            <div class="footer">
                <base-button label="Cancel" type="secondary" @action="close()"/>
                <base-button
                    label="Add file"
                    :disabled="loading || !validAccessToken || !validProjectId"
                    @action="submit()"
                />
            </div>
        </div>
    </base-modal>
</template>

<script>
import { validateUrl } from '../../lib/strings';
import { isObject, notifyCatchError } from '../../common';
import { getIdFromGoogleDriveUrl, isGoogleDriveUrl } from '../../lib/googleDrive';
export default {
    props: ['visible', 'accessToken', 'projectId'],
    data() {
        return {
            url: '',
            loading: false
        }
    },
    computed: {
        validProjectId(){
            return this.projectId
        },
        validAccessToken(){
            return isObject(this.accessToken) && this.accessToken.access_token
        }
    },
    methods: {
        async submit() {
            this.loading = true
            try{
                let { url } = this;                
                if(validateUrl(url) && isGoogleDriveUrl(url)){
                    const driveFileId = getIdFromGoogleDriveUrl(url);
                    if(driveFileId){
                        const response = await fetch(`https://www.googleapis.com/drive/v3/files/${driveFileId}?fields=id,name,mimeType,capabilities,permissions`, {
                            method: 'GET',
                            headers: new Headers({'Authorization': `Bearer ${this.accessToken.access_token}`}),
                        });
                        if (response) {
                            const json = await response.json();
                            const { name } = json;
                            const res = await this.$http.post('/api/upload-file/link', {
                                name, 
                                file: this.url, 
                                projectId: this.projectId 
                                // userId: null, // api uses session user
                                // folderId: null // root
                            });
                            if(res){
                                this.$notify({ title: 'Success', text: 'Drive file added successfully', type: 'success' });
                                this.close();
                                this.$emit('refresh');
                            }
                        }     
                    }else{
                        this.$notify({ title: 'Unable to get file ID from URL', text: 'Check the URL and try again', type: 'warn' });
                    }
                }else{
                    this.$notify({ title: 'Invalid URL', text: 'Check the URL and try again', type: 'warn' });
                }
            }catch(error){
                notifyCatchError(error, this.$notify);
            }finally{
                this.loading = false
            }
        },
        close(){
            this.url = '';
            this.$emit('close');
        }
    }
}
</script>
<style lang="scss" scoped>
    .add-drive-file-modal-content{
        .title{
            @apply text-h5 font-bold mb-5;
        }
        .description{
            @apply flex gap-x-2 items-center py-2;
        }
    }
</style>
