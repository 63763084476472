<template>
    <!-- this class is required to apply styles while printing -->
    <div class="report-groups-container">
        <div class="title" v-if="withTitle">
            Grouped Creators

            <div class="button" v-if="!asCircles">
                <base-button 
                    size="md" 
                    @action="createGroup" 
                    display="inline-block" 
                    label="Create Group"
                />
            </div>
        </div>

        <div v-else-if="withButton" class="button-container">
            <div class="button" v-if="!asCircles">
                <base-button 
                    size="md" 
                    @action="createGroup" 
                    display="inline-block" 
                    label="Create Group"
                />
            </div>
        </div>

        <!-- as-circles: used on CreateReportModal & ReportCreate/Edit View -->
        <div v-if="asCircles" class="as-circles-container" :key="groupKey">

            <div class="groups-grid">

                <div
                    class="group"
                    :class="{ 'attempt-group': attemptGroup === group.id } "
                    :key="group.id"
                    v-for="group in computedChunkedGroups"
                    @drop.prevent="onDrop($event, group)"
                    @dragover.prevent="attemptGroup = group.id"
                    @dragleave.prevent="attemptGroup = null"
                >
                    <!-- actions -->
                    <div class="actions">
                        <base-button @action="deleteGroup(group)" type="label" icon="trash"/>
                    </div>
                    <!-- avatar -->
                    <avatar
                        @callback="detailedGroup = group"
                        :draggable="false"
                        class="cursor-pointer max-w-none rounded-full text-white shadow-solid"
                        :user="group.profile"
                        size="x-large"
                    />
                    <!-- dropzone message -->
                    <div 
                        class="dropzone-message" 
                        :class="{ 'drag-started': dragStarted }" 
                        @click="renameGroup(group)"
                    >
                        {{ dragStarted ? `Drop here in order to add the user to` : '' }} {{  parseUnicodeCharacters(group.name) }} <base-button v-if="!dragStarted" type="label" icon="pencil" :icon-size="4"/>
                    </div>
                    <!-- group members grid -->
                    <div class="group-members-grid">
                        <div class="empty-state" v-if="!group.users.length">This group does not have any users yet</div>
                        <div
                            class="member"
                            v-for="(user, key) in group.users"
                            :key="`${group.id}_${key}_user`"
                            v-show="key < 4"
                            draggable
                            @dragstart="onDragStarted($event, user, group)"
                            @dragend="onDragEnd($event, user, group)"
                        >
                            <!-- member networks -->
                            <networks 
                                disabled 
                                :accounts="[user.platforms[0]]" 
                                size="x-small" 
                                wrapper-class="flex gap-4 absolute -top-2 -right-2 lg:right-0 xl:right-2"
                            />
                            <!-- member avatar -->
                            <avatar
                                @callback="inviteMember(user)"
                                class="cursor-pointer max-w-none rounded-full text-white shadow-solid"
                                v-tooltip="{ content: `${user.username}`, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } }"
                                :user="user.profile"
                                size="medium"
                            />
                        </div>
                        <div class="more-members" v-if="group.users.length > 4">
                            +{{ group.users.length - 4 }}
                        </div>
                    </div>
                </div>
                
                <!-- create new group dropzone -->
                <div
                    class="create-group-dropzone"
                    :class="{'attempt-create' : attemptCreate}"
                    @drop.prevent="onDrop($event)"
                    @dragover.prevent="attemptCreate = true"
                    @dragleave.prevent="attemptCreate = false"
                    @click="createGroup('', null)"
                >
                    <div 
                        class="content" 
                        :class="{'groups-exist': computedGroups.length }" 
                    >
                        <p>{{ dragStarted ? 'Drop here to create a group' : 'Create Group' }}</p>
                        <p><base-icon name="plus" border :size="8"/></p>
                    </div>
                </div>

            </div>


            <div class="see-more" v-if="computedGroups.length > chunkedValue">
                <p @click="chunked = !chunked">{{ chunked ? 'See more' : 'Hide groups' }}</p>
            </div>
            
            <!-- section to remove user from group -->
            <div class="remove-user-and-available-users-section">
                <div 
                    class="remove-user-dropzone"
                    v-if="dragStarted && old_group !== null"
                    @dragend="onDragEnd($event, user, null)" 
                    @dragover.prevent="attemptGroup = null" 
                    @dragleave.prevent="attemptGroup = null" 
                    @drop.prevent="removeFromGroups($event)"  
                >
                    Drop here if you want to remove the user from a group
                </div>
                <!-- available user list -->
                <div 
                    class="available-user" 
                    draggable 
                    @dragstart="onDragStarted($event, user)" 
                    v-for="(user, a_key) in availableUsers" 
                    :key="`au_${a_key}`"
                >
                    <avatar-list :users="[user]"/>
                </div>
            </div>
        </div>

        <!-- ! dont know where we are showing this, it would need to have asCircles == false & asList == false  -->
        <base-table
            v-else-if="!asList"
            :columns="[
                {
                    name: 'open',
                    type: 'toggle-open'
                },
                {
                    name: 'influencer-name',
                    label: 'Creator Name',
                    type: 'avatar-list',
                    value: (element) => {
                        return [element.profile]
                    }
                },
                {
                    name: 'platforms',
                    label: 'Platforms',
                    type: 'networks',
                    currentValues: networks,
                    currentAccounts: (element) => {
                        return element.currentPlatforms;
                    },
                    selector: false
                },
                {
                    name: 'engagements',
                    label: 'Engagements',
                    type: 'number-format'
                },
                {
                    name: 'followers',
                    label: 'Followers',
                    type: 'number-format'
                },
            ]"
            :data="computedGroups"

            :rowActions="[
                {
                    label: 'Change name',
                    icon: 'pencil-alt',
                    event: 'rename',
                    handler: (group) => {
                        renameGroup(group)
                    }
                },
                {
                    label: 'Delete',
                    icon: 'trash',
                    theme: 'cancel',
                    event: 'delete',
                    handler: (group) => {
                        deleteGroup(group)
                    }
                },
                {
                    label: 'Merge',
                    icon: 'merge',
                    event: 'merge',
                    handler: (group) => {
                        mergeGroup(group)
                    }
                },
            ]"

            actions-position="top"

            add-child-label="Add Creator"
            :allow-add-child="availableUsers.length > 0"
            @add-child="assignInfluencer"

            :child-columns="[
                {
                    name: 'influencer-name',
                    label: 'Creator Name',
                    type: 'avatar-list',
                    value: (element) => {
                        return [element.profile]
                    }
                },
                {
                    name: 'platforms',
                    label: 'Platform',
                    type: 'networks',
                    currentValues: networks,
                    currentAccounts: (element) => {
                        return element.currentPlatforms;
                    },
                    selector: false
                },
                {
                    name: 'engagements',
                    label: 'Engagements',
                    type: 'number-format'
                },
                {
                    name: 'total_followers',
                    label: 'Followers',
                    type: 'number-format'
                },
                {
                    name: 'drop',
                    type: 'single-icon',
                    icon: 'trash',
                    onClick: (element) => {
                        removeInfluencer(element)
                    }
                }
            ]"
            child-attribute="users"
            :hide-child-headers="false"
            child-empty-text="There are no creators on this group"
            empty-text="There are no groups yet"
            :sorting="false"
            :search="false"
            @toggle-open="toggleOpen"
            @rename="renameGroup"
            overflow
        />

        <!-- as list: used on ReportView -->
        <template v-else>

            <!-- groups list -->
            <!-- this class is required to apply styles while printing -->
            <div
                class="group-item"
                :class="{'has-border': availableUsers.length || g_pos < computedGroups.length - 1}"
                v-for="(influencer, g_pos) in computedGroups" :key="influencer.id"
            >
                <div
                    class="avatar"
                    :class="{'has-stories profile-user-story': influencer.has_stories}"
                    @click="$emit('show-stories', influencer)"
                >
                    <avatar size="medium" :user="influencer.profile"/>
                </div>
                <div class="content">
                    <div
                        class="name"
                        :class="{'editable': allowEdit && availableUsers.length}"
                        @click="assignInfluencer(influencer)"
                    >
                        {{ parseUnicodeCharacters(influencer.name) }}
                    </div>
                    <!-- this class is required to apply styles while printing -->
                    <div class="invite-text" v-if="influencer.showLabelForInvitation" @click="inviteMember(influencer)">Invite creator to login</div>
                    <div class="reach">Reach: <span>{{ parseInt(influencer.followers) | numeral('0.0a') }}</span></div>
                    <div class="engagements">Engagements: <span>{{ parseInt(influencer.engagements) | numeral('0.0a') }}</span></div>
                    <div class="invitation-platform-list" v-if="influencer.openInvite">
                        <div 
                            class="platform-item" 
                            v-for="platform in influencer.platforms" 
                            :key="platform.id"
                            @click="setInviteMember(influencer, platform)"
                        >
                            <networks small :accounts="[platform.network]" :current-accounts="[platform.network_id]"/>
                            {{ platform.handle }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- available users list -->
            <!-- this class is required to apply styles while printing -->
            <div
                class="available-user-item"
                :class="{'has-border': i_pos < availableUsers.length - 1}"
                v-for="(influencer, i_pos) in availableUsers"
                :key="influencer.social_id"
            >
                <div
                    class="avatar"
                    :class="{'has-stories profile-user-story': influencer.has_stories}"
                    @click="$emit('show-stories', influencer)"
                >
                    <avatar size="medium" :user="influencerProfile(influencer)"/>
                </div>
                <div class="content">
                    <div
                        class="name"
                        :class="{'editable': allowEdit}"
                        @click="createGroup(influencer.full_name || influencer.username, influencer)"
                    >
                        {{ parseUnicodeCharacters(influencer.full_name ? influencer.full_name : influencer.username) }}
                    </div>
                    <div class="invitation-text" v-if="influencer.showLabelForInvitation" @click="inviteMember(influencer)">Invite creator to login</div>
                    <div class="reach">Reach: <span>{{ influencer.total_followers | numeral('0.0a') }}</span></div>
                    <div class="engagements">Engagements: <span>{{ influencer.engagements | numeral('0.0a') }}</span></div>
                    <div class="invitation-platform-list" v-if="influencer.openInvite">
                        <div 
                            class="platform-item" 
                            v-for="platform in influencer.platforms" 
                            :key="platform.id"
                            @click="setInviteMember(influencer, platform)" 
                        >
                            <networks small :accounts="[platform.network]" :current-accounts="[platform.network_id]"/>
                            {{ platform.handle }}
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <base-modal
            :visible="edit && selectedGroup.name !== undefined"
            @close="cancelGroupForm"
            :title="selectedGroup.id ? 'Edit Creator Group' : 'Create Creator Group'"
        >
            <div class="flex flex-col justify-between" :key="!edit">
                <div class="field col-span-2">
                    <div class="text-h6 font-bold">Name</div>
                    <FormulateInput type="text" validation="required" placeholder="Type the group name" v-model="selectedGroup.name"/>
                </div>
            </div>
            <div class="flex justify-between gap-3 mt-6 rounded-br-2xl">
                <base-button @action="cancelGroupForm" :disabled="editing" type="secondary" size="md" label="Cancel"/>
                <base-button @action="acceptGroupForm" :disabled="editing || selectedGroup.name === ''" size="md" :icon-size="4" :label="selectedGroup.id ? 'Update' : 'Create'" bold/>
            </div>
        </base-modal>

        <base-modal
            :visible="add && selectedGroup.id !== undefined"
            @close="cancelGroupAdd"
            title="Group Creators"
        >
            <p class="mb-6">Select the creators that you want to merge into one</p>
            <div class="flex flex-col justify-between" :key="!add">
                <base-table
                    :columns="[
                        {
                            name: 'influencer-name',
                            label: 'Creator',
                            type: 'avatar-list',
                            value: (element) => {
                                return [element]
                            }
                        },
                        {
                            name: 'total_followers',
                            label: 'Reach',
                            type: 'number-format'
                        },
                        {
                            name: 'engagements',
                            label: 'Engagements',
                            type: 'number-format'
                        }
                    ]"
                    :data="availableUsers"
                    :initialSelectedElements="[]"
                    :bulkActions="[{ name: 'select' }]"
                    @select-element="toggleElements"
                    :key="`assign_${!add}`"
                    :search="false"
                />
            </div>
            <div class="flex justify-between gap-3 mt-6 rounded-br-2xl">
                <base-button @action="cancelGroupAdd" :disabled="adding" type="secondary" size="md" label="Cancel"/>
                <base-button @action="acceptGroupAdd" :disabled="adding || !selectedUsers.length" size="md" :icon-size="4" label="Add" bold/>
            </div>
        </base-modal>

        <base-modal
            :visible="merge && selectedGroup.id !== undefined"
            @close="cancelGroupMerge"
            title="Merge Group"
        >
            <p class="mb-6">Select the group that you want to merge into one</p>
            <div class="flex flex-col justify-between" :key="!add">
                <custom-select :options="availableForMerge" v-model="mergeSelected" placeholder="Select a group"/>
            </div>
            <div class="flex justify-between gap-3 mt-6 rounded-br-2xl">
                <base-button @action="cancelGroupMerge" :disabled="merging" type="secondary" size="md" label="Cancel"/>
                <base-button @action="acceptGroupMerge" :disabled="merging || !mergeSelected" size="md" :icon-size="4" label="Merge" bold/>
            </div>
        </base-modal>

        <base-modal
            :visible="computedSuggestions.length && computedSuggestions[0] !== undefined"
            title="Groups suggestions"
            @close="attemptToClose"
        >
            <p class="mb-6">Accept or reject the groups suggestions</p>
            <div class="flex flex-col gap-2 max-h-92 overflow-y-auto">
                <div class="bg-transparent hover:bg-gray-100 border-2 rounded-xl text-center p-6 relative cursor-pointer w-full grid grid-cols-8 items-center" v-for="(computedSuggestion, cs_key) in computedSuggestions" :key="`cs_${cs_key}`">
                    
                    <div class="flex items-center gap-2 col-span-3" @click="detailedGroup = computedSuggestion">
                        <avatar
                            class="cursor-pointer max-w-none rounded-full text-white shadow-solid"
                            :user="computedSuggestion.profile"
                            size="x-large"
                        />
                        <div class="text-md my-2 flex justify-center font-bold col-span-2">{{ computedSuggestion.name }}</div>
                    </div>
                    <div class="grid grid-cols-6 col-span-3" v-if="computedSuggestion && computedSuggestion.users">
                        <div
                            class="relative text-xs"
                            v-for="(user, key) in computedSuggestion.users.slice(0, 4)"
                            :key="`${computedSuggestion.id}_${key}_user`"
                        >
                            <networks disabled :accounts="[user.platforms[0]]" size="x-small" wrapper-class="flex gap-4 absolute -top-2 -right-2 lg:right-0 xl:right-2"/>
                            <avatar
                                class="cursor-pointer max-w-none rounded-full text-white shadow-solid"
                                v-tooltip="{ content: `${user.username}`, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } }"
                                :user="user.profile"
                                size="medium"
                            />
                            <p class="line-clamp-1">{{ parseUnicodeCharacters(user.username) }}</p>
                        </div>
                    </div>
                    <div class="col-span-2">
                        <transition name="fade" mode="out-in">
                            <div v-if="!computedSuggestion.seen" class="flex justify-between">
                                <base-button rounded="full" icon="close" size="xs" theme="danger" circle @action="prepareSuggestion(cs_key)"/>
                                <base-button rounded="full" icon="check" size="xs" circle @action="prepareSuggestion(cs_key, true)"/>
                            </div>
                            <div v-else class="flex justify-center">
                                <p
                                    @click="cancelPrepareSuggestion(cs_key)"
                                    @mouseenter="changeMouse(cs_key, true)"
                                    @mouseleave="changeMouse(cs_key)"
                                    class="font-bold"
                                    :class="computedSuggestion.hovered ? 'font-normal italic' : (computedSuggestion.selected ? 'text-green-500' : 'text-red-600')"
                                >{{ computedSuggestion.hovered ? 'Change option' : (computedSuggestion.selected ? 'Accepted' : 'Rejected') }}</p>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
            <div class="w-3/5 flex justify-between mx-auto mt-4">
                <base-button type="secondary" label="Reject" @action="attemptToClose"/>
                <base-button label="Accept" @action="confirmSuggestions"/>
            </div>
        </base-modal>

        <base-modal
            :visible="detailedGroup !== null"
            :title="detailedGroup ? detailedGroup.name + ' members' : 'Group details'"
            @close="detailedGroup = null"
        >
            <base-table
                :columns="[
                    {
                        name: 'influencer-name',
                        label: 'Creator Name',
                        type: 'avatar-list',
                        value: (element) => {
                            return [element.profile]
                        }
                    },
                    {
                        name: 'platforms',
                        label: 'Platform',
                        type: 'networks',
                        currentValues: networks,
                        currentAccounts: (element) => {
                            return element.current_platforms;
                        },
                        selector: false
                    },
                    {
                        name: 'engagements',
                        label: 'Engagements',
                        type: 'number-format'
                    },
                    {
                        name: 'total_followers',
                        label: 'Followers',
                        type: 'number-format'
                    },
                    ...(detailedGroup && detailedGroup.isSuggestion ? [] : {
                        name: 'drop',
                        type: 'single-icon',
                        icon: 'trash',
                        onClick: (element) => {
                            removeInfluencer(element)
                        }
                    })
                ]"
                :data="detailedGroup ? detailedGroup.users : []"

                actions-position="top"

                empty-text="There are no creators on this group"
                :sorting="false"
                :search="false"
                overflow
            />
        </base-modal>

        <send-invite-modal
            @close="cancelInvite"
            :visible="platformIntent.show"
            :user="platformIntent.user"
            :network-id="platformIntent.networkId"
            :use-user-id="false"
        />
    </div>
</template>

<script>
    import { toSnakeCase } from '../../lib/strings';
    import Networks from '../../components/Networks';
    import AvatarList from '../../components/AvatarList';
    import SendInviteModal from '../../components/SendInviteModal.vue';
    import { parseUnicodeCharacters } from '../../lib/strings';
    import { getBoostedData } from '../../lib/fbBoostedData'
    import { mapGetters } from 'vuex';

    export default {
        components: {
            Networks,
            AvatarList,
            SendInviteModal
        },
        name: 'ReportGroups',
        props: {
            initialGroups: {
                type: [Array, Object],
                default: function () {
                    return [];
                }
            },
            platformSelected: {
                type: [String, Number],
                default: 'all'
            },
            search: {
                type: String,
                default: '',
            },
            posts: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            networks: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            refreshed: {
                type: Boolean,
                default: false
            },
            withTitle: {
                type: Boolean,
                default: false
            },
            withButton: {
                type: Boolean,
                default: false
            },
            asList: {
                type: Boolean,
                default: false
            },
            asCircles: {
                type: Boolean,
                default: false
            },
            clean: {
                type: Boolean,
                default: false
            },
            hideStories: {
                type: Boolean,
                default: true
            },
            allowEdit: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            clean: function () {
                this.removeUnusedInfluencers();
            },

            reach: function (val) {
                this.$emit('set-parent', 'reach', val);
            },

            profiles: function (val) {
                this.$emit('set-parent', 'profiles', val);
            },

            filteredSearchInfluencers: {
                deep: true,
                handler: function (val) {
                    this.$emit('set-parent', 'influencers_list_items', val);
                }
            }
        },
        data() {
            return {
                groups: Array.isArray(this.initialGroups) ? this.initialGroups : [],
                has: Array.isArray(this.initialGroups) ? this.initialGroups.length > 0 : false,
                saving: false,
                selectedGroup: {},
                selectedUsers: [],
                availableForMerge: [],
                suggestions: [],
                allNetworks: [],
                mergeSelected: null,
                add: false,
                adding: false,
                edit: false,
                editing: false,
                merge: false,
                merging: false,
                dragStarted: false,
                attemptCreate: false,
                attemptGroup: null,
                old_group: null,
                groupKey: false,
                suggestionPos: 0,
                detailedGroup: null,
                chunked: true,
                chunkedValue: 3,
                groupOrUserActive: null,
                platformIntent: {
                    show: false,
                    user: null,
                    networkId: null
                },
                parseUnicodeCharacters
            }
        },
        mounted() {
            if (!this.has && !this.refreshed) {
                this.groupPosts();
            }
            this.getNetworks();

            this.setParent();
        },
        computed: {
            ...mapGetters(['isAdminOrHigher']),

            computedChunkedGroups() {
                if (this.chunked) {
                    return this.computedGroups.slice(0, this.chunkedValue);
                }
                return this.computedGroups;
            },

            computedGroups() {       
                // clone array
                let groups = [...this.groups];
                if (this.groupOrUserActive) {
                    for (let i in groups) {
                        groups[i].openInvite = groups[i] !== undefined && groups[i].id == this.groupOrUserActive.id;
                    }
                }
                return this.prepareGroup(groups);
            },
            
            profiles() {
                return this.computedGroups.length + this.availableUsers.length;
            },

            reach() {
                let groups = this.computedGroups.reduce((acc, cur) => {
                    return acc + parseInt(cur.followers);
                }, 0);

                let users = this.availableUsers.reduce((acc, cur) => {
                    return acc + parseInt(cur.total_followers);
                }, 0);

                return parseInt(groups) + parseInt(users);
            },

            computedSuggestions() {
                let groups = JSON.parse(JSON.stringify(this.suggestions));
                return this.prepareGroup(groups);
            },

            // set the "engagements" property for each post 
            computedPosts() {
                const properties = ['likes', 'comments', 'shares', 'saves', 'views'];
                return this.posts.map(post => {
                    let total = 0;
                    for (const property of properties) {
                        total += this.getPostStat(post, property);
                    }
                    post.profile_item.engagements = total;
                    return post;
                });
            },

            equitativePosts() {
                let influencers = [];
                this.posts.forEach(post => {
                    let { social_id } = post.profile_item;
                    let posts = this.computedPosts.filter(p => p.network_id === post.network_id && p.profile_item.social_id === social_id);
                    let engagements = 0;
                    for (let p of posts) {
                        engagements = engagements + p.profile_item.engagements;
                    }

                    post.profile_item.engagements = engagements;

                    influencers.push(post);
                });

                return influencers;
            },

            uniqueInfluencers() {
                let items = [];
                let map = new Map();
                for (let post of this.equitativePosts) {
                    let socialId = post.profile_item.social_id;

                    if (!map.has(socialId)) {
                        map.set(socialId, true);
                        let profile = post.profile_item;
                        profile.network_id = post.network_id;
                        profile.user = post.user;
                        profile.has_stories = this.hideStories && this.influencersWithStories.includes(socialId);
                        if (this.platformSelected === 'all' || post.network_id === this.platformSelected) {
                            items.push(profile);
                        }
                    }
                }

                return items;
            },

            filteredSearchInfluencers() {
                let users = JSON.parse(JSON.stringify(this.uniqueInfluencers));
                return users.map(u => {
                    u.open = false;
                    return u
                }).filter(influencer => {
                    if (this.search !== '') {
                        let name = influencer.full_name ? influencer.full_name : influencer.username;
                        return name.toLowerCase().includes(this.search.toLowerCase());
                    }
                    return true;
                });
            },

            availableUsers() {
                let users = JSON.parse(JSON.stringify(this.uniqueInfluencers));
                let groups = JSON.parse(JSON.stringify(this.groups));
                groups = groups.map(g => g.users.map(u => u.social_id)).reduce((acc, cur) => {
                    acc = acc.concat(cur);
                    return acc;
                }, []);
                return users.map(user => {
                    user.id = user.social_id;
                    user.avatar = user.profile_picture_url;
                    user.first_name = user.full_name || user.username;
                    user.last_name = '';

                    let platforms = [];
                    let platform = this.allNetworks.find(n => n.id === user.network_id);
                    if (platform) {
                        platforms = [{
                            network: platform,
                            network_id: user.network_id,
                            handle: user.username
                        }];
                    }
                    user.platforms = platforms;
                    user.openInvite = this.groupOrUserActive && user.id == this.groupOrUserActive.id;

                    return user;
                }).filter(user => !groups.includes(user.social_id));
            },

            influencersWithStories() {
                let items = [];
                let map = new Map();
                for (let post of this.posts) {
                    let profile = post.profile_item;
                    let socialId = profile.social_id;
                    if (!map.has(socialId) && post.type && post.type.name === 'story') {
                        map.set(socialId, true);
                        items.push(socialId);
                    }
                }
                return items;
            },
        },
        methods: {

            getPostStat(post, property){
                let val = post[property];

                // Aggregate the boosted number if found, otherwise it returns 0
                val += getBoostedData(post.boosted_data, property);
                
                return parseInt(val);
            },

            prepareGroup(groups) {
                return groups.map(group => {
                    group.users = (group.users || []).filter(g => {
                        return (this.platformSelected === 'all' || g.network_id === this.platformSelected);
                    });
                    group.engagements = group.users.reduce((acc, cur) => {
                        return acc + parseInt(cur.engagements);
                    }, 0);

                    let used_platforms = [];
                    let followers = 0;
                    for (let item of group.users) {
                        if (!used_platforms.includes(item.network_id)) {
                            used_platforms.push(item.network_id);
                            followers = followers + parseInt(item.total_followers);
                        }
                    }

                    group.followers = followers;

                    group.users = group.users.map(u => {
                        let influencer = this.uniqueInfluencers.find(i => i.social_id === u.social_id);
                        if (influencer) {
                            let { engagements, full_name, network_id, profile_picture_url, social_id, total_followers, uri, username, user } = influencer;
                            let profile = { avatar: profile_picture_url, first_name: full_name || username, last_name: '' };
                            let u_platforms = [{
                                id: social_id,
                                handle: username,
                                network_id,
                                direct: true,
                                uri,
                                social_url: username
                            }];
                            
                            let accounts = user && user.social_accounts ? user.social_accounts.filter(sa => [2, 14].includes(sa.network_id)) : [];
                            let loggedAccounts = user && user.social_logins ? user.social_logins.filter(s => [2, 6, 14].includes(s.network_id)) : [];
                            let showInvitationLabel = (accounts.length - loggedAccounts) > 0;
                            return { engagements, showInvitationLabel, network_id, social_id, platforms: u_platforms, current_platforms: [network_id], total_followers, uri, username, profile, user };
                        }
                        return null;
                    }).filter(u => u);

                    let showLabelForInvitation = false;
                    group.users.forEach(u => {
                        if (u.showInvitationLabel) {
                            showLabelForInvitation = this.isAdminOrHigher;
                        }
                    });

                    group.showLabelForInvitation = showLabelForInvitation;

                    if (group.users.length) {
                        let f_user = group.users[0];
                        let picture = f_user.profile_picture_url || (f_user.profile ? f_user.profile.avatar : undefined);
                        if (picture) {
                            group.picture = picture;
                        }
                    }

                    group.profile = {avatar: group.picture, id: group.id, name: group.name, first_name: group.name, last_name: ''};

                    let accounts = group.users.map(u => u.social_id);

                    let stories = this.posts.filter(post => {
                        return accounts.includes(post.profile_item.social_id) && post.type && post.type.name === 'story';
                    });

                    group.has_stories = stories.length > 0;

                    group.platforms = group.users.map(user => {
                        let network = this.allNetworks.find(n => n.id == user.network_id);
                        return {
                            id: user.social_id,
                            handle: user.username,
                            network_id: user.network_id,
                            direct: true,
                            social_url: user.uri,
                            network
                        };
                    });

                    group.currentPlatforms = group.users.map(user => {
                        return user.network_id;
                    });

                    return group;
                }).filter(g => !this.asList ? true : g.users.length);
            },

            async getNetworks() {
                const { data } = await this.$http.get('/api/userDeliverables/networks');
                this.allNetworks = data.map(network => {
                    network.network_id = network.id;
                    network.label = network.display_name;
                    return network;
                });
            },
            cancelInvite() {
                this.platformIntent = {
                    show: false,
                    user: null,
                    networkId: null
                };
            },
            inviteMember(influencer) {
                if (influencer.platforms.length == 1) {
                    this.setInviteMember(influencer, influencer.platforms[0]);
                } else {
                    this.groupOrUserActive = influencer;
                }
            },
            setInviteMember(influencer, platform) {
                this.groupOrUserActive = null;

                let networkId = null;

                if (platform.network_id == 2) {
                    networkId = 6;
                }

                if (platform.network_id == 14) {
                    networkId = 14;
                }
                if (networkId !== null) {
                    this.platformIntent = {
                        show: true,
                        user: influencer,
                        networkId
                    };
                }
            },
            attemptToClose() {
                this.$swal.fire({
                    title: `Are you sure you want to skip this suggestions?`,
                    text: 'You will be abble to group later',
                    icon: 'warning',
                    iconColor: '#0E092C',
                    showCancelButton: true,
                    confirmButtonText: 'Skip',
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.suggestions = [];
                        this.$emit('save', this.groups, false, true);
                    }
                });
            },

            changeMouse(key, enter = false) {
                if (this.suggestions[key] !== undefined) {
                    this.suggestions[key].hovered = enter;
                }
            },

            cancelPrepareSuggestion(key) {
                if (this.suggestions[key] !== undefined) {
                    this.suggestions[key].seen = false;
                    this.suggestions[key].selected = false;
                }
            },

            prepareSuggestion(key, selected = false) {
                if (this.suggestions[key] !== undefined) {
                    this.suggestions[key].seen = true;
                    this.suggestions[key].selected = selected;
                }
            },

            confirmSuggestions() {
                for (let i = 0; i < this.suggestions.length; i++) {
                    if (this.suggestions[i] !== undefined && this.suggestions[i].seen && this.suggestions[i]) {
                        let {id, name, open, picture, users} = this.suggestions[i];
                        this.groups.push({id, name, open, picture, users});
                    }
                }

                this.suggestions = [];
                this.$emit('save', this.groups);
            },

            rejectSuggestion() {
                this.suggestions.splice(this.suggestionPos, 1);
            },

            acceptSuggestion() {
                if (this.suggestions[this.suggestionPos] !== undefined) {
                    this.groups.push(this.suggestions[this.suggestionPos]);
                    this.suggestions.splice(this.suggestionPos, 1);
                }

                if (!this.suggestions.length) {
                    this.$emit('save', this.groups);
                }
            },

            onDrop(event, group = null) {
                let user = JSON.parse(event.dataTransfer.getData('user'));
                let old_group = JSON.parse(event.dataTransfer.getData('group'));

                if (group == null) {
                    let name = user.full_name ? user.full_name : `${user.profile.first_name} ${user.profile.last_name}`;
                    this.createGroup(name, user, old_group);
                } else {
                    if (old_group == null || (old_group && old_group.id && old_group.id !== group.id)) {
                        this.addUserToGroup(user, group);
                    }

                    if (old_group && old_group.id && old_group.id !== group.id) {
                        this.removeUserFromOldGroup(user, old_group);
                    }

                    if (old_group == null || (old_group && group && old_group.id !== group.id)) {
                        this.$emit('save', this.groups);
                        this.$forceUpdate();
                        this.$emit('refresh');
                    }
                }
            },

            removeFromGroups(event) {
                let user = JSON.parse(event.dataTransfer.getData('user'));
                let old_group = JSON.parse(event.dataTransfer.getData('group'));

                if (old_group && old_group.id) {
                    this.removeUserFromOldGroup(user, old_group);
                    
                    this.$emit('save', this.groups);
                    this.$forceUpdate();
                    this.$emit('refresh');
                }
            },

            addUserToGroup(user, group) {
                let groupIndex = this.groups.findIndex(g => g.id == group.id);
                if (groupIndex > -1) {
                    let users = group.users;
                    users.push(user);
                    let groups = this.groups;
                    let newGroup = group;
                    newGroup.users = users;
                    groups[groupIndex] = newGroup;
                    this.groups = groups;
                }
            },

            removeUserFromOldGroup(user, group) {
                let groupIndex = this.groups.findIndex(g => g.id == group.id);
                if (groupIndex > -1) {
                    let old_users = group.users;
                    let current = user;
                    let userIndex = old_users.findIndex(u => {
                        return u.network_id === current.network_id && u.social_id === current.social_id;
                    });

                    if (userIndex > -1) {
                        let groups = this.groups;
                        old_users.splice(userIndex, 1);
                        group.users = old_users;
                        groups[groupIndex] = group;
                        this.groups = groups;
                    }
                    this.$forceUpdate();
                }
            },

            onDragStarted(event, user, group = null){
                this.dragStarted = true;
                this.old_group = group;
                event.dataTransfer.setData('user', JSON.stringify(user));
                event.dataTransfer.setData('group', JSON.stringify(group));
            },

            onDragEnd() {
                this.old_group = null;
                this.dragStarted = false;
                this.attemptCreate = false;
                this.attemptGroup = null;
            },
            setParent() {
                this.$emit('set-parent', 'reach', this.reach);
                this.$emit('set-parent', 'profiles', this.profiles);
                this.$emit('set-parent', 'influencers_list_items', this.filteredSearchInfluencers);
            },

            removeUnusedInfluencers() {
                let profiles = this.uniqueInfluencers.map(u => u.social_id);
                this.groups.map(group => {
                    group.users.forEach(user => {
                        if (!profiles.includes(user.social_id)) {
                            this.confirmRemove(group, user, false);
                        }
                    });
                });
            },

            toggleElements(selectedElements) {
                let items = [];
                for (let element of selectedElements) {
                    let item = items.find(i => i.id === element.id);
                    if (!item) {
                        items.push(element);
                    }
                }

                this.selectedUsers = items;
            },
            influencerProfile(influencer) {
                let { profile_picture_url, full_name, username, social_id } = influencer;
                return {
                    id: social_id,
                    name: full_name,
                    avatar: profile_picture_url,
                    handle: username
                }
            },

            assignInfluencer(element) {
                if (this.allowEdit && this.availableUsers.length) {
                    this.selectedGroup = {id: element.id};
                    this.add = true;
                }
            },

            createGroup(name = '', user = null, old_group = null) {
                if (this.allowEdit) {
                    let fn = () => {
                        this.selectedGroup = {name};
                        this.selectedGroup.users = [];
                        if (name !== '' && user !== null) {
                            let {engagements, network_id, social_id, total_followers} = user;
                            this.selectedGroup.users = [{engagements, network_id, social_id, total_followers}];
                        }

                        if (old_group !== null) {
                            this.selectedGroup.old_group = old_group;
                        }
                        this.edit = true;
                    };

                    if (name !== '') {
                        this.$swal.fire({
                            title: `You are about to group the creator named ${name}`,
                            text: 'Do you want to continue?',
                            icon: 'warning',
                            iconColor: '#0E092C',
                            showCancelButton: true,
                            confirmButtonText: 'Continue',
                            reverseButtons: true,
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                fn();
                            }
                        });
                    } else {
                        fn();
                    }
                }
            },

            renameGroup(group) {
                let { id, name } = group;
                this.selectedGroup = {id, name };
                this.edit = true;
            },

            mergeGroup(group) {
                let { id, name } = group;
                this.selectedGroup = {id, name};
                this.availableForMerge = this.groups.filter(g => g.id !== id);
                this.merge = true;
            },

            cancelGroupMerge() {
                this.selectedGroup = {};
                this.mergeSelected = null;
                this.availableForMerge = [];
                this.merge = false;
            },

            acceptGroupMerge() {
                this.merging = true;
                if (this.mergeSelected !== null) {
                    let older = this.groups.find(group => group.id === this.selectedGroup.id);
                    let group = this.groups.find(group => group.id === this.mergeSelected.id);
                    if (group) {
                        for (let user of older.users) {
                            let {engagements, network_id, social_id, total_followers} = user;
                            group.users.push({engagements, network_id, social_id, total_followers});
                        }
                        let index = this.groups.map(g => g.id).indexOf(older.id);
                        if (index > -1) {
                            this.groups.splice(index, 1);
                        }
                        this.selectedGroup = {};
                        this.merging = false;
                        this.merge = false;

                        this.$forceUpdate();

                        this.$emit('save', this.groups);
                    }
                }
            },

            cancelGroupForm() {
                this.selectedGroup = {};
                this.selectedUsers = [];
                this.edit = false;
            },

            acceptGroupForm() {
                let old_group = null;
                if (this.selectedGroup.old_group !== undefined) {
                    old_group = this.selectedGroup.old_group;
                    delete this.selectedGroup.old_group;
                }

                if (this.selectedGroup.id) {
                    let group = this.groups.find(group => group.id === this.selectedGroup.id);
                    if (group) {
                        group.name = this.selectedGroup.name;
                        group.id = toSnakeCase(this.selectedGroup.name);
                    }
                } else {
                    this.groups.push({
                        id:  toSnakeCase(this.selectedGroup.name),
                        name: this.selectedGroup.name,
                        open: true,
                        users: this.selectedGroup.users.length ? this.selectedGroup.users : [],
                    });
                }

                if (old_group !== null && this.selectedGroup.users.length == 1) {
                    this.removeUserFromOldGroup(this.selectedGroup.users[0], old_group);
                }

                this.selectedGroup = {};
                this.edit = false;

                this.$forceUpdate();

                this.$emit('save', this.groups);
                this.$emit('refresh');
            },

            cancelGroupAdd() {
                this.selectedGroup = {};
                this.add = false;
            },

            acceptGroupAdd() {
                if (this.selectedGroup.id !== undefined && this.selectedUsers.length) {
                    let group = this.groups.find(g => g.id === this.selectedGroup.id);
                    if (group) {
                        for (let user of this.selectedUsers) {
                            let {engagements, network_id, social_id, total_followers} = user;
                            group.users.push({engagements, network_id, social_id, total_followers});
                        }
                    }

                }
                this.selectedGroup = {};
                this.add = false;

                this.$forceUpdate();

                this.$emit('save', this.groups);
            },

            deleteGroup(group) {
                this.$swal.fire({
                    title: `Are you sure you want to delete this creators group?`,
                    text: '',
                    icon: 'warning',
                    iconColor: '#0E092C',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    reverseButtons: true,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        let index = this.groups.map(g => g.id).indexOf(group.id);
                        if (index > -1) {
                            this.groups.splice(index, 1);

                            this.$emit('save', this.groups);
                        }
                    }
                });
            },

            removeInfluencer(element) {
                this.$swal.fire({
                    title: `Are you sure you want to remove this creator from the group?`,
                    text: '',
                    icon: 'warning',
                    iconColor: '#0E092C',
                    showCancelButton: true,
                    confirmButtonText: 'Remove',
                    reverseButtons: true,
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        this.groups.forEach(group => {
                            this.confirmRemove(group, element);
                        });
                    }
                });
            },

            confirmRemove(group, element, notify = true) {
                let index = group.users.map(item => item.social_id).indexOf(element.social_id);
                if (index > -1) {
                    group.users.splice(index, 1);

                    this.$emit('save', this.groups, notify);
                    this.detailedGroup = group;
                }
            },

            toggleOpen(group, index) {
                if (this.groups[index] !== undefined) {
                    if (this.groups[index].name === group.name) {
                        this.groups[index].open = !this.groups[index].open;
                    }
                }
            },

            groupPosts() {
                let users = this.uniqueInfluencers;

                users.map(u => {
                    u.accounts = users.filter(el => {
                        return this.compareUser(el, u) || (el.social_id === u.social_id);
                    }).map(u => u.social_id);
                    return u;
                });

                let used = [];
                let groups = [];

                for (let user of users) {
                    if (user.accounts.length) {
                        let name = user.full_name || user.username;
                        let id = toSnakeCase(name);
                        let picture = user.profile_picture_url;

                        let accounts = user.accounts;
                        accounts.push(user.social_id);

                        let exists = false;
                        for (let account of accounts) {
                            if (used.includes(account)) {
                                exists = true;
                            }
                        }

                        if (!exists) {
                            let elements = this.uniqueInfluencers.filter(u => accounts.includes(u.social_id)).map(u => {
                                let { engagements, network_id, social_id, total_followers } = u;
                                return { engagements, network_id, social_id, total_followers };
                            });

                            for (let element of elements) {
                                used.push(element.social_id);
                            }

                            groups.push({ id, name, picture, users: elements, open: false });
                        }
                    }
                }
                if (this.refreshed || !this.asCircles) {
                    this.groups = groups;
                } else {
                    let suggestions = JSON.parse(JSON.stringify(groups));
                    if (Array.isArray(suggestions) && suggestions.length) {
                        suggestions = suggestions.filter(s => s.users && s.users.length).map(s => ({...s, seen: false, selected: false, hovered: false, isSuggestion: true}));
                        suggestions.forEach(s => {
                            if (s.users.length == 1) {
                                this.groups.push(s);
                            }
                        });
                        suggestions = suggestions.filter(s => s.users.length > 1);

                        if (!suggestions.length) {
                            this.$emit('save', this.groups, false);
                        }
                    }
                    this.suggestions = suggestions;
                }
            },

            compareUser(el, u) {
                let u_full_name = u.full_name.toLowerCase();
                let u_username = u.username.toLowerCase();
                let el_full_name = el.full_name.toLowerCase();
                let el_username = el.username.toLowerCase();
                let sameName = el_full_name === u_full_name;
                let sameUsername = el_username === u_username;
                let notfn_empty = u_full_name !== '' && el_full_name !== '';
                let notun_empty = u_username !== '' && el_username !== '';
                let includesName = (el_full_name.includes(u_full_name) || u_full_name.includes(el_full_name)) && notfn_empty;
                let includesUsername = (el_username.includes(u_username) || u_username.includes(el_username)) && notun_empty;
                let includesInverted = (el_full_name.includes(u_username) || u_username.includes(el_full_name)) && notfn_empty && notun_empty;
                return sameName || sameUsername || includesName || includesUsername || includesInverted;
            },
        }
    }
</script>
<style lang="scss" scoped>
    .v-enter-active, .v-leave-active {
        transition: opacity 0.5s ease;
    }

    .v-enter-from, .v-leave-to {
        opacity: 0;
    }

    .report-groups-container{
        //
        .title{
            @apply col-span-2 mb-6 flex justify-between items-center text-h3 font-bold text-purple-m-secondary
            > .button{
                @apply py-3 flex gap-4;
            }
        }
        .button-container{
            @apply col-span-2 flex justify-end items-center text-h3 font-bold text-purple-m-secondary;
            > .button{
                @apply py-3 flex gap-4;
            }
        }

        .as-circles-container{
            @apply flex flex-col gap-x-4;
            .groups-grid{
                @apply grid grid-cols-4 gap-4;
                .group{
                    @apply bg-transparent border-2 rounded-xl text-center p-6 relative cursor-pointer;
                    &:hover{
                        @apply bg-gray-100;
                    }
                    &.attempt-group{
                        @apply bg-gray-100;
                    }
                    .actions{
                        @apply absolute top-2 right-2;
                    }
                    .dropzone-message{
                        @apply text-lg my-2 flex justify-center font-bold; 
                        &.drag-started{
                            @apply font-normal;
                        }
                    }
                    .group-members-grid{
                        @apply grid grid-cols-5;
                        > .empty-state{
                            @apply col-span-5 text-center text-sm;
                        }
                        .member{
                            @apply relative;
                        }
                        .more-members{
                            @apply relative;
                        }
                    }
                }
                .create-group-dropzone{
                    @apply bg-transparent border-2 border-dashed rounded-xl text-center relative cursor-pointer min-h-32;
                    &:hover{
                        @apply bg-gray-100;
                    }
                    &.attempt-create{
                        @apply border-solid bg-gray-100;
                    }
                    > .content{
                        @apply m-0 bottom-0 h-10;
                        transform: translateY(70%);
                        
                        &.groups-exist{
                            @apply h-20;
                        }
                    }

                }

            }
            .see-more{
                @apply col-span-4 flex flex-col mt-4 text-center;
                > p{
                    @apply text-xs font-bold cursor-pointer;
                }
            }
            .remove-user-and-available-users-section{
                @apply col-span-4 flex flex-col gap-2 mt-4;
                .remove-user-dropzone{
                    @apply text-center border-2 border-dashed p-2 rounded;
                }
                .available-user{
                    @apply cursor-pointer;
                }
            }
        }

        .group-item{
            @apply flex items-center gap-4 py-4;
            &.has-border{
                @apply border-b border-purple-m-main-light border-opacity-20;
            }
            > .avatar{
                @apply w-11 h-11 flex items-center justify-center;
                &.has-stories{
                    @apply rounded-full cursor-pointer;
                }
            }
            > .content{
                @apply flex flex-col relative;
                width: calc(100% - 4rem);
                > .name{
                    @apply font-bold text-base text-black truncate capitalize;
                    &.editable{
                        @apply cursor-pointer;
                    }
                }
                > .invite-text{
                    @apply text-sm cursor-pointer italic;
                }
                > .reach{
                    @apply text-sm text-gray-m-disable;
                    > span{
                        @apply uppercase;
                    }
                }
                > .engagements{
                    @apply text-sm text-gray-m-disable;
                    > span{
                        @apply uppercase;
                    }
                }
                > .invitation-platform-list{
                    @apply w-full h-auto py-2 absolute flex-col gap-1 bottom-0 border border-gray-300 bg-white shadow-lg;
                    > .platform-item{
                        @apply flex cursor-pointer items-center pl-1 bg-white;
                        &:hover{
                            @apply bg-gray-200;
                        }
                    }
                }
            }
        }

        .available-user-item{
            @apply flex items-center gap-4 py-4;
            &.has-border{
                @apply border-b border-purple-m-main-light border-opacity-20;
            }
            > .avatar{
                @apply w-11 h-11 flex items-center justify-center;
                &.has-stories{
                    @apply rounded-full cursor-pointer;
                }
            }
            > .content{
                @apply flex flex-col relative;
                width: calc(100% - 4rem);
                > .name{
                    @apply font-bold text-base text-black truncate capitalize;
                    &.editable{
                        @apply cursor-pointer;
                    }
                }
                > .invitation-text{
                    @apply text-sm cursor-pointer italic;
                }
                > .reach{
                    @apply text-sm text-gray-m-disable;
                    > span{
                        @apply uppercase;
                    }
                }
                > .engagements{
                    @apply text-sm text-gray-m-disable;
                    > span{
                        @apply uppercase;
                    }
                }
                > .invitation-platform-list{
                    @apply w-full h-auto py-2 absolute flex-col gap-1 bottom-0 border border-gray-300 bg-white shadow-lg;
                    > .platform-item{
                        @apply flex cursor-pointer items-center pl-1 bg-white;
                        &:hover{
                            @apply bg-gray-200;
                        }
                    }
                }


            }

        }



    }


</style>