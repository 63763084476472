<template>
    <div>
        <FormulateForm 
            name="form" 
            @submit="updateProfile" 
            v-model="profile"
        > 
            <div class="section personal-info">
                
                <div class="header">
                    <div class="title">
                        Personal Information
                    </div>                   
                </div>
                
                <div class="content">
                    
                    <div>
                        <span @click="openFileSelector">
                            <avatar
                                class="cursor-pointer max-w-none h-6 w-6 rounded-full text-white shadow-solid"
                                v-tooltip="{ content: `${profile.first_name} ${profile.last_name}`, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } }"
                                :user="profile"
                                size="xl2"
                            />
                            <input class="hidden" type="file" ref="file" accept="image/*" @change="changeProfilePicture"/>
                        </span>
                        <div class="role" :style="`background-color: ${role.color}; color:${hexToTextColor(role.color)}`">{{ role.display_name }}</div>
                    </div>

                    <div class="flex flex-col gap-4">
                        <div class="flex gap-4 items-center">
                            <FormulateInput
                                type="text"
                                validation="required"
                                name="first_name"
                                placeholder="Insert your first name"
                                validation-name="First name"
                                label="First Name"
                            />
                            <FormulateInput
                                type="text"
                                validation="required"
                                name="last_name"
                                placeholder="Insert your last name"
                                validation-name="Last name"
                                label="Last Name"
                            />
                        </div>
                        <FormulateInput
                            type="email"
                            name="email"
                            readonly
                            disabled
                            placeholder="Enter email adress"
                            label="Email Address"
                        />
                    </div>
                </div>
            </div>

            <div class="section change-password">
                <div class="header">
                    <div class="title">
                        Change Password              
                    </div>                   
                </div>
                <div class="content">   
                    <div class="flex gap-4">
                        <FormulateInput
                            type="password"
                            name="password"
                            placeholder="Enter password"
                            label="New Password"
                        />
                        <FormulateInput
                            type="password"
                            name="password_confirm"
                            placeholder="Repeat password"
                            :validation="confirmPWAttribute"
                            validation-name="Password confirmation"
                            label="Confirm New Password"
                        />                
                    </div>      
                    <div class="flex justify-start">
                        <base-button class="font-bold"
                            size="md"
                            :iconSize="4"
                            theme="dark"
                            type="secondary"
                            @action="$formulate.submit('form')" 
                            :disabled="saving" 
                            submit :label="saving ? 'Saving...' : 'Save'"
                        />                
                    </div>
                </div>
            </div>

        </FormulateForm>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { hexToTextColor } from '@/lib/strings'
export default {

    data() {
        return {
            profile: {},
            role: {},
            saving: false,
        }
    },

    computed: {
        ...mapGetters(['user']),
        confirmPWAttribute() {
            if (this.profile.password !== undefined && this.profile.password !== null && this.profile.password !== '') {
                return 'required|confirm';
            }
            return '';
        }
    },

    mounted() {
        this.profile = {...this.user};
        this.profile.password = '';
        this.getRole()
    },

    methods: {
        getRole(){
            this.$http.get('/api/me/role').then(({data}) => {
               this.role = data
            }).catch((err) => {
                console.log('getRole error', err)
                this.$notify({ title: 'Success', text: 'There was an error, try again later', type: 'success' })
            })
        },
        async changeProfilePicture(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e => {
                this.profile.avatar = e.target.result;
            };

            let data = new FormData();
            data.append('image', image);

            this.$http.post('/api/files/upload-profile-picture', data)
                .then(async (res) => {
                    if (res.status === 200) {
                        this.profile.avatar = res.data.user.avatar;
                        let user = {...this.profile};
                        delete user.password;
                        delete user.password_confirm;
                        await this.$store.dispatch('auth/setUser', user);

                        this.$notify({ title: 'Success', text: 'Profile picture updated successfully', type: 'success' })
                    }
                })
                .catch(() => {})
        },
        async updateProfile() {
            this.saving = true;
            const { first_name, last_name, password } = this.profile;
            let payload = { first_name, last_name, password };
            this.$http.post(`/api/profile/update`, payload).then(async (res) => {
                if (res.status === 200) {
                    let user = {...res.data};
                    delete user.password;
                    delete user.password_confirm;
                    await this.$store.dispatch('auth/setUser', user);

                    this.$notify({ title: 'Success', text: 'Profile updated successfully', type: 'success' })
                }
                this.saving = false;
            }).catch((/*e*/) => {
                this.$notify({ title: 'Error', text: 'Error updating profile, try again later', type: 'success' })
                this.saving = false;
            });
        },
        hexToTextColor: hexToTextColor,
        openFileSelector () {
            this.$refs.file.click();
        },
    }
}
</script>
<style lang="scss" scoped>
    .section{
        @apply px-12 py-5 flex flex-col;
        .header{
            @apply flex justify-between py-5;
            .title{
                @apply text-h2 font-bold text-purple-m-secondary;
            }
        }
        .content{
            @apply flex;
            .role{
                @apply rounded-full py-2 flex justify-center items-center font-bold text-xs;
            }
        }
        &.personal-info .content{
            @apply flex-row gap-x-4 items-center;
        }
        &.change-password .content{
            @apply flex-col gap-y-4 items-start;
        }
    }
</style>
