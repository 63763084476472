<template>
    <base-modal 
        title="Delete Review"
        :visible="visible"
        @close="onClose()"
        no-padding
    >

        <div v-if="rating" class="content">
            
            <div class="title">Are you sure you want to delete this review ?</div>

            <avatar-list :users="[rating.user]" size="medium" class="font-bold"/>

            <div class="w-full flex justify-between">
                <div class="field">
                    <star-rating
                        v-model="value" 
                        :increment="0.5"
                        :star-size="30"
                        :read-only="true"
                        text-class="font-bold"
                    />
                </div>

                <div v-if="rating.project">
                    Project: {{ rating.project.name }}
                </div>
            </div>

            <FormulateInput
                type="textarea"
                class="w-full"
                readonly
                placeholder="Write a review..."
                input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent"
                v-model="comment"
                :rows="3"
            />
        </div>

        <div class="footer">
            <base-button 
                @action="onClose()" 
                :disabled="loading" 
                type="secondary" 
                size="md" 
                bold 
                label="Cancel"
            />
            <base-button 
                @action="submit()" 
                :disabled="loading"  
                theme="danger"
                label="Delete"
                secondaryIcon="trash"
                bold
            />
        </div>
    </base-modal>
</template>

<script>
import StarRating from 'vue-star-rating';
import AvatarList from '@/components/AvatarList.vue';
import { notifyCatchError } from '@/common';
export default {
    name: 'DeleteUserRatingModal',
    props: {
        visible: {
            type: Boolean, 
            default: false
        },
        rating: {
            type: Object
        }
    },
    components: {
        StarRating,
        AvatarList
    },
    data() {
        return {
            value: 0,
            comment: '',
            loading: false
        }
    },
    watch: {
        visible(val){
            if(val){
                this.setData()
            }
        }
    },
    methods: {
        setData(){
            this.value = this.rating.value
            this.comment = this.rating.comment 
        },
        submit() {
            this.loading = true;
            this.$http.delete(`/api/user-ratings/${this.rating.id}`).then((/*{ data }*/) => {
                this.$notify({ title: 'Success', text: 'Review deleted successfully', type: 'success' });
                this.$emit('reload');
                this.onClose();
            }).catch(err => {
                notifyCatchError(err, this.$notify, 'There was an error while trying to delete the review, try again later.')
            }).finally(() => {
                this.loading = false;
            });
        },
        onClose(){
            this.value = 0
            this.comment = ''
            this.$emit('close')
        }
    }
}
</script>
<style lang="scss" scoped>
    .content{
        @apply px-7 pt-0 pb-10;
        @apply flex flex-col gap-y-4;
        .title{
            @apply text-h5 font-bold;
        }
        .field{
            @apply flex flex-col gap-y-1 mb-3;
        }
    }
    .footer{
        @apply py-7 px-7;
        @apply flex justify-between;
    }
</style>
