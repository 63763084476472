<template>
    <div class="profile-completion" v-if="isCreator && profileCompletion && !profileCompletion.completed && !hidden">
        
        <div class="absolute right-2 top-2">
            <base-icon 
                class="cursor-pointer hover:opacity-50" 
                :size="6" 
                @action="hidden = true" 
                name="close" 
            />
        </div>

        <div class="left">
            <div>
                <div class="title">Complete your profile</div>
                <div class="message">{{profileCompletion.message}}</div>
                <div class="view-details-button" @click="detailsVisible = !detailsVisible">
                    {{detailsVisible ? 'Hide' : 'View'}} details
                    <base-icon size="3" name="chevron-down" v-if="!detailsVisible" />
                    <base-icon size="3" name="chevron-up" v-else />
                </div>
                <ul class="details" v-if="detailsVisible">
                    <template v-for="(field, index) in profileCompletion.errors">
                        <template v-if="field.errors && field.errors.length">
                            <li class="item" v-for="(error, i) in field.errors" :key="`${index}-${i}`">
                                {{ error.name }}
                            </li>
                        </template>
                    </template>
                </ul>
            </div>
            <base-button
                v-if="profileCompletion.redirect"
                :label="redirectLabel(profileCompletion)"
                size="lg"
                @action="$router.push(profileCompletion.redirect)"
            />
        </div>

        <div class="right">

            <div class="label">
                <div class="percentage">{{profileCompletion.percentage}}</div>
                <div class="text">Completed</div>
            </div>
            <div class="chart">
                <doughnut
                    :key="chartKey"
                    :chartData="{
                    labels: ['Female', 'Male'],
                    datasets: [
                            {
                                data: [
                                    parseInt(profileCompletion.percentage.replace('%','')), 
                                    100 - parseInt(profileCompletion.percentage.replace('%',''))
                                ],
                                backgroundColor: [
                                    '#70F880',
                                    '#FFFFFF'
                                ]
                            }
                        ]
                    }"
                    :options="{
                        legend: { 
                            display: false 
                        },
                        tooltips: {
                            enabled: false
                        },
                        cutoutPercentage: 50
                    }"
                />
            </div>
        </div>
    </div> 
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    
    data(){
        return {
            hidden: false,
            chartKey: false,
            detailsVisible: false
        }
    },

    mounted(){
        if(this.isCreator){
            this.$store.dispatch('auth/getProfileCompletion');
        }
    },

    watch: {
        profileCompletion(/*val*/){
            this.chartKey = !this.chartKey
        },
        isSessionLoaded(val){
            if(val){
                if(this.isCreator){
                    this.$store.dispatch('auth/getProfileCompletion');
                }
            }
        }
    },

    computed: {
        ...mapGetters(['isSessionLoaded', 'isCreator', 'profileCompletion']),
    },

    methods: {
        redirectLabel(profileCompletion){
            const redirect = profileCompletion.redirect
            return redirect.includes('/me') ? 'Go to profile' : redirect.includes('/profile/edit') ? 'Connect account' : 'Complete Profile'
        }
    }
}
</script>
<style lang="scss" scoped>
    .profile-completion{
        @apply relative px-12 py-2 bg-tan-m flex justify-between items-center;

        .left{
            @apply flex gap-x-12 items-center;
            .title{
                @apply font-bold text-h4;
            }
            .message{
                @apply text-h6 text-gray-m-dark;
            }
            .view-details-button{
                @apply text-h6 text-blue-600 cursor-pointer flex items-center gap-x-2;
            }
            .details{
                @apply py-2 list-disc;
                .item{
                    @apply text-h6 text-gray-m-dark;
                }
            }
        }
        .right{
            @apply flex items-center gap-x-8;
            .label{
                @apply flex flex-col items-end;
                .percentage{
                    @apply font-bold text-h4;
                }
                .text{
                    @apply text-h6 text-gray-m-dark;
                }
            }
            .chart{
                width: 70px; 
                height: 70px;
            }
        }
    }
</style>
