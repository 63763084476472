<template>
    <div v-if="user && user.audience_data" class="stats-wrapper">
        
        <div class="title" v-if="followerAudienceSet">Audience</div>

        <div class="content" :class="{ 'no-audience': !followerAudienceSet }">

            <div
                class="grid gap-x-1 gap-y-14"
                style="height: fit-content"
                :class="[
                    !followerAudienceSet ? 'md:grid-cols-3 sm:grid-cols-1 gap-y-8' : 'gap-y-8 md:grid-cols-2 sm:grid-cols-1',
                    !followerAudienceSet ? (filteredAudienceDatas.length <= 3 ? `lg:grid-cols-${filteredAudienceDatas.length + 2}` : 'lg:grid-cols-5') : ''
                ]"
            >
                <stat-card 
                    label="Total Reach"
                    tooltip="Overall reach of the creator, the cumulative sum of their followers across their different social media accounts."
                    :value="computedTotalAudience < 1000 ? computedTotalAudience.toFixed(0) : computedTotalAudience | numeral('0.0a')" 
                    :icon-size="7" 
                    icon="reach"
                />
                <stat-card 
                    label="Average Engagements" 
                    tooltip="Average likes and comments of their latest posts."
                    :value="computedAverageEngagements < 1000 ? computedAverageEngagements.toFixed(0) : computedAverageEngagements | numeral('0.0a')" 
                    :icon-size="7"
                    icon="report-comments"
                />
                <!-- <stat-card 
                    label="Engagement Rate" 
                    tooltip="Total number of engagements (likes, comments, shares, saves) on all of this creator's posts, and on their different accounts, divided by their number of followers."
                    :value="(engagementAvgRate / 100) | numeral('0.00%')" 
                    :icon-size="7"
                    icon="report-comments"
                /> -->
                <stat-card 
                    label="Average Views"
                    v-if="computedTotalViews > 0"
                    tooltip="Average views of their latest videos."
                    :value="computedTotalViews < 1000 ? computedTotalViews.toFixed(0) : computedTotalViews | numeral('0.0a')" 
                    :icon-size="7"
                    icon="views"
                />

                <stat-card
                    v-for="(network, i) in filteredAudienceDatas" 
                    :span="spanText(network)"
                    :visible-span="isAdmin" 
                    span-class="text-sm italic cursor-pointer" 
                    @span-action="sendInvitation(network)" 
                    :link="network.social_url" 
                    label="Followers"
                    :tooltip="`Total number of followers on ${NETWORKS[network.network_id].display_name}`"
                    :key="i" 
                    :value="getFollowers(network) | numeral('0.0a')" 
                    :icon-size="7" 
                    :icon="NETWORKS[network.network_id].name"
                />

            </div>

            <!-- container for all the charts  -->
            <div class="charts-wrapper" v-if="followerAudienceSet">
                
                <!-- Gender pie Chart -->
                <gender-chart 
                    v-if="gendersSet" 
                    :data="followerAudience.audience_genders" 
                    :printView="printView" 
                />
                
                <template v-if="genderAgesSet || agesSet">
                    <!-- Gender age bar chart -->
                    <gender-age-chart 
                        v-if="genderAgesSet"
                        :data="followerAudience.audience_ages" 
                    />

                    <!-- (deprecated) Age chart -->
                    <age-chart 
                        v-else-if="agesSet" 
                        :data="followerAudience.audience_ages" 
                    />
                </template>
                
                <!-- geo location chart -->
                <audience-geo-chart
                    v-if="geoSet"
                    :data="followerAudience.audience_geo"
                />

            </div>
        </div>

        <send-invite-modal
            @close="cancelInvite"
            :visible="platformIntent.show"
            :user="platformIntent.user"
            :network-id="platformIntent.networkId"
        />
    </div>
</template>
<script>
import StatCard from './StatCard.vue'
import SendInviteModal from './SendInviteModal.vue';
import { NETWORKS, getObjectValue, isObject, isValidArray, isValidObject, isNumber, isNumberString, isNotNull } from '../common';
import GenderAgeChart from './_charts/GenderAgeChart.vue';
import AgeChart from './_charts/AgeChart.vue';
import GenderChart from './_charts/GenderChart.vue';
import AudienceGeoChart from './_charts/AudienceGeoChart.vue';
export default {
    props: {
        user: {
            type: Object,
            required: true
        },
        settings: {
            type: Object,
            required: false,
            default: () => {
                return {
                    "audience_followers": {
                        "audience_geo": {
                            "cities": true,
                            "states": true,
                            "countries": true
                        }
                    },
                    posts_limit: {
                        id: 9,
                        label: 9
                    }
                }
            }
        },
        printView: {
            type: Boolean,
            required: false,
            default: false
        },
        isAdmin: {
            type: Boolean,
            default: false
        }
    },
    components: {
        StatCard,
        SendInviteModal,
        AgeChart,
        GenderChart,
        GenderAgeChart,
        AudienceGeoChart
    },
    data() {
        return {
            NETWORKS,

            platformIntent: {
                show: false,
                user: null,
                networkId: null
            },

            hidden_settings: [
                'audience_lookalikes',
                'notable_users',
                'notable_users_ratio'
            ],

            isObject
        }
    },
    computed: {
        followerAudienceSet(){
            // if the user has at least some follower audience data
            let set = false;
            if(this.followerAudience && isValidArray(this.followerAudience.audience_genders)){
                const found = this.followerAudience.audience_genders.find(element => element.code == "MALE" || element.code == "FEMALE");
                if(found){
                    set = true;
                }
            }
            for (const key in this.computedSettings) {
                if (Object.hasOwnProperty.call(this.computedSettings, key)) {
                    if(this.followerAudience[key]) set = true
                }
            }
            return set;
        },

        computedSettings() {

            let items = {};

            let followers_settings = this.settings['audience_followers'];
            // {
            //     "audience_geo": {
            //         "cities": true,
            //         "states": true,
            //         "countries": true
            //     },
            //     "audience_genders": false,
            //     "audience_genders_per_age": false
            // }
            
            // if the report's follower settings are not set, we use these
            // default settings, showing citites/states/countries
            if(!isObject(followers_settings)){
                followers_settings = {
                    "audience_geo": {
                        "cities": true,
                        "states": true,
                        "countries": true
                    }
                }
            }
            
            for (let key in followers_settings) {

                const value = followers_settings[key];
                const isHidden = this.hidden_settings.includes(key);

                // only audience_geo is an object
                if (isValidObject(value)) {
                    // if its not explicitly hidden 
                    if (!isHidden) {
                        // added to the items list
                        items[key] = followers_settings[key];
                    }
                }

            }

            let filteredItems = {};
            for (let _key in items) {

                const value = items[_key];
                const creatorValue = this.followerAudience[_key]; 
                const isHidden =  this.hidden_settings.includes(_key);
                
                // audience_geo
                let isAudienceGeoObject = isObject(value);
                let creatorHasAudienceGendersPerAge = false;
                let creatorHasProperty = false;

                if(_key === 'audience_genders_per_age'){
                    creatorHasAudienceGendersPerAge = isValidArray(creatorValue);
                }else{
                    // If the creator has audience_geo, audience_genders, audience_ages
                    if(isNotNull(creatorValue) && !isHidden){
                        creatorHasProperty = true;
                    }
                }

                if (isAudienceGeoObject || creatorHasProperty || creatorHasAudienceGendersPerAge) {
                    filteredItems[_key] = value;
                }
            }

            return filteredItems;
        },

        followerAudience() {
            return this.audience.audience_followers && this.audience.audience_followers.data ? this.audience.audience_followers.data : {};
        },
        
        // all of these are not considering if they are hidden
        agesSet(){
            if(this.followerAudience && isValidArray(this.followerAudience.audience_ages)){
                return true;
            }
            return false;    
        },
        genderAgesSet(){
            if(this.followerAudience && isValidArray(this.followerAudience.audience_ages)){
                let hasOnlyGenderLabels = true;
                for (const age of this.followerAudience.audience_ages) {
                    if(!age.code.includes('F') && !age.code.includes('M') && !age.code.includes('U')){
                        hasOnlyGenderLabels = false;
                    }
                }
                return hasOnlyGenderLabels;
            }
            return false;
        },
        gendersSet(){
            return this.followerAudience && isValidArray(this.followerAudience.audience_genders) ? true : false;
        },
        geoSet(){
            if(this.followerAudience && isValidObject(this.followerAudience.audience_geo)){
                const cities = isValidArray(this.followerAudience.audience_geo.cities);
                const countries = isValidArray(this.followerAudience.audience_geo.countries);
                // const states = isValidArray(this.followerAudience.audience_geo.states);
                if(countries && cities){
                    return true;
                }
            }
            return false;
        },

        audience() {
            
            // from the filtered audience datas array, find one that is
            // instagram, if not found, just use the first one
            let audience = null;
            const found = this.filteredAudienceDatas.find(audience_data => {
                return audience_data.network_id === 2; 
            });
            if(found){
                audience = found;
            }else{
                audience = this.filteredAudienceDatas[0];
            }

            // fallback 1: Get the instagram audience data straight from the user.audience_data array
            let fallback_audience_data = this.user.audience_data.find(audience => {
                return audience.network_id === 2;
            });

            // fallback 2: - Just get the first element of the user.audience_data array
            let fallback_2_audience_data = this.user.audience_data[0];

            return audience && audience.payload ? audience.payload : 
            (fallback_audience_data && fallback_audience_data.payload ? fallback_audience_data.payload : 
            (fallback_2_audience_data && fallback_2_audience_data.payload ? fallback_2_audience_data.payload : {}));
        },
        // If "user.report_user.networks" is set then it will filter the "user.audience_data" array to match only those networks.
        // Will also filter out "audience_data" elements that dont have valid payload objects.
        filteredAudienceDatas() {
            let report_user_networks = getObjectValue(this.user, 'report_user.networks');
            if(!report_user_networks){
                return this.user.audience_data.filter(audience_data => isValidObject(audience_data.payload));
            }else{
                return this.user.audience_data.filter(audience_data => {
                    return report_user_networks.includes(audience_data.network_id) && isValidObject(audience_data.payload);
                });
            }
        },
        computedTotalAudience() {
            let followers = 0;
            for (let network of this.filteredAudienceDatas) {
                if(network.payload.user_profile.followers){
                    followers = followers + parseInt(network.payload.user_profile.followers);
                }
            }
            return followers;
        },
        computedAverageEngagements() {
            let total = 0;
            if(isValidArray(this.user.audience_data)) {
                let foundAudience = this.user.audience_data.find(element => {
                    const top_posts = getObjectValue(element, "payload.user_profile.top_posts", []);
                    if(isValidArray(top_posts)){
                        return true;
                    }
                    return false;
                });
                if(isValidObject(foundAudience)){
                    
                    let posts = getObjectValue(foundAudience, "payload.user_profile.top_posts", []);

                    // sort by top performing
                    posts = posts.sort((a, b) => {
                        const aTotal = a.stat.likes + a.stat.comments;
                        const bTotal = b.stat.likes + b.stat.comments;
                        return bTotal - aTotal;
                    });
                    
                    // sort by posts that are videos
                    posts = posts.sort((a, b) => (a.type < b.type) ? 1 : ((b.type < a.type) ? -1 : 0));

                    if (isValidArray(posts)) {
                        let length = 9;

                        // set length to settings post limit
                        if (this.settings.posts_limit !== undefined) {
                            length = parseInt(this.settings.posts_limit.id);
                        }

                        // if the amount of posts is less than this, then length is the amount of posts
                        if (posts.length < length) {
                            length = posts.length;
                        }

                        const stat_properties = ['likes', 'comments', 'saves', 'views', 'shares'];

                        // slice array to match the post length
                        // use map to do gather the total sum of (likes, comments, saves, views & shares) by post  
                        // use reduce to do sum 
                        let sum_total = posts.slice(0, length).map(post => {
                            let _total = 0;
                            for (const property of stat_properties) {
                                let value = post.stat[property];
                                if(value && (isNumber(value) || isNumberString(value))){
                                    let parsed = parseInt(value);
                                    if(parsed && parsed > 0){
                                        _total = _total + parsed;
                                    }
                                }
                            }
                            return _total;
                        }).reduce((acc, cur) => acc + cur, 0);
                        
                        // divide by length
                        if (length) {
                            const avg = sum_total / length;
                            total = avg;
                        }

                    }
                }
            }
            return parseFloat(total.toFixed(2));
        },
        computedTotalViews() {
            let total = 0;
            if(isValidArray(this.user.audience_data)) {
                let foundAudience = this.user.audience_data.find(element => {
                    const top_posts = getObjectValue(element, "payload.user_profile.top_posts", []);
                    if(isValidArray(top_posts)){
                        return true;
                    }
                    return false;
                });
                if(isValidObject(foundAudience)){

                    let posts = getObjectValue(foundAudience, "payload.user_profile.top_posts", []);
                    
                    // only consider posts that are of video type and that have valid views stat
                    posts = posts.filter(post => {
                        return post.type == 'video' && post.stat && isNumber(post.stat.views) && post.stat.views > 0;
                    });

                    if (isValidArray(posts)) {
                        let length = 9;

                        // set length to settings post limit
                        if (this.settings.posts_limit !== undefined) {
                            length = parseInt(this.settings.posts_limit.id);
                        }
                        
                        // if the amount of posts is less than this, then length is the amount of posts
                        if (posts.length < length) {
                            length = posts.length;
                        }
                        
                        // slice array to match the post length
                        // map to only views number
                        // use reduce to do sum  
                        let sum_total = posts.slice(0, length).map(post => {
                            return parseInt(post.stat.views);
                        }).reduce((acc, cur) => acc + cur, 0);
                        
                        // divide by length
                        if (length) {
                            const avg = sum_total / length;
                            total = avg;
                        }
                    }
                }
            }
            return parseFloat(total.toFixed(2));
        },

        // not using these
        // likerAudience() {
        //     return null;
        //     //return this.audience.audience_likers.data;
        // },

        // audienceRaces() {
        //   return this.followerAudience ? this.followerAudience.audience_ethnicities : {};
        // },

        // audienceAges() {
        //   return this.followerAudience ? this.followerAudience.audience_ages : {};
        // },

        // audienceLanguage() {
        //   return this.followerAudience ? this.followerAudience.audience_languages : {};
        // },
        
        // not using this right now
        // engagementAvgRate() {
        //     if (this.computedTotalAudience > 0) {
        //         return parseFloat( (this.computedAverageEngagements * 100 / this.computedTotalAudience)).toFixed(2) ;
        //     }
        //     return 0;
        // }

        // groupedImages() {
        //     return arrayChunk(this.audience.user_profile.top_posts, 2);
        // },
        
    },
    methods: {
        spanText(network) {
            // if user has audience data for instagram or tiktok
            let hasAccount = this.user.social_accounts.find(account => [2, 14].includes(account.network_id));
            let hasLogin = this.user.social_logins.find(socialLogin => {
                if(network.network_id === 2) return socialLogin.network_id === 6 
                return socialLogin.network_id == network.network_id
            });
            // return 'Invite to login';
            return !!hasAccount && !hasLogin ? 'Invite to login' : '';
        },
        sendInvitation(network) {
            let networkId = null;

            if (network.network_id == 2) {
                networkId = 6;
            }

            if (network.network_id == 14) {
                networkId = 14;
            }
            
            if (networkId !== null) {
                this.platformIntent = {
                    show: true,
                    user: this.user,
                    networkId
                };
            }
        },
        cancelInvite() {
            this.platformIntent = {
                show: false,
                user: null,
                networkId: null
            }
        },
        getFollowers(network){
            if(!network.payload.user_profile.followers) return 0;
            return network.payload.user_profile.followers < 1000 ? network.payload.user_profile.followers.toFixed(0) : network.payload.user_profile.followers;
        }
    }
}
</script>
<style lang="scss" scoped>
    .stats-wrapper{
        @apply rounded-2xl bg-white p-6;
        .title{
            @apply text-center font-bold text-lg mb-4
        }
        .content{
            @apply grid grid-cols-1 mx-0 px-0;
            @media (min-width: 1024px) {
                @apply grid-cols-3;
            }
            @media (max-width: 768px) {
                @apply grid-cols-1;
            }
            &.no-audience{
                @apply grid-cols-1;
            }
        }
        .data{
            @apply grid grid-cols-1 gap-x-1 gap-y-8;
            @media (min-width: 1280px) {
                @apply grid-cols-2;
            }
            &.no-audience{
                @media (min-width: 1024px) {
                    @apply grid-cols-3;
                }
                @media (max-width: 768px) {
                    @apply grid-cols-2;
                }
                @media (max-width: 500px) {
                    @apply grid-cols-1;
                }
            }
        }

        .charts-wrapper{
            @apply col-span-2 gap-y-6 gap-x-4 grid grid-cols-1;
            @media (min-width: 1024px) {
                @apply grid-cols-2;
            }
        }
    }

</style>