<template>
    <transition 
        name="fade"
        enter-active-class="enter-active-class"
        leave-active-class="leave-active-class"
        enter-class="enter-class"
        enter-to-class="enter-to-class"
        leave-class="leave-class"
        leave-to-class="leave-to-class"
    >
        <!-- wrapper -->
        <div 
            class="feedback-sidebar-wrapper" 
            v-if="feedback.show"
        >
            <!-- overlay -->
            <div 
                class="overlay" 
                @click="feedback.show = false" 
            />
            <!-- sidebar wrapper -->
            <div class="sidebar-wrapper">
                <section class="sidebar-positioner">
                    <div class="sidebar-container">
                        <!-- sidebar -->
                        <div class="sidebar">

                            <!-- content -->
                            <div class="content">

                                <!-- header -->
                                <header class="header">
                                    <base-button 
                                        icon="close" 
                                        type="label" 
                                        @action="feedback.show = false"
                                    />
                                    <div class="title">Feedback</div>
                                    <div class="description">
                                        Rate the creator based on different criteria from 1 to 5.
                                    </div>
                                </header>
                                
                                <!-- feedback user -->
                                <div
                                    class="user"
                                    v-if="feedbackUser"
                                >
                                    <!-- left -->
                                    <img-selector
                                        :images="userPicture"
                                        cover
                                        prop-class="rounded-full h-16 w-16"
                                    />
                                    <!-- right -->
                                    <div class="right">
                                        <div class="name">{{ userName }}</div>
                                        <div class="city" v-if="userCity">
                                            <base-icon name="location" :wrapper-class="'inline-block'"/>
                                            <span>{{ userCity }}</span>
                                        </div>
                                    </div>
                                </div>
                                
                                <!-- feedback form -->
                                <div class="form-wrapper">
                                    <div class="form">
                                        <div class="field-list">
                                            <div class="field-item" v-for="rating in computedFeedback" :key="rating.id">
                                                <div class="field-content">
                                                    
                                                    <label>{{ rating.display }}</label>
                                                    
                                                    <!-- 1 - 5 rating -->
                                                    <div class="type-1-5-rating" v-if="rating.type === 1">
                                                        <div
                                                            class="rating-item"
                                                            v-for="i in 5"
                                                            :key="i"
                                                            :class="{'active': parseInt(i) <= (rating.search || rating.value)}"
                                                            @click="rating.value = i; sendFeedback()"
                                                            @mouseover="handleValue(rating, i)"
                                                            @mouseleave="removeHandle(rating)"
                                                        >
                                                            {{ i.toString() }}
                                                        </div>
                                                    </div>
                                                    
                                                    <!-- text -->
                                                    <div class="type-text" v-if="rating.type === 2">
                                                        <FormulateInput
                                                            type="text"
                                                            label="Enter feedback"
                                                            class="w-full text-left"
                                                            placeholder="Enter description"
                                                            v-model="rating.value"
                                                            v-debounce="sendFeedback"
                                                            :label-class="['font-bold text-black']"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="footer">
                                    <base-button 
                                        label="Close" 
                                        @action="feedback.show = false" 
                                        size="sm"
                                    />
                                </div>

                                <!-- changes saved notification -->
                                <div class="changes-saved-notification-wrapper" v-if="feedback.saved_changes">
                                    <div class="changes-saved-notification-container">
                                        Changes Saved
                                        <base-icon name="check"/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </transition>
</template>
<script>
import ImgSelector from '../../components/ImgSelector.vue';
export default {
    props: [
        'firestore',
        'feedback', 
        'feedbackUser',
        'userPicture',
        'userName',
        'userCity',
        'computedFeedback',
        'forceFeedbackUpdatedNotification'
    ],

    components: {
        ImgSelector
    },

    data() {
        return {

        }
    },

    computed: {

    },

    watch: {
        forceFeedbackUpdatedNotification(){
             this.feedback.saved_changes = true;
            setTimeout(() => {
                this.feedback.saved_changes = false;
            }, 3000);
        }
    },

    methods: {
        handleValue(rating, i) {
            rating.searching = true; // 
            rating.search = i; //
            this.$forceUpdate();
        },
        removeHandle(rating) {
            rating.searching = false; //
            rating.search = null; //
            this.$forceUpdate();
        },
        async sendFeedback() {
            this.feedback.saved_changes = false; //

            const ref = this.firestore.ref(this.$route.params.id);
            const childRef = ref.child(this.feedback.user_id);

            const complete = await childRef.update({
                ratings: this.feedback.ratings,
                comments: this.feedback.comments,
            });

            console.log('sendFeedback complete data', complete);

            // this might not always mean that the feedback was updated
            // this.feedback.saved_changes = true;
            // setTimeout(() => {
            //     this.feedback.saved_changes = false;
            // }, 3000);

            // Complete is being returned as undefined so this is
            // not be working right now.
            // const complete = true;
            // if (complete) {
                /* this.$swal.fire({
                    title: 'Feedback sent',
                    text: 'Your feedback was sent for this creator.',
                    icon: 'success',
                    confirmButtonText: 'Finished',
                }); */
                // this.feedback.saved_changes = true; //
            // }

        },
        // not using this
        async closeFeedback() {
            this.feedback.show = false; // 
        },
    }
}
</script>
<style lang="scss" scoped>
    // transition classes
    .enter-active-class{
        @apply transition-all ease-in-out duration-500;
        // @apply transition-fastest
    }
    .leave-active-class{
        @apply transition-all ease-in-out duration-500;
        // @apply transition-fastest
    }
    .enter-class{
        @apply opacity-0;
        // @apply scale-70
    }
    .enter-to-class{
        @apply opacity-100;   
        // @apply scale-100
    }
    .leave-class{
        @apply opacity-100;
        // @apply scale-100
    }
    .leave-to-class{
        @apply opacity-0;
        // @apply scale-70
    }

    // feedback-sidebar classes
    .feedback-sidebar-wrapper{
        @apply fixed z-100 inset-0 overflow-hidden pointer-events-none;
        > .overlay{
            @apply absolute w-full h-full bg-gray-500 opacity-75 pointer-events-auto;
        }
        > .sidebar-wrapper{
            @apply absolute inset-0 overflow-hidden pointer-events-none;
            > .sidebar-positioner{
                @apply absolute inset-y-0 w-full max-w-full right-0 flex;
                // // md:w-1/2
                @media (min-width: 768px) {
                    @apply w-1/2;
                }
                > .sidebar-container{
                    @apply w-screen pointer-events-auto;
                    > .sidebar{
                        @apply h-full flex flex-col bg-white shadow-xl;
                        > .content{
                            @apply flex-1 h-0 overflow-y-auto;
                            > .header{
                                @apply py-6 px-4;
                                @media (min-width: 640px) {
                                    @apply px-6;
                                }
                                > .title{
                                    @apply text-xl font-bold mt-6;
                                }
                                > .description{
                                    @apply text-gray-400 text-sm;
                                }
                            }

                            > .user{
                                @apply flex px-4;
                                @media (min-width: 640px) {
                                    @apply px-6;
                                }
                                > .right{
                                    @apply w-full pl-5;
                                    > .name{
                                        @apply text-2xl font-semibold text-left;
                                    }
                                    > .city{
                                        @apply text-sm text-gray-m-disable text-left;
                                        > span{
                                            @apply inline-block align-top;
                                        }
                                    }
                                }
                            }

                            > .form-wrapper{
                                //
                                > .form{
                                    @apply flex-1 flex flex-col justify-between;
                                    > .field-list{
                                        @apply px-4;
                                        @media (min-width: 640px) {
                                            @apply px-6;
                                        }
                                        > .field-item{
                                            @apply py-3;
                                            > .field-content{
                                                @apply flex flex-col gap-y-1;
                                                > label{
                                                    @apply block text-sm text-left font-bold leading-5 text-black pb-1;
                                                }
                                                > .type-1-5-rating{
                                                    @apply relative z-0 inline-flex gap-x-5;
                                                    > .rating-item{
                                                        @apply bg-gray-m text-gray-600 px-6 py-2 rounded-full cursor-pointer;
                                                        &.active{
                                                            @apply bg-green-m-main;
                                                        }
                                                    }
                                                }
                                                > .type-text{
                                                    @apply relative rounded-md shadow-sm;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .footer{
                                @apply flex-shrink-0 px-4 py-4 gap-x-4 flex justify-end items-center;
                            }

                            .changes-saved-notification-wrapper{
                                @apply absolute top-10 right-10;
                                .changes-saved-notification-container{
                                    @apply bg-green-m-main px-10 py-4 rounded-md;
                                    @apply flex justify-between items-center gap-x-2 font-bold;
                                }
                            }
                            
                        }
                    }
                }
            }
        }
    }
</style>
