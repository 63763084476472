<template>
  <div class="report-import-wrapper">
    <Header />

    <div class="content">
      <!-- Upload file -->
      <tab :visible="step == 1">
        <step-1-upload-file
          @set-file-creators="creators => (this.fileCreators = creators)"
          @set-report-name="fileName => (this.name = fileName)"
          @next="step++"
        />
      </tab>

      <!-- Report settings -->
      <tab :visible="step == 2">
        <step-2-report-settings
          :name="name"
          :type="type"
          :project-slug="projectSlug"
          :tags="tags"
          :description="description"
          :password-protected="passwordProtected"
          :password="password"
          :posts-limit="settings.posts_limit"
          :allow-feedback="settings.allow_feedback"
          :feedback="settings.feedback"
          :hide-resume="settings.hide_resume"
          :show-boosted-data="settings.show_boosted_data"
          :hide-stats="settings.hide_stats"
          :preview="settings.preview"
          @update:project-id="val => (projectId = val)"
          @update:project-name="val => (projectName = val)"
          @update:name="val => (name = val)"
          @update:tags="val => (tags = val)"
          @update:description="val => (description = val)"
          @update:password-protected="val => (passwordProtected = val)"
          @update:password="val => (password = val)"
          @update:posts-limit="val => (settings.posts_limit = val)"
          @update:allow-feedback="val => (settings.allow_feedback = val)"
          @update:feedback="(index, val) => (settings.feedback[index].active = val)"
          @update:hide-resume="val => (settings.hide_resume = val)"
          @update:show-boosted-data="val => (settings.show_boosted_data = val)"
          @update:hide-stats="val => (settings.hide_stats = val)"
          @update:preview="val => (settings.preview = val)"
          @create-feedback-option="val => settings.feedback.push(val)"
        />
      </tab>

      <!-- Field mapper -->
      <tab :visible="step == 3">
        <step-3-field-mapper
          :file-creators="fileCreators"
          :mapper="mapper"
          :additional-information="additionalInformation"
          @update:mapper="val => (mapper = val)"
          @update:additional-information="val => (additionalInformation = val)"
          :mount-event-trigger="step"
        />
      </tab>

      <!-- Creator list -->
      <tab :visible="step == 4">
        <step-4-creator-list
          :file-creators="fileCreators"
          :mapper="mapper"
          :additional-information="additionalInformation"
          :creators="creators"
          @update:creators="val => (creators = val)"
          :mount-event-trigger="step"
        />
      </tab>

      <!-- Final settings -->
      <tab :visible="step == 5">
        <step-5-final-settings
          :selected-existing-data-option="selectedExistingDataOption"
          :selected-social-invite-option="selectedSocialInviteOption"
          @update:selected-existing-data-option="val => (selectedExistingDataOption = val)"
          @update:selected-social-invite-option="val => (selectedSocialInviteOption = val)"
        />
      </tab>

      <!-- Overview -->
      <tab :visible="step == 6">
        <step-6-overview
          :creators="creators"
          :name="name"
          :project-name="projectName"
          :tags="tags"
          :description="description"
          :password-protected="passwordProtected"
          :settings="settings"
          :selected-existing-data-option="selectedExistingDataOption"
          :selected-social-invite-option="selectedSocialInviteOption"
        />
      </tab>

      <!-- ReportLink -->
      <tab :visible="step == 7">
        <step-7-report-link :guid="guid" />
      </tab>

      <loader :visible="loading" label="Creating Report" />
    </div>

    <Footer
      :step="step"
      :nextEnabled="nextEnabled"
      @next="step++"
      @back="step--"
      @submit="submit()"
      @submit-beta="submit(true)"
    />
  </div>
</template>
<script>
import Header from "./Header.vue";
import Step1UploadFile from "./Step1UploadFile.vue";
import Step2ReportSettings from "./Step2ReportSettings.vue";
import Step3FieldMapper from "./Step3FieldMapper.vue";
import Step4CreatorList from "./Step4CreatorList.vue";
import Step5FinalSettings from "./Step5FinalSettings.vue";
import Step6Overview from "./Step6Overview.vue";
import Step7ReportLink from "./Step7ReportLink.vue";
import Footer from "./Footer.vue";

// TODO remove these imports and delete files
// import { fileCreatorsDataExample } from './_tempFileCreatorsDataExample'
// import { mapperDataExample } from './_tempMapperDataExample'
// import { creatorsDataExample } from './_tempCreatorsDataExample2'
// import { additionalInformationDataExample } from './_tempAdditionalInformationDataExample'
import { copyObject, isValidArray, isValidObject, isValidString, notifyCatchError } from "../../common";

// import Loader from '../../components/Loader';
// import { jaroWinkler, formatNumberString } from '../../lib/strings';

export default {
  components: {
    Header,
    Step1UploadFile,
    Step2ReportSettings,
    Step3FieldMapper,
    Step4CreatorList,
    Step5FinalSettings,
    Step6Overview,
    Step7ReportLink,
    Footer
  },
  data() {
    return {
      step: 1,

      fileCreators: [],

      // report general settings
      name: "",
      type: 1,
      projectSlug: this.$route.query.project,
      projectId: null,
      projectName: "",
      tags: [],
      description: "",
      passwordProtected: false,
      password: "",

      // report advanced settings
      settings: {
        allow_feedback: false,
        // platforms: false,
        // audience_followers: { audience_geo: false, audience: false },
        // audience_likers: { audience_geo: false, audience: false },
        hide_resume: false,
        show_boosted_data: true,
        hide_stats: false,
        preview: true,
        feedback: [
          { id: 1, label: "Local Relevance", type: 1, active: true },
          { id: 2, label: "Intuitive Fit", type: 1, active: true },
          { id: 3, label: "Creativity", type: 1, active: true },
          { id: 4, label: "Quality", type: 1, active: true },
          { id: 5, label: "Other Notes", type: 2, active: true }
        ],
        // groups: [],
        posts_limit: { id: 9, label: 9 }
        // loading_type: { id: 'cascade', label: 'Sequentially' }
      },

      // field mapper
      mapper: [],

      // additional information
      additionalInformation: [],

      // This var should merge the fileCreators & mapper vars, and should have all of the creator values
      creators: [],

      // final settings
      selectedExistingDataOption: "keep",
      selectedSocialInviteOption: "false",

      // created report guid
      guid: null,

      loading: false
    };
  },
  computed: {
    nextEnabled() {
      if (this.step == 1) {
        return isValidArray(this.fileCreators);
      }
      if (this.step == 2) {
        const {
          name,
          type,
          projectSlug,
          projectName,
          projectId,
          /*tags,*/
          /*description,*/
          passwordProtected,
          password
        } = this;
        let errors = false;
        if (!isValidString(name)) errors = true;
        if (!type) errors = true;
        if (!isValidString(projectSlug)) errors = true;
        if (!isValidString(projectName)) errors = true;
        if (!projectId) errors = true;
        if (passwordProtected == true) {
          if (!isValidString(password)) errors = true;
        }
        return !errors;
      }
      if (this.step == 3) {
        let _mapper = copyObject(this.mapper);
        _mapper = _mapper.filter(element => element.required == true);
        let errors = false;
        for (const field of _mapper) {
          if (!field.mapped || !field.fileColumn) {
            errors = true;
          }
        }
        return !errors;
      }
      // values can only be 1 of the 2 options
      if (this.step == 4) {
        return true;
      }
      // overview, informative only
      if (this.step == 5) {
        return true;
      }
      return false;
    }
  },
  methods: {
    submit(beta = false) {
      this.loading = true;
      const {
        name,
        type,
        projectId,
        tags,
        description,
        passwordProtected,
        password,
        settings,
        creators,
        selectedExistingDataOption,
        selectedSocialInviteOption
      } = this;
      // the creators var is a big object, which might lead to a "Payload too large" error,
      // we remove the post previews and some found users properties so it is not so large
      let creatorsObj = copyObject(creators);
      creatorsObj = creatorsObj.map(creator => {
        if (isValidArray(creator.posts)) {
          for (let post of creator.posts) {
            post.preview = null;
          }
        }
        creator.user_matches = null;
        if (isValidObject(creator.user)) {
          creator.user.additional_info = [];
          creator.user.user_companies = null;
          creator.user.user_roles = null;
        }
        return creator;
      });

      const apiUrl = !beta ? "/reports/create/imported" : "/reports/v2/create/imported";

      this.$http
        .post(apiUrl, {
          // general settings
          name,
          type,
          project_id: projectId,
          tags,
          description,
          password_protected: passwordProtected,
          password,

          // advanced settings
          settings,

          // creators
          creators: creatorsObj,

          // final settings
          existing_data_option: selectedExistingDataOption,
          social_invite_option: selectedSocialInviteOption
        })
        .then(({ data }) => {
          if (data && data.guid) {
            this.guid = data.guid;
            this.step++;
          }
        })
        .catch(err => {
          notifyCatchError(err, this.$notify);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.report-import-wrapper {
  @apply flex-1 h-screen;
  @apply flex flex-col justify-between;
  > :not(.content) {
    @apply flex-grow-0 flex-shrink-0;
  }
  > .content {
    @apply px-12 py-8 flex-grow flex-shrink overflow-y-auto;
    height: calc(100vh - 212px); // Viewport height - (header height + footer height)
  }
}
</style>
