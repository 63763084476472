import { render, staticRenderFns } from "./DueDates.vue?vue&type=template&id=177694c0&scoped=true&"
import script from "./DueDates.vue?vue&type=script&lang=js&"
export * from "./DueDates.vue?vue&type=script&lang=js&"
import style0 from "./DueDates.vue?vue&type=style&index=0&id=177694c0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "177694c0",
  null
  
)

export default component.exports