<template>
    <div class="container">
        <loader label="Getting tiktok" visible/>
    </div>
</template>

<script>
export default {
    mounted() {
        this.processResponse();
    },
    methods: {
        processResponse() {
            let localName = 'scrapper_id';
            let localResult = 'scrapper_result';
            localStorage.removeItem(localName);
            localStorage.removeItem(localResult);
            let result = false;
            if (this.$route.query.success !== undefined) {
                console.log(this.$route.query);
                let { success, /*fail,*/ scrapperId } = this.$route.query;
                if (success == 'true') {
                    result = true;
                    localStorage.setItem(localName, parseInt(scrapperId));
                }
            }

            localStorage.setItem(localResult, result);

            window.close();
        }
    }
}
</script>

<style lang="scss" scoped>
    .container{
        @apply py-16 px-24;
    }
</style>