<template>
    <div style="width: 100%; height: 100vh" class="flex justify-center items-center">
        
        <create-version-modal 
            @showBriefAttachment="showBriefAttachment" 
            :visible="createVersionModalVisible"
            :standAlone="true"
            :deliverable="{token: $route.query.t}"
            :submissionType="getType"
            :type="getType == 'Concept' ? 1 : 2"
            name=""
        />
        
        <div v-if="lightboxVisible" style="z-index: 100" class="inset-0 fixed bg-black overflow-y-auto">
            <div class="relative w-screen h-screen py-16 px-36">
                <pdf-preview
                    :url="filePath"
                    :fixed-pagination="true"
                />
                <div @click="lightboxVisible = false" class="absolute top-6 right-10">
                    <base-icon 
                        name="close" 
                        iconClass="text-white cursor-pointer"
                        :size="10" 
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PdfPreview from '../../components/PdfPreview.vue'
import CreateVersionModal from './CreateVersionModal.vue'
import axios from 'axios'
export default {

    mounted() {
        // to trigger the "visible" watcher on create version modal
        this.createVersionModalVisible = true
    },

    components: {
        CreateVersionModal,
        PdfPreview
    },
    data(){
        return {
            submissionType: "Concept",
            selectedAttachment: null,
            lightboxVisible: false,
            createVersionModalVisible: false
        }
    },
    computed: {

        getType() {
            return this.$route.query.type;
        },

        filePath(){
            if (this.selectedAttachment) {
                return `${axios.defaults.baseURL}/api/files/render/${this.selectedAttachment.guid}?token=${this.selectedAttachment.token}`
            }
            return '';
        }
    },
    methods: {
        showBriefAttachment(attachment){
            this.selectedAttachment = attachment
            this.lightboxVisible = true
        }
    }

}
</script>