<template>
<!-- wrapper -->
<main class="main wrapper" tabindex="0">

    <!-- container -->
    <div class="container">
        
        <!-- table -->
        <div 
            class="table wrapper" 
            :class="{'full': !showPinnedProjects || isOnlyCreator || isClient || isGuest}"
        >

            <div class="container">

                <base-table
                    :columns="[
                        {
                            name: 'name',
                            label: 'Project Name',
                            type: 'link',                            
                            value: (element) => {
                                return `${ element.name } - ${ element.brand }`
                            },
                            options: {
                                cellClasses: 'max-w-screen-sm truncate',
                                cellStyles: `${showPinnedProjects && !isOnlyCreator ? `max-width: 200px; width: 200px;` : ``} padding-left: 12px; padding-right: 12px;`,
                                thStyles: 'padding-left: 12px; padding-right: 12px;'
                            },
                        },
                        {
                            name: 'budget',
                            label: isOnlyCreator ? 'Amount' : 'Budget',
                            type: 'price',
                            hidden: !isAdminOrHigher,
                            options: {
                                cellStyles: 'padding-left: 12px; padding-right: 12px;',
                                thStyles: 'padding-left: 12px; padding-right: 12px;'
                            },
                            value: (element) => {
                                return element.budget || ''
                            }
                        },
                        ...(isOnlyCreator ? [] :
                        {
                            name: 'owners',
                            label: 'Owner',
                            type: 'avatar-list',                
                            searchBy: ownersSearchBy,
                            value: (element) => {
                                return owners(element.project_owners)
                            },
                            options: {
                                cellStyles: 'padding-left: 12px; padding-right: 12px;',
                                thStyles: 'padding-left: 12px; padding-left: 12px;'
                            }
                        }),
                        ...(isOnlyCreator ? [] : {
                            name: 'users',
                            label: 'Members',
                            type: 'avatar-list',                    
                            searchBy: membersSearchBy,
                            hidden: true
                        }),
                        ...(isOnlyCreator ? [] :
                        {
                            name: 'statuses',
                            label: 'Status',
                            type: !isAdminOrHigher || tab === 'archived' ? 'string' : 'status',
                            searchBy: statusSearchBy, 
                            value:  (element) => {
                                return !isAdminOrHigher || tab === 'archived' ? element.statusLabel : element;
                            },
                            options: {
                                stopPropagation: true,
                                cellStyles: 'max-width: 224px; width: 224px; padding-left: 12px; padding-right: 0px;',
                                thStyles: 'padding-left: 12px; padding-left: 12px;'
                            }
                        })
                    ]"
                    :data="projects"
                    :rowActions="rowActions()"
                    :onRowClick="(element) => {
                        if(!isClient && !isGuest){
                            this.$router.push(`/projects/${element.slug}`)
                        }
                    }" 
                    :loading="loading"
                    @refresh-data="resetData(false)"
                    @toggle-status="toggleStatus"
                    table-id="home-table"
                    container-id="home-table-container"
                    container-styles="min-height: calc(100vh - 340px);max-height: calc(100vh - 256px);overflow-y: scroll; overflow-x: hidden;margin-right: 5px;"
                    sticky-header
                    overflow
                    search-class="sm:mx-10"
                    table-class="min-w-95 ml-12 sm:mx-10"
                    :page="page"
                    :per-page="perPage"
                    :changePage="value => page = value"
                    :total="0"
                    @reload-filters="filterChange"
                >
                
                    <template v-slot:filters-footer>

                        <!-- header -->
                        <base-button
                            @action="$router.push('/projects/create')"
                            v-if="!isOnlyCreator && isAdminOrHigher || isProjectManager"
                            :iconSize="4"
                            theme="dark"
                            type="secondary"
                            class="font-bold float-right self-start mr-10"
                            size="auto" 
                            label="New Project" 
                            icon="plus"
                        />
                    
                        <tabs
                            v-if="isAdminOrHigher || isProjectManager"
                            :tabs="[
                                { name: 'active', active: true }, 
                                { name: 'archived', active: true }
                            ]"
                            v-model="tab"
                            capitalize
                            custom-class="self-start ml-12 sm:ml-12 mb-5 mt-3"
                        />
                            
                    </template>

                    <template v-slot:table-footer>
                        <div class="flex justify-between items-center px-16">
                            <div>
                                Showing {{ projects.length }} results 
                            </div>
                            <div class="flex justify-center">
                                <base-button  
                                    icon="chevron-left" 
                                    size="xs" 
                                    type="secondary" 
                                    rounded="0" 
                                    :disabled="page === 1" 
                                    class="border border-r-0 border-gray-300 rounded-l-xl" 
                                    @action="page--"
                                />
                                <base-button 
                                    icon="chevron-right" 
                                    size="xs" 
                                    type="secondary" 
                                    rounded="0" 
                                    :disabled="projects.length < perPage" 
                                    class="border border-gray-300 rounded-r-xl" 
                                    @action="page++"
                                />
                            </div>
                        </div>
                    </template>

                </base-table>

            </div>
        </div>

        <!-- pinned projects -->
        <div class="pinned-projects mr-5 wrapper" v-if="showPinnedProjects && !isOnlyCreator && !isClient && !isGuest">
            <div class="container">
                <div class="content">
                    <div class="header">
                        <base-icon 
                            name="flagged" 
                            :size="10" 
                            class="text-white" 
                            :border="true" 
                        />
                        <base-icon
                            @action="toggleSidebar(false)"
                            name="arrow-right" 
                            :size="10" 
                            class="text-white cursor-pointer hover:text-green-m-main" 
                            :border="true" 
                        />
                    </div>
                    <div>
                        <pinned-projects-list
                            v-if="pinnedProjects.length"
                            :projects="filteredPinnedProjects"
                            @toggle-pinned="togglePinned"
                            @archive-project="(project) => archiveProjectIntent(project)"
                            @refresh-data="resetData(false)"
                        />
                        <section-empty-state 
                            icon="folder" 
                            v-else 
                            message="There are no pinned projects yet" 
                            class="text-center pr-8"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="hide toggle-pinned" v-else-if="!isOnlyCreator">
            <base-icon
                @action="toggleSidebar(true)"
                name="arrow-left" 
                :size="10" 
                class="cursor-pointer hover:text-green-m-main" 
                :border="true" 
            />
        </div>
    </div>

</main>
</template>

<script>
import PinnedProjectsList from './PinnedProjectsList.vue';
import SectionEmptyState from '../../components/SectionEmptyState.vue';
import { mapGetters } from 'vuex';
import { isValidArray, isValidString } from '../../common';

const perPage = 8;

export default {
    components: {
        PinnedProjectsList,
        SectionEmptyState
    },
    data() {
        return {
            projects: [],
            pinnedProjects: [],
            page: 1,
            perPage,
            searchTerm: '',
            allOwners: null,
            allMembers: null,
            loadingPinned: false,
            showPinnedProjects: true,
            loading: false,
            tab: 'active'
        }
    },

    watch: {
        // if we change from active to archived
        tab(val){
            if(val){
                // we return the page to 1
                this.page = 1;
                // we remove the search term too
                this.searchTerm = '';
                this.getData();
            }
        },
        page(val){
            if(val){
                this.getData();
            }
        }
        // not using boards anymore
        // '$store.state.boards.selectedBoard': function() {
        //     this.getPinnedProjects();
        //     this.getData();
        // }
    },

    computed: {
        ...mapGetters(['selectedBoard', 'boards', 'filterableStatuses', 'isAdminOrHigher', 'isProjectManager', 'isClient', 'isGuest', 'isCollaborator', 'isOnlyCreator']),

        filteredPinnedProjects(){
            return this.projects.filter(element => this.pinnedProjects.map(el => el.project_id).includes(element.id))
        },

        projectUserPreviewCount() {
            return 5;
        }

    },

    beforeMount() {
        this.$setTitle('Projects');
    },

    mounted() {
        this.getPinnedProjects();
        this.getData();

        // get show                  
        if(localStorage.getItem("pinned_show") === "false")
            this.showPinnedProjects = false;
    },

    methods: {
        changePage(page){
            this.page = page;
            this.getData();
        },
        toggleStatus({id}) {
            this.projects.forEach(p => {
                if (p.id === id) {
                    p.isOpen = !p.isOpen;
                } else {
                    p.isOpen = false;
                }
            });
        },
        toggleSidebar(state){
            this.showPinnedProjects = state;
            localStorage.setItem("pinned_show", state)
        },
        resetData(showLoader = true) {
            // "forceUpdate" parameter set to true so we will load the statuses even if they already exist on the store
            this.$store.dispatch('statuses/getStatuses', true);
            this.$store.dispatch('boards/getBoards'); 
            this.getPinnedProjects(showLoader);
            this.getData(showLoader);
        },
        async getData(showLoader = true) {
            this.loading = showLoader;
            // [get-all-projects]
            let response = await this.$http.get('/api/projects', {
                params: {
                    page: this.page,
                    perPage: this.perPage,
                    ...(isValidString(this.searchTerm) ? { searchTerm: this.searchTerm } : {}),
                    view: 'project-list',
                    ...(this.tab == 'archived' ? { archived: true } : {})
                }
            });
            const data = response.data;
            if (!data) {
                if (response.response && response.response.data && response.response.data.logout) {
                    window.location = '/login';
                }
                this.loading = false;
                return
            }
            this.projects = await data.map(project => {
                project.statusLabel = (project.project_status && project.project_status.name) ? project.project_status.name : 'w/o status';
                project.isOpen = false;
                return project;
            }).reverse();

            let user, value, label;

            /**Extract owners to show in select*/
            let a = []
            for (let i in data) {
                for (let j in data[i].project_owners) {
                    user = data[i].project_owners[j].user;
                    value = label = user.first_name + ' ' + user.last_name;
                    if (!a.find(v => v.value === value)) {
                        a.push({value, label})
                    }
                }
            }
            this.allOwners = a

            /**Extract members to show in select*/
            let b = []
            for (let k in data) {
                for (let l in data[k].users) {
                    user = data[k].users[l];
                    value = label = user.first_name + ' ' + user.last_name;
                    if (!b.find(v => v.value === value)) {
                        b.push({value, label});
                    }
                }
            }
            this.allMembers = b
            this.loading = false
        },

        filterChange(filters){
            const oldSearchTerm = this.searchTerm;
            const newSearchTerm = (filters && isValidString(filters.search)) ? filters.search : '';
            const areDifferent = oldSearchTerm !== newSearchTerm;
            this.searchTerm = newSearchTerm;
            if(areDifferent){
                this.page = 1;
            }
            setTimeout(() => {
                this.getData();
            }, 250); 
        },

        async archiveProjectIntent(project, unpin = false) {
            this.$swal.fire({
                title: `Are you sure you want to archive the '${project.name}' project?`,
                text: '',
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Archive',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.archiveProject(project, unpin);
                }
            });
        },

        async trashProjectIntent(project, unpin = false) {
            this.$swal.fire({
                title: `Are you sure you want to delete the '${project.name}' project?`,
                text: 'This cannot be reversed.',
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.trashProject(project, unpin);
                }
            });
        },

        async archiveProject({id}, unpin) {
            const { data } = await this.$http.post(`/api/projects/${id}/delete`);
            if (data) {
                if (unpin) {
                    this.togglePinned(id, true, false);
                }
                this.$store.dispatch('restorer/set', {
                    message: 'Project archived successfully',
                    success: 'Project unarchived successfully',
                    fail: 'Project cannot be unarchived',
                    route: `/api/projects/${id}/restore`,
                    action: data.show_undo,
                    forceFn: true,
                    fn: () => {
                        this.getData(false);
                        this.getPinnedProjects(false);
                    }
                });
            }
            const projectIndex = this.projects.findIndex(c => c.id === id);
            this.projects[projectIndex].deleted_at = new Date().toISOString();
        },

        async trashProject({id}, unpin) {
            const { data } = await this.$http.post(`/api/projects/${id}/hard-delete`);
            if (data) {
                if (unpin) {
                    this.togglePinned(id, true, false);
                }
                this.$store.dispatch('restorer/set', {
                    message: 'Project trashed successfully',
                    success: 'Project untrashed successfully',
                    fail: 'Project cannot be untrashed',
                    route: false,
                    action: false,
                    forceFn: true,
                    fn: () => {
                        this.getData(false);
                        this.getPinnedProjects(false);
                    }
                });
            }
            const projectIndex = this.projects.findIndex(c => c.id === id);
            this.projects[projectIndex].deleted_at = new Date().toISOString();
        },

        async getPinnedProjects(showLoader = true) {
            this.loadingPinned = showLoader
            let response = await this.$http.get(`/api/projects/pinned`);
            const data = response.data;
            if (!data) {
                console.log('Failing to get pinned projects');
                this.loadingPinned = false;
                return;
            }
            this.pinnedProjects = data.map(element => {
                return element;
            }).reverse();
            this.loadingPinned = false;
        },

        async togglePinned(id, isPinned, showAlert = true) {
            let action = 'pin';
            let toast = 'Pinned project';

            if (isPinned) {
                action = 'unpin';
                toast = 'Removed from pinned projects';
            }

            const { data } = await this.$http.post(`/api/projects/${id}/${action}`);
            if (data) {
                this.getPinnedProjects();
                this.getData();
                if (showAlert) {
                    this.$notify({title: 'Success', text: toast, type: 'success'});
                }
            }
        },

        async restoreProject(project) {
            this.$swal.fire({
                title: `Are you sure you want to unarchive the '${project.name}' project?`,
                text: '',
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Restore',
                reverseButtons: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const { data } = await this.$http.post(`/api/projects/${project.id}/restore`);
                    if (data) {
                        this.getData();
                        this.$notify({title: 'Success', text: 'Project unarchived successfully', type: 'success'});
                    }
                }
            });
        },

        rowActions(){
            if(this.isOnlyCreator || (!this.isAdminOrHigher && !this.isCollaborator)) return []
            if(this.isAdminOrHigher && this.tab === 'archived') {
                return [
                        {
                            label: 'Unarchive',
                            icon: '',
                            event: 'restore',
                            handler: (project) => {
                                this.restoreProject(project);
                            }
                        },
                        {
                            label: 'Trash',
                            icon: '',
                            event: 'trash',
                            handler: (project) => {
                                this.trashProjectIntent(project);
                            }
                        }
                ]
            }     
            return [
                ...(this.isAdminOrHigher ? [{
                    label: 'Edit', 
                    icon: 'pencil-alt', 
                    event:'edit',
                    handler: (project) => {
                        this.$router.push(`/projects/${project.slug}/edit`)
                    }
                }] : []),
                {
                    label: 'Pin', 
                    icon: 'flagged',
                    event: 'pin',
                    if: this.renderPinAction,
                    separator: true,
                    handler: (project) => {
                        this.togglePinned(project.id, false)
                    }
                },
                {
                    label: 'Unpin', 
                    icon: 'flagged',
                    event: 'unpin',
                    if: this.renderUnpinAction,
                    separator: true,
                    handler: (project) => {
                        this.togglePinned(project.id, true)
                    }
                },
                ...(this.isAdminOrHigher ? [{
                    label: 'Archive', 
                    icon: 'trash', 
                    theme: 'cancel',
                    event: 'archive',
                    handler: (project) => {
                        this.archiveProjectIntent(project)
                    } 
                }] : [])
            ]
        },

        owners(owners){
            return isValidArray(owners) ? owners.map(element => element.user).reverse() : [];
        },

        ownersSearchBy(element){
            return element.project_owners.map(owner => `${owner.user.first_name} ${owner.user.last_name}`).toString().toLowerCase() 
        },

        membersSearchBy(element){
            return element.users.map(user => `${user.first_name} ${user.last_name}`).toString().toLowerCase()
        },

        statusSearchBy(element){
            return element.statusLabel.toString().toLowerCase()
        },

        renderPinAction(project){
            return !this.pinnedProjects.map(element => element.project_id).includes(project.id)
        },
     
        renderUnpinAction(project){
            return this.pinnedProjects.map(element => element.project_id).includes(project.id)
        }
    }
}
</script>

<style lang="scss" scoped>

    .main.wrapper{

        .container {
            width: 100%;
            max-width: 100%;
        }

        .toggle-pinned {
            color: black;
            position: fixed;
            bottom: 11px;
            right: 11px;
        }

        @apply flex-1 relative z-0 overflow-y-hidden;
        &:focus{
            @apply outline-none;
        }

        > .container{
            @apply flex w-full py-10;

            > .table.wrapper{
                @apply w-1/2;
                @media (min-width: 768px) { @apply w-3/5; } // md
                @media (min-width: 1280px) { @apply w-2/3; } // xl
                @media (min-width: 1536px) { @apply w-3/4; } // 2xl
                
                &.full{
                    @apply w-full;
                    @media (min-width: 768px) { @apply w-full; } // md
                    @media (min-width: 1280px) { @apply w-full; } // xl
                    @media (min-width: 1536px) { @apply w-full; } // 2xl
                }

                > .container{
                    @apply flex;
                    @media (min-width: 640px) { // "sm"
                        @apply gap-1 items-center justify-between;
                    }
                    @media (min-width: 1024px) { @apply gap-4; }
                }
            }
        }

    }

    .table-header{
        @apply bg-tan-m flex justify-between items-center my-2 py-8 px-10 mx-12 rounded-3xl;
        @media (min-width: 640px) { // "sm"
            @apply mx-8; 
        }
        > .title{
            @apply text-h2 font-bold text-purple-m-secondary;
        }
    }

    .pinned-projects.wrapper{
        @apply w-1/2 sticky top-10;
        @media (min-width: 768px) { @apply w-2/5; } // md
        @media (min-width: 1280px) { @apply w-1/3; } // xl
        @media (min-width: 1536px) { @apply w-1/4; } // 2xl
        > .container{
            @apply bg-purple-m-main rounded-4xl flex flex-col justify-between;
            background-image: url("../../assets/images/dinosaur.png");
            background-repeat: no-repeat;
            background-position: bottom;
            background-size: contain;
            height: calc(100vh - 50px); 
            > .content{
                @apply p-8 pr-0 h-full flex flex-col gap-6;
                > .header{
                    @apply w-full pr-8 flex flex-row justify-between;
                }
            }
        }
    }

    .hide.container{
        @apply bg-purple-m-main fixed top-16 right-0 rounded-xl w-14 p-2;
    }

</style>
