import store from '../store'
import { decryptStr } from '../lib/strings';
import axios from 'axios';

const scope = 'email,public_profile,business_management,pages_show_list,instagram_basic,instagram_manage_insights,instagram_manage_comments,pages_read_engagement'
const fields = 'id,email,first_name,last_name,middle_name,name,name_format,picture,short_name,permissions,accounts{instagram_business_account{name,username,profile_picture_url}}'

function facebookLogin() {

    return new Promise((resolve, reject) => {
        window.FB.login(function(response) {

            // Logged into your webpage and Facebook.
            if (response.status === 'connected') {
    
                let { userId, accessToken } = response.authResponse
                
                // get email, names, picture, etc
                window.FB.api(`/me?fields=${fields}&access_token=${accessToken}`,
                    'GET',
                    {},
                    async(res) => {
                        try {
                            // create long lived access token
                            const { data } = await axios.get('/api/facebook/long-lived-user-access-token', {
                                params: { access_token: accessToken }
                            })
                            const longLivedAccessToken = data
                            resolve({...res, userId, accessToken, longLivedAccessToken })       
                        }
                        catch(e) {
                            console.log("error uploading file");
                            reject(e.message)
                            return { error: true, message: e.message }
                        }
                    }
                )
            } else {
                // The person is not logged into your webpage or we are unable to tell. 
                reject(response)
            }
        }, { 
                scope: scope,
                auth_type: 'rerequest'
        })
    })
}

function requestInstagramPermissions(){
    return new Promise((resolve, reject) => {
        window.FB.login(
            function(response) {
               
                if (response.status === 'connected') {
                    
                    let { userId, accessToken } = response.authResponse
                    
                    window.FB.api(`/me?fields=${fields}&access_token=${accessToken}`,
                        'GET',
                        {},
                        async (res) => {
                            try {
                                const { data } = await axios.get('/api/facebook/long-lived-user-access-token', {
                                    params: {
                                        access_token: accessToken
                                    }
                                })
                                const longLivedAccessToken = data
                                resolve({...res, userId, accessToken, longLivedAccessToken })       
                            }
                            catch(e) {
                                console.log("error uploading file");
                                reject(e.message)
                                return { error: true, message: e.message }
                            }
                        }
                    )
    
                } else {
                    reject(response)
                }
            },
            {
                scope: 'instagram_basic,instagram_manage_insights',
                auth_type: 'rerequest'
            }
        );
    })
}

function facebookLogout(){
    if(window.FB){
        window.FB.logout(function(response) {
            store.dispatch('fb/setStatus', response)
        })
    }else{
        console.log('Could not logout of FB because the sdk is not initialized yet')
    }
}

function checkFacebookStatus() {

    const statusChangeCallback = (response) => {
        store.dispatch('fb/setStatus', response)
    }
    
    if(window.location.origin.includes('https://')){
        window.FB.getLoginStatus(response => {
            statusChangeCallback(response)
        }, { scope: scope })
    }else{
        // console.log('Cant get FB login status from not secure pages')
    }

}

async function initFacebookSdk(axios) {
    window.fbAsyncInit = async function() {
        let { data } = await axios.get(`/api/auth/get-auth-credentials/facebook`);
        const appId = decryptStr(data.FACEBOOK_APP_ID, 'facebook');
        if (appId !== '') {
            const {FB} = window;
            // [fb-api-version] If the FB API version number gets changed make sure to also change it on 
            // other files on this project, on platform-api and social-scraper, search for "fb-api-version".
            try{
                FB.init({
                    appId,
                    cookie: true,
                    xfbml: true,
                    version: 'v21.0'
                });
                FB.AppEvents.logPageView()
                store.dispatch('fb/setInitialized', true)
                checkFacebookStatus()
            }catch(err){
                console.log('FB init error', err)
                store.dispatch('fb/setSdkError', true)
            }
        }
    };
    
    (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

}

export { initFacebookSdk, checkFacebookStatus, facebookLogin, facebookLogout, requestInstagramPermissions, scope }