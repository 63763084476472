<template>
    <div class="header">

        <!-- searchbar  -->
        <div class="searchbar-wrapper">
            <div class="searchbar-container">
                <div class="icon">
                    <base-icon name="search" />
                </div>
                <FormulateInput
                    type="text"
                    v-model="searchTerm"
                    :placeholder="`Search ${title.toLowerCase()} by name`"
                    outer-class="w-full"
                    element-class="w-full" 
                    wrapper-class="border rounded-md py-2 px-3 pl-16 bg-white h-16 flex items-center"
                />
            </div>
        </div>
        
        <!-- report type filter -->
        <div class="filter-container" v-if="mimetype == 'reports'">
            <custom-select
                label="Report type"
                v-model="selectedReportTypeFilter"
                :options="[
                    { label: 'All', value: 'all' },
                    { label: 'Creators', value: 'creators' },
                    { label: 'Content', value: 'content' },
                    { label: 'Brand', value: 'brand' }
                ]"
            />
        </div>

        <base-icon
            name="refresh"
            @action="() => $emit('refresh')"
            class="cursor-pointer"
            size="4"
            v-tooltip="{
                content: 'Refresh content',
                classes: 'v-tooltip-black' 
            }"
        />
        
    </div>
</template>
<script>
export default {
    props: ['title', 'mimetype'],
    data(){
        return {
            searchTerm: '',
            selectedReportTypeFilter: { label: 'All', value: 'all' },
        }
    },
    watch: {
        searchTerm(val /*, old*/){
            this.$emit('changeSearchTerm', val)
        },
        selectedReportTypeFilter(val /*, old*/){
            this.$emit('changeSelectedReportTypeFilter', val)
        }
    }
}
</script>

<style lang="scss" scoped>
    .header{
        @apply flex gap-x-10 items-center;
        > .searchbar-wrapper{
            width: 475px;
            > .searchbar-container{
                @apply relative flex w-full;
                > .icon{
                    @apply absolute w-16 h-full flex items-center justify-center pointer-events-none;
                    > div{
                        @apply text-purple-m-secondary;
                    }
                }
            }
        }
        > .filter-container{
            width: 475px;
        }
    }
</style>
