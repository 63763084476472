v<template>
    <base-modal
        :visible="visible"
        :title="title"
        @close="$emit('close')"
    >
        <div class="relative" style="min-height: 300px">
            
            <base-icon v-if="loading" name="loader" />
            
            <template v-else>
                
                <!-- scopes -->
                <div class="scopes-section">
                    
                    <!-- scope list -->
                    <div class="scope-list" v-if="validScopes">
                        <div class="title">Scopes: </div>
                        <div class="item" v-for="(scope, index) in scopes" :key="index">
                            {{ scope }}
                        </div>
                    </div>
                    <div v-else>This access token does not have access to any information.</div>

                    <!-- missing scopes -->
                    <div class="scope-list" v-if="scopesAreMissing">
                        <div class="title">There are some missing scopes: </div> 
                        <div class="item" v-for="(scope, index) in missingScopes" :key="`missing-scope-${index}`">
                            {{ scope }}
                        </div>
                    </div>
                    
                    <!-- connect button -->
                    <div v-if="!validScopes || scopesAreMissing">
                        <div class="font-bold">Connect your tiktok account to grant the new permissions</div> 
                        <tiktok-login 
                            style="margin-top: 10px;width: 250px;"
                            @success="(data) => { $emit('successLink', data); this.getTokenInfo(); }" 
                        />
                    </div>

                </div>

                <!-- data -->
                <div v-if="!data">There is no profile data for this user</div>
                
                <div class="profile" v-else>
                    
                    <div class="display-name" v-if="data.display_name">{{data.display_name}}</div>
                    <avatar
                        :user="{ first_name: '', last_name: '', avatar: data.avatar_url }" 
                        v-if="data.avatar_url"
                        size="x-large"
                        class="mt-4 mb-1"
                    />
                    <div class="username" v-if="data.username">
                        {{data.username}}

                        <div class="verified" v-if="data.is_verified">
                            <base-icon name="check" class="text-white" :size="2.5"/>
                        </div>
                    </div>
                    
                    <div class="stats">
                        <div class="stat" v-if="data.following_count">
                            <div class="value">{{data.following_count}}</div>
                            <div class="label">Following</div>
                        </div>
                        <div class="stat" v-if="data.follower_count">
                            <div class="value">{{data.follower_count}}</div>
                            <div class="label">Followers</div>
                        </div>
                        <div class="stat" v-if="data.likes_count">
                            <div class="value">{{data.likes_count}}</div>
                            <div class="label">Likes</div>
                        </div>
                        <div class="stat" v-if="data.video_count">
                            <div class="value">{{data.video_count}}</div>
                            <div class="label">Videos</div>
                        </div>
                    </div>
                    
                    <base-button
                        label="View profile"
                        v-if="data.username"
                        class="mt-4"
                        @action="viewProfile(data.username)"            
                    />

                    <div class="bio" v-if="data.bio_description">{{data.bio_description}}</div>

                </div>
            </template>

        </div>
    </base-modal>
</template>
<script>
import TiktokLogin from '../../components/TiktokLogin.vue';
const allScopes = ['user.info.basic', 'user.info.profile', 'user.info.stats'];
export default {
    props: ['visible', 'title', 'accessToken'],
    components: {
        TiktokLogin
    },
    data() {
        return {
            data: null,
            scopes: null,
            ready: false,
            loading: false
        }
    },
    watch: {
        visible(val){
            if(val && this.ready == false){
                this.getTokenInfo()
            }
        }
    },
    computed: {
        validScopes(){
            return this.scopes && Array.isArray(this.scopes) && this.scopes.length
        },
        missingScopes(){
            if(!this.validScopes) return [];
            let arr = [];
            for (const scope of allScopes) {
                if(!this.scopes.includes(scope)) arr.push(scope);
            }
            return arr;
        },
        scopesAreMissing(){
            if(!this.validScopes) return true;
            let err = false;
            for (const scope of allScopes) {
                if(!this.scopes.includes(scope)) err = true;
            }
            return err;
        }
    },
    methods: {
        async getTokenInfo(){
            this.loading = true
            this.$http.get('/api/tiktok/user-info').then(({data}) => {
                this.data = data
            }).catch((err) => {
                console.log('getTokenInfo error', err)
            }).finally(() => {
                this.loading = false
                this.getScopes()
            })
        },
        async getScopes(){
            this.loading = true
            this.$http.get('/api/tiktok/scopes').then(({data}) => {
                this.scopes = data
            }).catch((err) => {
                console.log('getScopes error', err)
            }).finally(() => {
                this.ready = true
                this.loading = false
            })
        },
        viewProfile(username){
            window.open(`https://www.tiktok.com/@${username}`, '_blank'); 
        }
    }
}
</script>
<style lang="scss" scoped>
    .scopes-section{
        @apply flex flex-col gap-y-4 mb-10;
        .scope-list{
            @apply flex flex-wrap gap-x-2 gap-y-2 mt-2;
            > .title{
                @apply font-bold;
            }
            > .item{
                @apply bg-purple-m-main text-white text-pxs rounded-full px-3 py-1;
            }
        }
    }
    .profile{
        @apply flex flex-col items-center mb-8;
        .display-name{
            @apply font-bold text-h4;
        }
        .username{
            @apply font-bold flex items-center gap-x-1;
            .verified{
                @apply rounded-full flex justify-center items-center h-3 w-3;
                background-color: #20D5EC;
            }
        }
        .stats{
            @apply flex gap-x-4 items-center mt-4; 
            .stat{
                @apply flex flex-col justify-center gap-y-0.5;
                .value{
                    @apply font-bold text-center text-h6;
                }
                .label{
                    @apply text-pxs;
                }
            }
        }
        .bio{
            @apply mt-4 text-pxs;
        }
    }
</style>
