<template>
<main class="main" tabindex="0">
<div class="container wide">

    <div class="header">
        <div class="wrapper">
            <div class="icon-container">
                <base-icon class="text-green-m-main" name="user-group" :size="5" />
            </div>
            <div class="title">
                Import Creators
            </div>
        </div>
    </div>

    <div class="content">

        <div class="instructions">
            <p>Import your file in <span class="font-bold">XLS, XLSX or CSV</span>.</p>
            <p>Your file must have at least the following columns: <span class="font-bold">First Name, Last Name, Email</span>.</p>
            <template-download />
        </div>

        <form id="file-form">
            <input class="invisible" type="file" ref="file" accept=".xlsx, .xls, .csv, .json" @change="onFileChange">
        </form>

        <base-button 
            v-if="!fileUploaded" 
            @action="$refs.file.click()" 
            class="font-bold" 
            type="secondary" 
            theme="dark" 
            size="lg" 
            label="Upload File" 
            secondary-icon="submit" 
            :iconSize="5"
        />
        <div v-else class="uploaded-file">
            <div class="name">
                <div class="icon-container">
                    <base-icon class="text-purple-m-main" name="file" :size="5" />
                </div>
                <p class="font-bold">{{ file.name }}</p>
            </div>
            <base-button @action="cancelImport" icon="x" :icon-size="4" type="label" />
        </div>

        <div v-if="fileUploaded" class="overview">

            <div class="item">
                <div class="title">Values</div>
                <base-table
                    :columns="customColumnsValue"
                    :data="values"
                    :loading="loading"
                    :search="false"
                    class="overflow-x-scroll"
                />
            </div>

            <i class="text-center text-gray-600">The above is a sample. There are an additional <b>{{totalCount - values.length}}</b> rows of data that have been uploaded...</i>

            <div class="item" v-if="replaces.length">
                <div class="title">Columns</div>
                <base-table
                    :columns="[
                        { 
                            name: 'target_column', 
                            label: 'Target Column', 
                            value: (element) => {
                                return `${element.target_column.label}${element.target_column.required ? ` *` : ``}`
                            }
                        },
                        { 
                            name: 'replace', 
                            label: 'File Column', 
                            value: (element, column) => {
                                return matchColumns(element, column)
                            },
                            type: 'single-select', 
                            options: (element, column) => filteredAvailables(element, column),
                            placeholder: 'Select the file column',
                            width: '250px'
                        },
                        { 
                          name: 'sample', 
                          label: 'Example Data',
                          type: 'object',
                          value: (element) => {
                            return element.sample;
                          }
                        }
                    ]"
                    :data="replaces"
                    :loading="loading"
                    :search="false"
                    :invalidRows="invalidRows"
                    :loadingRows="loadingRows"
                    @change-single-select="changeFileColumn"
                />
            </div>

            <div class="item gap">
                <div class="title">Tags</div>
                <p class="subtitle">You can add tags to all the creators added in this import.</p>
                <custom-select
                    class="w-full"
                    label="Tags"
                    placeholder="Select the creators tag(s)"
                    :options="tags"
                    allow-create
                    create-label="Create new tag"
                    model-name="Tag"
                    :create-route="`/api/tags`"
                    :multiple="true"
                    size="auto"
                    v-model="selectedTags"
                    @finish-create="getTags()"
                />
            </div>

            <div class="item gap">
                <div class="title">
                    Duplicated Creators
                </div>
                <p class="subtitle">If there are some creators in your file that already exists in Creatorsaurus, what would you like to do?</p>
                <FormulateInput
                    name="type"
                    :options="duplicateOptions"
                    help-position="after"
                    type="radio"  
                    v-model="duplicateSelection"                  
                />
            </div>

            <div class="item gap">
                <div class="title">
                    Social connection invitations
                </div>
                <p class="subtitle">¿ Send email invitations to all creators to authenticate with social platforms so Creatorsaurus can automatically retrieve their posts and profile data?</p>
                <FormulateInput
                    type="radio"
                    v-model="socialConnectionInvitations"
                    :options="socialConnectionInvitationsOptions"
                />
            </div>

        </div>
    </div>   

    <div class="footer">
        <base-button 
            @action="cancelImport" 
            type="secondary" 
            class="bg-tan-m font-bold" 
            :label="fileUploaded ? 'Discard' : 'Cancel'"
        />
        <base-button 
            @action="submit" 
            :disabled="!fileUploaded || submitting || !values.length || pendingColumns || invalidRows.size > 0" 
            class="font-bold" 
            label="Import" 
        />
    </div>

    <loader 
        :visible="submitting" 
        label="Importing creators"
    />

    <select-excel-sheet-modal
        :visible="selectExcelSheetModalVisible"
        :file="file"
        @close="closeSheetModal"
        @done="(selectedSheets, path) => uploadFile(selectedSheets, path)"
    />

</div>
</main>
</template>
<script>
import Loader from '../../components/Loader';
import TemplateDownload from './TemplateDownload.vue';
import SelectExcelSheetModal from './SelectExcelSheetModal.vue';
import { jaroWinkler, formatNumberString } from '../../lib/strings';
import { mapGetters } from 'vuex';
const duplicateOptions = [
    { value: 'ignore', label: 'Ignore' },
    { value: 'update', label: 'Update' }
];
const socialConnectionInvitationsOptions = [
    { value: 'false', label: "Don't send invitations" },
    { value: 'true', label: 'Send invitations' }
];
export default {
    components: {
        Loader,
        TemplateDownload,
        SelectExcelSheetModal
    },
    data() {
        return {
            fileName: "",
            totalCount: 0,
            the_scores: {},
            invalidRows: new Set(),
            loadingRows: [],

            loading: false,
            fileUploaded: false,
            duplicateSelection: 'ignore',
            socialConnectionInvitations: 'false',
            file: null,
            selectExcelSheetModalVisible: false,
            users: [],
            tags: [],
            selectedTags: [],
        
            columns: [],
            availables: [],
            replaces: [],
            submitting: false,

            // static
            duplicateOptions,
            socialConnectionInvitationsOptions
        }
    },
    beforeMount() {
        this.getTags();
        this.getColumns();
    },
    computed: {
        ...mapGetters(['me']),
        ignoredColumns() {
            let ignoredColumns = []
            for (const column of this.replaces) {
                const found = column.replace.find(element => {
                    return !element.value && element.label == "Ignore column"
                })
                if(found){
                    ignoredColumns.push(column.target_column)
                }
            }
            return ignoredColumns
        },
        customColumnsValue(){
            return this.columns.filter(obj => !(obj && Object.keys(obj).length === 0 && obj.constructor === Object)).filter(column => {
                const found = this.ignoredColumns.find(ignoredColumn => {
                    return ignoredColumn.name == column.name
                }) 
                return !found
            })
        },
        pendingColumns() {
            let required = this.columns.filter(col => col.required).map(col => col.name);
            if (this.values.length) {
                let item = this.values[0];
                let errors = 0;
                for (let column of required) {
                    // return column;
                    if (item[column] === undefined) {
                        errors++;
                    }
                }

                return errors > 0;
            }
            return true;
        },
        values() {
            let users = JSON.parse(JSON.stringify(this.users));
            return users.map(u => {
                for (let column of this.columns) {

                    let search = column.name;

                    if (u[search] === undefined) {
                        let replace = this.replaces.find(r => r.target_column.name === search);
                        if (replace && replace.replace.length) {
                            u[search] = u[replace.replace[0].value];
                        }
                    }

                    if (search === 'tags') {
                        let tags = [];
                        if (u.tags !== undefined && Array.isArray(u.tags)) {
                            tags = u.tags.map(tag => {
                                return {
                                    tag: { name: tag }
                                }
                            })
                        }
                        u.tags = tags
                    }
                }

                return u;
            })
        },
        fileExtension(){
            const file = this.file
            const split = file.name.split('.')
            const last = split[split.length-1]
            return `.${last}` 
        }
    },

    watch: {
        
        created() {
            this.initializeReplaceUpdates();
        },

        replaceUpdates: {
            handler(newVal) {
                for (const [index, updatedReplace] of newVal.entries()) {
                    if (updatedReplace) {
                        this.$set(this.replaces[index], 'replace', [updatedReplace]);
                    }
                }
            },
            deep: true,
        },
    },

    methods: {

        closeSheetModal(cancel = false) {
            this.selectExcelSheetModalVisible = false;
            
            if ( cancel )
                this.cancelImport(true);
        },

        initializeReplaceUpdates() {
            this.replaceUpdates = new Array(this.replaces.length).fill(null);
        },

        matchColumns(element, column) {
            // Get an array of available options filtered based on `element` and `column`
            const filteredOptions = this.filteredAvailables(element, column);

            // Select the first option from the filtered options array as the default result
            const defaultResult = filteredOptions[0];

            let necessaryScore = 0.8;

            // Find the option with the highest similarity score to the `target_column.label` of `element`
            let topResult = defaultResult;
            let maxScore = 0;
            for (const filterable of filteredOptions) {
                const score = jaroWinkler(element.target_column.label.toLowerCase(), filterable.label.toLowerCase());
                if (score > maxScore) {
                    maxScore = score;
                    topResult = filterable;
                }
            }

            // If the highest similarity score is greater than or equal to 0.8, consider the result as a match
            if ((maxScore >= necessaryScore)) {
                // Create a new object by copying the top result and adding the score to it
                const currentReplace = { ...topResult, score: maxScore };

                // Add the score to the_scores object
                const value = currentReplace.value;
                const score = currentReplace.score;
                this.the_scores[value] = this.the_scores[value] || [];
                this.the_scores[value].push(score);

                // Check if the score is the highest for the given value and return the result accordingly
                const maxScoreForValue = Math.max(...this.the_scores[value]);
                if (maxScoreForValue && maxScoreForValue > 0 && currentReplace.score < maxScoreForValue) {
                    return defaultResult;
                } else {
                    return currentReplace;
                }
            } else {
                // If there is no match, return the default result
                return defaultResult;
            }
        },

        async getColumns() {
            const { data } = await this.$http.get(`/api/upload-file/get-all-import-columns`);
            this.columns = data.filter( column => column.target !== 'raw_pricing_data');
        },

        async getTags(){
            const { data } = await this.$http.get(`/api/tags?type=users`);
            this.tags = data.map(tag => {
                return {
                    value: tag.id,
                    label: tag.name
                }
            });
        },
        onFileChange(e) {
            this.file = e.target.files[0]
            if(['.xls', '.xlsx'].includes(this.fileExtension) ){
                this.selectExcelSheetModalVisible = true
            }else{
                this.uploadFile()
            }
        },
        uploadFile(selectedSheets = null, path = null){
                
            this.totalCount = 0;

            let fileData = new FormData()
            fileData.append('file', this.file);

            let sheets = (selectedSheets !== null) ? selectedSheets[0] : null;
          
            this.fileUploaded = true;
            this.loading = true;
            this.availables = [];
            this.availables.push({value: null, label: 'Ignore column', disabled: false});
            this.replaces = [];
            this.$http.post(`/api/upload-file/import/file?path=${path}&sheets=${sheets}`, fileData).then(async ({data}) => {
                
                if (data.success) {

                    this.users = data.sampleRows;

                    if (data.sampleRows.length) {

                        // we'll take the columns of the first item for reference
                        let item = data.sampleRows[0];

                        // let's set the count of how many rows of data exist in the document
                        this.totalCount = data.count;
                        // console.log('data', data);
                        this.fileName = data.path;

                        // add all columns to availables so we can use them at the time of replacing
                        for (let prop in item) {
                            this.availables.push({value: prop, label: prop});
                        }

                        // loop through required columns to see if we have all the needed ones
                        this.columns.forEach(column => {

                            // if email column is not found, we use automatic email generation
                            if ( item[column.name] === undefined && column.name == 'email' ) {
                                this.$notify({ title: 'Missing emails!', text: 'Please make sure you have an email address for each row of data.', type: 'warn' });
                            }

                            this.replaces.push({ target_column: column, replace: [], sample: '' });

                        })

                        // if there is an "email" column but some users dont have the email, 
                        // we have option to ignore them or fill them.
                        if(item["email"]){
                            let err = 0
                            for (const row of data.sampleRows) {
                                if(!row.email || row.email.trim() == ""){
                                    err++
                                }    
                            }
                            if(err){
                                this.showMissingEmailOptions();
                            }
                        }
                    }
                } else {

                    const errorMessage = ( data.message ) ? data.message : 'Try again later';
                    this.users = [];
                    const screenWidth = window.innerWidth;
                    const textLength = errorMessage.length;
                    const idealWidth = Math.min(screenWidth * 0.8, textLength * 10 + 50);

                    this.$notify({
                        title: 'Something went wrong while uploading the file.',
                        text: errorMessage,
                        type: 'error',
                        style: {
                            maxWidth: idealWidth + 'px !important',
                            overflow: 'scroll !important',
                            wordWrap: 'break-word !important',
                            whiteSpace: 'normal !important',
                        },
                        duration: 50000
                    });

                    this.loading = false;
                    this.cancelImport();
                }
            }).catch((err) => {
                console.log('upload file error', err.response)
                const text = err.response.data?.message ? err.response.data.message : 'Try again later'
                this.$notify({ title: 'Something went wrong while uploading the file.', text: text, type: 'error', duration: 500000, style: { maxWidth: '100%', overflow: 'auto' } });
            }).finally(() => {
                this.loading = false;
            });
        },
        async submit() {

            if ( this.invalidRows.size > 0 ) {

                for ( const [/*key,*/row] of this.invalidRows.entries() ) {
                    this.$notify({ title: 'Fix row matches.', text: `The type of data in the ${row} row is invalid`, type: 'warn' });
                }

            } else {
                this.submitting = true;
                // not using this right now
                // let users = JSON.parse(JSON.stringify(this.values));
                const social_connection_invitations = this.socialConnectionInvitations == 'false' ? false : this.socialConnectionInvitations == 'true' ? true : false
                let data = {
                    column_matches: this.replaces,
                    file_name: this.fileName,
                    duplicates: this.duplicateSelection,
                    social_connection_invitations: {
                        enabled: social_connection_invitations, 
                        user_id: this.me.id, 
                        url: `${window.location.origin}/social-login`
                    },
                    tags: this.selectedTags.map(st => {return st.value || st.id})
                };

                this.$http.post(`/api/upload-file/import/data`, data).then(async ({data}) => {

                    if ( data.success ) {
                        this.$notify({ title: 'Success.', text: 'The influencers have been created successfully,', type: 'success' });
                        this.$router.push('/creators');
                        this.fileName = "";
                    } else {
                        this.$notify({ title: 'Something went wrong.', text: data.message, type: 'warn', duration: 20000 });
                    }
                }).catch((err) => {
                    console.log('upload file error', err.response);
                    this.$notify({ title: 'Something went wrong while creating the influencers.', text: 'Try again later', type: 'warn', duration: 20000 });
                }).finally(() => {
                    this.submitting = false;
                });
            }
        },
        showMissingEmailOptions(){
            this.$swal.fire({
                title: `Some of the imported users dont have an email`,
                text: "Do you want to remove the users without emails or automatically generate emails for all creators, using with their social handles?",
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                cancelButtonText: 'Remove users',
                confirmButtonText: 'Generate emails',
                reverseButtons: true,
            }).then((result) => {
                if(result.isConfirmed){
                    try{
                        this.users = this.users.map(user => {
                            if(!user.email || user.email.trim() == ""){
                                user.email = `ui-generated-${user.handle}@creatorsaurus.com`
                            }
                            return user
                        })
                        this.$notify({ title: 'Emails generated successfully', text: '', type: 'success' });
                    }catch(err){
                        this.$notify({ title: 'Something went wrong while generating the emails', text: 'Make sure that the "handle" field exists', type: 'warn' });
                    }
                }else{
                    this.users = this.users.filter(user => {
                        return user.email && user.email.trim() != ""
                    })
                    this.$notify({ title: 'Users removed from the list', text: '', type: 'success' });
                }
            })
        },
        showAutomaticEmailGeneration(){
            this.$swal.fire({
                title: `The imported data doesn't have an 'email' field`,
                text: "Do you want to automatically generate emails for all creators, using with their social handles?",
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Generate emails',
                reverseButtons: true,
            }).then((result) => {
                if(result.isConfirmed){
                    try{
                        this.users = this.users.map(user => {
                            user.email = `ui-generated-${user.handle}@creatorsaurus.com`
                            return user
                        })
                        this.replaces = this.replaces.filter(element => {
                            return element.target_column.name != 'email'
                        })
                    }catch(err){
                        this.$notify({ title: 'Something went wrong while generating the emails', text: 'Make sure that the "handle" field exists', type: 'warn' });
                    }
                }
            })
        },
        filteredAvailables(/*element, column*/){
            return this.availables;

        },
        async changeFileColumn(element, column, val) {
            // This is where we do validation for uploading into columns
            let isValid = val.value == null && element.target_column.target === "email" ? false : true;

            if (val) {
                let item = this.replaces.find(r => r.target_column.name === element.target_column.name);

                if (item) {
                    let samples = this.users.map(u => u[val.value]).filter(u => !!u);

                    const pricing_regex = /(?:\b|_)(?:price|pricing)(?:\b|_)/i;
                    const isPricing = pricing_regex.test(element.target_column.target);

                    if (element.target_column.target === "pricing_data") {

                        samples = samples.map(str => formatNumberString(str, isPricing));

                        if (!this.loadingRows.includes(element.target_column.name)) {
                          this.loadingRows.push(element.target_column.name);
                        }

                        const regexNumbers = /\d/; // Regular expression to match digits
                        const regexLetters = /[a-zA-Z]/; // Regular expression to match letters

                        if (samples.some(string => regexNumbers.test(string) && regexLetters.test(string))) {
                          
                          // Code to execute if a string contains both numbers and letters

                            if ( samples.length > 0 ) {

                                try {

                                    const platform = element.target_column.name.split(' - ')[0];

                                    let {data} = await this.$http.post(`/api/ai-completion/structure-pricing-data`, {samples, platform}, {
                                        timeout: 10000 // Set a timeout of 10 seconds
                                    });

                                    var new_arr = [];
                                    for ( const sample_pricing of data ) {
                                        if ( sample_pricing )
                                            new_arr.push(sample_pricing);
                                    }

                                    item.sample = new_arr;
                                    
                                    this.invalidRows.delete(element.target_column.name);

                                    
                                    this.loadingRows = this.loadingRows.filter(item => item !== element.target_column.name);

                                    console.log('done loading', this.loadingRows);

                                } catch (error) {
                                    this.$notify({ title: 'Something went wrong', text: error, type: 'warn', style: { maxWidth: '420px', overflow: 'scroll' } });
                                    console.log('error', error);
                                }

                            }
                            item.replace = [val];
                            return;
                        }
                    }

                    const splitter_str = '  –---  ';

                    item.sample = samples.join(splitter_str);
                    item.replace = [val];

                    // Check for invalid rows
                    if (element.target_column.type === "INT") {
                        
                        let sample = element.sample;

                        if ( sample.indexOf(splitter_str) > -1 ) {

                            for ( let splitted_sample of sample.split(splitter_str) ) {

                                console.log('getting here ---> ', splitted_sample);

                                // Remove number signs, spaces, and commas from the string
                                let cleanedSample = splitted_sample.replace(/[$,#,\s]/g, '');

                                console.log('and here ---> ', splitted_sample);

                                // Check if the cleaned sample is a valid number
                                isValid = cleanedSample !== "" && /^(\d+(\.\d+)?)([KkMm])?$/.test(cleanedSample);

                                console.log('is valid: ', isValid);

                                if (!isValid) break;

                            }

                        } else {
                            // Remove number signs, spaces, and commas from the string
                            const cleanedSample = sample.replace(/[#,\s]/g, '');

                            // Check if the cleaned sample is a valid number
                            isValid = cleanedSample === "" || /^\d+$/.test(cleanedSample) && Number.isInteger(parseInt(cleanedSample));
                        }

                    }

                    if (element.target_column.target === "email") {
                        for (const the_sample of samples) {
                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            isValid = emailRegex.test(the_sample.trim());
                            if (!isValid) break;
                        }
                    }

                }
            }

            if (!isValid && element.sample !== undefined || !isValid && element.target_column.target == 'email') {
                this.invalidRows.add(element.target_column.name);
            } else {
                this.invalidRows.delete(element.target_column.name);
            }

            this.loadingRows = this.loadingRows.filter(item => item !== element.target_column.name);

        },
        cancelImport(force = false){
            if (this.fileUploaded || force) {
                this.fileUploaded = false;
                this.users = [];
                this.replaces = [];

                let form = document.getElementById('file-form');
                if (form) {
                    form.reset();
                }
            } else {
                this.$router.push('/creators');
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .main{
        @apply flex-1 relative z-0 overflow-y-auto;
        &:focus{
            @apply outline-none;
        }
        .container{

            &.wide {
                max-width: 100% !important;
            }

            @apply relative;
            .header{
                @apply flex justify-between items-center py-14 px-12;
                .wrapper{
                    @apply flex gap-4 items-start items-center;
                    .icon-container{
                        @apply inline-flex items-center justify-center rounded-full bg-purple-m-main w-15 h-15;
                    }
                    .title{
                        @apply text-h2 font-bold text-purple-m-secondary;
                    }
                }
            }
            .content{
                @apply flex flex-col gap-8 px-12 h-full mb-16;
                .instructions{
                    @apply w-full flex flex-col gap-5 text-purple-m-secondary text-h5;
                }
                .uploaded-file{
                    @apply px-4 py-6 w-96 flex items-center justify-between bg-gray-m-light rounded-md;
                    .name{
                        @apply flex gap-2 items-center;
                        .icon-container{
                            @apply flex items-center justify-center flex-grow-0 flex-shrink-0 rounded-full bg-white w-10 h-10 border border-gray-m-light;
                        }
                    }
                }
                .overview{
                    @apply py-6 flex flex-col gap-16;
                    .item{
                        &.gap{
                            @apply flex flex-col gap-2;
                        }
                        .title{
                            @apply text-h2 font-bold text-purple-m-secondary;
                        }
                        .subtitle{
                            @apply text-h5 font-bold text-purple-m-secondary;
                        }
                    }
                }
            }
            .footer{
                @apply sticky bottom-0 bg-white px-12 py-4 flex gap-4 justify-end;
            }
        }
    }
</style>