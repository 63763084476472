<template>
    <!-- TODO: fix search -->
    <!-- TODO: the collapsed state of projects looks bad -->
    <main class="deliverable-list-wrapper" tabindex="0">

        <!-- header -->
        <div class="header">
            <div class="filters">
                <div class="searchbar-wrapper">
                    <div class="icon">
                        <base-icon 
                            name="search" 
                            class="text-purple-m-secondary" 
                        />
                    </div>                    
                    <FormulateInput
                        type="text"                        
                        v-model="filters[0].selected"
                        placeholder="Search"
                        outer-class="w-full"
                        element-class="w-full"
                        wrapper-class="border rounded-md py-2 px-3 pl-16 bg-white h-16 flex items-center"
                    />
                </div>
                <!-- ... more filters -->
            </div>
        </div> 
        
        <!-- content -->
        <div class="content space-y-10">
            <!-- loader -->
            <table-loader 
                v-if="loading"
                table-class="master-table w-full"
            />
            <template v-else>
                <!-- empty state -->
                <div class="empty-state" v-if="!isValidArray(projects)">
                    No projects yet
                    <!-- create a project -->
                    <base-button
                        label="Create project"
                        bold
                        size="lg"
                        @action="$router.push('/projects/create')"
                    />
                </div>
                <template v-else>
                    <template v-for="(project, i) in projects">
                        <!-- If we are not able to get the "projectTableRows" of a project it just wont show up -->
                        <template v-if="isValidArray(projectTableRows(project))">
                            <project-deliverables
                                :isStandAlone="false"
                                :key="i"
                                :project="project"
                                :rowData="projectTableRows(project)"
                                :loading="loading"
                                :passedColumnDataTypes="columnDataTypes"
                                :passedCreatorColumns="creatorColumns"
                                :passedSelectedCreatorColumns="selectedCreatorColumns[project.id]"
                                :passedTags="tags"
                                hide-loader
                            />
                        </template>
                    </template>
                </template>
            </template>
        </div>
        
        <!-- load more button -->
        <div class="load-more">
            <base-button
                v-if="loadMoreButtonVisible"
                bold
                label="Load more..."
                @action="onLoadMore()"
            />
        </div>

    </main>
</template>

<script>
import ProjectDeliverables from '../../components/ProjectDeliverables.vue';
import TableLoader from '../../components/TableLoader.vue';
import { isValidArray } from '../../common';
import { findBestMatch } from '../../lib/strings';
import { mapGetters } from 'vuex';
export default {
    components: {
        ProjectDeliverables,
        TableLoader
    },
    data() {
        return {
            loading: false,  
            projects: [],
            page: 1,
            perPage: 6,
            loadMoreButtonVisible: true,
            filters: [
                {
                    name: 'search',
                    type: 'string',
                    selected: ''
                },
            ],
            columnDataTypes: [],
            creatorColumns: [],
            selectedCreatorColumns: {},
            tags: [],

            isValidArray
        }
    },
    mounted(){
        this.getData();
        this.getColumnTypes();
        this.getTags();
    },
    destroyed() {
    },
    computed: {
        ...mapGetters(['me', 'isAdminOrHigher', 'isUserAdminOrHigher', 'isGuest', 'isClient', 'fbLogin']),
    },
    methods: {

        async getSelectedCreatorColumns(projects) {
            if(!this.isGuest && !this.isClient && this.isUserAdminOrHigher){
                const ids = projects.map( project => project.id);
                const { data } = await this.$http.post(`/api/projects/creator-columns/`, {ids});
                for ( const column of data ) {
                    if ( typeof this.selectedCreatorColumns[column.project_id] == "undefined" ) {
                        this.$set(this.selectedCreatorColumns, [column.project_id], []);
                    }
                    this.selectedCreatorColumns[column.project_id].push(column);
                }
            }
        },

        async getCreatorColumns() {
            if(!this.isGuest && !this.isClient && this.isUserAdminOrHigher){
                const { data } = await this.$http.get(`/api/companies/creator-columns/`);
                for ( const column of data ) {
                    this.creatorColumns.push(column);
                }
            }
        },

        async getTags(){
            const { data } = await this.$http.get(`/api/tags`)
            this.tags = data
        },

        async getColumnTypes() {
            const columnTypes = await this.$http.get('/api/userDeliverables/columnTypes');
            this.columnDataTypes = columnTypes.data.map(type => {
                type.value = type.id
                return type;        
            });
        },

        onLoadMore(){
            this.page++;
            this.getData(false);
        },

        async getData(showLoader = true) {

            // not using these right now
            // let validProjects = [];
            // let valid = false; 

            try {

                this.loading = showLoader;

                const { data } = await this.$http.get('/api/projects/new-deliverables', {
                    params: {
                        page: this.page,
                        perPage: this.perPage
                    }
                });

                this.getCreatorColumns(data);
                this.getSelectedCreatorColumns(data);
                
                if(data.length < this.perPage){
                    this.loadMoreButtonVisible = false;
                }
                for (const project of data) {
                    const found = this.projects.find(element => element.id == project.id);
                    if(found){
                        const index = this.projects.findIndex(element => element.id == found.id);
                        if(index){
                            this.projects[index] = project;
                        }
                    }else{
                        this.projects.push(project);
                    }
                }

            } catch (error) {
                if ( error.response.status >= 400 ) {
                    
                    // Handle the 400+ error
                    this.$notify({ title: 'Error', text: error.response.data.message, type: 'error' });
                    this.$router.push("/home");

                } else {
                    // Handle other errors
                    console.error(error);
                }
            } finally {
                this.loading = false;
            }

        },

        // Utility function to get a nested field by its path (e.g. "user.first_name")
        getFieldByPath(object, path) {
            const parts = path.split('.');
            let value = object;

            for (let part of parts) {
                if (value === null || value === undefined) {
                    return null;
                }
                value = value[part];
            }

            return value;
        },

        filterRows(rows, query) {
            if (!query) {
                return rows;
            }

            const trimmedQuery = query.trim();

            if (trimmedQuery === '') {
                return rows;
            }

            return rows.filter((row) => {
                const searchFields = [
                    'user.first_name',
                    'user.last_name',
                    'column_values',
                    'project_name',
                    'brand'
                    ];

                return searchFields.some((field) => {
                    const fieldValue = this.getFieldByPath(row, field);

                    if (Array.isArray(fieldValue)) {
                        return fieldValue.some((nestedData) => {
                            if (!nestedData.data) {
                                return false;
                            }

                            const lowerCaseData = nestedData.data.toLowerCase();
                            const lowerCaseQuery = trimmedQuery.toLowerCase();

                            return (
                                lowerCaseData.includes(lowerCaseQuery) ||
                                findBestMatch(lowerCaseData, lowerCaseQuery) > 0.8
                                );
                        });
                    } else {
                        if (!fieldValue) {
                            return false;
                        }

                        const lowerCaseValue = fieldValue.toLowerCase();
                        const lowerCaseQuery = trimmedQuery.toLowerCase();

                        return (
                            lowerCaseValue.includes(lowerCaseQuery) ||
                            findBestMatch(lowerCaseValue, lowerCaseQuery) > 0.8
                            );
                    }
                });
            });
        },

        projectTableRows(project) {
            let query = this.filters[0].selected.trim();
            // not using this var right now
            // let rows = project.rows;

            if (query !== '') {
                // rows = this.filterRows(rows, query);
            } else {
                return project?.rows || [];
            }

            return project?.rows || [];
        }

    }
}
</script>
<style lang="scss" scoped>
    .deliverable-list-wrapper{
        @apply flex-1 relative z-0 overflow-y-auto;
        &:focus{
            @apply outline-none;
        }
        > .header{
            @apply flex justify-between px-11 pt-10 pb-4;
            > .filters{
                @apply flex-shrink w-full;
                > .searchbar-wrapper{
                    @apply relative flex w-full;
                    > .icon{
                        @apply absolute w-16 h-full flex items-center justify-center pointer-events-none;
                    }
                }
            }
        }
        > .content{
            @apply px-11 pb-10 overflow-x-auto;
            > .empty-state{
                @apply w-full p-12 flex flex-col items-center justify-center gap-y-2;
            }
        }
        > .load-more{
            @apply w-full flex justify-center mt-6 mb-20;
        }
    }
</style>