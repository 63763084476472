<template>
    <div class="file-grid-wrapper">

        <Header
            :title="title"
            :mimetype="mimetype"
            @refresh="getData()"
            @changeSearchTerm="val => searchTerm = val"
            @changeSelectedReportTypeFilter="val => selectedReportTypeFilter = val"
        />
        
        <div class="navigation">

            <div class="actions">
                <base-button 
                    v-if="navigation.length > 0" 
                    type="label"
                    @action="onGoBack"
                    icon="arrow-narrow-left"
                />
            </div>

            <div class="breadcrumbs" v-if="navigation.length"> 
                <span
                    class="breadcrumb"
                    :class="{ 'drag-started': dragStarted }"
                    @click="onBreadcrumbClick('root')"
                    @drop="onBreadcrumbDrop($event, 'root', title)"
                    @dragover.prevent="()=>null"
                    @dragleave="()=>null"
                >
                    {{title}}
                </span>
                <span
                    v-for="(navigationItem, index) in navigation"
                    :key="index"
                    @click="onBreadcrumbClick(navigationItem)"
                    @drop="onBreadcrumbDrop($event, navigationItem.id, navigationItem.name)"
                    @dragover.prevent="()=>null"
                    @dragleave="()=>null"
                >
                    /<span class="breadcrumb" :class="{ 'drag-started': dragStarted && !isLastBreadCrumb(navigationItem) }">{{navigationItem.name}}</span>
                </span>
            </div>

        </div>

        <div class="file-grid-container">

            <!-- drag files to upload element (documents only, no reports) -->
            <div 
                v-if="mimetype === null && !disabled"
                class="file-upload-dropzone" 
                :class="{'dragged-over': draggedOverDropzone}" 
                @dragover.prevent="draggedOverDropzone = true" 
                @dragleave="draggedOverDropzone = false"
                @drop.stop.prevent="dropHandler"
            >
                <div class="content" @click="openFileExplorer()">
                    <div class="icon">
                        <base-icon class="text-purple-m-main" name="plus" :size="5" />
                    </div>
                    <div class="text">
                        {{ draggedOverDropzone ? 'Drop' : 'Drag' }} files {{ draggedOverDropzone ? 'here ' : '' }}to upload
                    </div>
                    <div class="description">or click here</div>
                </div>
                <div class="hidden-input">
                    <FormulateInput type="file" name="file" :uploader="uploadHandler" multiple ref="uploaderElement"/>
                </div>
            </div>
            
            <!-- "Add google drive folder" element, only on Projects 'Documents' tab -->
            <div
                class="add-g-drive-folder"
                v-if="mimetype == null && excludeFileType == 'reports' && !navigation.length && mimetype === null && !disabled"
                @click="openAddCreateDriveFolderModalIntent()"
            >
                <div class="content">
                    <div class="icon">
                        <base-icon class="text-purple-m-main" name="drive" :size="5" />
                    </div>
                    <div class="text">
                        Add Google Drive Folder
                    </div>
                </div>
            </div>

            <!-- "Add google drive file" element, only on Projects 'Documents' tab -->
            <div
                class="add-g-drive-folder"
                v-if="mimetype == null && excludeFileType == 'reports' && !navigation.length && mimetype === null && !disabled"
                @click="openAddDriveFileModalIntent()"
            >
                <div class="content">
                    <div class="icon">
                        <base-icon class="text-purple-m-main" name="drive" :size="5" />
                    </div>
                    <div class="text">
                        Add Google Drive <br> File
                    </div>
                </div>
            </div>
            
            <!-- loader -->
            <skeleton 
                v-for="i in (loaded ? 0 : (mimetype === 'reports' ? 12 : 10))" 
                type="square" 
                custom-class="w-56 h-56" 
                :key="`loader-1-${i}`" 
            />

            <!-- loader -->
            <template v-if="loading || processingFiles">
                <skeleton
                    v-for="i in 10"
                    type="square" 
                    custom-class="w-56 h-56" 
                    :key="`loader-2-${i}`"
                />
            </template>

            <!-- item -->
            <template v-if="!loading && !processingFiles">
                <item
                    v-for="(file, i) in filteredFiles" :key="i"
                    :file="file"
                    :is-project-owner="isProjectOwner"
                    :grid-style="'grid'"
                    :dragStarted="dragStarted"
                    :allow-to-edit="allowToEdit"
                    :disabled="disabled"
                    @share="showShareModal"
                    @share-drive-folder="showShareDriveModal"
                    @edit-file="showEditFile"
                    @edit-folder="showEditFolder"
                    @refresh-data="getData()"
                    @openFolder="onOpenFolder"
                    @dragStarted="onDragStarted"
                    @dragEnded="onDragEnded"
                    @delete-drive="deleteDrive"
                    @fileDroppedInFolder="onFileDroppedInFolder"
                />
            </template>

            <!-- empty state for reports -->
            <div class="empty-state" v-if="loaded && !filteredFiles.length && (mimetype === 'reports' || disabled)">
                There are no files to show
            </div>

            <!-- empty state for documents -->
            <div class="empty-state-documents" v-else-if="mimetype !== 'reports' && !filteredFiles.length">
                There are no files to show
            </div>

            <share-modal
                :visible="sharing.show"
                @close="sharing.show = false"
                @saved="shareModalSaved"
                :type="sharing.type"
                :typeId="sharing.typeId"
            />
            <edit-file-modal
                :visible="showEditFileModal"
                :file="editFile"
                @close="showEditFileModal = false; editFile = null"
                @file-edited="updateFiles"
            />
            <edit-folder-modal
                :visible="showEditFolderModal"
                :folder="editFolder"
                @close="showEditFolderModal = false; editFolder = null"
                @done="updateFiles"
            />
            
            <!-- TODO this modal should be its own component -->
            <!-- add/create drive folder modal -->
            <!-- also edit google drive folder name modal -->
            <base-modal
                :title="editingDriveFolderName ? 'Edit Google Drive Folder Name' : 'Add Drive Folder'"
                :visible="editingDriveFolderName || addCreateDriveFolderModalVisible"
                @close="closeAddCreateDriveFolderModal"
            >
                <!-- <base-icon class="text-purple-m-main" name="drive" :size="5" /> -->
                <div class="edit-g-drive-folder-name-modal-content">

                    <p class="text-h5 font-bold mb-5">Connect a Google Drive folder with a shareable url or create a new folder in your account by typing a folder name.</p>

                    <div class="text flex gap-x-2 items-center py-2"><base-icon name="info-circle"/>To get a shareable folder url, click on "Share" then "Copy link" on the google drive folder</div>

                    <div class="input-container">
                        <FormulateInput
                            type="text"
                            v-model="driveFolderUrlOrName"
                            @input="val => onDriveFolderUrlOrNameInput(val)"
                            placeholder="https://drive.google.com/drive/folders/1231a2b3c4d"
                            label="Shareable URL or new folder name"
                        />
                    </div>

                    <div class="footer">
                        <base-button label="Cancel" type="secondary" @action="closeAddCreateDriveFolderModal"/>
                        <base-button 
                            :label="editingDriveFolderName ? 'Change Name' : (validateUrl(driveFolderUrlOrName) || driveFolderUrlOrName == '' ? 'Add Folder' : 'Create Folder')"
                            :disabled="creatingDriveFolder || driveFolderUrlOrName === ''"
                            @action="postCreateDriveFolder"
                        />
                    </div>
                </div>
            </base-modal>

            <add-drive-file-modal
                :visible="addDriveFileModalVisible"
                :access-token="accessToken"
                :project-id="projectId"
                @refresh="getData()"
                @close="addDriveFileModalVisible = false"
            />
            
            <!-- TODO this modal should be its own component -->
            <!-- share google drive folder modal -->
            <base-modal
                title="Share Google Drive folder"
                :visible="shareDriveFolderModalVisible"
                @close="closeShareDriveFolderModal"
            >
                <div class="share-g-drive-folder-modal">
                    <div class="content">

                        <div class="label">Share this Google Drive folder with a partner by entering their email</div>

                        <FormulateInput
                            type="text"
                            v-model="sharedEmail"
                            placeholder="example@creatorsaurus.com"
                            label="Partner email"
                        />

                        <div class="users">
                            <div class="title">Users with permissions</div>
                            <div class="list" v-if="selectedFolder">
                                <div 
                                    class="item"
                                    v-for="userPermission in selectedFolder.permissions"
                                    :key="userPermission.id"
                                >

                                    <div class="user">
                                        <img class="profile-picture" :src="userPermission.photoLink" />
                                        <div class="names">
                                            <div class="name">{{userPermission.displayName}} ({{userPermission.role}})</div>
                                            <div class="email">{{userPermission.emailAddress}}</div>
                                        </div>
                                    </div>

                                    <div class="actions">
                                        <base-button 
                                            label="Revoke"
                                            class="h-12"
                                            v-if="userPermission.role !== 'owner'" 
                                            @action="revokePermission(userPermission)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="footer">
                        <base-button 
                            label="Cancel" 
                            type="secondary" 
                            @action="closeShareDriveFolderModal"
                        />
                        <base-button 
                            label="Share Folder"
                            @action="shareDriveFolder()" 
                            :disabled="shareDriveFolderLoading || sharedEmail === '' || sharedEmailInvalid"
                        />
                    </div>
                </div>
            </base-modal>
        </div>
    </div>
</template>

<script>
import Item from './Item';
import EditFolderModal from './EditFolderModal.vue';
import Header from './Header.vue';
import AddDriveFileModal from './AddDriveFileModal.vue';

import ShareModal from '../ShareModal';
import EditFileModal from '../EditFileModal.vue';

import { decryptStr, validateEmail, validateUrl } from '../../lib/strings';
import { getIdFromGoogleDriveUrl, isGoogleDriveUrl } from '../../lib/googleDrive';
import { fileUpload, getObjectValue, isArray, isString, notifyCatchError } from '../../common';
import { removeQueryParams } from '../../lib/url';
import { mapGetters } from "vuex";

export default {
    props: {
        title: {
            type: String,
            default: 'Documents'
        },
        projectId: {
            type: Number,
            default: null
        },
        userId: {
            type: Number,
            default: null,
        },
        isProjectOwner: {
            type: Boolean,
            default: false,
        },
        // creatorsaurus folders will have numbers, but drive folders have strings as identifiers
        folderId: {
            type: [Number, String]
        },
        // this will be inserted in the folders table when creating a new folder
        // { 
        //     type: 'project', // folderable_type
        //     id: project.id // folderable_id,
        // }
        resource: {
            type: Object,
            required: true
        },
        // Options: ['null' (all files/documents), 'reports']
        mimetype: {
            type: String,
            default: null
        },
        // exluded file type when getting attachments
        excludeFileType: {
            type: String,
            default: null
        },
        // opens the file explorer from a parent component
        forceOpenFileExplorer: {
            type: Boolean,
            default: false
        },
        forceFileGridRefresh: {
            type: Boolean,
            default: false
        },
        forceFileGridNavigationChange: {
            type: Array,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        allowToEdit: {
            type: Boolean,
            default: false
        },
        // force google drive logout or login
        forceDriveLoginState: {
            type: Boolean,
            default: false
        }
    },

    components: {
        Item,
        ShareModal,
        Header,
        EditFileModal,
        EditFolderModal,
        AddDriveFileModal
    },

    data() {
        return {
            files: [],
            uploadedFiles: [],
            draggedOverDropzone: false,
            addingFile: false,
            
            sharing: {
                show: false,
                type: '',
                typeId: null
            },
            external_link: '',
            external_link_name: '',

            searchTerm: '',
            selectedReportTypeFilter: { label: 'All', value: 'all' },

            showEditFileModal: false,
            editFile: null,
            filesKey: false,
            navigation: [],
            dragStarted: false,
            showEditFolderModal: false,
            editFolder: null,
            loading: false,
            processingFiles: false,
            loaded: false,
            exit: false,

            host: '',

            shareDriveFolderModalVisible: false,
            shareDriveFolderLoading: false,
            sharedEmail: '',
            selectedFolder: null,
            editingDriveFolderName: false,
            addCreateDriveFolderModalVisible: false,
            creatingDriveFolder: false,
            driveFolderUrlOrName: '',
            googleLoaded: false,
            googleSignedIn: false,
            gapiInited: false,
            gisInited: false,
            developerKey: '',
            clientId: '',
            tokenClient: null,
            accessToken: null,
            passphrase: 'google',
            discovery_docs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
            scopes: 'https://www.googleapis.com/auth/drive',

            addDriveFileModalVisible: false,

            validateUrl
        }
    },

    watch: {
        hasProject: function (val, old) {
            if (val && !old && !this.files.length) {
                this.getData();
            }
        },
        // if we want to open the file explorer from a parent component, we change this prop
        forceOpenFileExplorer: function (val, old) {
            if (val && !old) {
                this.openFileExplorer();
            }
        },
        // if the [Files, Reports] tab changes we reset navigation
        mimetype: function (val, old) {
            if (val != old) {
                this.navigation = []
                this.$emit('navigationChange', this.navigation)
                //this.getData();
            }
        },
        // if we want to refresh the file grid files from the parent component we change this prop
        forceFileGridRefresh: function (val, old) {
             if (val != old) {
                this.getData();
            }
        },
        // if a navigation change happens outside of this component, we update the navigation here too
        forceFileGridNavigationChange: function (val, old) {
             if (val != old) {
                this.navigation = val
                this.$emit('navigationChange', this.navigation)
                this.getData()
            }
        },
        exit: function (val) {
            if (val) {
                this.driveLogout();
            }
        },
        // the parent component can force the google drive logout or login
        forceDriveLoginState: function (val) {
            if (!val) {
                this.driveLogout();
            } else {
                this.checkLoginAndRun(this.getData);
            }
        }
    },

    beforeMount() {
        this.getGoogleCredentials();
    },

    mounted() {
        this.getData();
        this.checkLogin();
    },

    created () {    
        window.onbeforeunload = () => {
            return this.addingFile ? 'File upload in progress' : null
        };
    },

    methods: {

        onDriveFolderUrlOrNameInput(url){
            // if the url is valid and has "/u" on it (the "/u" lets us know that the user copied the url from the browser instead of getting the shareable link)
            if(validateUrl(url) && url.includes('drive.google.com') && url.includes('/u')){
                // remove any query parameters
                url = removeQueryParams(url);
                // get the folder id
                url = url.split('/');
                const id = url[url.length - 1];

                // if the folder id is valid
                if(id && isString(id) && id.trim() != ''){
                    this.driveFolderUrlOrName = `https://drive.google.com/drive/folders/${id}`;
                }
            }
        },

        updateSignInStatus(isSignedIn) {
            this.googleSignedIn = isSignedIn;
            this.$emit('drive-handle', isSignedIn);
            if (isSignedIn) {
                this.processFiles();
            }
        },

        // logout from drive, 
        async driveLogout() {
            const token = await this.vuexOrClient();
            if (token !== null) {
                // check that the objects and functions exist
                if(getObjectValue(window, 'window.google.accounts.oauth2.revoke')){
                    window.google.accounts.oauth2.revoke(token.access_token);
                }
                if(getObjectValue(window, 'window.gapi.client.setToken')){
                    window.gapi.client.setToken('');
                }
                this.accessToken = null;
                await this.$store.dispatch('auth/logoutDrive');
            }
            this.updateSignInStatus(false);
            setTimeout(() => {
                this.getData();
            }, 1500);
        },

        // check if the google drive auth object's exists and we are not past the expiration date
        // if its valid, returns the accessToken, else logs out from drive or returns null 
        async vuexOrClient() {

            let item = this.driveAuth;
            if (item && item.expiration) {

                let now = new Date();
                let expiration = new Date(item.expiration);

                // Not expired, set accessToken var and return it
                if (expiration > now) {
                    let { access_token, refresh_token, token_type, expires_in, scope } = item
                    let accessToken = { access_token, refresh_token, token_type, expires_in, scope };
                    this.accessToken = accessToken;
                    return accessToken;
                }else{
                    // Expired
                    const { refresh_token } = item;
                    if(refresh_token){
                        // Get a new access_token with the refresh_token
                        try{
                            const { data } = await this.$http.post('/api/drive/refresh/token', { refresh_token });
                            if(data){
                                if(data.access_token && data.expires_in){
                                    console.log('Google drive access token refreshed successfully');
                                    let { refresh_token, token_type, scope } = item;
                                    // add new values to the access token
                                    let accessToken = { 
                                        access_token: data.access_token, 
                                        refresh_token, 
                                        token_type, 
                                        expires_in: data.expires_in,
                                        scope 
                                    };
                                    // set expiration property and save on localstorage and vuex
                                    await this.vuexDrive(accessToken);
                                    // set accessToken var
                                    this.accessToken = accessToken;
                                    // return it
                                    return accessToken;
                                }
                            }
                        }catch(err){
                            console.log('refresh access token error', err)
                            return null;
                        }
                    }
                }
            } else {
                await this.$store.dispatch('auth/logoutDrive');
            }
            return null;
        },

        // Given an accessToken object, sets its expiration date and saves it on local storage and vuex store
        async vuexDrive(accessToken) {
            if (accessToken) {
                let token = JSON.parse(JSON.stringify(accessToken));
                let date = new Date();
                date.setSeconds(date.getSeconds() + token.expires_in);
                token.expiration = date;
                // saves it on the localstorage and on the vuex store
                await this.$store.dispatch('auth/setDriveAuth', token);
            }
        },

        async checkLogin(){
            const token = await this.vuexOrClient();
            if (token) {
                this.updateSignInStatus(true);
            }else{
                this.updateSignInStatus(false);
            }
        },

        // will check the google drive auth object, and if its valid, it will run the 
        // given function, else, it will open the google drive auth wizard
        async checkLoginAndRun(fn = null) {
            let token = await this.vuexOrClient();
            if (token) {
                if (fn !== null) {
                    fn();
                }
            } else {
                let return_uri = encodeURIComponent(window.location.origin + '/drive/auth');

                let win = window.open(`${this.host}/api/drive/auth/?return=${return_uri}`, "", "width=600,height=800");

                var timer = setInterval(async () => { 
                    if (win.closed) {
                        clearInterval(timer);
                        await this.setTokenFromLocal();
                        token = await this.vuexOrClient();

                        if (token) {
                            this.updateSignInStatus(true);
                            if (fn !== null) {
                                fn();
                            }
                        }
                    }
                }, 1000);
            }
        },

        async setTokenFromLocal() {
            let token = JSON.parse(localStorage.getItem('drive_auth')) || null;
            if (token) {
                // saves it on the localstorage and on the vuex store
                await this.$store.dispatch('auth/setDriveAuth', token);
            }
        },
        
        // triggered when clicking the google drive item on the documents tab,
        // will first check your auth status
        async openAddCreateDriveFolderModalIntent() {
            await this.checkLoginAndRun(this.openAddCreateDriveFolderModal);
        },
        // if you are auth for drive, it will open the modal
        openAddCreateDriveFolderModal() {
            this.addCreateDriveFolderModalVisible = true;
            this.driveFolderUrlOrName = '';
        },
        async openAddDriveFileModalIntent(){
            await this.checkLoginAndRun(this.openAddDriveFileModal);
        },
        openAddDriveFileModal(){
            this.addDriveFileModalVisible = true;
        },
        // get google credentials
        async getGoogleCredentials() {
            const { data } = await this.$http.get(`/api/auth/get-auth-credentials/google`);
            this.developerKey = decryptStr(data.GOOGLE_DEVELOPER_KEY, this.passphrase);
            this.clientId = decryptStr(data.GOOGLE_CLIENT_ID, this.passphrase);
            let httpsProtocol = data.host.includes('localhost') ? '' : 's';
            this.host = `http${httpsProtocol}://${data.host}`;
            this.gapiInited = true;
            this.gisInited = true;
            this.googleLoaded = true;
        },
        
        // trigered when you are done inputing the url or name of the g drive folder and hit "Change Name", "Add Folder", "Create Folder"
        async postCreateDriveFolder() {
            if (this.driveFolderUrlOrName !== '' && !this.creatingDriveFolder && this.accessToken !== null) {
                let access_token = this.accessToken.access_token;
                this.creatingDriveFolder = true;
                let editing = this.editingDriveFolderName;

                // I think we might not be using this logic right now, we could create a new modal that handles the name change tho
                if (editing) {
                    fetch(`https://www.googleapis.com/drive/v2/files/${this.selectedFolder.external_id}`, {
                        method: 'PATCH',
                        headers: new Headers({'Content-type': 'application/json; charset=UTF-8', 'Authorization': `Bearer ${access_token}`}),
                        body: JSON.stringify({
                            title: this.driveFolderUrlOrName,
                        })
                    }).then(async (response) => {
                        await this.$http.put(`/api/folders/${this.selectedFolder.id}`, {
                            name: this.driveFolderUrlOrName
                        });
                        this.$notify({title: 'Success!', text: 'Folder updated successfully', type: 'success'});

                        await this.getData();

                        return response.json();
                    }).catch((err) => {
                        notifyCatchError(err, this.$notify);
                    }).finally(() => {
                        this.creatingDriveFolder = false;
                    });
                } else {
                    // creating or adding folder
                    let uriSub = 'https://drive.google.com/drive/folders/';
                    
                    let name = this.driveFolderUrlOrName.trim();
                    let isURL = validateUrl(name);
                    let response, json;

                    // if its a url and includes: https://drive.google.com/drive/folders/
                    // we will get the drive folder and create it internally too
                    if (isURL && name.includes(uriSub)) {
                        let uri = name.replace(uriSub, '');

                        response = await fetch(`https://www.googleapis.com/drive/v3/files/${uri}?fields=id,name,mimeType,capabilities,permissions`, {
                            method: 'GET',
                            headers: new Headers({'Authorization': `Bearer ${access_token}`}),
                        });
                        if (response) {
                            json = await response.json();
                            await this.createInternalDriveFolder(json.name, json.id);
                        }
                    } else if (isURL) {
                        // if its a url but it does not have https://drive.google.com/drive/folders/ then its not valid, show message
                        this.$notify({ title: 'This url is invalid, try again with another url.', text: 'Make sure to use a shareable folder URL', type: 'warn' });
                        this.creatingDriveFolder = false;
                    } else {
                        // if its not a URL then its a name, we will create a new folder on the drive
                        let body = {
                            title: name,
                            mimeType: 'application/vnd.google-apps.folder'
                        };

                        response = await fetch(`https://www.googleapis.com/drive/v2/files`, {
                            method: 'POST',
                            headers: new Headers({'Authorization': `Bearer ${access_token}`, 'Content-Type': 'application/json'}),
                            body: JSON.stringify(body)
                        });

                        if (response) {
                            json = await response.json();
                            await this.createInternalDriveFolder(json.title || json.name, json.id);
                        }
                        
                        this.creatingDriveFolder = false;
                    }
                }
            }
        },
        // executed when creating or adding a drive folder
        async createInternalDriveFolder(name, external_id) {
            try {
                const { data } = await this.$http.post('/api/folders', {
                    folderable_id: this.projectId,
                    folderable_type: 'project',
                    parent_id: this.folderId,
                    attachment_type: null,
                    name,
                    external_id
                });
                if (data) {
                    this.$notify({ title: 'Success!', text: 'Folder created successfully', type: 'success' });
                    this.closeAddCreateDriveFolderModal();
                    this.getData();
                }
            } catch (err) {
                console.log(err);
                notifyCatchError(err, this.$notify);
            } finally {
                this.creatingDriveFolder = false;
            }
        },
        // share the drive folder with another user
        async shareDriveFolder() {
            let fileID = this.selectedFolder.external_id;
            let access = await this.vuexOrClient();
            let access_token = access.access_token;
            let body = {
                role: 'commenter',
                type: 'user',
                emailAddress: this.sharedEmail
            };

            if (validateEmail(this.sharedEmail) && this.selectedFolder.external_id) {
                fetch(`https://www.googleapis.com/drive/v3/files/${fileID}/permissions`, {
                    method: 'POST',
                    headers: new Headers({'Authorization': `Bearer ${access_token}`, 'Content-Type': 'application/json'}),
                    body: JSON.stringify(body)
                }).then(() => {
                    this.$notify({title: 'Success!', text: 'Folder shared successfully', type: 'success'});
                    this.processFiles(fileID);
                    this.closeShareDriveFolderModal();
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        // close add/create drive folder modal
        closeAddCreateDriveFolderModal() {
            this.driveFolderUrlOrName = '';
            this.selectedFolder = null;
            this.addCreateDriveFolderModalVisible = false;
            this.editingDriveFolderName = false;
        },
        // revoke permission intent
        revokePermission(permission) {
            if (this.selectedFolder && this.selectedFolder.external_id) {
                this.$swal.fire({
                    title: `Are you sure you want to revoke this permission from ${permission.emailAddress}?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Revoke',
                    buttonsStyling: false,
                    reverseButtons: true,
                    focusCancel: true
                }).then((result) => {
                    if (result.isConfirmed && this.selectedFolder.external_id) {
                        this.confirmRevokePermission(this.selectedFolder.external_id, permission.id);
                    }
                });
            }
        },
        // revoke permission
        async confirmRevokePermission(fileId, permissionId) {
            let access = await this.vuexOrClient();
            let access_token = access.access_token;
            this.loading = true;
            fetch(`https://www.googleapis.com/drive/v2/files/${fileId}/permissions/${permissionId}`, {
                method: 'DELETE',
                headers: new Headers({'Authorization': `Bearer ${access_token}`}),
            }).then(() => {
                this.processFiles(fileId);
            }).finally(() => {
                this.loading = false;
            });
        },
        // close share drive folder modal 
        closeShareDriveFolderModal() {
            this.selectedFolder = null;
            this.shareDriveFolderModalVisible = false;
            this.sharedEmail = '';
        },
        // upload a computer file to the drive folder
        async uploadDriveFile(file) {
            const { name, type } = file;
            let access = await this.vuexOrClient();
            let access_token = access.access_token;
            let parentFolder = this.currentFolder.external_id;
            const blob = new Blob([file], { type });
            let metadata = {
                name,
                mimeType: type,
                parents: [parentFolder]
            };
            let formData = new FormData();
            formData.append('metadata', new Blob([JSON.stringify(metadata)], {type: 'application/json'}));
            formData.append('file', blob);

            this.loading = true;

            fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
                method: 'POST',
                headers: new Headers({'Authorization': `Bearer ${access_token}`}),
                body: formData
            }).then((response) => {
                this.getData();
                return response.json();
            }).finally(() => {
                this.loading = false;
            });
        },
        
        // (intent) remove the drive folder from the documents project section
        deleteDrive(file) {
            this.$swal.fire({
                title: `Are you sure you want to delete ${file.name} ?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                buttonsStyling: false,
                reverseButtons: true,
                focusCancel: true
            }).then((result) => {
                if (result.isConfirmed && file.external_id) {
                    this.confirmDeleteDrive(file);
                }
            });
        },

        // remove the drive folder from the documents project section
        async confirmDeleteDrive(file) {
            let access_token = await this.vuexOrClient();
            if (access_token) {
                access_token = access_token.access_token;
                fetch(`https://www.googleapis.com/drive/v2/files/${file.external_id}`, {
                    method: 'DELETE',
                    headers: new Headers({'Authorization': `Bearer ${access_token}`}),
                }).then(async () => {
                    const { data } = await this.$http.delete(`/api/folders/${file.id}`);
                    if (data) {
                        this.$notify({title: 'Success!', text: 'File deleted successfully', type: 'success'});
                        this.getData();
                    }
                }).catch((err) => {
                    notifyCatchError(err, this.$notify);
                });
            }
        },
        // triggered when clicking the dropzone
        openFileExplorer(){
            let items = document.getElementsByClassName('formulate-input');
            for (let i in items) {
                if (items[i].dataset && items[i].dataset.type === 'file') {
                    let descendants = items[i].querySelectorAll("*");
                    for (let j in descendants) {
                        if (descendants[j].name && descendants[j].name === 'file') {
                            descendants[j].click();
                        }
                    }
                }
            }
        },

        // go back on the folder navigation
        onGoBack(){
            this.navigation.pop()
            this.$emit('navigationChange', this.navigation)
            this.getData()
        },
        
        // when clicking on any folder
        onOpenFolder(folder) {
            this.navigation.push(folder);
            this.$emit('navigationChange', this.navigation);
            this.getData();
        },

        // get the contents of the current folder or the root
        async getData() {
            let folder = this.currentFolder;
            
            // if we are inside of a google drive folder
            if (folder !== null && (folder.external_id || folder.mimetype == 'application/vnd.google-apps.folder')) {
                await this.getDriveFolder(folder.external_id);
            } else if (this.resource && this.resource.type && this.resource.id) {
                try{
                    this.loading = true;
                    const { data } = await this.$http.get('/api/folders', {
                        params: { 
                            folderable_id: this.resource.id,
                            folderable_type: this.resource.type,
                            user_id: this.userId,
                            project_id: this.projectId,
                            folder_id: this.openedFolder,
                            exclude: this.excludeFileType,
                        } 
                    });
                    this.files = data.map(file => {
                        file.visible = !file.external_id;
                        return file;
                    }).reverse();
                    this.processFiles();
                    const isRoot = this.openedFolder == null;
                    this.$emit('filesChange', this.files, isRoot);
                    this.filesKey = !this.filesKey;
                }catch(err){
                    console.log('getData error', err)
                }finally{
                    this.loaded = true;
                    this.loading = false
                }   
            }
        },

        // used/required mainly for google drive folders & files
        // checks that we have the required google permissions to view them on the list
        // normal files are also processed in here but we don't do anything to them
        processFiles(fileId = null) {
            
            this.processingFiles = true;

            setTimeout(async () => {
                let access_token_valid = await this.vuexOrClient();
                let access_token = access_token_valid !== null ? access_token_valid.access_token : null;
                let files = [];
                
                // prevents the "this.files" array from being emptied in some of this function's runs
                if(!isArray(this.files)){
                    this.processingFiles = false;
                    return;
                }

                this.files.forEach(async (file) => {

                    const { external_id, is_external, mimetype, url } = file;

                    // g drive folders have "external_id"
                    const isGDriveFolder = !!external_id;

                    // g drive files have "is_external": true, type: "link", and the url has docs.google.com
                    const isGDriveFile = is_external == true && mimetype == 'link' && isGoogleDriveUrl(url);

                    if (isGDriveFolder || isGDriveFile) {
                        
                        // flag this file as a drive file, we use this in the Item.vue component
                        file.isDrive = true;

                        // hide file until we know if we have the right permissions to view it
                        file.visible = false;
                    
                        // get the file or folder id
                        let identifier = null;
                        if(isGDriveFolder){
                            identifier = external_id;
                        }
                        if(isGDriveFile){
                            identifier = getIdFromGoogleDriveUrl(url);
                        }

                        // if a g drive access token and an identifier exist
                        if (access_token && identifier) {
                            try {
                                let response = await fetch(`https://www.googleapis.com/drive/v3/files/${identifier}?fields=id,name,mimeType,capabilities,permissions,thumbnailLink,iconLink,webViewLink,webContentLink`, {
                                    method: 'GET',
                                    headers: new Headers({'Authorization': `Bearer ${access_token}`}),
                                });
                                if (response) {
                                    let json = await response.json();
                                    if (json) {
                                        const { capabilities, permissions, thumbnailLink, iconLink, webViewLink, webContentLink /*, createdDate*/ } = json;
                                        if(capabilities) file.visible = true;
                                        if(permissions) file.permissions = permissions;
                                        if(iconLink) file.iconLink = iconLink;
                                        if(thumbnailLink) file.thumbnailLink = thumbnailLink;
                                        if(webViewLink) file.webViewLink = webViewLink;
                                        if(webContentLink) file.webContentLink = webContentLink;
                                    }
                                }
                            } catch (e) {
                                console.log(e);
                                this.accessToken = null;
                                this.updateSignInStatus(false);
                            }
                        }

                        if (fileId !== null) {
                            this.selectedFolder = file;
                        }
                    }
                    files.push(file);
                });

                this.files = files;
                
                setTimeout(() => {
                    this.processingFiles = false;
                }, 500)

            }, 250);
        },

        // get the contents/files of a g drive folder,
        async getDriveFolder(folderId) {
            this.loading = true;
            let access = await this.vuexOrClient();
            let access_token = access.access_token;
            let route = `https://www.googleapis.com/drive/v3/files?q='${folderId}' in parents&fields=files(*)`;
            let data = await fetch(route, {
                method: 'GET',
                headers: new Headers({'Authorization': `Bearer ${access_token}`, 'Content-Type': 'application/json'})
            }).then((response) => {
                return response.json();
            }).finally(() => {
                this.loading = false;
            });

            // We used to have this filter for the data.files array: .filter(item => item.mimeType !== 'application/vnd.google-apps.folder')
            this.files = data.files.map(item => {

                let { id, mimeType, name, webViewLink, webContentLink, thumbnailLink, iconLink, alternateLink, createdTime, createdDate } = item;
                
                // Google drive subfolders have mimetype = 'application/vnd.google-apps.folder' and isDrive: true
                // for these subfolders we will set the external_id: id
                return {
                    id,
                    ...(mimeType == 'application/vnd.google-apps.folder' ? { external_id: id } : {}),
                    isDrive: true,
                    mimetype: mimeType,
                    name,
                    webViewLink,
                    webContentLink,
                    thumbnailLink,
                    iconLink,
                    alternateLink,
                    url: webViewLink,
                    is_external: true,
                    uploading: false,
                    created_at: createdTime || createdDate,
                    visible: true
                };
            });

            const isRoot = this.openedFolder == null;
            this.$emit('filesChange', this.files, isRoot);
            this.filesKey = !this.filesKey;
            this.loading = false;
        },
        
        // shows the edit folder modal, for normal and g drive folders
        // i think we disabled this option for g drive folders so this should
        // only be for normal folders
        showEditFolder(folder){
            if (folder.external_id) {
                this.driveFolderUrlOrName = folder.name;
                this.selectedFolder = folder;
                this.editingDriveFolderName = true;
            } else {
                this.showEditFolderModal = true;
                this.editFolder = folder;
            }
        },
        
        // show edit file name modal
        showEditFile(file) {
            if (this.mimetype === 'reports') {
                this.$router.push(file.url + '/edit');
            } else {
                this.showEditFileModal = true;
                this.editFile = file;
            }
        },
        
        // refreshes the list of files when we change a folder name for example
        async updateFiles() {
            await new Promise(resolve => setTimeout(resolve, 500));
            await this.getData();
        },

        dragOverHandler(event) {
            console.log(event);
        },

        async dropHandler(event) {
            console.log('File(s) dropped on add file');
            const droppedFiles = event.dataTransfer.files;

            if (!droppedFiles) {
                return;
            }

            let items = [];
            for (let file of [...droppedFiles]) {
                // g drive folder
                if (this.currentFolder && this.currentFolder.external_id) {
                    await this.uploadDriveFile(file);
                } else {
                    // normal folder
                    let item = await this.addFile(file);
                    items.push(item);
                }
            }

            this.draggedOverDropzone = false;
            this.getData();

            return items;
        },
        
        // dropped a file in any folder
        async onFileDroppedInFolder(fileId, folderId, fileType, fileName, folderName, mimeType = null, external_id = null, files = []){
            console.log('File(s) dropped in folder');

            // google drive uploads
            if (external_id !== null && !!mimeType) {
                this.$http.get(`/api/files/${fileId}/encrypted`).then(async ({data}) => {
                    let url = `data:${mimeType};base64,${data}`;

                    let file = await (await fetch(url)).blob();
                    let access_token = await this.vuexOrClient().access_token;
                    const blob = new Blob([file], { type: mimeType });
                    let metadata = {
                        name: fileName,
                        mimeType,
                        parents: [external_id]
                    };
                    let formData = new FormData();
                    formData.append('metadata', new Blob([JSON.stringify(metadata)], {type: 'application/json'}));
                    formData.append('file', blob);

                    this.loading = true;

                    fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
                        method: 'POST',
                        headers: new Headers({'Authorization': `Bearer ${access_token}`}),
                        body: formData
                    }).then((response) => {
                        this.$notify({ title: 'Success', text: 'File created successfully', type: 'success' });
                        this.getData();
                        return response.json();
                    }).finally(() => {
                        this.loading = false;
                    });
                });
                // trying to upload folder to drive
            } else if (external_id && fileType === 'folder') {
                this.$notify({
                    title: 'Warning',
                    text: 'You can not create folders into a shared Drive folder',
                    type: 'warn'
                })
                // single file
            } else if (fileId !== '') {
                this.moveFile(fileId, folderId, fileType, fileName, folderName)
                // multiple files
            } else if (files.length) {
                let items = await this.dropHandler({dataTransfer: {files}});
                for (let file of items) {
                    this.moveFile(file.id, folderId, 'file', file.name, folderName)
                }
            }
        },
        
        // add a file to a normal folder
        async addFile(file) {
            console.log("adding file", file);
            this.addingFile = true
            this.files.unshift({
                id: null,
                name: file.name,
                created: 'just now',
                visibility: 'private',
                uploading: true
            });

            const fileIndex = this.files.findIndex(u => u.name === file.name);
            const [uploadedFile] = await fileUpload(file, { 
                projectId: this.projectId, 
                userId: this.userId, 
                folderId: this.openedFolder 
            });
            if (uploadedFile.error) {
                this.files[fileIndex].uploading = false;
                this.files[fileIndex].error = true;
                this.addingFile = false
                return;
            }
            
            this.files[fileIndex].id = uploadedFile.id;
            this.files[fileIndex].created_at = uploadedFile.created_at;
            this.files[fileIndex].uploading = false;
            this.$notify({ title: 'Success', text: 'File uploaded successfully', type: 'success' })
            this.addingFile = false

            return uploadedFile;
            // this.uploadedFiles[fileIndex] = uploadedFile;
        },
        
        // not using this, deprecated ?
        // async submitLink () {
        //     console.log("adding link", this.external_link);
        //     this.files.unshift({
        //         id: null,
        //         name: this.external_link,
        //         created: 'just now',
        //         visibility: 'private',
        //         uploading: true
        //     });

        //     await linkUpload({ name: this.external_link_name, file: this.external_link, projectId: this.projectId, userId: this.userId, folderId: this.openedFolder });
        //     this.external_link_name = '';
        //     this.external_link = '';
        //     this.getData();
        // },
        
        // not sure where we are using this logic
        async uploadHandler(file /*, progress, error, option*/) {
            if (this.currentFolder && this.currentFolder.external_id) {
                return this.uploadDriveFile(file);
            } else {
                const [uploadedFile] = await fileUpload(file, {
                    projectId: this.projectId,
                    userId: this.userId || null,
                    folderId: this.openedFolder
                });
                this.getData();

                return [{url: uploadedFile.url}];
            }
        },

        shareModalSaved(){
            this.sharing.show = false
            this.getData()
        },

        showShareModal({type, id }) {
            this.sharing.show = true;
            this.sharing.type = type;
            this.sharing.typeId = id;
        },

        showShareDriveModal(file) {
            this.selectedFolder = file;
            this.shareDriveFolderModalVisible = true;
        },
        
        // events for drag, drop, breadcrumbs, etc
        onDragStarted(value){
            this.dragStarted = value
            this.$emit('dragStarted', value)
        },

        onDragEnded(){
            this.dragStarted = false
            this.$emit('dragEnded')
        },

        onBreadcrumbClick(navigationItem){
            if(navigationItem == 'root'){
                this.navigation = [];
            }else{
                const index = this.navigation.findIndex(element => {
                    return element.id == navigationItem.id
                });
                this.navigation = this.navigation.slice(0, index+1); 
            }
            this.$emit('navigationChange', this.navigation)
            this.getData();
        },

        onBreadcrumbDrop(event, folderId, breadcrumbName){
            const fileId = event.dataTransfer.getData('fileId')
            const fileType = event.dataTransfer.getData('fileType')
            const fileName = event.dataTransfer.getData('fileName')
            if(folderId == 'root') return this.moveFile(fileId, 'root', fileType, fileName, breadcrumbName)
            this.moveFile(fileId, folderId, fileType, fileName, breadcrumbName)
        },

        async moveFile(fileId, folderId, fileType, fileName, folderName){
            const endpoint = fileType == 'file' ? `/api/files/${fileId}` : `/api/folders/${fileId}`
            const payload = fileType == 'file' ? {
                folder_id: folderId
            } : {
                parent_id: folderId
            }
            this.$http.put(endpoint, payload).then((/*{data}*/) => {
                this.$notify({ title: 'Success', text: `${fileName} moved to ${folderName}`, type: 'success' })
                setTimeout(()=>{
                    this.getData();
                }, 200) 
            }).catch(err => {
                console.error('update file err', err)
            })
        },

        isLastBreadCrumb(navigationItem){
            return this.navigation[this.navigation.length-1].id == navigationItem.id ? true : false
        }
    },

    computed: {
        ...mapGetters(['driveAuth']),

        sharedEmailInvalid() {
            return !validateEmail(this.sharedEmail);
        },

        openedFolder(){
            return this.navigation.length > 0 ? this.navigation[this.navigation.length-1].id : null
        },

        currentFolder() {
            return this.navigation.length ? this.navigation[this.navigation.length-1] : null;
        },

        filteredFiles: function () {
            let repeated = [];
            return this.files.filter(elem => elem.visible).map(elem => {
                elem.repeated = false;
                // first check g drive files
                const isGDriveFile = elem.external_id !== undefined && elem.external_id !== '' && elem.external_id !== null; 
                if (isGDriveFile) {
                    if (repeated.includes(elem.external_id)) {
                        elem.repeated = true;
                    } else {
                        repeated.push(elem.external_id);
                    }
                }
                return elem;
            }).filter(elem => {

                // if element is repeated filter it out
                if (elem.repeated) {
                    return false;
                }

                // if it's a folder
                if(elem.folderable_id){
                    
                    // if we are in reports, check that the attachment type is reports
                    if(this.mimetype === 'reports'){
                        return elem.attachment_type === 'reports';
                    }
                    
                    // if we are in documents, check that attachment type is null
                    if(this.mimetype === null){
                        return elem.attachment_type === null;
                    }

                    // g drive
                    if (!this.accessToken) {
                        return !elem.external_id;
                    }

                    return elem.attachment_type === null
                }
                
                // actual files or reports
                if (this.mimetype) {
                    if (this.mimetype === 'reports' && elem.mimetype === 'link' && elem.url !== undefined) {
                        return elem.url.indexOf('/reports/') > -1;
                    }
                    return elem.mimetype === this.mimetype
                }
                
                // filter out excluded file types
                if (this.excludeFileType) {
                    if (this.excludeFileType === 'reports' && elem.mimetype === 'link' && elem.url !== undefined) {
                        return elem.url.indexOf('/reports/') === -1;
                    }
                    return elem.mimetype !== this.excludeFileType
                }
                return elem;
            }).filter(elem => {
                // search filter
                if(this.searchTerm && this.searchTerm !== ''){
                    if(elem.name.toLowerCase().includes(this.searchTerm.toLowerCase())){
                        return true
                    }else{
                        return false
                    }
                }else{
                    return true
                }
            }).filter(elem => {
                // report type filter
                if(this.selectedReportTypeFilter && this.selectedReportTypeFilter.value){
                    // folders dont get filtered out
                    if(elem.folderable_id) return true;
                    const type = this.selectedReportTypeFilter.value;
                    if(type == 'all') return true;
                    if(type == 'creators') return elem.report.type == 1;
                    if(type == 'content') return elem.report.type == 2;
                    if(type == 'brand') return elem.report.type == 3;
                    return true;
                }else{
                    return true;
                }
            }).sort((a, b) => (a.uuid > b.uuid) ? 1 : -1);
        },

        hasProject() {
            return this.projectId !== null;
        }

    }
}
</script>

<style lang="scss">
.file-list-wrapper {
    min-height: 250px;
}

.file-list-wrapper {
    .file-wrapper {
        /* @apply border-t border-gray-200;

        &:first-child {
            border: none;
        } */

        .has-upload-error {
            &:hover {
                @apply bg-red-100;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
    .file-grid-wrapper{
        @apply flex flex-col;
        min-height: 480px;
        > .navigation{
            @apply flex gap-x-6 items-center py-5;
            min-height: 40px;
            > .actions{
                @apply relative;
            }
            > .breadcrumbs{
                @apply text-h4 font-bold text-purple-m-secondary;
                .breadcrumb{
                    @apply px-1 cursor-pointer rounded-md;
                    &:hover{
                        @apply bg-gray-100
                    }
                    &.drag-started{
                        @apply bg-tan-m-hover
                    }
                }
            }
        }
        > .file-grid-container{
            @apply w-full h-full flex flex-wrap gap-8;
            > .file-upload-dropzone{
                @apply border-2 cursor-pointer border-dashed border-tan-m w-56 h-56 z-10;
                background: rgba(255, 255, 255, .8);
                &.dragged-over{
                    @apply bg-gray-m-light border-purple-m-secondary;
                    > .content{
                        > .icon{
                            @apply border-purple-m-secondary;
                        }
                    }
                }
                
                > .content{
                    @apply flex flex-col p-4 gap-2 justify-center items-center h-full;
                    > .icon{
                        @apply inline-flex items-center justify-center rounded-full bg-transparent w-15 h-15 border border-gray-m-light;
                    }
                    > .text{
                        @apply text-purple-m-secondary text-h5 font-bold pt-4 text-center;
                    }
                    > .description{
                        @apply text-purple-m-secondary text-ps;
                    }
                }
                > .hidden-input{
                    @apply h-0 overflow-hidden;
                }
            }

            > .add-g-drive-folder{
                @apply border-2 cursor-pointer border-dashed border-tan-m w-56 h-56 z-10;
                background: rgba(255, 255, 255, .8);
                > .content{
                    @apply flex flex-col p-4 gap-2 justify-center items-center h-full;
                    > .icon{
                        @apply inline-flex items-center justify-center rounded-full bg-transparent w-15 h-15 border border-gray-m-light;
                    }
                    > .text{
                        @apply text-purple-m-secondary text-h5 font-bold pt-4 text-center;
                    }
                }
            }

            > .empty-state{
                @apply text-center w-full mt-28 text-gray-400 italic;
            }
            > .empty-state-documents{
                @apply flex items-center h-56 ml-16 text-gray-400 italic; 
            }

            .edit-g-drive-folder-name-modal-content{
                > .input-container{
                    @apply flex flex-col gap-y-2;
                }
                > .text{
                    @apply text-xs text-gray-500;
                }
                > .footer{
                    @apply pt-3 gap-x-2 flex justify-end;
                }
            }

            .share-g-drive-folder-modal{
                > .content{
                    @apply flex flex-col gap-y-2;
                    > .label{
                        @apply text-h5 font-bold;
                    }
                    > .users{
                        @apply flex flex-col gap-1 mt-5;
                        > .title{
                            @apply font-bold mb-2;
                        }
                        > .list{
                            @apply max-h-48 overflow-y-auto flex flex-col mb-2;
                            > .item{
                                @apply flex justify-between gap-2 w-full rounded-md transition-colors p-2 duration-200;
                                &:hover{
                                    @apply bg-gray-200;
                                }
                                > .user {
                                    @apply flex gap-x-4 py-2;
                                    .profile-picture{
                                        @apply rounded-full border border-gray-500 w-12 h-12;
                                    }
                                    .names{
                                        @apply flex flex-col justify-center gap-y-0.5;
                                        .name{
                                            @apply font-bold text-h6;
                                        }
                                        .email{
                                            @apply text-gray-500 text-h6;
                                        }
                                    }
                                }
                                > .actions{
                                    @apply flex items-center;
                                }
                            }
                        }
                    }
                }
                > .footer{
                    @apply pt-3 gap-x-2 flex justify-end;
                }
            }

        }
    }
</style>