<template>
    <base-modal
        :visible="visible"
        title="Share Project"
        styles="max-height: 90vh; min-width: 90vw; width: 90vw; height: 90vw; overflow-y: auto; background-color: white"
        :no-padding="true"
        @close="$emit('close')"
    >
        <div class="px-14 relative flex flex-col justify-between items-stretch" style="min-height: calc(90vh - 88px)">
            <div>
                <div class="mb-5">
                    <div>Clients: View projects</div>
                    <div>Collaborators: Edit projects</div>
                </div>
                <div v-if="step == 1">
                    <div class="pb-6 font-bold">
                        Select the clients and collaborators that you want to share this project with
                    </div>
                    <base-table
                        :columns="[
                            {
                                name: 'name',
                                label: 'Name',
                                type: 'avatar-list',
                                value: (element) => {
                                    return [element]
                                }
                            },
                            {
                                name: 'name-searchable',
                                label: 'Name Searchable',
                                type: 'text',
                                value: (element) => {
                                    return `${element.first_name} ${element.last_name}`
                                },
                                hidden: true
                            },
                            {
                                name: 'email',
                                label: 'Email',
                                type: 'text',
                                value: (element) => {
                                    return element.email
                                }
                            },
                            {
                                name: 'role',
                                label: 'Role',
                                type: 'role-list',
                                value: (element) => {
                                    return element.roles
                                }
                            },
                            {
                                name: 'assignation',
                                label: '',
                                type: 'assign-button',
                                disabled: loading || emailsProcessing,
                                value: (element) => {
                                    return this.selectedUsers.find(selectedUser => selectedUser.id == element.id) ? true : false
                                },
                                onClick: (element, isAssigned) => {
                                    return isAssigned ? this.selectedUsers = this.selectedUsers.filter(selectedUser => selectedUser.id != element.id) : this.selectedUsers.push(element)
                                }
                            }
                        ]"
                        :data="users"
                        :empty-text="'No users'"
                        :loading="!project.assigned_users || !usersLoaded"
                    />
                </div>

                <div v-if="step == 2">
                    <div class="pb-6 font-bold">
                        Assign roles for email invited users
                    </div>
                    <base-table
                        :columns="[
                            {
                                name: 'email',
                                label: 'Email',
                                type: 'text',
                                value: (element) => {
                                    return element.email
                                }
                            },
                            {
                                name: 'roles',
                                label: 'Access Type',
                                type: 'single-select',
                                options: invitedUsersRoleOptions
                            },
                            {
                                name: 'assignation',
                                label: '',
                                type: 'assign-button',
                                disabled: loading || emailsProcessing,
                                value: (element) => {
                                    return true
                                },
                                onClick: removeInvitedUser
                            }
                        ]"
                        @change-single-select="handleRoleChange"
                        :data="invitedUsers"
                        :loading="false"
                        :search="false"
                        :filtering="false"
                    />

                </div>

                <div v-if="step == 3">
                    <div class="pb-6 font-bold">
                        The following users will have access to the project
                    </div>
                    <base-table
                        :columns="[
                            {
                                name: 'name',
                                label: 'Name',
                                type: 'avatar-list',
                                value: (element) => {
                                    return [element]
                                }
                            },
                            {
                                name: 'name-searchable',
                                label: 'Name Searchable',
                                type: 'text',
                                value: (element) => {
                                    return `${element.first_name} ${element.last_name}`
                                },
                                hidden: true
                            },
                            {
                                name: 'email',
                                label: 'Email',
                                type: 'text',
                                value: (element) => {
                                    return element.email
                                }
                            },
                            {
                                name: 'role',
                                label: 'Role',
                                type: 'text',
                                value: (element) => {
                                    return element.roles[0].display_name
                                }
                            },
                        ]"
                        :bulkActions="[]"
                        :data="[...selectedUsers, ...invitedUsers]"
                        :initialSelectedElements="[]"
                        :empty-text="'There are no selected users'"
                        :loading="false"
                        :search="false"
                        :filtering="false"
                        :key="selectedUsersTableKey"
                    />
                </div>

                <div v-if="step == 4">
                    <div class="flex flex-col gap-y-6 pb-32">
                        <div class="text-h4 font-bold">
                            Are you sure you want to share budget, project & user information with these users?
                        </div>
                        <FormulateInput
                            type="checkbox"
                            v-model="hideShareProjectNotice"
                            label="Don't show again"
                        />
                    </div>
                </div>
            </div>

            <div class="sticky bottom-0 bg-white py-3 mt-4 flex gap-3 justify-between">

                <div class="flex-shrink flex-grow relative flex gap-x-4 items-center">
                    <div class="absolute w-12 left-0 top-0 bottom-0 flex justify-center items-center">
                        <base-icon name="mail" />
                    </div>
                    <FormulateInput 
                        v-model="emails"
                        type="email"
                        label="Not listed above ? Invite by email address"
                        placeholder="email@example.com, email@example.com"
                        style="width: 450px"
                        :wrapper-class="['pl-12']"
                        @input="onEmailsInput"
                        v-debounce:2000="setInvitedUsers"
                    /> 
                    <base-icon v-if="emailsProcessing" name="loader" />
                    <div v-else-if="emailsError" class="text-px text-red-400">
                        {{emailsError}}
                    </div>
                </div>

                <div class="flex gap-x-6 flex-shrink-0 flex-grow-0">
                    <base-button 
                        @action="$emit('close')" 
                        type="secondary"  
                        class="bg-tan-m" 
                        :disabled="loading || emailsProcessing"
                        size="sm" 
                        label="Cancel"
                    />
                    <base-button
                        v-if="step > 1"
                        @action="backAction"
                        :disabled="loading || emailsProcessing"
                        label="Back"
                        icon="arrow-left"
                    />
                    <base-button
                        v-if="step == 1 || step == 2 || (step == 3 && show4thStep)"
                        @action="nextAction"  
                        :disabled="loading || emailsProcessing"
                        label="Next"
                        secondary-icon="arrow-right"
                    />
                    <base-button
                        v-if="step == 4 || (step == 3 && !show4thStep)"
                        @action="submit"  
                        :disabled="loading || emailsProcessing"
                        label="Confirm"
                    />
                </div>
            </div>

        </div>
    </base-modal>
</template>

<script>
import BaseModal from '../../components/BaseModal.vue'
import { validateEmail } from '../../lib/strings'
import { mapGetters } from 'vuex'
export default {
    props: ['visible', 'project'],
    components: {
        BaseModal
    },
    data(){
        return {
            step: 1,

            users: [],
            loading: false,
            usersLoaded: false,
            selectedUsers: [],
            selectedUsersTableKey: 0,

            emails: '',
            emailsProcessing: false,
            emailsError: false,
            invitedUsers: [],
            
            show4thStep: true,
            hideShareProjectNotice: false
        }
    },
    mounted(){
        const item = localStorage.getItem('hide_share_project_notice') 
        if(item == null || item == "false") this.show4thStep = true
        if(item == "true"){
            this.show4thStep = false
            this.hideShareProjectNotice = true
        }        
    },
    watch: {
        visible(val){
            if(val == true) {
                if(!this.usersLoaded){
                    this.getUsers()
                }
            }
            this.selectedUsers = this.project.assigned_users
            this.selectedUsersTableKey++
        }
    },
    computed: {
        ...mapGetters(['company'])
    },
    methods: {
        handleRoleChange(element, column, val){
            let index = this.invitedUsers.indexOf(element)
            this.invitedUsers[index].roles = [val]
        },
        removeInvitedUser(element){
            let index = this.invitedUsers.indexOf(element)
            
            // remove email from text field
            this.emails = this.emails.replace(this.invitedUsers[index].email, '')
            
            // remove any commas or spaces left
            this.emails = this.emails.trim()
            const firstChar = this.emails[0]
            const lastChar = this.emails[this.emails.length - 1]
            if(firstChar == ',') this.emails = this.emails.substring(1)
            if(lastChar == ',') this.emails = this.emails.slice(0, -1)
            this.emails = this.emails.trim()

            // remove from invitedUsers array
            this.invitedUsers.splice(index, 1)

            setTimeout(() => this.setInvitedUsers(), 1000)
        },
        onEmailsInput(){
            this.emailsProcessing = true
            if(this.invitedUsers.length && this.step > 2){
                this.step = 2
            }else{
                this.step = 1
            }

        },

        invitedUsersRoleOptions(element){
            const domain = this.company ? this.company.email_domain : null
            return [
                ...(!domain || element.email.split('@')[1].trim() == domain ? [
                    {
                        id: 1,
                        label: 'Collaborator',
                        display_name: 'Collaborator', 
                        name: 'collaborator'
                    },
                    {
                        id: 16,
                        label: 'Project Manager',
                        display_name: 'Project Manager', 
                        name: 'projectManager'
                    },
                ] : []),
                {
                    id: 15,
                    label: 'Client',
                    display_name: 'Client', 
                    name: 'client'
                }
            ] 
        },
        

        async setInvitedUsers(){
            this.emailsError = false
            let users = []
            let arr = this.emails.trim().split(',')

            // check repeated email on field
            let array = this.emails.trim().split(',').map(element => element.trim() )
            array.forEach((element, index) => {
                let spliceable = this.emails.trim().split(',').map(element => element.trim() )
                spliceable.splice(index, 1)
                if(spliceable.includes(element)){
                    this.emailsError = 'Repeated email address'
                }
            })
            
            // check email validation and on db
            for (let email of arr) {
                if(!email) break; 
                email = email.trim()
                if(validateEmail(email)){
                    try {
                        const { data } = await this.$http.get(`/api/users/byEmail`, { params: { email: email } })
                        if(data){
                            this.emailsError = 'Email already in use'
                        }
                    } catch(err){
                        users.push({
                            first_name: 'Invited',
                            last_name: 'User',
                            email: email,
                            roles: [{
                                id: 15,
                                label: 'Client',
                                display_name: 'Client', 
                                name: 'client'
                            }]
                        })
                    }
                }else{
                    this.emailsError = 'Invalid email'
                }
            }
            
            this.invitedUsers = users
            this.emailsProcessing = false
        },
        async getUsers() {
            this.loading = true
            this.$http.get(`/api/users`, {
                params: {
                    collaborators: true,
                    projectManagers: true,
                    clients: true
                }
            }).then(res => {
                this.users = res.data
                this.usersLoaded = true
            }).catch((/*err*/) => {
                this.$notify({ title: 'Error', text: 'Error trying to get users', type: 'error' })
            }).finally(() => {
                this.loading = false
            })
        },
        nextAction(){
            if(this.step == 1 && !this.invitedUsers.length) return this.step = 3
            this.step++
        },
        backAction(){
            if(this.step == 3 && !this.invitedUsers.length) return this.step = 1
            this.step--
        },
        async submit(){
            this.loading = true
            try{
                
                const { data } = await this.$http.post(`/api/user-project`, {
                    users: this.selectedUsers,
                    project_id: this.project.id
                })

                if(data){
                    
                    if(this.hideShareProjectNotice){
                        localStorage.setItem("hide_share_project_notice", "true")
                        this.show4thStep = false
                    }else{
                        localStorage.setItem("hide_share_project_notice", "false")
                    }
                    
                    this.$notify({ title: 'Success', text: 'Shared project with users', type: 'success' })
                    
                    for (const invitedUser of this.invitedUsers) {
                        
                        const res = await this.$http.post(`/api/invite/intent`, {
                            roleId: invitedUser.roles[0].id,
                            projectId: this.project.id,
                            targetEmails: invitedUser.email
                        })
                        const location = `${window.location.origin}/register?t=${res.data.token}`

                        await this.$http.post('/api/invite', { email: invitedUser.email, url: location })

                        console.log('Email sent successfully')

                    }

                    this.step = 1
                    this.emails = ''
                    this.$emit('success')
                    this.$emit('close')

                }

            }catch(err){
                this.$notify({ title: 'Error', text: 'Error trying to give access to users', type: 'error' })
            }finally{
                this.loading = false  
            }

        }
    }

}
</script>