var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
        'button', 
        ("justify-" + _vm.justify), 
        'items-center gap-3', 
        ("rounded-" + _vm.rounded), 
        _vm.textClass, 
        'font-base border focus:outline-none transition duration-150 ease-in-out', 
        _vm.display,
        {'font-bold': _vm.bold},
        {'w-12': _vm.size === 'xs'}, 
        {'w-24': _vm.size === 'sm'}, 
        {'w-36': _vm.size === 'md'}, 
        {'w-44': _vm.size === 'lg'}, 
        {'w-60': _vm.size === 'xl'}, 
        {'w-auto': _vm.needsAutoWidth},                
        ("" + (_vm.type !== 'label' ? _vm.padding : '')),
        {'border-transparent': _vm.type !== 'secondary' || !_vm.border},
        {'focus:shadow-outline-gray': _vm.type !== 'label' && !_vm.disabled && _vm.outline},
        {'cursor-not-allowed': _vm.disabled},                

        {'text-purple-m-main': _vm.type === 'main' && !_vm.disabled && _vm.theme === 'light'},
        {'bg-green-m-main hover:bg-green-m-main-dark focus:bg-green-m-dark': _vm.type === 'main' && !_vm.disabled && _vm.theme === 'light'},
        {'text-white': _vm.type === 'main' && !_vm.disabled && _vm.theme === 'dark'},
        {'bg-purple-m-main hover:opacity-90 focus:opacity-90': _vm.type === 'main' && !_vm.disabled && _vm.theme === 'dark'},
        {'bg-purple-m-secondary hover:bg-purple-m-secondary-light focus:bg-purple-m-main-light': _vm.type === 'main' && !_vm.disabled && _vm.theme === 'dark-mode'},
        {'text-gray-m-dark bg-gray-m': _vm.type === 'main' && _vm.disabled},
        {'bg-tan-m hover:bg-tan-m-hover': _vm.type === 'main' && _vm.theme === 'back'},
        {'bg-red-600 hover:bg-red-800 text-white': _vm.type === 'main' && _vm.theme === 'danger'},

        {'text-purple-m-main bg-white': _vm.type === 'secondary' && !_vm.disabled && !_vm.isWhite},
        {'border-transparent' : _vm.type === 'secondary' && !_vm.disabled && _vm.border && _vm.theme === 'light'},
        {'border-gray-m' : _vm.type === 'secondary' && !_vm.disabled && _vm.border && _vm.theme === 'dark'},
        {'hover:bg-green-m-main-dark focus:bg-green-m-main-dark': _vm.type === 'secondary' && !_vm.disabled && _vm.theme === 'light'},
        {'hover:bg-gray-m-light focus:bg-gray-m-light': _vm.type === 'secondary' && !_vm.disabled && _vm.theme === 'dark'},
        {'text-gray-m border-gray-m': _vm.type === 'secondary' && _vm.disabled},

        {'text-gray-m-dark bg-white': _vm.isWhite},

        {'text-purple-m-main hover:text-purple-m-secondary focus:text-purple-m-secondary': _vm.type === 'label' && !_vm.disabled && _vm.theme === 'light' && _vm.hover && !_vm.icon},
        {'text-purple-m-main': _vm.type === 'label' && !_vm.disabled && _vm.theme === 'light' && _vm.hover && _vm.icon},
        {'text-purple-m-main-light hover:text-purple-m-main focus:text-purple-m-main': _vm.type === 'label' && !_vm.disabled && _vm.theme === 'cancel' && _vm.hover},
        {'text-white hover:text-gray-m-light focus:text-gray-m-light': _vm.type === 'label' && !_vm.disabled && _vm.theme === 'dark' && _vm.hover && !_vm.icon},
        {'text-white': _vm.type === 'label' && !_vm.disabled && _vm.theme === 'dark' && _vm.hover && _vm.icon},

        {'text-purple-m-main': _vm.type === 'label' && !_vm.disabled && _vm.theme === 'light' && !_vm.hover},
        {'text-white': _vm.type === 'label' && !_vm.disabled && _vm.theme === 'dark' && !_vm.hover},
        {'text-gray-m': _vm.type === 'label' && _vm.disabled},
        {'text-purple-m-secondary': _vm.type === 'label' && !_vm.disabled && _vm.theme === 'neutral'},
        {'text-white': _vm.type === 'label' && _vm.theme === 'neutral-dark'}
    ],attrs:{"type":_vm.submit ? 'submit' : 'button'},on:{"mouseenter":function($event){_vm.hovered = true},"mouseleave":function($event){_vm.hovered = false},"click":function($event){$event.preventDefault();return _vm.action()}}},[(_vm.icon && !_vm.img)?_c('base-icon',{class:[
                {'text-green-m-main-dark': _vm.type === 'label' && !_vm.disabled && _vm.theme === 'light' && _vm.hover && _vm.icon && _vm.hovered},
                {'text-green-m-main': _vm.type === 'label' && !_vm.disabled && _vm.theme === 'dark' && _vm.hover && _vm.icon && (_vm.hovered || _vm.active)}
            ],style:({color: _vm.iconColor ? _vm.iconColor : undefined}),attrs:{"name":_vm.icon,"fill":_vm.iconFill,"size":_vm.computedIconSize}}):_vm._e(),(_vm.img)?_c('img',{class:[
                'border border-purple-m-main-light rounded-full',
                {'w-7 h-7': _vm.size === 'sm'},
                {'w-12 h-12': _vm.size !== 'sm'}
            ],attrs:{"src":_vm.img}}):_vm._e(),(_vm.label)?[(_vm.label)?_c('div',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.label)+" "),(_vm.labelCompletion)?_c('small',[_vm._v("("+_vm._s(_vm.labelCompletion)+")")]):_vm._e()]):_vm._e()]:_vm._e(),(_vm.$slots.default)?[_vm._t("default")]:_vm._e(),(_vm.secondaryIcon)?_c('base-icon',{attrs:{"name":_vm.secondaryIcon,"fill":_vm.iconFill,"size":_vm.computedIconSize}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }