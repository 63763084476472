<template>
    <base-modal
        :visible="visible"
        :invisible="modalInvisible"
        title="Sharing Options"
        @close="$emit('close')"
    >
        <FormulateForm v-model="formValues" @submit="submitForm">
            <div class="flex flex-col space-y-6">
                <FormulateInput
                    name="deliverable_id"
                    :options="socialPostOptions"
                    help-position="after"
                    type="radio"
                    v-model.number="formValues.sharing_value"
                />

            </div>

            <div v-if="formValues.sharing_value === 3" class="mt-4">
                <FormulateInput
                    type="password"
                    label="Password"
                    placeholder="Enter password"
                    v-model="password"
                />
            </div>
            <div class="bg-white flex justify-end">
            </div>
            <div class="py-3 sm:flex sm:flex-row-reverse gap-4">
                <base-button submit @action="submitForm" label="Save"/>
                <base-button type="secondary" label="Cancel" border @action="$emit('close')"/>
            </div>
        </FormulateForm>
    </base-modal>
</template>

<script>
export default {
    props: ['type', 'typeId', 'visible'],
    data() {
        return {
            formValues: {},
            password: '',
            loading: false,
            modalInvisible: false
        }
    },

    computed: {
        socialPostOptions() {
            return [
                {
                    value: 1,
                    label: 'Public Access',
                    id: 'public-access',
                    help: 'Anyone with the link can view it'
                },
                {
                    value: 2,
                    label: 'Owners Only',
                    id: 'owners-only',
                    help: 'Only owners of the project have access'
                },
                {
                    value: 3,
                    label: 'Private',
                    id: 'private',
                    help: 'Password protected'
                }
            ]
        },
    },

    methods: {
        async submitForm(){
            let sharing_value = parseInt(this.formValues.sharing_value)
            if (sharing_value) {
                this.loading = true;
                try{
                    if(sharing_value === 1){
                        this.modalInvisible = true
                        const confirmed = await this.$swal.fire({
                            title: 'Are you sure you want to make this file public ?',
                            text: 'Anyone will be able to view it',
                            icon: 'warning',
                            iconColor: '#0E092C',
                            showCancelButton: true,
                            confirmButtonText: 'Make public',
                            reverseButtons: true,
                        }).then((result) => {
                            return result.isConfirmed
                        })
                        this.modalInvisible = false
                        if(!confirmed) return
                    }
                    if (sharing_value === 3) {
                        if (!this.password || this.password === '') {
                            return this.$notify({ title: 'Warning', text: 'Empty password', type: 'warn' })
                        }
                    }
                    const res = await this.$http.put(`/api/files/${this.typeId}`, {
                        type: sharing_value,
                        ...( sharing_value === 3 ? { password: this.password } : {})
                    });
                    console.log('submit form res', res);
                    this.$notify({ title: 'Success', text: 'File permissions saved successfully', type: 'success' })
                    this.$emit('saved', {});
                } catch(err) {
                    console.log('putFile error', err);
                    this.$notify({ title: 'Error', text: `${err.response.data.message}`, type: 'warn' })
                } finally {
                    this.loading = false
                }

            } else {
                this.$notify({ title: 'Warning', text: 'Please, select a sharing option', type: 'warn' })
            }
        }
    }
}
</script>