var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper scroll-dark"},[_c('div',{ref:"scroll_container",attrs:{"id":"scroll-container"}},_vm._l((_vm.projects),function(project,index){return _c('div',{key:index,staticClass:"project"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"name has-tooltip",on:{"click":function($event){return _vm.$router.push(("/projects/" + (project.slug)))}}},[_vm._v(" "+_vm._s(((project.name) + " - " + (project.brand)))+" ")]),(_vm.isAdminOrHigher)?_c('div',{staticClass:"budget"},[_vm._v("Budget: "+_vm._s(_vm._f("numeral")(project.budget,'$0,0.00')))]):_vm._e()]),_c('div',{staticClass:"options-container"},[_c('options-dropdown',{attrs:{"theme":"dark","custom-drop-down":"right--4","right":false,"top":false,"options":(_vm.isAdminOrHigher ? [{
                                label: 'Edit',
                                icon: 'pencil-alt',
                                event:'edit'
                            }] : []).concat( [{
                                label: 'Unpin',
                                icon: 'flagged',
                                event: 'unpin',
                                separator: true
                            }],
                            (_vm.isAdminOrHigher ? [{
                                label: 'Archive',
                                icon: 'trash',
                                theme: 'cancel',
                                event: 'archive'
                            }]: []) )},on:{"edit":function($event){return _vm.$router.push(("/projects/" + (project.slug) + "/edit"))},"unpin":function($event){return _vm.$emit('toggle-pinned', project.id, true)},"archive":function($event){return _vm.$emit('archive-project', project, true)}}})],1)]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"owners-container"},[(!_vm.owners(project.project_owners).length)?_c('div',{staticClass:"empty-state"},[_vm._m(0,true),_c('span',{staticClass:"text"},[_vm._v("Unassigned")])]):_vm._e(),_c('avatar-list',{attrs:{"theme":"dark","users":_vm.owners(project.project_owners),"showAmount":5,"showExtra":true}})],1),_c('div',{staticClass:"status-dropdown container"},[(!_vm.isAdminOrHigher)?_c('div',{staticClass:"text-white w-full"},[_vm._v(_vm._s(project.statusLabel))]):_c('crud-dropdown',{attrs:{"value":{
                            name: project.statusLabel,
                            id: project.status
                        },"create":{
                            route: '/api/statuses',
                            success: 'Status created successfully',
                            error: 'Failed to create new status',
                            warning: 'Status name required'
                        },"select":{
                            route: ("/api/projects/" + (project.id) + "/status"),
                            success: 'Status updated successfully',
                        },"edit":{
                            route: '/api/statuses/',
                            success: 'Status updated successfully',
                            error: 'Failed to edit status',
                            warning: 'Status name required',
                        },"delete":{
                            deleteLabel: 'status',
                            route: '/api/statuses/',
                            success: 'Status deleted successfully',
                            error: 'Failed to delete status'
                        },"statuses":_vm.statuses},on:{"done":function($event){return _vm.$emit('refresh-data')},"toggle-dropdown":_vm.runScrollPinned}})],1)])])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"avatar"},[_c('span',{staticClass:"text"},[_vm._v("UA")])])}]

export { render, staticRenderFns }