<template>
    <div class="fb-business-id-form">
        <div class="field">
            <FormulateInput
                type="text"
                v-model="fbBusinessId"
                :readonly="safeMode"
                label="FB Business ID"
                placeholder="FB Business ID"
                validation-name="FB Business ID"
                class="w-full"
            />

            <base-button
                v-if="safeMode == true"
                label="Change"
                icon="pencil"
                icon-size="4"
                type="label"
                @action="changeFbBusinessIdIntent()"
            />

            <template v-else>
                <base-button
                    @action="submit"
                    submit
                    :disabled="loading || !isValid"
                    bold
                    label="Submit"
                />
                <base-button
                    @action="safeMode = true"
                    size="sm" 
                    type="secondary"
                    theme="dark"                                                          
                    label="Cancel"
                    class="font-bold"                                                     
                />
            </template>
        </div>

    </div>
</template>
<script>
import { validString } from '../../../lib/strings';
import { mapGetters } from 'vuex';
import { notifyCatchError } from '../../../common';
export default {
    data(){
        return {
            fbBusinessId: '',
            loading: false,
            safeMode: true,
            warningModalVisible: false
        }
    },
    mounted(){
        this.fbBusinessId = this.company.fb_business_id;  
    },
    computed: {
        ...mapGetters(['company']),
        isValid(){
            return validString(this.fbBusinessId)
        }
    },
    methods: {
        changeFbBusinessIdIntent(){
            this.$swal.fire({
                title: "Are you sure you want to change the company's FB Business ID ?",
                text: "All of this company's FB access tokens refer to this FB Business, if the access tokens are not part of the FB Business, functionality may be affected.",
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Change ID',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.safeMode = false;
                }
            })
        },
        submit(){
            this.loading = true;
            this.$http.put('/api/companies', {
                fb_business_id: this.fbBusinessId
            }).then((/*{ data }*/) => {
                this.$store.dispatch('company/getCompany');
                this.$notify({ title: 'Success', text: 'Company updated successfully', type: 'success' });
            }).catch(err => {
                notifyCatchError(err, this.$notify, 'There was an error while trying to update the company, try again later.')
            }).finally(() => {
                this.safeMode = true;
                this.loading = false;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .fb-business-id-form{
        @apply px-6 pb-8;
        .field{
            @apply flex gap-x-4 items-center;
            width: 500px;
        }
    }
</style>
