<template>
    <div class="creator-onboarding" v-if="!done && isCreator">

        <Header 
            :step="step"
            @close="close" 
        />
    
        <steps-progress
            :step="step"
        />

        <base-icon v-if="loading" name="loader" class="mx-auto" />

        <div class="content">

            <!-- Select & Connect Networks (Step 1) -->
            <tab :visible="step === 1">
                <Connections
                    v-model="selectedNetworks"
                    :key="`network-select-${networkSelectKey}`"
                    @next="next"
                    @back="back"
                />
            </tab>

            <!-- Additional Information (Step 2) -->
            <tab :visible="step === 2">
                <AdditionalInfo
                    :bio="bio"
                    :birthdate="birthdate"
                    @update:bio="val => bio = val"
                    @update:birthdate="val => birthdate = val"
                    @next="next"
                    @back="back" 
                />
            </tab>

            <!-- Gender (Step 3) -->
            <tab :visible="step === 3">
                <Gender
                    v-model="gender"
                    @next="next"
                    @back="back" 
                />
            </tab>

            <!-- Brands (Step 4) -->
            <tab :visible="step === 4">
                <Brands
                    v-model="brands"
                    @next="next"
                    @back="back" 
                />
            </tab>

            <!-- Interests (Step n) Hidden for now -->
            <!-- <tab :visible="step === n">
                <Interests
                    v-model="interests"
                    @next="next"
                    @back="back" 
                />
            </tab> -->

        </div>

    </div> 
</template>

<script>
import { mapGetters } from 'vuex';
import Header from './Header.vue';
import StepsProgress from './StepsProgress.vue'
import Connections from './Connections.vue';
import AdditionalInfo from './AdditionalInfo.vue';
import Gender from './Gender.vue';
// import Interests from './Interests.vue';
import Brands from './Brands.vue';
import { validString } from '../../lib/strings';
import { notifyCatchError, isValidDate, isValidObject, isValidArray, isNotNull } from '../../common';
export default {
    components: {
        Header,
        StepsProgress,
        Connections,
        AdditionalInfo,
        Gender,
        // Interests,
        Brands
    },
    data(){
        return {
            step: 1,
            done: false,
            
            selectedNetworks: [],
            bio: '',
            birthdate: '',
            gender: null,
            // interests: [],
            brands: [],

            loading: false,
            networkSelectKey: false
        }
    },

    async beforeMount(){
        const onboardingDone = localStorage.getItem('creatorOnboardingDone');
        // if the onboardingDone value on local storage is set to true, then we wont show the onboarding 
        if(onboardingDone){
            this.done = true;
        }else{
            // if its not set then we will check the profile completion, so we dispatch
            // the action to make sure that the value is ready 
            await this.$store.dispatch('auth/getProfileCompletion');
            // check that the profile completion object and errors within it are set,
            // if they are not, the onboarding WILL be shown
            if(isValidObject(this.profileCompletion)){
                if(isValidArray(this.profileCompletion.errors)){
                    // if any of these fields are NOT found, then that means that the value
                    // is set and that the creator HAS gone through the onboarding, so we 
                    // wont show the onboarding
                    const fields = ['bio', 'birthdate', 'gender'];
                    let done = false;
                    for (const key of fields) {
                        const found = this.profileCompletion.errors.find(element => {
                            return element.field == key;
                        });
                        if(!found){
                            done = true;
                        }
                    }
                    this.done = done;
                }else{
                    this.done = true;
                }
            }
        }
    },

    watch: {
        selectedNetworks(){
            if(this.step == 2){
                this.networkSelectKey = !this.networkSelectKey
            }
        }
    },

    computed: {
        ...mapGetters(['isCreator', 'profileCompletion'])
    },

    methods: {
        back(){
            this.step-- 
        },
        next(){
            if(this.step == 4){
                return this.close();
            }
            this.step++;
        },
        close(){
            // submit everything
            this.loading = true
            const { bio, birthdate, gender, brands } = this;
            this.$http.put('/api/me', {
                ...( validString(bio) ? { bio } : { } ),
                ...( isValidDate(birthdate, false) ? { birthdate } : { } ),
                ...( isNotNull(gender) ? { gender } : { } ),
                ...( brands.length ? { brands } : { } )
            }).then((/*{data}*/) => {
                this.$notify({ title: 'Success', text: 'User updated successfully', type: 'success' })
                this.$store.dispatch('auth/getProfileCompletion');
            }).catch(err => {
                notifyCatchError(err, this.$notify, 'An error ocurred, try again later.')
            }).finally(() => {
                this.loading = false
                localStorage.setItem('creatorOnboardingDone', true)
                this.done = true
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .creator-onboarding{
        @apply fixed z-10 inset-0 bg-white overflow-y-auto;
        @apply flex flex-col;
        .content{
            @apply flex-grow flex-shrink px-12 pb-12 py-4;
        }
    }
</style>
