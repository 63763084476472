<template>
    <base-modal 
        title="Edit Review"
        :visible="visible"
        @close="onClose()"
        no-padding
    >

        <div v-if="rating" class="content">
            
            <avatar-list :users="[rating.user]" size="medium" class="font-bold"/>
   
            <div class="w-full flex justify-between">

                <div class="field">
                    <div class="label">Rate this creator</div>
                    <star-rating
                        v-model="value" 
                        :increment="0.5"
                        :star-size="30"
                        clearable
                        class="cursor-pointer"
                        text-class="font-bold"
                    />
                </div>

                <div class="project-select-field">
                    <base-icon v-if="project != null" name="close" @action="project = null" class="cursor-pointer" />
                    <div class="project-select-wrapper">
                        <custom-select
                            :key="projectSelectKey"
                            placeholder="Select a project"
                            :options="computedProjects"
                            v-model="project"
                            customSearch
                            @search="val => projectSearchTerm = val"
                        />
                    </div>
                </div>

            </div>

                <FormulateInput
                    type="textarea"
                    class="w-full"
                    :disabled="loading"
                    placeholder="Write a review..."
                    input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent"
                    v-model="comment"
                    :rows="3"
                />

           
        </div>

        <div class="footer">
            <base-button 
                @action="onClose()" 
                :disabled="loading" 
                type="secondary" 
                size="md" 
                bold 
                label="Cancel"
            />
            <base-button 
                @action="submit()" 
                :disabled="loading" 
                size="lg" 
                label="Create Review"
                secondaryIcon="pencil"
                bold
            />
        </div>
    </base-modal>
</template>

<script>
import StarRating from 'vue-star-rating';
import AvatarList from '@/components/AvatarList.vue';
import { notifyCatchError } from '@/common';
export default {
    name: 'EditUserRatingModal',
    props: {
        visible: {
            type: Boolean, 
            default: false
        },
        rating: {
            type: Object
        },
        user: {
            type: Object,
            required: true
        }
    },
    components: {
        StarRating,
        AvatarList
    },
    data() {
        return {
            value: 0,
            comment: '',

            project: null,
            projectSearchTerm: '',
            projectSelectKey: false,
            
            loading: false
        }
    },
    watch: {
        visible(val){
            if(val){
                this.setData()
            }
        },  
        project(){
            this.projectSelectKey = !this.projectSelectKey
        }
    },
    computed: {
        computedProjects(){
            if( !this.user || !this.user.user_projects || !Array.isArray(this.user.user_projects) ) return [];
            return this.user.user_projects.filter(element => {
                // make sure that the project, its name and its id exist
                return (element.project && element.project.name && element.project.id)
            }).map(element => {
                // map for custom select
                return { label: element.project.name, value: element.project.id }
            }).filter(element => {
                // todo filter by search term
                const label = element.label.toLowerCase()
                const term = this.projectSearchTerm.toLowerCase()
                return label.includes(term)
            });  
        }  
    },
    methods: {
        setData(){
            this.value = this.rating.value
            this.comment = this.rating.comment
            this.project = this.computedProjects.find(element => element.value == this.rating.project_id)
        },
        submit() {
            this.loading = true;
            this.$http.put(`/api/user-ratings/${this.rating.id}`, {
                value: this.value,
                comment: this.comment,
                project_id: this.project ? this.project.value : null
            }).then((/*{ data }*/) => {
                this.$notify({ title: 'Success', text: 'Review updated successfully', type: 'success' });
                this.$emit('reload');
                this.onClose();
            }).catch(err => {
                notifyCatchError(err, this.$notify, 'There was an error while trying to update the review, try again later.')
            }).finally(() => {
                this.loading = false;
            });
        },
        onClose(){
            this.value = 0
            this.comment = ''
            this.$emit('close')
        }
    }
}
</script>
<style lang="scss" scoped>
    .content{
        @apply px-7 pt-0 pb-10;
        @apply flex flex-col gap-y-4;
        .field{
            @apply flex flex-col gap-y-1 mb-3;
            >.textarea-wrapper{
                @apply relative;
                .char-limit{
                    @apply absolute bottom-3 right-5 flex justify-end;
                    .text{
                        @apply text-pxs text-gray-m;
                    }
                }
            }
        }
        .project-select-field{
            @apply flex justify-end items-center gap-x-4 mb-3;
            .project-select-wrapper{
                width: 300px;
            }
        }
    }
    .footer{
        @apply py-7 px-7;
        @apply flex justify-between;
    }
</style>
