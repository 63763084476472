import axios from 'axios';
import { facebookLogin } from '../lib/facebook'

export const fb = {
    namespaced: true,
    state: {
        status: [], // refers to the current connection to Facebook
        initialized: false, // refers to the current connection to Facebook
        login: {}, // checks db for social login records and validates the tokens
        loading: false,
        sdkError: false
    },
    mutations: {
        setStatus(state, val) {
            state.status = val;
        },
        setInitialized(state, val){
            state.initialized = val
        },
        setLogin(state, val){
            state.login = val
        },
        setLoading(state, val){
            state.loading = val
        },
        setSdkError(state, val){
            state.sdkError = val
        }
    },
    actions: {
        async setStatus({commit}, status){
            await commit('setStatus', status)
        },
        async setInitialized({commit}, value){
            await commit('setInitialized', value)
        },
        async setSdkError({commit}, value){
            await commit('setSdkError', value)
        },
        async getLoginStatus({commit} /*, value*/){
            await commit('setLoading', true)
            const { data } = await axios.get('/api/me/fb?validation=true&ig_account=true');
            if(data){
                const { is_valid, ig_account } = data
                data.error = !is_valid || !ig_account
                data.message = !is_valid ? 'You are not logged in via Facebook' : !ig_account ? 'No Instagram account connected' : ''
                data.action = !is_valid ? `In order to perform this action, you need to link your Facebook account` : !ig_account ? `Your connected Facebook account must be linked to an instagram business account in order to perform this action` : ''
                data.computedMessage = (action) => !is_valid ? `In order to ${action}, you need to link your Facebook account` : !ig_account ? `Your connected Facebook account must be linked to an instagram business account in order to ${action}` : ''
                await commit('setLogin', data)
            }else{
                await commit('setLogin', {
                    error: true,
                    message: 'You are not logged in via Facebook',
                    action: 'In order to perform this action, you need to link your Facebook account',
                    computedMessage: (action) => `In order to ${action}, you need to link your Facebook account`
                })
            }
            await commit('setLoading', false)
        },
        async simpleLogin(context, notify){

            await facebookLogin().then(async res => {
                const { userId, id, email, longLivedAccessToken } = res
                const identifier = userId || id
                await axios.post(`/api/auth/register/link-account`, {
                    network_id: 6, // fb
                    profile_id: identifier,
                    email,
                    access_token: longLivedAccessToken,
                }).then((/*response*/) => {
                    notify({ title: 'Success', text: `Account Linked Successfully`, type: 'success' });
                    context.dispatch("getLoginStatus");
                }).catch((err) => {
                    notify({ title: 'Error', text: `${(err.response && err.response.data) ? err.response.data.message : ``}`, type: 'error' });
                    console.log('link account error', err)
                })
       
            }).catch(err => {
                console.log('Facebook login error', err)
            })

        },
        async generateAudienceData(context, userId){
            await axios.post('/users/generate-audience-data', {
                userId,
                force_update: true
            }).then(({data}) => {
                if(data){
                    console.log('Audience data generated', data)
                }
            }).catch(err => {
                console.log('generateAudienceData error', err)
            });
        }
    }
};