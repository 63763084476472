<template>        
    <div class="container">
        
        <!-- tags section -->
        <div class="section">
            <div class="header">
                <div class="title">Tags</div>
            </div>
            <div class="content">            
                <custom-pills
                    label="Tags"
                    placeholder="add tags"
                    :options="tags"
                    allow-create
                    create-label="Create new tag"
                    model-name="Tag"
                    :create-route="`/api/tags`"
                    :multiple="true"
                    size="auto"
                    v-model="selectedTags"
                    preventMountedEmit
                    :key="key"
                    @finish-create="getTags"
                />
                <base-icon v-if="loading" name="loader" />
                <base-button 
                    class="font-bold"
                    size="md"
                    :iconSize="4"
                    theme="dark"
                    type="secondary"
                    @action="submitTags()" 
                    :disabled="loading" 
                    submit 
                    label="Save"
                />       
            </div>
        </div>

        <!-- social accounts section -->
        <div class="section">
            
            <div class="header">
                <div class="title">Social Accounts</div>
                <base-button 
                    label="Add Social Account" 
                    type="label" 
                    icon="plus-circle" 
                    @action="addSocialAccountModalVisible = true" 
                />
            </div>

            <div class="content">
                <!-- empty state -->
                <div 
                    class="empty-state"
                    v-if="!isValidArray(user.social_accounts)"
                >
                    There are no social accounts
                </div>

                <!-- list -->
                <div class="list">
                    <div 
                        class="item"
                        v-for="(social_account, index) in user.social_accounts" 
                        :key="index"
                    >
                        <div class="box">
                            <div class="left">
                                <avatar :user="social_account"/>
                                <div>{{social_account.handle}}</div>
                            </div>
                            <base-icon :name="social_account.network.name" />
                        </div>
                        <div
                            v-if="social_account.network_id == 2 && !!sentRequest(social_account.handle)" 
                            class="ig-request-container"
                        >
                            <div class="text">
                                You have a request to connect this instagram account {{ getPermittedTasks(sentRequest(social_account.handle)) }}
                            </div>
                            <base-button
                                label="View request"
                                size="md"
                                class="h-14"
                                @action="requestRedirect(sentRequest(social_account.handle))"
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <!-- modals section -->
        <add-social-account-modal
            :visible="addSocialAccountModalVisible"
            :user-id="(isValidObject(user) && user.id) ? user.id : null"
            :social-accounts="(isValidObject(user) && isValidArray(user.social_accounts)) ? user.social_accounts : []"
            @close="addSocialAccountModalVisible = false"
            @refresh="$emit('refresh')"
        />

    </div>
</template>
<script>
    import { getObjectValue, isValidArray, isValidObject, notifyCatchError } from '../../common';
    import AddSocialAccountModal from './AddSocialAccountModal';
    export default {
        props: ['user'],
        components: {
            AddSocialAccountModal
        },
        data(){
            return {
                tags: [],
                selectedTags: [],
                igConnectionRequests: [],
                loading: false,
                key: false,
                addSocialAccountModalVisible: false,

                isValidArray,
                isValidObject
            }
        },
        mounted(){
            this.setData();
        },
        methods: {
            async setData() {
                await this.getTags();
                this.setSelectedTags();
                this.getIgConnectionRequests();
            },
            async getTags() {
                const { data } = await this.$http.get('/api/tags')
                this.tags = data.map(tag => {
                    return {
                        value: tag.id,
                        label: tag.name
                    }
                });
            },
            setSelectedTags() {
                this.selectedTags = this.user.user_tags.map(user_tag => {
                    return {
                        value: user_tag.tag.id,
                        label: user_tag.tag.name
                    }
                });
                this.key = !this.key
            },
            submitTags(){
                this.loading = true
                this.$http.put('/api/me', {
                    tags: this.selectedTags.map(tag => tag.value)
                }).then(() => {
                    this.$notify({ title: 'Success', text: 'Tags saved successfully', type: 'success' })
                }).catch(err => {
                    notifyCatchError(err, this.$notify, 'The tags could not be saved, try again later.');
                }).finally(() => {
                    this.loading = false
                })
            },
            getIgConnectionRequests(){
                this.loading = true
                this.$http.get('/api/ig-connection-request').then(({ data }) => {
                    if(data){
                        this.igConnectionRequests = data
                    }
                }).catch(err => {
                    notifyCatchError(err, this.$notify);
                }).finally(() => {
                    this.loading = false
                })
            },
            sentRequest(handle){
                const found = this.igConnectionRequests.find(element => element.data?.instagram_account == handle)
                return found
            },
            requestRedirect(request){
                window.open(`${window.location.origin}/ig-connection-wizard?t=${request.token}`, '_blank')
            },
            getPermittedTasks(request){
                const val = getObjectValue(request, 'data.permitted_tasks')
                const valid = val && Array.isArray(val) && val.length
                return valid ? `(${val.join(', ')})` : ''
            },
        }
    }
</script>

<style lang="scss" scoped>
    .container{
        @apply px-12 py-10 text-purple-m-main;
        > .section{
            //
            > .header{
                @apply flex gap-x-6;
                > .title{
                    @apply text-h3 font-bold;
                }
            }
            > .content{
                @apply pt-6 pb-12 w-1/2 flex flex-col gap-y-4;
                > .empty-state{
                    @apply text-h5;
                }
                > .list{
                    @apply pt-6 pb-12 flex flex-col gap-y-4;
                    > .item{
                        @apply flex gap-x-4 items-center;
                        > .box{
                            @apply border border-black rounded-md px-4 py-2 flex justify-between items-center;
                            width: 400px;
                            > .left{
                                @apply flex items-center gap-x-4;
                            }
                        }
                        > .ig-request-container{
                            @apply flex gap-x-4 items-center;
                            > .text{
                                @apply text-center w-60;
                            }
                        }
                    }
                }
            }
        }
    }
</style>