<template>
    <base-modal 
        title="Request handle change"
        :visible="visible"
        noPadding
        @close="$emit('close')"
    >
        <div class="request-handle-change-modal-container">
            <div class="content">
                <div class="title">Notify the admin to update your handle if {{isValidString(handle) ? ` ${handle}` : `the handle`}} is not matching your social handle.</div>
                <div class="title">An email and an app notification will be sent to the admin.</div>
                <FormulateInput
                    type="text"
                    label="Current handle"
                    placeholder="Current handle"
                    class="mt-6"
                    :value="handle"
                    :disabled="true"
                />
                <div class="user" v-if="isValidObject(user)">
                    <avatar :user="user" />
                    <div class="names">
                        <div class="name">
                            {{ user.first_name }} {{ user.last_name }}
                        </div>
                        <div class="email">
                            {{user.email}}
                        </div>
                    </div>
                </div>
                <FormulateInput
                    type="textarea"
                    class="mt-4 w-full"
                    placeholder="Write email message..."
                    v-model="message"
                    input-class="w-full focus:outline-none text-pm text-purple-m-secondary resize-none bg-transparent h-24"
                    :rows="6"
                />
                
                <!-- message -->
                <div v-if="isValidString(error)" class="error">{{ error }}</div>
            </div>

            <div class="footer">
                <base-button
                    label="Close"
                    type="secondary"
                    :disabled="loading"
                    @action="$emit('close')"
                />
                <base-button
                    label="Send"
                    bold
                    :disabled="loading"
                    @action="submit()"
                />
            </div>

        </div>
    </base-modal>
</template>
<script>
import { isValidObject, isValidString } from '../../common';
export default {
    props: ['visible', 'handle'],
    data() {
        return {
            invitation: null,
            message: '',
            error: '',
            
            loading: false,
            
            isValidString,
            isValidObject
        }
    },
    mounted(){
        this.getInvitationIntent();
    },
    watch: {
        visible(val, old){
            if(val == true && val !== old){
                this.getInvitationIntent();
            }
        }
    },
    computed:{
        // "invited by" user
        user(){
            if(isValidObject(this.invitation) && isValidObject(this.invitation.user)){
                return this.invitation.user;
            }else{
                return null;
            }
        }
    }, 
    methods: {
        getInvitationIntent(){
            if(!this.invitation){
                let token = this.$route.query.t;
                if(token){
                    this.getInvitation();
                }else{
                    this.error = 'Unable to get invitation, token not found.'
                }
            }
        },
        getInvitation() {
            this.loading = true;
            this.$http.get(`/api/invite/${this.$route.query.t}`).then(({ data }) => {
                this.invitation = data;
            }).catch(err => {
                const msg = err && err.response && err.response.data && err.response.data.message ? err.response.data.message : null; 
                this.error = msg || 'An unknown error occurred. Please try again.';
            }).finally(() => {
                this.loading = false;
            });
        },
        submit(){
            this.loading = true;
            this.$http.post(`/api/invite/${this.$route.query.t}/request-handle-change`, { 
                message: this.message
            }).then((/*{data}*/) => {
                this.$notify({ title: 'Success', text: 'Change handle request sent to admin!', type: 'success' });
                this.$emit('close');
            }).catch(err => {
                console.log(err);
                this.$notify({ 
                    title: 'An error ocurred while trying to send notifications.', 
                    text: `Try again later`, 
                    type: 'warn', 
                    duration: 7500 
                });
            }).finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    .request-handle-change-modal-container{
        > .content{
            @apply flex flex-col pt-2 px-7;
            > .title{
                @apply mb-1;
            }
            > .user{
                @apply mt-4 flex items-center gap-x-4;
                > .names{
                    @apply flex flex-col gap-y-1;
                    > .name{
                        @apply text-h5 font-bold;
                    }
                    > .email{
                        @apply text-pxs;
                    }
                    > .found-by{
                        @apply text-pxs;   
                    }
                    > .handle-list{
                        @apply flex flex-col;
                        > .handle-item{
                            @apply flex items-center gap-x-2;
                            @apply text-pxs;
                        }
                    }
                }
            }
            > .error{
                @apply text-pxs text-red-500;
            }
            > .message{
                @apply text-pxs;
            }
        }
        .footer{
            @apply w-full;
            @apply pt-10 pb-6 px-6;
            @apply flex justify-between;
        }
    }
</style>