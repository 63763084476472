<template>
    <div class="step-1-upload-file">

        <!-- instructions -->
        <div class="instructions">
            <p class="title">Import your file in <span>XLS, XLSX or CSV</span>.</p>
            <div class="description">
                <p>Your file must have at least the following columns: <span>"First name", "Last name", "Email", "Handle"</span>.</p>
                <p>Optional columns: <span>"Description", "Posts", "Additional information"</span>.</p>
            </div>
        </div>
        
        <!-- download template example section -->
        <template-example-download />

        <!-- upload button, only if there is no file set -->
        <base-button
            v-if="!file"
            @action="$refs.file.click()" 
            class="font-bold mt-8" 
            type="secondary" 
            theme="dark" 
            size="lg" 
            label="Upload file" 
            secondary-icon="submit" 
            :iconSize="5"
        />
        <!-- invisible input -->
        <form id="file-form">
            <input 
                ref="file"
                type="file"
                class="invisible"   
                accept=".xlsx, .xls, .csv" 
                @change="onFileChange"
            >
        </form>
        
        <!-- show uploaded file -->
        <div class="uploaded-file" v-if="file && file.name">
            <!-- icon -->
            <div class="left">
                <base-icon v-if="loading" name="loader" :size="5" />
                <base-icon v-else class="text-purple-m-main" name="document" :size="5" />
            </div>
            <!-- right -->
            <div class="right">
                <div class="info">
                    <div class="name">{{file.name}}</div>
                    <div class="size" v-if="file.size">{{formatBytes(file.size)}}</div>
                    <div class="sheets" v-if="selectedSheets && selectedSheets.length"><b>{{selectedSheets.length}}</b> sheet{{selectedSheets.length == 1 ? '' : 's'}} selected</div>
                    <div class="rows" v-if="rowsCount > 0"><b>{{rowsCount}}</b> row{{rowsCount == 1 ? '' : 's'}}</div>
                </div>
                <base-button @action="removeFile()" icon="x" :icon-size="4" type="label" />
            </div>
        </div>

        <select-excel-sheets-modal 
            :visible="selectExcelSheetsModalVisible"
            :file="file"
            @done="(selectedSheets, path) => getFileData(selectedSheets, path)"
            @close="selectExcelSheetsModalVisible = false"
        />

    </div>
</template>
<script>
import TemplateExampleDownload from './TemplateExampleDownload.vue'
import formatBytes, { getFileExtension } from '../../lib/files';
import SelectExcelSheetsModal from './SelectExcelSheetsModal.vue';
import { isArray, isValidArray, isValidString, notifyCatchError } from '../../common';
import { fields } from './fields';
const requiredColumns = fields.filter(element => element.required == true);
export default {
    components: {
        TemplateExampleDownload,
        SelectExcelSheetsModal
    },
    data(){
        return {
            file: null,
            selectedSheets: [],
            rowsCount: 0,

            loading: false,
            selectExcelSheetsModalVisible: false,
            formatBytes
        }
    },
    computed: {
        // clean file name
        fileName() {
            if(this.file && isValidString(this.file.name)){
                let name = this.file.name;
                name = name.split('.')[0];
                name = name.replaceAll('-', ' ');
                name = name.replaceAll('_', ' ');
                if(isValidString(name)){
                    return name;
                }   
            }
            return ``;
        }
    },
    methods: {

        onFileChange(e) {
            this.file = e.target.files[0];
            const extension = getFileExtension(this.file);
            // for xlsx we will open the sheet selector first
            if(['.xls', '.xlsx'].includes(extension) ){
                this.selectExcelSheetsModalVisible = true;
            }
            // for csvs we will upload the file directly
            if(extension == '.csv'){
                this.getFileData();
            }
        },

        getFileData(selectedSheets = null, path = null){
                
            // this.totalCount = 0;

            let fileData = new FormData()
            fileData.append('file', this.file);

            // build the sheets query string if selectedSheets exist
            let sheetsQueryString = null;  
            if(isArray(selectedSheets)){
                this.selectedSheets = selectedSheets;
                for (const [index, sheet] of selectedSheets.entries()) {
                    sheetsQueryString += `&sheets[${index}]=${encodeURIComponent(sheet)}`;
                }
            }
            
            this.loading = true;
            this.$http.post(`/api/upload-file/import/file?path=${path}${sheetsQueryString}`, fileData).then(async ({ data }) => {
                if (data.success && data.rows.length) {
                    const creators = data.rows;
                    this.rowsCount = creators.length;
                    const missingData = this.getMissingData(creators);
                    if(isValidArray(missingData)){
                        this.showMissingDataSwal(missingData);
                    }else{
                        this.$emit('set-report-name', this.fileName) 
                        this.$emit('set-file-creators', creators);
                    }
                } else {
                    this.$notify({ title: 'Something went wrong while getting data from file.', type: 'error' });
                    this.removeFile();
                }
            }).catch((err) => {
                console.log('getFileData error', err);
                notifyCatchError(err, this.$notify, 'Something went wrong while getting data from file.');
                this.removeFile();
            }).finally(() => {
                this.loading = false;
            });
        },

        getMissingData(creators){
            
            // check if there is missing data for required columns 
            let missingData = [];

            function exists(requiredColumn){
                const found = missingData.find(element => element.slug == requiredColumn.slug);
                if(found){
                    return true;
                }else{
                    return false;
                }
            }
            
            function add(requiredColumn){
                if(exists(requiredColumn)){
                    const index = missingData.findIndex(element => element.slug == requiredColumn.slug);
                    missingData[index].count++
                }else{
                    missingData.push({ ...requiredColumn, count: 1 });
                }
            }

            for (const requiredColumn of requiredColumns) {
                for (const creator of creators) {
                    const hasSlug = creator[requiredColumn.slug];
                    let hasVariant = false;
                    for (let variant of requiredColumn.variants) {
                        variant = variant.toLowerCase().replaceAll(' ', '_');
                        if(creator[variant]){
                            hasVariant = true;
                        }
                    }
                    if(!hasSlug && !hasVariant){
                        add(requiredColumn);
                    }
                }
            }

            return missingData;

        },

        showMissingDataSwal(missingData){

            let html = `<div>These are the required columns: `;
            
            for (const [index, requiredColumn] of requiredColumns.entries()) {
                const isLastItem = index + 1 == length;
                html += `<b>${requiredColumn.label}</b>${isLastItem ? `.` : `, `}`
            }

            html += `</div></br>`

            // description
            html += `<div>These are the missing values:</div>`
            
            // start the list
            html += `<ul class='mt-6 flex flex-col gap-y-4 list-disc text-left'>`;
            
            // add list items
            for (const _missingData of missingData) {
                html += `<li><b>${_missingData.count}</b> row${_missingData.count == 1 ? `` : `s`} ${_missingData.count == 1 ? `does not` : `don't`} contain the "<b>${_missingData.label}</b>" value.</li>`
            }

            // close the list
            html += `</ul>`;

            this.$swal.fire({
                title: 'There is missing data in this file',
                html,
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Continue',
                reverseButtons: true,
            }).then(() => {
                // this.cleanForm()
            });

        },

        removeFile(){
            this.file = null;
            this.selectedSheets = [];
            let form = document.getElementById('file-form');
            if (form) {
                form.reset();
            }
            // reset parent's "fileCreators" and report "name"
            this.$emit('set-file-creators', []);
            this.$emit('set-report-name', '');
        },

        // we'll have to see what we want to do with this
        getColumns(creators){
            const firstCreator = creators[0]; 
            // get all the columns/properties that exist in the .xlsx
            let properties = [];
            for (let property in firstCreator) {
                properties.push({value: property, label: property});
            }
            return properties;
        }
    }
}
</script>
<style lang="scss" scoped>
    .step-1-upload-file{
        > .instructions{
            @apply flex flex-col gap-y-4 mb-4;
            > .title{
                > span{
                    @apply font-bold;
                }
            }
            > .description{
                > p{
                    > span{
                        @apply font-bold;
                    }
                }
            }
        }
        > .uploaded-file{
            @apply flex items-center gap-x-4 border border-gray-300 rounded-lg py-3 px-4;
            width: 400px;
            > .left{
                @apply flex-grow-0 flex-shrink-0;
                @apply flex justify-center items-center;
            }
            > .right{
                @apply flex-grow flex-shrink;
                @apply flex justify-between items-center;
                > .info{
                    @apply flex flex-col;
                    > .name{
                        @apply font-bold;
                    }
                    > .size{
                        @apply text-pxs;
                    }
                    > .sheets{
                        @apply text-pxs;
                        margin-top: 2px;
                    }
                    > .rows{
                        @apply text-pxs;
                        margin-top: 2px;
                    }
                }
            }
        }
    }
</style>