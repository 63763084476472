<template>
    <div class="network-selector">
        <div class="content">

            <!-- connection status -->
            <div
                class="connection-status"
                v-if="connectionStatus"
                v-tooltip="{ 
                    content: connectionStatus.connected_message ? connectionStatus.connected_message : `Not connected${!readonly ? `, click to invite` : ``}`, 
                    classes: 'v-tooltip-black', 
                    delay: { show: 100, hide: 50 } 
                }"
                :class="{
                    'readonly': readonly,
                    'connected': connectionStatus.is_connected, 
                    'not-connected': !connectionStatus.is_connected
                }" 
            >   
                <div 
                    class="invite-button" 
                    @click.stop="() => sendInviteModalVisible = true"
                >
                    Invite
                    <base-icon name="mail" :size="3" />
                </div>
                <base-icon 
                    class="exclamation-icon" 
                    :name="connectionStatus.is_connected ? 'check' : 'exclamation'" 
                    :size="3" 
                />
            </div>

            <div 
                :class="['edit-button-container', { 'readonly': readonly }]" 
                v-if="!readonly"
                @click="readonly ? null : toggleDropdown()"
            >
                <base-icon
                    name="pencil"
                    :size="3"
                    @action="()=>null"
                />
            </div>
            <div
                :class="['icon-container', { 'readonly': readonly }]" 
                v-if="isValidObject(value)"
                @click="readonly ? null : toggleDropdown()"
            >
                <base-icon
                    :name="value.name"
                />
            </div>
            <!-- there should always be a target network so in theory this should not be showing -->
            <div v-else>No network</div>

            <!-- dropdown -->
            <div class="dropdown" v-if="dropdownVisible">
                <div 
                    :class="['item', { 'selected': isSelected(network) }]" 
                    v-for="(network, index) in networkOptions" 
                    :key="index"
                    @click="onItemClick(network)"
                >
                    <base-icon :name="network.name" /> 
                    <div class="name">{{ network.display_name }}</div>
                </div>
            </div>

            <send-invite-modal
                v-if="user && value"
                @close="sendInviteModalVisible = false"
                :visible="sendInviteModalVisible"
                :network-id="value.id == 2 ? 6 : value.id"
                :user="user"
            />

        </div>
    </div>
</template>
<script>
import { isValidObject } from '../common'
import SendInviteModal from './SendInviteModal.vue'
const networkOptions = [
    {
        "id": 2,
        "name": "instagram",
        "display_name": "Instagram",
        "network_id": 2
    },
    {
        "id": 14,
        "name": "tiktok",
        "display_name": "TikTok",
        "network_id": 14
    }
]
export default {
    components: { 
        SendInviteModal 
    },
    props: {
        value: {
            type: Object,
            required: true
        },
        socialAccounts: {
            type: Array,
            required: false
        },
        user: {
            type: Object,
            required: false
        },
        readonly: {
            type: Boolean,
            required: true
        }
    },
    data(){
        return {
            dropdownVisible: false,
            sendInviteModalVisible: false,
            networkOptions,
            isValidObject
        }
    },
    computed: {
        connectionStatus(){
            // find the user's social account for this target network
            const found = this.socialAccounts.find(element => element.network_id == this.value.id);
            if(found){
                const { is_connected, connected_by_fb_business_manager, connected_message } = found;
                return {
                    is_connected,
                    connected_by_fb_business_manager,
                    connected_message
                }
            }
            return null;
        }
    },
    methods: {
        onItemClick(network){
            if(!this.isSelected(network)){
                this.$emit('update:value', network);
            }
            this.dropdownVisible = false;
        },
        isSelected(network){
            return this.value.id == network.id 
        },
        toggleDropdown(){
            this.dropdownVisible = !this.dropdownVisible;
        }
    }
}
</script>
<style lang="scss" scoped>
    .network-selector{
        @apply flex justify-center items-center;
        .content{
            @apply relative;
            > .edit-button-container{
                @apply absolute;
                top: -15px;
                right: -15px;
                &:not(.readonly){
                    @apply cursor-pointer transition-opacity duration-200;
                    &:hover{
                        @apply opacity-60;
                    }
                }
            }
            > .icon-container{
                &:not(.readonly){
                    @apply cursor-pointer transition-opacity duration-200;
                    &:hover{
                        @apply opacity-60;
                    }
                }
            }
            > .dropdown{
                @apply absolute z-50 top-5 right-1 bg-white rounded-lg border border-gray-500 overflow-hidden;
                > .item{
                    @apply px-4 py-3 flex gap-x-2 cursor-pointer transition-colors duration-200;
                    &.selected{
                        @apply font-bold;
                    }
                    &:hover{
                        @apply bg-gray-300;
                    }
                }
            }
            .connection-status{
                @apply absolute z-40 w-4 h-4 rounded-full flex justify-center items-center cursor-pointer;
                bottom: -13px;
                right: -17px;
                @apply transition-all duration-200;
                &.connected{
                    @apply bg-green-500
                }
                &.not-connected{
                    @apply bg-yellow-300;
                    &:not(.readonly){
                        &:hover{
                            width: 60px;
                            filter: brightness(0.8);
                            .invite-button{
                                @apply w-full h-full flex justify-center items-center gap-x-1 text-pxs font-bold;
                            }
                            .exclamation-icon{
                                @apply hidden;
                            }
                        }
                    }
                }
                .exclamation-icon{
                    @apply flex;
                }
                .invite-button{
                    @apply hidden;
                }
            }
        }
    }
</style>
