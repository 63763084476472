import Vue from "vue";
import Vuex from "vuex";
// import VuexPersist from "vuex-persist";
import { auth } from "./auth.store";
import { loader } from "./loader.store";
import { restorer } from "./restorer.store";
import { boards } from "./boards.store";
import { statuses } from "./statuses.store";
import { colors } from "./colors.store";
import { prevent } from "./prevent.store";
import { company } from "./company.store";
import { notifications } from "./notifications.store";
import { fb } from "./fb.store";

Vue.use(Vuex);

// [deprecated]
// not using this right now
// const vueLocalStorage = new VuexPersist({
//     key: "STORE",
//     storage: window.sessionStorage,
//     reducer: (state) => ({
//         auth: {
//             me: state.auth.me,
//         },
//         company: state.company,
//         statuses: state.statuses,
//         colors: state.colors,
//         boards: state.boards,
//     }),
// });

export default new Vuex.Store({
    // plugins: [vueLocalStorage.plugin],
    modules: {
        auth,
        loader,
        restorer,
        boards,
        statuses,
        colors,
        prevent,
        company,
        notifications,
        fb,
    },
    state: {
        dialog: {
            title: "",
            description: "",
            show: false,
            action: null,
        },
    },
    mutations: {
        showDialog(state, { title, description, action = null }) {
            state.dialog.show = true;
            state.dialog.title = title;
            state.dialog.description = description;
            state.dialog.action = action;
        },
        hideDialog(state) {
            state.dialog.show = false;
        },
    },
    getters: {
        isLoggedIn() {
            return !!auth.state.token;
        },

        user() {
            return auth.state.user;
        },

        loader() {
            return loader.state.visible;
        },

        restorer() {
            return restorer.state;
        },

        me() {
            return auth.state.me;
        },

        prevent() {
            return prevent.state;
        },

        driveAuth() {
            return auth.state.drive;
        },

        googleAuth() {
            return auth.state.google;
        },

        tiktokAuth() {
            return auth.state.tiktok;
        },

        isCollaborator() {
            return auth.state.user.roles ? auth.state.user.roles.includes("collaborator") || auth.state.user.roles.includes("projectManager") : false;
        },

        isAdmin() {
            return auth.state.user.roles ? auth.state.user.roles.includes("admin") : false;
        },

        isSuperAdmin() {
            return auth.state.user.roles ? auth.state.user.roles.includes("superAdmin") : false;
        },

        isAdminOrHigher() {
            return auth.state.user.roles ? auth.state.user.roles.includes("admin") || auth.state.user.roles.includes("superAdmin") : false;
        },

        isProjectManager() {
            return auth.state.user.roles ? auth.state.user.roles.includes("projectManager") : false;
        },

        isUserAdminOrHigher() {
            return auth.state.user.roles
                ? auth.state.user.roles.includes("collaborator") ||
                      auth.state.user.roles.includes("projectManager") ||
                      auth.state.user.roles.includes("admin") ||
                      auth.state.user.roles.includes("superAdmin")
                : false;
        },

        isGuest() {
            return (auth.state.user.roles && auth.state.user.roles.includes("guest")) || auth.state.me.isGuest ? auth.state.me.isGuest : false;
        },

        isClient() {
            return auth.state.user.roles ? auth.state.user.roles.includes("client") : false;
        },

        isCreator() {
            return auth.state.user.roles ? auth.state.user.roles.includes("creator") : false;
        },

        isOnlyCreator() {
            return auth.state.user.roles ? auth.state.user.roles.length == 1 && auth.state.user.roles[0] == "creator" : false;
        },

        bestRole() {
            let role = "No Role";
            if (auth.state.user.roles) {
                if (auth.state.user.roles.includes("superAdmin")) {
                    role = "Super Admin";
                } else if (auth.state.user.roles.includes("admin")) {
                    role = "Admin";
                } else if (auth.state.user.roles.includes("projectManager")) {
                    role = "Project Manager";
                } else if (auth.state.user.roles.includes("collaborator")) {
                    role = "Collaborator";
                } else if (auth.state.user.roles.includes("viewOnly")) {
                    role = "View Only";
                } else if (auth.state.user.roles.includes("creator")) {
                    role = "Creator";
                }
            }
            return role;
        },

        isSessionLoaded() {
            return auth.state.isSessionLoaded;
        },

        profileCompletion() {
            return auth.state.profileCompletion;
        },

        redirect() {
            return auth.state.redirect;
        },

        accessToken() {
            return auth.state.token;
        },

        dialogVisible(state) {
            return state.dialog.show;
        },

        dialogProps(state) {
            return state.dialog;
        },

        boards() {
            return boards.state.boards;
        },

        selectedBoard() {
            return boards.state.selectedBoard;
        },

        statuses() {
            return statuses.state.statuses;
        },

        filterableStatuses() {
            return statuses.state.filterableStatuses;
        },

        statusesLoading() {
            return statuses.state.statusesLoading;
        },

        colors() {
            return colors.state.colors;
        },

        colorsLoading() {
            return colors.state.colorsLoading;
        },

        company() {
            return company.state.company;
        },

        companyLoading() {
            return company.state.companyLoading;
        },

        companyNotFound() {
            return company.state.companyNotFound;
        },

        pendingNotificationsCount() {
            return notifications.state.pendingNotificationsCount;
        },

        pendingNotificationsCountLoading() {
            return notifications.state.pendingNotificationsCountLoading;
        },

        fb() {
            return fb.state;
        },

        fbLogin() {
            return fb.state.login;
        },

        fbLoading() {
            return fb.state.loading;
        },
    },
});
